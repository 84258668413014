import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../components/Button/Button';
import styles from '../sass/Header.module.scss';

import { ReactComponent as Plus } from '../../../assets/icons/plusBlack.svg';

const NewAction = ({ text, onClick }) => {
  return (
    <Button
      onClick={onClick}
      icon={<Plus />}
      text={text}
      style={styles.newActionContainer}
      styleButton={styles.newActionButton}
    />
  );
};

NewAction.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default NewAction;
