import { batch } from 'react-redux';
import {
  CLEAN_M4,
  SET_M4_COMPETITORS_LIST_SUCCESS,
  SET_M4_DECISION_DRIVERS_SUCCESS,
  SET_M4_DRIVER_OFFER_SUCCESS,
  SET_M4_FAILED,
  SET_M4_REQUEST,
  SET_M4_SEGMENT_DRIVERS_LIST_SUCCESS,
  SET_M4_SEGMENT_LIST_SUCCESS,
  SET_M4_SEGMENT_SUCCESS
} from './types';
import instance from '../../services/axios';
import {
  COMPETITIVE_ANALYSIS_DRIVERS_URL,
  COMPETITOR_DRIVER_OFFER_URL,
  COMPETITOR_OFFER_URL,
  PROJECT_COMPETITORS_LIST_URL,
  SEGMENT_DRIVERS_LIST_URL
} from '../../constants/api';
import { getSegmentsAction } from '../general/actions';
import notify from '../../utils/notify';

export const setM4Request = () => ({ type: SET_M4_REQUEST });
export const setM4Failed = (error) => ({ type: SET_M4_FAILED, error });
export const cleanM4 = () => ({ type: CLEAN_M4 });

export const setDecisionDriversSuccess = (decisionDriverX, decisionDriverY) => ({
  type: SET_M4_DECISION_DRIVERS_SUCCESS, decisionDriverX, decisionDriverY
});

export const setSegmentListSuccess = (data) => ({ type: SET_M4_SEGMENT_LIST_SUCCESS, data });

export const setDriversListSuccess = (data) => ({ type: SET_M4_SEGMENT_DRIVERS_LIST_SUCCESS, data });

export const setSegmentSuccess = (data) => ({ type: SET_M4_SEGMENT_SUCCESS, data });

export const setCompetitorsListSuccess = (data) => ({ type: SET_M4_COMPETITORS_LIST_SUCCESS, data });

export const setDriverOfferSuccess = (data) => ({ type: SET_M4_DRIVER_OFFER_SUCCESS, data });

export const getDriverOfferAction = (driverX, driverY) => {
  return (dispatch) => {
    dispatch(setM4Request());
    instance.get(`${COMPETITOR_OFFER_URL}/${driverX}/${driverY}`)
      .then(({ data: { data } }) => {
        const {
          id, competitor_id: competitorId, our, delivers
        } = data;
        const values = {
          offerId: id || 0,
          competitorId: competitorId || 0,
          our: our || '',
          delivers: delivers || ''
        };
        dispatch(setDriverOfferSuccess(values));
      })
      .catch(({ response: { data } }) => {
        dispatch(setM4Failed(data));
        notify('error', data.message);
      });
  };
};

export const getDecisionDriversAction = (id, segmentId = 0, driverX = '', driverY = '') => {
  return (dispatch, getState) => {
    const { m4: { driverOffer: { offerId } } } = getState();
    dispatch(setM4Request());
    instance.get(
      `${COMPETITIVE_ANALYSIS_DRIVERS_URL}/${id}/${segmentId}${driverX && driverY ? `/${driverX}/${driverY}` : ''}`
    )
      .then(({ data: { data } }) => {
        const [x, y] = data;
        if (x && y) {
          batch(() => {
            dispatch(getDriverOfferAction(x.id, y.id));
            dispatch(setDecisionDriversSuccess(x, y));
          });
        } else {
          if (offerId) {
            dispatch(setDriverOfferSuccess({
              offerId: 0,
              competitorId: 0,
              our: '',
              delivers: ''
            })); 
          }
          dispatch(setDecisionDriversSuccess(x || {}, y || {}));
        }
      })
      .catch(({ response: { data } }) => {
        dispatch(setM4Failed(data));
        notify('error', data.message);
      });
  };
};

export const getSegmentListAction = (id) => {
  return (dispatch) => {
    dispatch(setM4Request());
    getSegmentsAction(id)
      .then(({ data: { data } }) => dispatch(setSegmentListSuccess(data)))
      .catch(({ response: { data } }) => {
        dispatch(setM4Failed(data));
        notify('error', data.message);
      });
  };
};

export const getDriversListAction = (id, segmentId) => {
  return (dispatch) => {
    dispatch(setM4Request());
    instance.get(`${SEGMENT_DRIVERS_LIST_URL}/${id}/${segmentId || ''}`)
      .then(({ data: { data } }) => dispatch(setDriversListSuccess(data)))
      .catch(({ response: { data } }) => {
        dispatch(setM4Failed(data));
        notify('error', data.message);
      });
  };
};

export const getSegmentIdAction = (id, idSegment) => {
  return (dispatch) => {
    batch(() => {
      dispatch(setSegmentSuccess(idSegment));
      dispatch(getDecisionDriversAction(id, idSegment));
      dispatch(getDriversListAction(id, idSegment));
    });
  };
};

export const getCompetitorsAction = (id) => {
  return (dispatch) => {
    dispatch(setM4Request());
    instance.get(`${PROJECT_COMPETITORS_LIST_URL}/${id}`)
      .then(({ data: { data } }) => dispatch(setCompetitorsListSuccess(data)))
      .catch(({ response: { data } }) => {
        dispatch(setM4Failed(data));
        notify('error', data.message);
      });
  };
};

export const createDriverOfferAction = (values) => {
  return (dispatch, getState) => {
    const { m4: { decisionDriverX, decisionDriverY } } = getState();
    const newValues = {
      first_driver_id: decisionDriverX.id,
      second_driver_id: decisionDriverY.id,
      our: values?.our || undefined,
      competitor_id: values?.competitorId || undefined
    };
    dispatch(setM4Request());
    instance.post(COMPETITOR_DRIVER_OFFER_URL, newValues)
      .then(({ data: { data } }) => {
        const driverOffer = {
          offerId: data.id,
          competitorId: data.competitor_id || 0,
          our: data.our,
          delivers: ''
        };
        dispatch(setDriverOfferSuccess(driverOffer));
      })
      .catch(({ response: { data } }) => {
        dispatch(setM4Failed(data));
        notify('error', data.message);
      });
  };
};

export const updateDriverOfferAction = (values) => {
  return (dispatch, getState) => {
    const { m4: { driverOffer: { offerId } } } = getState();
    const updateDriverOffer = {
      competitor_id: values.competitorId || undefined,
      our: values.our || '',
      delivers: values.delivers || ''
    };
    dispatch(setM4Request());
    instance.put(`${COMPETITOR_DRIVER_OFFER_URL}/${offerId}`, updateDriverOffer)
      .then(({ data: { data } }) => {
        const driverOffer = {
          offerId: data.id,
          competitorId: data.competitor_id || 0,
          our: data.our || '',
          delivers: data.delivers || ''
        };
        dispatch(setDriverOfferSuccess(driverOffer));
      })
      .catch(({ response: { data } }) => {
        dispatch(setM4Failed(data));
        notify('error', data.message);
      });
  };
};

export const deleteDriverOfferAction = () => {
  return (dispatch, getState) => {
    const { m4: { driverOffer: { offerId, competitorId, delivers } } } = getState();
    dispatch(setM4Request());
    if (!competitorId) {
      instance.delete(`${COMPETITOR_DRIVER_OFFER_URL}/${offerId}`)
        .then(() => {
          dispatch(setDriverOfferSuccess({
            offerId: 0,
            competitorId: 0,
            our: '',
            delivers: ''
          }));
        })
        .catch(({ response: { data } }) => {
          dispatch(setM4Failed(data));
          notify('error', data.message);
        });
    } else {
      dispatch(updateDriverOfferAction({ our: '', competitorId, delivers }));
    }
  };
};

export const getCompetitorOfferAction = (driverX, driverY, id) => {
  return (dispatch) => {
    dispatch(setM4Request());
    instance.get(`${COMPETITOR_OFFER_URL}/${driverX}/${driverY}/${id}`)
      .then(({ data: { data } }) => {
        if (data.competitor_id) {
          const driverOffer = {
            offerId: data.id,
            competitorId: data.competitor_id,
            our: data.our,
            delivers: data.delivers || ''
          };
          dispatch(setDriverOfferSuccess(driverOffer));
        } else dispatch(updateDriverOfferAction({ competitorId: id }));
      })
      .catch(({ response: { data } }) => {
        dispatch(setM4Failed(data));
        notify('error', data.message);
      });
  };
};
