const adminTrendsHeader = [
  {
    name: 'createDate',
    sortName: 'created_at',
    sortable: true
  },
  {
    name: 'industry',
    sortName: 'industry',
    sortable: true
  },
  {
    name: 'category',
    sortName: 'category',
    sortable: true
  },
  {
    name: 'trend',
    sortName: 'name',
    sortable: true
  },
  {
    name: 'influence',
    sortName: 'influence',
    sortable: true
  },
  {
    name: 'rank',
    sortName: 'rank',
    sortable: true
  },
  {
    name: 'actions',
    sortable: false
  }
];

export default adminTrendsHeader;
