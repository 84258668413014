import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AuthLayout from '../../../layout/AuthLayout/AuthLayout';
import Title from '../../../components/Title/Title';
import Button from '../../../components/Button/Button';
import styles from '../sass/SuccessRegistration.module.scss';
import Check from '../../../assets/icons/check.svg';

const SuccessRegistrationSuccess = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <AuthLayout>
      <img src={Check} className={styles.icon} alt="check-icon" />
      <Title text={t('success')} style={styles.title} />
      <Button text={t('login')} style={styles.button} onClick={onClick} />
    </AuthLayout>
  );
};

SuccessRegistrationSuccess.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default SuccessRegistrationSuccess;
