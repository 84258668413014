import {
  SET_ADMIN_STAKEHOLDERS_SUCCESS,
  SET_ADMIN_STAKEHOLDERS_REQUEST,
  SET_ADMIN_STAKEHOLDERS_FAILED,
  SET_ADMIN_STAKEHOLDERS_SCENARIO_SUCCESS,
  CLEAN_ADMIN_STAKEHOLDERS,
  SET_ADMIN_STAKEHOLDERS_LIST_SUCCESS
} from './types';
import instance from '../../../services/axios';
import { ADMIN_SCENARIOS_URL, STAKEHOLDER_TYPES_URL } from '../../../constants/api';
import notify from '../../../utils/notify';
import { getSearchParams } from '../../../utils/helpers';

export const setAdminStakeholdersSuccess = (data, total) => ({ type: SET_ADMIN_STAKEHOLDERS_SUCCESS, data, total });
export const setAdminStakeholdersRequest = () => ({ type: SET_ADMIN_STAKEHOLDERS_REQUEST });
export const setAdminStakeholdersFailed = (error) => ({ type: SET_ADMIN_STAKEHOLDERS_FAILED, error });
export const setAdminStakeholdersScenarioSuccess = (data) => ({ type: SET_ADMIN_STAKEHOLDERS_SCENARIO_SUCCESS, data });
export const setAdminStakeholdersListSuccess = (data) => ({ type: SET_ADMIN_STAKEHOLDERS_LIST_SUCCESS, data });
export const cleanAdminStakeholders = () => ({ type: CLEAN_ADMIN_STAKEHOLDERS });

export const getStakeholdersAction = (scenarioId, filterParam, setSearchParams) => {
  const getParams = new URLSearchParams({
    ...getSearchParams(filterParam)
  }).toString();
  return (dispatch) => {
    dispatch(setAdminStakeholdersRequest());
    instance.get(`${ADMIN_SCENARIOS_URL}/${scenarioId}/scenario_stakeholders?${getParams}`)
      .then(({ data: { data, meta: { total } } }) => {
        if (setSearchParams) setSearchParams(getParams);
        dispatch(setAdminStakeholdersSuccess(data, total));
      })
      .catch(({ response: { data } }) => {
        dispatch(setAdminStakeholdersFailed(data));
        notify('error', data.message);
      });
  };
};

export const deleteStakeholderAction = (scenarioId, id, params, setSearchParams) => {
  return (dispatch) => {
    dispatch(setAdminStakeholdersRequest());
    instance.delete(`${ADMIN_SCENARIOS_URL}/${scenarioId}/scenario_stakeholders/${id}`)
      .then(() => dispatch(getStakeholdersAction(scenarioId, params, setSearchParams)))
      .catch(({ response: { data } }) => {
        dispatch(setAdminStakeholdersFailed(data));
        notify('error', data.message);
      });
  };
};

export const getScenarioAction = (id) => {
  return (dispatch) => {
    dispatch(setAdminStakeholdersRequest());
    instance.get(`${ADMIN_SCENARIOS_URL}/${id}`)
      .then(({ data: { data } }) => dispatch(setAdminStakeholdersScenarioSuccess(data)))
      .catch(({ response: { data } }) => {
        dispatch(setAdminStakeholdersFailed(data));
        notify('error', data.message);
      });
  };
};

export const getStakeholdersListAction = () => {
  return (dispatch) => {
    dispatch(setAdminStakeholdersRequest());
    instance.get(STAKEHOLDER_TYPES_URL)
      .then(({ data: { data } }) => {
        dispatch(setAdminStakeholdersListSuccess(data));
      })
      .catch(({ response: { data } }) => {
        dispatch(setAdminStakeholdersFailed(data));
        notify('error', data.message);
      });
  };
};
