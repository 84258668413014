import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import rateOptions from '../../../constants/rateOptions';
import SelectInput from '../../../components/SelectInput/SelectInput';
import styles from '../sass/Stakeholders.module.scss';

const StakeholdersRank = ({ onChange, value }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.rankContainer}>
      <span>{`${t('rank')}:`}</span>
      <SelectInput
        onChange={(rank) => onChange(+rank)}
        styleContainer={styles.selectContainer}
        value={value}
        values={rateOptions}
        checkId={false}
      />
    </div>
  );
};

StakeholdersRank.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default StakeholdersRank;
