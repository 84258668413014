import { SET_SUCCESS_REGISTRATION_REQUEST, SET_SUCCESS_REGISTRATION_FAILED, SET_SUCCESS_REGISTRATION_SUCCESS } from './types';

const initialState = {
  status: '',
  loading: false,
  error: null,
};

export default function successRegistration(state = initialState, action) {
  switch (action.type) {
    case SET_SUCCESS_REGISTRATION_SUCCESS:
      return {
        ...state,
        status: action.status,
        loading: false,
        error: null
      };
    case SET_SUCCESS_REGISTRATION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_SUCCESS_REGISTRATION_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
