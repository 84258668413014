import instance from '../../services/axios';
import notify from '../../utils/notify';
import { assignFile } from '../general/actions';
import {
  SET_PROJECTS_SUCCESS,
  SET_PROJECTS_REQUEST,
  SET_PROJECTS_FAILED,
  SET_PROJECTS_PROJECT,
  SET_PROJECTS_NEW_PROJECT_SUCCESS,
  SET_PROJECTS_EDIT_PROJECT,
  SET_PROJECTS_EDIT_PROJECT_SUCCESS,
  SET_PROJECTS_DELETE_SUCCESS,
  SET_PROJECTS_DUPLICATE_SUCCESS,
  CLEAN_PROJECTS,
  SET_PROJECTS_SCENARIOS_SUCCESS
} from './types';
import { PROJECTS_URL, SCENARIOS_URL } from '../../constants/api';

export const setProjectsSuccess = (data, nextLink) => ({ type: SET_PROJECTS_SUCCESS, data, nextLink });
export const setProjectsRequest = () => ({ type: SET_PROJECTS_REQUEST });
export const setProjectsFailed = (error) => ({ type: SET_PROJECTS_FAILED, error });
export const setProjectsProject = (field, value) => ({ type: SET_PROJECTS_PROJECT, field, value });
export const setProjectsNewProjectSuccess = (data) => ({ type: SET_PROJECTS_NEW_PROJECT_SUCCESS, data });
export const setProjectsEditProject = (data) => ({ type: SET_PROJECTS_EDIT_PROJECT, data });
export const setProjectsEditProjectSuccess = (data) => ({ type: SET_PROJECTS_EDIT_PROJECT_SUCCESS, data });
export const setProjectsDeleteSuccess = (data) => ({ type: SET_PROJECTS_DELETE_SUCCESS, data });
export const setProjectsDuplicateSuccess = (data) => ({ type: SET_PROJECTS_DUPLICATE_SUCCESS, data });
export const setProjectsScenariosSuccess = (data) => ({ type: SET_PROJECTS_SCENARIOS_SUCCESS, data });

export const cleanProjects = () => ({ type: CLEAN_PROJECTS });

export const getProjectsAction = (userId, setFetchFalse) => {
  return (dispatch, getState) => {
    const { projects: { projectsList, nextLink } } = getState();
    dispatch(setProjectsRequest());
    instance.get(nextLink || `${PROJECTS_URL}${userId ? `?user_id=${userId}` : ''}`)
      .then(({ data: { data, links: { next } } }) => {
        dispatch(setProjectsSuccess([...projectsList, ...data], next));
        if (setFetchFalse) setFetchFalse();
      })
      .catch(({ response: { data: error } }) => {
        dispatch(setProjectsFailed(error));
        notify('error', error.message);
      });
  };
};

export const getProjectsNewProjectAction = (userId, onClose) => {
  return (dispatch, getState) => {
    const {
      projects: {
        project: {
          name, description, industry: { id: industryId }, avatar, currency, scenario
        }
      },
      general: { currencyList }
    } = getState();
    const currencyId = currencyList.find(({ symbol }) => symbol === currency).id;
    const values = {
      name,
      status: 'active',
      description: description || undefined,
      industry_id: industryId,
      user_id: userId || undefined,
      currency_id: currencyId,
      scenario_id: scenario || undefined
    };
    dispatch(setProjectsRequest());
    instance.post(PROJECTS_URL, values)
      .then(({ data: { data } }) => {
        if (avatar) {
          assignFile(avatar.id, 'Project', data.id, 'avatar')
            .then(() => {
              dispatch(setProjectsNewProjectSuccess({ ...data, avatar }));
              onClose();
            })
            .catch(({ response: { data: error } }) => {
              dispatch(setProjectsFailed(error));
              notify('error', error.message);
            });
        } else {
          dispatch(setProjectsNewProjectSuccess(data));
          onClose();
        }
      })
      .catch(({ response: { data: error } }) => {
        dispatch(setProjectsFailed(error));
        notify('error', error.message);
      });
  };
};

export const getProjectsEditProjectAction = (onClose) => {
  return (dispatch, getState) => {
    const {
      projects: { project, projectsList },
      general: { currencyList }
    } = getState();
    const {
      name, description, industry: { id: industryId }, id, currency
    } = project;
    const currencyId = currencyList.find(({ symbol }) => symbol === currency).id;
    const values = {
      name,
      status: 'active',
      description: description || undefined,
      industry_id: industryId,
      currency_id: currencyId
    };
    dispatch(setProjectsRequest());
    instance.put(`${PROJECTS_URL}/${id}`, values)
      .then(({ data: { data } }) => {
        const index = projectsList.findIndex(({ id: idProject }) => idProject === id);
        const updateProjectList = [...projectsList];
        updateProjectList[index] = data;
        dispatch(setProjectsEditProjectSuccess(updateProjectList));
        onClose();
      })
      .catch(({ response: { data: error } }) => {
        dispatch(setProjectsFailed(error));
        notify('error', error.message);
      });
  };
};

export const deleteProjectAction = () => {
  return (dispatch, getState) => {
    const { projects: { projectsList, project: { id } } } = getState();
    dispatch(setProjectsRequest());
    instance.delete(`${PROJECTS_URL}/${id}`)
      .then(() => {
        const data = projectsList.filter(({ id: idProject }) => idProject !== id);
        dispatch(setProjectsDeleteSuccess(data));
      })
      .catch(({ response: { data: error } }) => {
        setProjectsFailed(error);
        notify('error', error.message);
      });
  };
};

export const duplicateProjectAction = (onClose) => {
  return (dispatch, getState) => {
    const {
      projects: {
        project: {
          id, name, description, industry, scenario
        } 
      } 
    } = getState();
    const body = {
      name,
      description,
      industry_id: industry?.id,
      scenario_id: scenario
    };
    dispatch(setProjectsRequest());
    instance.post(`${PROJECTS_URL}/${id}/duplicate`, body)
      .then(({ data: { data } }) => {
        dispatch(setProjectsDuplicateSuccess(data));
        onClose();
      })
      .catch(({ response: { data: error } }) => {
        dispatch(setProjectsFailed(error));
        notify('error', error.message);
      });
  };
};

export const getScenariosAction = () => {
  return (dispatch) => {
    instance.get(`${SCENARIOS_URL}/available`)
      .then(({ data: { data } }) => {
        dispatch(setProjectsScenariosSuccess(data));
      })
      .catch(({ response: { data: error } }) => {
        notify('error', error.message);
      });
  };
};
