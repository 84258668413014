import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button/Button';
import M5OpportunityHeader from './M5OpportunityHeader';
import M5OfferingTitle from './M5OfferingTitle';
import M5Offering from './M5Offering';
import styles from '../sass/M5.module.scss';

import { ReactComponent as Plus } from '../../../assets/icons/plusBlue.svg';

const M5Opportunity = ({
  id,
  name,
  value,
  offerings,
  onUpdateOpportunity,
  onUpdateOffering,
  onDeleteOffering,
  style,
  onDeleteOpportunity,
  title: titleHeader,
  meaningsList,
  meaningId
}) => {
  const { t } = useTranslation();
  const [newOffering, setNewOffering] = useState(false);

  const offeringsList = useMemo(() => {
    return newOffering ? [...offerings, {
      id: 0, title: '', value: 0
    }] : offerings;
  }, [offerings, newOffering]);

  return (
    <div className={styles.opportunityContainer}>
      <M5OpportunityHeader
        id={id}
        name={name}
        value={value}
        onUpdateOpportunity={onUpdateOpportunity}
        onDeleteOpportunity={onDeleteOpportunity}
        style={style}
        title={titleHeader}
        meaningsList={meaningsList}
        meaningId={meaningId}
      />
      <M5OfferingTitle />
      {offeringsList.map(({
        id: offeringId, title, value: offeringValue, message_for_offering: messageForOffering
      }) => (
        <M5Offering
          key={offeringId}
          id={offeringId}
          title={title}
          value={offeringValue}
          messageForOffering={messageForOffering || ''}
          onUpdateOffering={(values) => onUpdateOffering(values, () => setNewOffering(false))}
          onDeleteOffering={onDeleteOffering}
        />
      ))}
      <Button
        text={t('createNewOffering')}
        onClick={() => setNewOffering(true)}
        icon={<Plus />}
        style={styles.buttonContainer}
        styleButton={styles.button}
        disabled={newOffering || offeringsList.length > 9}
      />
    </div>
  );
};

M5Opportunity.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  offerings: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    message_for_offering: PropTypes.string,
    value: PropTypes.number
  })),
  onUpdateOpportunity: PropTypes.func.isRequired,
  onUpdateOffering: PropTypes.func.isRequired,
  onDeleteOffering: PropTypes.func.isRequired,
  style: PropTypes.shape({
    color: PropTypes.string,
    fontWeight: PropTypes.string
  }).isRequired,
  onDeleteOpportunity: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  meaningsList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  meaningId: PropTypes.number.isRequired
};

M5Opportunity.defaultProps = {
  offerings: []
};

export default M5Opportunity;
