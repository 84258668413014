import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../Button/Button';
import styles from '../sass/ModalConfirmDelete.module.scss';

const DeleteButtons = ({ onClickNo, onClickYes }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.competitorModalEditButtonsContainer}>
      <Button
        onClick={onClickNo}
        text={t('no')}
        style={styles.editButtonContainer}
        styleButton={styles.deleteButton}
      />
      <Button
        onClick={onClickYes}
        text={t('yes')}
        style={styles.editButtonContainer}
        styleButton={styles.editButton}
      />
    </div>
  );
};

DeleteButtons.propTypes = {
  onClickNo: PropTypes.func.isRequired,
  onClickYes: PropTypes.func.isRequired,
};

export default DeleteButtons;
