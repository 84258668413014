export const SET_STAKEHOLDERS_WORK_SHEET_SUCCESS = 'SET_STAKEHOLDERS_WORK_SHEET_SUCCESS';
export const SET_STAKEHOLDERS_REQUEST = 'SET_STAKEHOLDERS_REQUEST';
export const SET_STAKEHOLDERS_FAILED = 'SET_STAKEHOLDERS_FAILED';
export const CLEAN_STAKEHOLDERS = 'CLEAN_STAKEHOLDERS';
export const SET_STAKEHOLDERS_AVATAR_SUCCESS = 'SET_STAKEHOLDERS_AVATAR_SUCCESS';
export const SET_STAKEHOLDERS_WORK_SHEET_NAME_SUCCESS = 'SET_STAKEHOLDERS_WORK_SHEET_NAME_SUCCESS';
export const SET_STAKEHOLDERS_WORK_SHEET_MEANING_SUCCESS = 'SET_STAKEHOLDERS_WORK_SHEET_MEANING_SUCCESS';
export const SET_STAKEHOLDERS_WORK_SHEET_LINKS_SUCCESS = 'SET_STAKEHOLDERS_WORK_SHEET_LINKS_SUCCESS';
export const SET_STAKEHOLDERS_WORK_SHEET_DRIVERS_SUCCESS = 'SET_STAKEHOLDERS_WORK_SHEET_DRIVERS_SUCCESS';
export const SET_STAKEHOLDERS_WORK_SHEET_TRIGGERS_SUCCESS = 'SET_STAKEHOLDERS_WORK_SHEET_TRIGGERS_SUCCESS';
export const SET_STAKEHOLDERS_LIST_SUCCESS = 'SET_STAKEHOLDERS_LIST_SUCCESS';
export const SET_STAKEHOLDERS_PROFILES_SUCCESS = 'SET_STAKEHOLDERS_PROFILES_SUCCESS';
export const SET_STAKEHOLDERS_RANK_SUCCESS = 'SET_STAKEHOLDERS_RANK_SUCCESS';
