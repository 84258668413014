import React from 'react';
import PropTypes from 'prop-types';
import CompetitorsColumnTitle from './CompetitorsColumnTitle';
import CompetitorsColumnItem from './CompetitorsColumnItem';
import styles from '../sass/MarketDrivers.module.scss';

const CompetitorsColumn = ({
  data,
  title,
  onUpdate,
  isCompetitor,
  onSelect,
  onChangeOwnOrganization,
  offering
}) => {
  return (
    <div className={styles.competitorsColumnContainer}>
      {!isCompetitor && <div className={styles.verticalLine} />}
      <div className={styles.line} />
      <CompetitorsColumnTitle
        title={title}
        offering={offering}
        isCompetitor={isCompetitor}
        onSelect={onSelect}
        onChangeOwnOrganization={onChangeOwnOrganization}
      />

      {data.map(({ driver_id: driverId, value, id }) => (
        <CompetitorsColumnItem
          key={driverId}
          value={value}
          onUpdate={(rate) => onUpdate({ driverId, id, rate })}
        />
      ))}
    </div>
  );
};

CompetitorsColumn.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    value: PropTypes.number,
    driver_id: PropTypes.number
  })),
  title: PropTypes.string,
  offering: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  isCompetitor: PropTypes.bool,
  onChangeOwnOrganization: PropTypes.func
};

CompetitorsColumn.defaultProps = {
  data: [],
  title: '',
  offering: '',
  isCompetitor: false,
  onSelect: undefined,
  onChangeOwnOrganization: undefined
};

export default CompetitorsColumn;
