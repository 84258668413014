import React, {
  useEffect, useMemo, useState, useCallback, useLayoutEffect
} from 'react';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useOnBlur from '../../hooks/useOnBlur';
import {
  cleanO8,
  getMissionStatementAction,
  updateMissionStatementAction,
  deleteMissionStatementAction
} from '../../store/o8/actions';
import { deleteSpacesInString, getBreadcrumbs, getSwitchPages } from '../../utils/helpers';
import Page from '../../layout/Page/Page';
import styles from './sass/O8.module.scss';

const O8 = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    o8: { description, missionStatementId, loading },
    project: { name: projectName }
  } = useSelector(({ o8, project }) => ({ o8, project }), shallowEqual);

  const [missionStatement, setMissionStatement] = useState(description);

  const breadcrumbs = useMemo(() => (
    getBreadcrumbs(projectName, `/${id}/dashboard`, 'Mission')
  ), [projectName, id]);

  const switchPages = useMemo(() => (
    getSwitchPages(`/${id}/o5`, `/${id}/dashboard`)
  ), [id]);

  useEffect(() => {
    dispatch(getMissionStatementAction(id));
    return () => {
      dispatch(cleanO8());
    };
  }, []);

  useLayoutEffect(() => {
    setMissionStatement(description);
  }, [description]);

  const handleChangeMissionStatement = useCallback((e) => {
    const text = deleteSpacesInString(e.target.value);
    setMissionStatement(text);
  }, []);

  const handleUpdateMissionStatement = useCallback(() => {
    dispatch(updateMissionStatementAction(id, missionStatementId, missionStatement));
  }, [missionStatementId, missionStatement]);

  const handleDeleteMissionStatement = useCallback(() => {
    dispatch(deleteMissionStatementAction(missionStatementId));
  }, [missionStatementId]);

  const handleBlurMissionStatement = useOnBlur(
    description,
    missionStatement,
    handleUpdateMissionStatement,
    handleDeleteMissionStatement
  );

  return (
    <Page
      pageContainer={styles.container}
      breadcrumbs={breadcrumbs}
      switchPages={switchPages}
      loader={loading}
    >
      <div className={styles.contentContainer}>
        <span className={styles.title}>{t('missionStatement')}</span>
        <textarea
          value={missionStatement}
          onChange={handleChangeMissionStatement}
          onBlur={handleBlurMissionStatement}
          placeholder={t('missionStatementPlaceholder')}
        />
      </div>
    </Page>
  );
};

export default O8;
