import React from 'react';
import PropTypes from 'prop-types';
import Title from '../../Title/Title';
import TopMarketDriversItem from './TopMarketDriversItem';
import styles from '../sass/MarketDrivers.module.scss';

const TopMarketDrivers = ({
  textHeader,
  topMarketDrivers,
  onUpdateTopMarketDriver,
  tipTopMarket,
  onDeleteTopMarketDriver,
  placeholderTopMarketDrivers,
  meaningsList
}) => {
  return (
    <div className={styles.topMarketContainer}>
      <div className={styles.titleContainer}>
        <Title
          style={styles.title}
          text={textHeader}
        />
        <div className={styles.tipTextContainer}>
          {tipTopMarket}
        </div>
      </div>

      {topMarketDrivers.map(({
        id, name, style, value, meaningId
      }) => (
        <TopMarketDriversItem
          key={id}
          id={id}
          name={name}
          style={style}
          value={value}
          meaningId={meaningId}
          onUpdateTopMarketDriver={onUpdateTopMarketDriver}
          onDeleteTopMarketDriver={onDeleteTopMarketDriver}
          placeholderTopMarketDrivers={placeholderTopMarketDrivers}
          meaningsList={meaningsList}
        />
      ))}
    </div>
  );
};

TopMarketDrivers.propTypes = {
  textHeader: PropTypes.string.isRequired,
  topMarketDrivers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    style: PropTypes.shape({
      color: PropTypes.string,
      fontWeight: PropTypes.string,
    }),
    name: PropTypes.string,
    value: PropTypes.number,
    meaningId: PropTypes.number
  })),
  onUpdateTopMarketDriver: PropTypes.func.isRequired,
  tipTopMarket: PropTypes.node.isRequired,
  onDeleteTopMarketDriver: PropTypes.func.isRequired,
  placeholderTopMarketDrivers: PropTypes.string.isRequired,
  meaningsList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired
};

TopMarketDrivers.defaultProps = {
  topMarketDrivers: []
};

export default TopMarketDrivers;
