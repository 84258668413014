import instance from '../../services/axios';
import {
  CLEAN_M2,
  SET_M2_CUSTOMER_SEGMENTS_SUCCESS,
  SET_M2_FAILED,
  SET_M2_REQUEST
} from './types';
import {
  DRIVERS_URL, MEANING_URL,
  WORKSHEET_CALCULATE_URL,
  WORKSHEET_PROFILES_URL,
  WORKSHEET_SEGMENTS_URL
} from '../../constants/api';
import notify from '../../utils/notify';

export const setM2Request = () => ({ type: SET_M2_REQUEST });
export const setM2Failed = (error) => ({ type: SET_M2_FAILED, error });

export const setCustomerSegmentsSuccess = (data) => ({ type: SET_M2_CUSTOMER_SEGMENTS_SUCCESS, data });

export const cleanM2 = () => ({ type: CLEAN_M2 });

export const getCustomerSegmentsAction = (id) => {
  return (dispatch) => {
    dispatch(setM2Request());
    instance.get(`${WORKSHEET_SEGMENTS_URL}/${id}`)
      .then(({ data: { data } }) => {
        const customerSegments = [...data];
        const detailedProfiles = [];
        data.forEach((item) => {
          detailedProfiles.push(instance.get(`${WORKSHEET_PROFILES_URL}/${id}/${item.id}`)
            .then(({ data: detailedProfilesData }) => detailedProfilesData));
        });
        Promise.all(detailedProfiles)
          .then((response) => {
            response.forEach((item, index) => {
              customerSegments[index].detailedProfiles = item;
            });
          })
          .then(() => dispatch(setCustomerSegmentsSuccess(customerSegments)))
          .catch(({ response: { data: error } }) => {
            dispatch(setM2Failed(error));
            notify('error', error.message);
          });
      })
      .catch(({ response: { data } }) => {
        dispatch(setM2Failed(data));
        notify('error', data.message);
      });
  };
};

export const getSegmentAction = (idProject, idSegment) => {
  return (dispatch, getState) => {
    const { m2: { customerSegmentsList } } = getState();
    instance.get(`${WORKSHEET_SEGMENTS_URL}/${idProject}/${idSegment}`)
      .then(({ data: { data } }) => {
        const newCustomerSegments = customerSegmentsList
          .map((item) => (item.id === idSegment
            ? { ...data, detailedProfiles: item.detailedProfiles }
            : item));
        dispatch(setCustomerSegmentsSuccess(newCustomerSegments));
      })
      .catch(({ response: { data } }) => {
        dispatch(setM2Failed(data));
        notify('error', data.message);
      });
  };
};

export const updateMeaningAction = (meaningItem, idProject, idSegment) => {
  return (dispatch) => {
    const {
      id, name, value, worksheet_id: idWorkSheet
    } = meaningItem;
    dispatch(setM2Request());
    instance.put(`${MEANING_URL}/${id}`, {
      meaning_id: Number.isNaN(+name) ? undefined : +name,
      worksheet_id: idWorkSheet,
      value: +value,
    }).then(() => dispatch(getSegmentAction(idProject, idSegment)))
      .catch(({ response: { data } }) => {
        dispatch(setM2Failed(data));
        notify('error', data.message);
      });
  };
};

export const deleteDriverAction = (id, idProject, idSegment) => {
  return (dispatch) => {
    dispatch(setM2Request());
    instance.delete(`${DRIVERS_URL}/${id}`)
      .then(() => dispatch(getSegmentAction(idProject, idSegment)))
      .catch(({ response: { data } }) => {
        dispatch(setM2Failed(data));
        notify('error', data.message);
      });
  };
};

export const updateDriverAction = (driverItem, idProject, idSegment) => {
  return (dispatch) => {
    const {
      name, value, id, worksheet_id: idWorkSheet, type
    } = driverItem;
    dispatch(setM2Request());
    instance.put(`${DRIVERS_URL}/${id}`, {
      name,
      value: +value,
      type,
      worksheet_id: idWorkSheet
    })
      .then(() => dispatch(getSegmentAction(idProject, idSegment)))
      .catch(({ response: { data } }) => {
        dispatch(setM2Failed(data));
        notify('error', data.message);
      });
  };
};

export const loadMoreDetailedProfiles = (nextLink, idSegment) => {
  return (dispatch, getState) => {
    const { m2: { customerSegmentsList } } = getState();
    dispatch(setM2Request());
    instance.get(nextLink)
      .then(({ data: { data, links } }) => {
        const newCustomerSegments = customerSegmentsList
          .map((item) => (item.id === idSegment
            ? {
              ...item,
              detailedProfiles: {
                data: [...item.detailedProfiles.data, ...data],
                links
              }
            }
            : item));
        dispatch(setCustomerSegmentsSuccess(newCustomerSegments));
      })
      .catch(({ response: { data } }) => {
        dispatch(setM2Failed(data));
        notify('error', data.message);
      });
  };
};

export const availableAction = (availableItem, idProject, idSegment) => {
  return (dispatch, getState) => {
    const { m2: { customerSegmentsList } } = getState();
    dispatch(setM2Request());
    instance.put(`${WORKSHEET_CALCULATE_URL}/${idProject}/${idSegment}`, availableItem)
      .then(({ data: { data } }) => {
        const newCustomerSegments = customerSegmentsList
          .map((item) => (item.id === idSegment
            ? {
              ...item,
              calculate: data
            }
            : item));
        dispatch(setCustomerSegmentsSuccess(newCustomerSegments));
      })
      .catch(({ response: { data } }) => {
        dispatch(setM2Failed(data));
        notify('error', data.message);
      });
  };
};
