export const GET_LOGIN_SUCCESS = 'GET_LOGIN_SUCCESS';
export const GET_LOGIN_REQUEST = 'GET_LOGIN_REQUEST';
export const GET_LOGIN_FAILED = 'GET_LOGIN_FAILED';
export const GET_FORGOT_PASSWORD_REQUEST = 'GET_FORGOT_PASSWORD_REQUEST';
export const GET_FORGOT_PASSWORD_SUCCESS = 'GET_FORGOT_PASSWORD_SUCCESS';
export const GET_FORGOT_PASSWORD_FAILED = 'GET_FORGOT_PASSWORD_FAILED';
export const GET_RESET_PASSWORD_REQUEST = 'GET_RESET_PASSWORD_REQUEST';
export const GET_RESET_PASSWORD_SUCCESS = 'GET_RESET_PASSWORD_SUCCESS';
export const GET_RESET_PASSWORD_FAILED = 'GET_RESET_PASSWORD_FAILED';
export const REFRESH_TOKENS = 'REFRESH_TOKENS';
export const CLEAR_TOKENS = 'CLEAR_TOKENS';
export const SET_TOKENS = 'SET_TOKENS';
export const CLEAN_STORE = 'CLEAN_STORE';
export const CLEAN_AUTH = 'CLEAN_AUTH';
