import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { batch, useDispatch, useSelector } from 'react-redux';
import { cleanAdminTrends, deleteTrendAction, getTrendsAction } from '../../../store/admin/adminTrends/actions';
import { getIndustriesListAction, getTrendCategoryAction } from '../../../store/general/actions';
import { PAGE_SIZE, TREND } from '../../../constants/general';
import AdminPage from '../../../layout/admin/AdminPage/AdminPage';
import Title from '../../../components/Title/Title';
import ModalDelete from '../../../components/ModalDelete/ModalDelete';
import Pagination from '../../../components/Pagination/Pagination';
import Button from '../../../components/Button/Button';
import AdminTrendFilter from './components/AdminTrendFilter';
import AdminTrendTrends from './components/AdminTrendTrends';
import styles from './sass/AdminTrends.module.scss';

import { ReactComponent as PlusIcon } from '../../../assets/icons/plusBlack.svg';

const AdminTrends = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [idTrend, setIdTrend] = useState(null);

  const [filterParam, setFilterParam] = useState({
    name: searchParams.get('name') || '',
    category: +searchParams.get('category') || null,
    industry: +searchParams.get('industry') || null,
    start: searchParams.get('start') || null,
    end: searchParams.get('end') || null,
    page: +searchParams.get('page') || 1,
    sortOrder: searchParams.get('sortOrder') || 'DESC',
    sortBy: searchParams.get('sortBy') || 'created_at',
  });

  const {
    adminTrends: {
      loading, trends, totalCount
    },
    general: { categoryList, industriesList }
  } = useSelector(({ adminTrends, general }) => ({ adminTrends, general }));

  useEffect(() => {
    batch(() => {
      dispatch(getTrendCategoryAction());
      dispatch(getIndustriesListAction());
      dispatch(getTrendsAction(filterParam));
    });
    return () => {
      dispatch(cleanAdminTrends());
    };
  }, []);

  const handlePagination = useCallback((page) => {
    setFilterParam({ ...filterParam, page });
    dispatch(getTrendsAction({ ...filterParam, page }, setSearchParams));
  }, [filterParam]);

  const handleSearchInput = useCallback((event) => {
    setFilterParam((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  }, []);

  const handleSearchCategoryIndustry = useCallback((value, field) => {
    setFilterParam((prevState) => ({ ...prevState, [field]: value === prevState[field] ? null : value }));
  }, []);

  const handleSearchDate = useCallback((start, end) => {
    setFilterParam((prevState) => ({ ...prevState, start, end }));
  }, []);

  const handleFilter = useCallback(() => {
    setFilterParam({ ...filterParam, end: filterParam.end || filterParam.start, page: 1 });
    dispatch(getTrendsAction({ ...filterParam, end: filterParam.end || filterParam.start, page: 1 }, setSearchParams));
  }, [filterParam]);

  const handleDeleteTrend = useCallback(() => {
    if (trends.length === 1 && filterParam.page > 1) {
      setFilterParam({ ...filterParam, page: filterParam.page - 1 });
      dispatch(deleteTrendAction(idTrend, { ...filterParam, page: filterParam.page - 1 }, setSearchParams));
    } else {
      dispatch(deleteTrendAction(idTrend, filterParam));
    }
    setIdTrend(null);
  }, [filterParam, trends, idTrend]);

  const handleSort = useCallback((sortBy, sortOrder) => {
    setFilterParam({ ...filterParam, sortBy, sortOrder });
    dispatch(getTrendsAction({ ...filterParam, sortBy, sortOrder }, setSearchParams));
  }, [filterParam]);

  return (
    <AdminPage pageContainer={styles.container} loader={loading} activeTab={TREND}>
      <div className={styles.content}>
        <div className={styles.logo} />
        <Title text={t('administration')} style={styles.title} />
        <div className={styles.subtitle}>
          <Title text={t('predefinedTrends')} Type="h3" style={styles.subtitleText} />
          <Button
            text={t('addTrends')}
            icon={<PlusIcon />}
            style={styles.buttonContainer}
            styleButton={styles.button}
            onClick={() => navigate('create')}
          />
        </div>
        <AdminTrendFilter
          trend={filterParam.name}
          category={filterParam.category}
          industry={filterParam.industry}
          start={filterParam.start}
          end={filterParam.end}
          categoryList={categoryList}
          industriesList={industriesList}
          onChange={handleSearchInput}
          onChangeCategoryIndustry={handleSearchCategoryIndustry}
          onChangeDate={handleSearchDate}
          onClick={handleFilter}
        />
        <AdminTrendTrends
          trends={trends}
          onDelete={setIdTrend}
          sortBy={filterParam.sortBy}
          sortOrder={filterParam.sortOrder}
          industriesList={industriesList}
          onSort={handleSort}
        />
        <Pagination
          currentPage={filterParam.page}
          totalCount={totalCount}
          pageSize={PAGE_SIZE}
          onPageChange={(page) => handlePagination(page)}
        />
      </div>

      {!!idTrend && (
        <ModalDelete
          onDelete={handleDeleteTrend}
          onClose={() => setIdTrend(null)}
          text={t('trend')}
        />
      )}
    </AdminPage>
  );
};

export default AdminTrends;
