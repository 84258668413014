import React from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/Dashboard.module.scss';

import { ReactComponent as CloseIcon } from '../../../assets/icons/closeWhiteIcon.svg';

const DashboardGuide = ({ onGuideClose, text }) => {
  return (
    <div className={styles.guideContainer}>
      <div className={styles.guideContent}>
        {text}
        <div className={styles.guideTriangle} />
        <button className={styles.closeGuide} onClick={onGuideClose}>
          <CloseIcon />
        </button>
      </div>
    </div>
  );
};

DashboardGuide.propTypes = {
  onGuideClose: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired
};

export default DashboardGuide;
