import React from 'react';
import { useTranslation } from 'react-i18next';
import Tip from '../../../components/Tip/Tip';

const M3TipTopMarket = () => {
  const { t } = useTranslation();
  return (
    <Tip>
      <p>{t('tipM3TopMarket1')}</p>
      <p>
        {t('tipM3TopMarket2')}
      </p>
    </Tip>
  );
};

export default M3TipTopMarket;
