import React from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/Dashboard.module.scss';

import SupplyChainRowItem from './SupplyChainRowItem';

const SupplyChainRow = ({
  data, id, onChangeVisible
}) => {
  return (
    <div className={styles.row}>
      {data.map(({
        title,
        isEmpty,
        style,
        visible,
        disabled,
        link,
        id: itemId
      }) => (
        <SupplyChainRowItem
          key={title}
          title={title}
          isEmpty={isEmpty}
          style={style}
          visible={visible}
          disabled={disabled}
          link={link}
          id={id}
          itemId={itemId}
          onChangeVisible={onChangeVisible}
        />
      ))}
    </div>
  );
};

SupplyChainRow.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
    name: PropTypes.string,
    style: PropTypes.shape({
      backgroundColor: PropTypes.string,
      borderRadius: PropTypes.string,
      letterSpacing: PropTypes.string,
    }),
    disabled: PropTypes.bool,
    id: PropTypes.number,
    visible: PropTypes.bool,
  })).isRequired,
  id: PropTypes.string.isRequired,
  onChangeVisible: PropTypes.func.isRequired,
};

export default SupplyChainRow;
