import React, {
  useEffect, useState, useCallback
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
  getUsersAction,
  deleteUserAction,
  cleanAdminUsers
} from '../../../store/admin/adminUsers/actions';
import { getIndustriesListAction, setUserIdForSwitchAction } from '../../../store/general/actions';
import { PAGE_SIZE, ROLE, USER } from '../../../constants/general';
import accessLevel from '../../../constants/accessLevel';
import Pagination from '../../../components/Pagination/Pagination';
import ModalDelete from '../../../components/ModalDelete/ModalDelete';
import AdminPage from '../../../layout/admin/AdminPage/AdminPage';
import Title from '../../../components/Title/Title';
import Button from '../../../components/Button/Button';
import AdminUserFilter from './components/AdminUserFilter';
import AdminUserUsers from './components/AdminUserUsers';
import styles from './sass/AdminUsers.module.scss';

import { ReactComponent as PlusIcon } from '../../../assets/icons/plusBlack.svg';

const AdminUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useState(null);

  const {
    adminUsers: {
      users, loading, totalCount
    },
    general: { industriesList }
  } = useSelector(({ adminUsers, general }) => ({ adminUsers, general }));

  const [filterParam, setFilterParam] = useState({
    name: searchParams.get('name') || '',
    role: searchParams.get('role') || '',
    industry: +searchParams.get('industry') || null,
    project: searchParams.get('project') || '',
    page: Number(searchParams.get('page')) || 1,
    sortOrder: searchParams.get('sortOrder') || 'DESC',
    sortBy: searchParams.get('sortBy') || 'name',
  });

  useEffect(() => {
    batch(() => {
      dispatch(getIndustriesListAction());
      dispatch(getUsersAction(filterParam));
    });
    return () => {
      dispatch(cleanAdminUsers());
    };
  }, []);

  const handleSearchInput = useCallback((event) => {
    setFilterParam((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  }, []);

  const handleFilter = useCallback(() => {
    setFilterParam({ ...filterParam, page: 1 });
    dispatch(getUsersAction({ ...filterParam, page: 1 }, setSearchParams));
  }, [filterParam]);

  const handleSearchRoleIndustry = useCallback((field, value) => {
    if (field === ROLE) {
      const { name } = accessLevel.find(({ name: role }) => role === value);
      setFilterParam((prevState) => ({ ...prevState, [field]: filterParam.role === name ? '' : name }));
    } else {
      setFilterParam((prevState) => ({
        ...prevState,
        [field]: filterParam.industry === value ? null : value
      }));
    }
  }, [accessLevel, filterParam]);

  const handleDeleteUser = useCallback(() => {
    if (users.length === 1 && filterParam.page > 1) {
      setFilterParam({ ...filterParam, page: filterParam.page - 1 });
      dispatch(deleteUserAction(user.id, { ...filterParam, page: filterParam.page - 1 }, setSearchParams));
    } else {
      dispatch(deleteUserAction(user.id, filterParam));
    }
    setUser(null);
  }, [filterParam, users, user]);

  const handlePagination = useCallback((page) => {
    setFilterParam({ ...filterParam, page });
    dispatch(getUsersAction({ ...filterParam, page }, setSearchParams));
  }, [filterParam]);

  const handleSwitchUser = useCallback((id) => {
    dispatch(setUserIdForSwitchAction(id, navigate));
  }, []);

  const handleSort = useCallback((sortBy, sortOrder) => {
    setFilterParam({ ...filterParam, sortBy, sortOrder });
    dispatch(getUsersAction({ ...filterParam, sortBy, sortOrder }, setSearchParams));
  }, [filterParam]);

  return (
    <AdminPage pageContainer={styles.container} loader={loading} activeTab={USER}>
      <div className={styles.logo} />
      <Title text={t('administration')} style={styles.title} />
      <div className={styles.subtitle}>
        <Title text={t('users')} Type="h3" style={styles.subtitleText} />
        <Button
          text={t('addUser')}
          icon={<PlusIcon />}
          style={styles.buttonContainer}
          styleButton={styles.button}
          onClick={() => navigate('user')}
        />
      </div>
      <AdminUserFilter
        name={filterParam.name}
        role={filterParam.role}
        industry={filterParam.industry}
        project={filterParam.project}
        industriesList={industriesList}
        onChange={handleSearchInput}
        onChangeRoleIndustry={handleSearchRoleIndustry}
        onClick={handleFilter}
      />
      <AdminUserUsers
        users={users}
        onDelete={setUser}
        onSwitchUser={handleSwitchUser}
        sortBy={filterParam.sortBy}
        sortOrder={filterParam.sortOrder}
        onSort={handleSort}
      />
      <Pagination
        currentPage={filterParam.page}
        totalCount={totalCount}
        pageSize={PAGE_SIZE}
        onPageChange={(page) => handlePagination(page)}
      />

      {!!user && (
        <ModalDelete
          onDelete={handleDeleteUser}
          onClose={() => setUser(null)}
          text={`${user?.firstName} ${user?.lastName}`}
        />
      )}
    </AdminPage>
  );
};

export default AdminUsers;
