import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DriversColumn from './components/DriversColumn';
import styles from './sass/Drivers.module.scss';

const Drivers = ({
  drivers,
  onChange,
  driversTypes,
  maxLength,
  placeholder,
  withOutEmptyBlock
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.driversContainer}>
      {!withOutEmptyBlock ? <div className={styles.empty} /> : null}
      <div className={`${styles.driversWrapper} ${withOutEmptyBlock ? styles.fullWidth : ''}`}>
        {Object.keys(driversTypes).map((item) => {
          const data = drivers
            .filter(({ type }) => type === item)
            .sort((a, b) => b.value - a.value);
          return (
            <DriversColumn
              key={item}
              drivers={data}
              title={t(`${driversTypes[item].title}`)}
              type={item}
              onChange={onChange}
              tip={driversTypes[item].tip}
              textButton={driversTypes[item].textButton}
              maxLength={maxLength}
              placeholder={placeholder}
            />
          );
        })}
      </div>
    </div>
  );
};

Drivers.propTypes = {
  drivers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.number,
    type: PropTypes.string,
    date: PropTypes.string
  })),
  onChange: PropTypes.func.isRequired,
  driversTypes: PropTypes.shape({
    emotional: PropTypes.shape({
      type: PropTypes.string,
      title: PropTypes.string,
      tip: PropTypes.node,
      textButton: PropTypes.string,
    }),
  }).isRequired,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string.isRequired,
  withOutEmptyBlock: PropTypes.bool
};

Drivers.defaultProps = {
  drivers: [],
  maxLength: undefined,
  withOutEmptyBlock: false
};

export default Drivers;
