import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SORT_DIRECTION } from '../../../../constants/general';
import SortArrow from '../../../../components/SortArrow/SortArrow';
import adminTrendsHeader from '../../../../constants/adminTrendsHeader';
import styles from '../sass/AdminTrends.module.scss';

const AdminTrendTrendsHeader = ({ sortBy, sortOrder, onSort }) => {
  const { t } = useTranslation();
  const handleSort = (name) => {
    if (name === sortBy) {
      onSort(name, sortOrder === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC);
    } else {
      onSort(name, SORT_DIRECTION.DESC);
    }
  };

  return (
    <div className={styles.row}>
      {adminTrendsHeader.map(({ name, sortName, sortable }) => (
        <button
          key={name}
          className={`${styles.column} ${styles.header} ${styles[name]}`}
          onClick={() => handleSort(sortName)}
          disabled={!sortable}
        >
          <span>{t(name)}</span>
          {sortBy === sortName && <SortArrow up={sortOrder === SORT_DIRECTION.ASC} />}
        </button>
      ))}
    </div>
  );
};

AdminTrendTrendsHeader.propTypes = {
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired
};

export default AdminTrendTrendsHeader;
