import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import rateOptions from '../../../constants/rateOptions';
import SelectInput from '../../../components/SelectInput/SelectInput';
import styles from '../sass/O3.module.scss';

const O3PartnershipItem = ({
  name,
  value,
  id,
  stakeholderTypeId,
  onChangePartnership,
  onChangePartnershipName,
  onBlurPartnershipName,
  stakeholderPartnerList
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.partnershipItemContainer}>
      <SelectInput
        onChangeInput={onChangePartnershipName}
        value={name}
        values={stakeholderPartnerList}
        disabledInput={!stakeholderTypeId}
        title={t('potentialPartnerPlaceholder')}
        styleContainer={styles.inputContainer}
        styleSelectInputItem={styles.selectInputItem}
        onBlur={onBlurPartnershipName}
        onChange={(idStakeholder) => {
          const stakeholder = stakeholderPartnerList.find((item) => item.id === idStakeholder);
          onChangePartnership(
            stakeholder.name,
            stakeholder.value,
            idStakeholder,
            stakeholderTypeId,
            true
          );
        }}
        withOutArrow
      />
      <SelectInput
        onChange={(rate) => onChangePartnership(name, +rate, id, stakeholderTypeId)}
        styleContainer={styles.selectContainer}
        title={t('rank')}
        value={!id ? '' : value.toString()}
        values={rateOptions}
        checkId={false}
        disabled={!id || !name}
      />
    </div>
  );
};

O3PartnershipItem.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  id: PropTypes.number,
  stakeholderTypeId: PropTypes.number,
  onChangePartnership: PropTypes.func.isRequired,
  onChangePartnershipName: PropTypes.func.isRequired,
  onBlurPartnershipName: PropTypes.func.isRequired,
  stakeholderPartnerList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired
};

O3PartnershipItem.defaultProps = {
  name: '',
  value: 0,
  id: 0,
  stakeholderTypeId: 0,
};

export default O3PartnershipItem;
