import React from 'react';
import { useTranslation } from 'react-i18next';
import { SUPPORT_EMAIL } from '../../../constants/general';
import styles from '../sass/Login.module.scss';

const LoginHeader = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.headerContainer}>
      <span className={styles.title}>{t('thisIsBetaSoftware')}</span>
      <span>{t('thereAreToolTips')}</span>
      <span>
        {`${t('forMoreInformationAbout')} `}
        <a href={`mailto:${SUPPORT_EMAIL}`} className={styles.email}>{SUPPORT_EMAIL}</a>
      </span>
    </div>
  );
};

export default LoginHeader;
