import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { video5KindsOfValue } from '../../../constants/general';

const M1CustomerTipContent = ({ onOpenVideo }) => {
  const { t } = useTranslation();
  return (
    <p>
      {t('m1Tip3_1')}
      <button onClick={() => onOpenVideo(video5KindsOfValue)}>{` (${t('link')}).`}</button>
    </p>
  );
};

M1CustomerTipContent.propTypes = {
  onOpenVideo: PropTypes.func.isRequired, 
};

export default M1CustomerTipContent;
