import React from 'react';
import { useTranslation } from 'react-i18next';
import Tip from '../../../components/Tip/Tip';
import styles from '../sass/M2.module.scss';

const M2TipDetailedProfiles = () => {
  const { t } = useTranslation();
  return (
    <Tip
      styleTip={styles.tipDetailedProfiles}
      styleTipTextContainer={styles.tipTextContainer}
      styleTriangle={styles.triangle}
    >
      <p>{t('tipM2DetailedProfiles')}</p>
    </Tip>
  );
};

export default M2TipDetailedProfiles;
