import React from 'react';
import PropTypes from 'prop-types';
import TrendsItemValues from './TrendsItemValues';
import TrendsItemMessage from './TrendsItemMessage';
import styles from '../sass/Trends.module.scss';

const TrendsItem = ({
  name,
  influenceId,
  trendMessage,
  value,
  influenceList,
  onDeleteTrend,
  onUpdateTrend,
  id
}) => {
  return (
    <div className={styles.trendItemContainer}>
      <TrendsItemValues
        name={name}
        value={value}
        influenceId={influenceId}
        influenceList={influenceList}
        onUpdateTrend={onUpdateTrend}
        id={id}
        trendMessage={trendMessage}
        onDeleteTrend={onDeleteTrend}
      />
      <TrendsItemMessage
        trendMessage={trendMessage}
        onUpdateTrend={(text) => onUpdateTrend({
          trendMessage: text, name, value, influenceId 
        })}
        id={id}
      />
    </div>
  );
};

TrendsItem.propTypes = {
  name: PropTypes.string.isRequired,
  influenceId: PropTypes.number.isRequired,
  trendMessage: PropTypes.string,
  value: PropTypes.number.isRequired,
  influenceList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  onDeleteTrend: PropTypes.func.isRequired,
  onUpdateTrend: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired
};

TrendsItem.defaultProps = {
  trendMessage: '',
  influenceList: []
};

export default TrendsItem;
