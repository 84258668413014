import {
  SET_INDUSTRIES_LIST_SUCCESS, GET_SIGNUP_REQUEST, GET_SIGNUP_FAILED, SET_LOADING 
} from './types';

const initialState = {
  industriesList: [],
  loading: false,
  error: null,
};

export default function signUp(state = initialState, action) {
  switch (action.type) {
    case SET_INDUSTRIES_LIST_SUCCESS:
      return {
        ...state,
        industriesList: [...action.industriesList],
        loading: false,
        error: null
      };
    case GET_SIGNUP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_SIGNUP_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.value
      };
    default:
      return state;
  }
}
