import {
  CLEAN_O1, SET_O1_FAILED, SET_O1_REQUEST, SET_O1_OFFERINGS_SUCCESS
} from './types';
import instance from '../../services/axios';
import {
  CAPABILITIES_WORKSHEET_URL,
  OPERATIONAL_PRIORITY_URL, POST,
  POTENTIAL_CUSTOMER_OFFERING_URL, PUT
} from '../../constants/api';
import notify from '../../utils/notify';

export const setO1Request = () => ({ type: SET_O1_REQUEST });
export const setO1Failed = (error) => ({ type: SET_O1_FAILED, error });
export const cleanO1 = () => ({ type: CLEAN_O1 });

export const setO1OfferingsSuccess = (data) => ({ type: SET_O1_OFFERINGS_SUCCESS, data });

export const getO1Offerings = (idProject) => {
  return (dispatch) => {
    dispatch(setO1Request());
    instance.get(`${CAPABILITIES_WORKSHEET_URL}/${idProject}`)
      .then(({ data: { data } }) => dispatch(setO1OfferingsSuccess(data)))
      .catch(({ response: { data } }) => {
        dispatch(setO1Failed(data));
        notify('error', data.message);
      });
  };
};

export const changeO1OfferingAction = (idProject, idOffering, values) => {
  return (dispatch) => {
    dispatch(setO1Request());
    instance.put(`${POTENTIAL_CUSTOMER_OFFERING_URL}/${idOffering}`, values)
      .then(() => dispatch(getO1Offerings(idProject)))
      .catch(({ response: { data } }) => {
        dispatch(setO1Failed(data));
        notify('error', data.message);
      });
  };
};

export const deleteOfferingAction = (idProject, idOffering) => {
  return (dispatch) => {
    dispatch(setO1Request());
    instance.delete(`${POTENTIAL_CUSTOMER_OFFERING_URL}/${idOffering}`)
      .then(() => dispatch(getO1Offerings(idProject)))
      .catch(({ response: { data } }) => {
        dispatch(setO1Failed(data));
        notify('error', data.message);
      });
  };
};

export const changeO1OperationalPriorityAction = (idOffering, idOperational, values, closePlaceholder) => {
  return (dispatch, getState) => {
    const { o1: { offerings } } = getState();
    dispatch(setO1Request());
    const operationalPriority = {
      title: values.title || undefined,
      value: values.operationalPrioritiesValue,
      potential_customer_offering_id: !idOperational ? idOffering : undefined,
    };
    instance[idOperational ? PUT : POST](`${OPERATIONAL_PRIORITY_URL}/${idOperational || ''}`, operationalPriority)
      .then(({ data: { data } }) => {
        const copyOfferings = [...offerings];
        const index = copyOfferings.findIndex((item) => item.id === idOffering);
        if (idOperational) {
          const indexOperational = copyOfferings[index].operational_priorities
            .findIndex((item) => item.id === idOperational);
          copyOfferings[index].operational_priorities[indexOperational] = data;
        } else {
          copyOfferings[index].operational_priorities.push(data);
        }
        closePlaceholder();
        dispatch(setO1OfferingsSuccess(copyOfferings));
      })
      .catch(({ response: { data } }) => {
        dispatch(setO1Failed(data));
        notify('error', data.message);
      });
  };
};

export const deleteOperationalPriorityAction = (idOffering, idOperational) => {
  return (dispatch, getState) => {
    const { o1: { offerings } } = getState();
    dispatch(setO1Request());
    instance.delete(`${OPERATIONAL_PRIORITY_URL}/${idOperational}`)
      .then(() => {
        const copyOfferings = [...offerings];
        const index = copyOfferings.findIndex((item) => item.id === idOffering);
        const indexOperational = copyOfferings[index].operational_priorities
          .findIndex((item) => item.id === idOperational);
        copyOfferings[index].operational_priorities.splice(indexOperational, 1);
        copyOfferings[index].operational_priorities = copyOfferings[index].operational_priorities
          .filter((item) => item.id !== idOperational);
        dispatch(setO1OfferingsSuccess(copyOfferings));
      })
      .catch(({ response: { data } }) => {
        dispatch(setO1Failed(data));
        notify('error', data.message);
      });
  };
};
