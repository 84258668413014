import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Title from '../../../components/Title/Title';
import M2TipDetailedProfiles from './M2TipDetailedProfiles';
import Button from '../../../components/Button/Button';
import styles from '../sass/M2.module.scss';

import { ReactComponent as Plus } from '../../../assets/icons/plusBlue.svg';

const M2Profiles = ({
  detailedProfiles, onLoadMore, idProject, onCreateCustomer
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.profilesContainer}>
      <div className={styles.profilesHeader}>
        <Title
          text={t('detailedProfiles')}
          Type="h3"
          style={styles.profilesTitle}
        />
        <M2TipDetailedProfiles />
      </div>
      <div className={styles.profilesContent}>
        <div className={styles.profilesDetailedContainer}>
          {detailedProfiles.data.map(({ id, full_name: name }) => (
            <Link
              key={id}
              to={`/${idProject}/m1`}
              state={{ worksheet: id }}
              className={styles.profilesDetailedName}
            >
              {name}
            </Link>
          ))}
          {detailedProfiles.links.next && (
            <div
              role="presentation"
              onClick={onLoadMore}
              className={styles.profilesDetailedName}
            >
              {t('more')}
            </div>
          )}
        </div>
      </div>
      <Button
        text={t('addCustomer')}
        style={styles.profilesButtonContainer}
        styleButton={styles.profilesButton}
        icon={<Plus />}
        onClick={onCreateCustomer}
      />
    </div>
  );
};

M2Profiles.propTypes = {
  detailedProfiles: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      full_name: PropTypes.string
    })),
    links: PropTypes.shape({
      next: PropTypes.string
    })
  }).isRequired,
  onLoadMore: PropTypes.func.isRequired,
  idProject: PropTypes.string.isRequired,
  onCreateCustomer: PropTypes.func.isRequired
};

export default M2Profiles;
