import {
  SET_02_ORGANIZATION_DRIVERS_SUCCESS,
  SET_O2_COMPETITORS_DRIVERS_SUCCESS,
  SET_O2_FAILED,
  SET_O2_OWN_ORGANIZATION_NAME_SUCCESS,
  SET_O2_OWN_ORGANIZATION_OFFERING_SUCCESS,
  SET_O2_REQUEST,
  SET_O2_TOP_MARKET_DRIVERS_SUCCESS,
  CLEAN_O2,
} from './types';

const initialState = {
  topMarketDrivers: [],
  organizationDrivers: [],
  competitorsDrivers: [],
  ownOrganizationName: '',
  ownOrganizationOffering: '',
  loading: false,
  error: null
};

export default function o2(state = initialState, action) {
  switch (action.type) {
    case SET_O2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_O2_TOP_MARKET_DRIVERS_SUCCESS:
      return {
        ...state,
        topMarketDrivers: action.data,
        loading: false,
        error: null
      };
    case SET_02_ORGANIZATION_DRIVERS_SUCCESS:
      return {
        ...state,
        organizationDrivers: action.data,
        loading: false,
        error: null
      };
    case SET_O2_COMPETITORS_DRIVERS_SUCCESS:
      return {
        ...state,
        competitorsDrivers: action.data,
        loading: false,
        error: null
      };
    case SET_O2_OWN_ORGANIZATION_NAME_SUCCESS:
      return {
        ...state,
        ownOrganizationName: action.data,
        loading: false,
        error: null
      };
    case SET_O2_OWN_ORGANIZATION_OFFERING_SUCCESS:
      return {
        ...state,
        ownOrganizationOffering: action.data,
        loading: false,
        error: null
      };
    case SET_O2_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data
      };
    case CLEAN_O2:
      return initialState;
    default:
      return state;
  }
}
