import instance from '../../../services/axios';
import {
  SET_ADMIN_USERS_REQUEST,
  SET_ADMIN_USERS_SUCCESS,
  SET_ADMIN_USERS_FAILED,
  SET_ADMIN_USERS_DELETE_REQUEST,
  SET_ADMIN_USERS_DELETE_FAILED,
  CLEAN_ADMIN_USERS,
} from './types';
import { USERS_URL } from '../../../constants/api';
import notify from '../../../utils/notify';
import { getSearchParams } from '../../../utils/helpers';

export const setAdminUsersSuccess = (data, total) => ({ type: SET_ADMIN_USERS_SUCCESS, data, total });
export const setAdminUsersRequest = () => ({ type: SET_ADMIN_USERS_REQUEST });
export const setAdminUsersFailed = (error) => ({ type: SET_ADMIN_USERS_FAILED, error });

export const setUsersDeleteRequest = () => ({ type: SET_ADMIN_USERS_DELETE_REQUEST });
export const setUsersDeleteFailed = (error) => ({ type: SET_ADMIN_USERS_DELETE_FAILED, error });

export const cleanAdminUsers = () => ({ type: CLEAN_ADMIN_USERS });

export const getUsersAction = (filterParam, setSearchParams) => {
  const getParams = new URLSearchParams({
    ...getSearchParams(filterParam)
  }).toString();
  return (dispatch) => {
    dispatch(setAdminUsersRequest());
    instance.get(`${USERS_URL}?${getParams}`)
      .then(({ data: { data, meta: { total } } }) => {
        if (setSearchParams) setSearchParams(getParams);
        dispatch(setAdminUsersSuccess(data, total));
      })
      .catch(({ response: { data } }) => {
        dispatch(setAdminUsersFailed(data));
        notify('error', data.message);
      });
  };
};

export const deleteUserAction = (id, params, setSearchParams) => {
  return (dispatch) => {
    dispatch(setUsersDeleteRequest());
    instance.delete(`${USERS_URL}/${id}`)
      .then(() => dispatch(getUsersAction(params, setSearchParams)))
      .catch(({ response: { data } }) => {
        dispatch(setUsersDeleteFailed(data));
        notify('error', data.message);
      });
  };
};
