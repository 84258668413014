import React from 'react';
import PropTypes from 'prop-types';
import AuthLayout from '../../../layout/AuthLayout/AuthLayout';
import Title from '../../../components/Title/Title';
import styles from '../sass/SuccessRegistration.module.scss';

const SuccessRegistrationError = ({ error }) => (
  <AuthLayout>
    <Title text={error} style={styles.error} />
  </AuthLayout>
);

SuccessRegistrationError.propTypes = {
  error: PropTypes.string.isRequired
};

export default SuccessRegistrationError;
