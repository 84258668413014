import React from 'react';
import { useTranslation } from 'react-i18next';
import Tip from '../Tip/Tip';

const TipOrganizationEmotionalDriver = () => {
  const { t } = useTranslation();
  return (
    <Tip>
      <p>
        {t('tipOrganizationEmotionalDriver')}
      </p>
    </Tip>
  );
};

export default TipOrganizationEmotionalDriver;
