import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SORT_DIRECTION } from '../../../../constants/general';
import adminScenarioHeader from '../../../../constants/adminScenarioHeader';
import SortArrow from '../../../../components/SortArrow/SortArrow';
import styles from '../sass/AdminScenarios.module.scss';

const AdminScenarioScenariosHeader = ({ sortBy, sortOrder, onSort }) => {
  const { t } = useTranslation();

  const handleSort = (name) => {
    if (name === sortBy) {
      onSort(name, sortOrder === SORT_DIRECTION.ASC ? SORT_DIRECTION.DESC : SORT_DIRECTION.ASC);
    } else {
      onSort(name, SORT_DIRECTION.DESC);
    }
  };
  return (
    <div className={styles.row}>
      {adminScenarioHeader.map(({ name, sortName, sortable }) => (
        <button
          key={name}
          className={`${styles.column}`}
          onClick={() => handleSort(sortName)}
          disabled={!sortable}
        >
          <span>{t(name)}</span>
          {sortBy === sortName && <SortArrow up={sortOrder === SORT_DIRECTION.ASC} />}
        </button>
      ))}
    </div>
  );
};

AdminScenarioScenariosHeader.propTypes = {
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired
};

export default AdminScenarioScenariosHeader;
