import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  markets,
  operations,
  trends,
  supplyChainFirst,
  supplyChainSecond,
  supplyChainThird,
  supplyChainFourth,
  supplyChainFifth,
  supplyChainSixth
} from '../../constants/dashboard';
import {
  getBreadcrumbsDashboard,
  marketsAndOperationsData,
  stakeholdersData,
  trendsData
} from '../../utils/helpers';
import { cleanDashboard, getDashboardAction, updateStakeholderAction } from '../../store/dashboard/actions';
import { updateProjectGuideTip } from '../../store/project/actions';
import Page from '../../layout/Page/Page';
import Market from './components/Market';
import Operations from './components/Operations';
import Trends from './components/Trends';
import SupplyChain from './components/SupplyChain';
import styles from './sass/Dashboard.module.scss';

const Dashboard = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    dashboard: {
      loading, marketsScheme, trendsScheme, stakeholdersScheme, operationsScheme
    },
    project: { name, firstTip, secondTip }
  } = useSelector(({ dashboard, project }) => ({ dashboard, project }), shallowEqual);

  useEffect(() => {
    dispatch(getDashboardAction(id));
    return () => {
      dispatch(updateProjectGuideTip(id));
      dispatch(cleanDashboard());
    };
  }, []);

  const breadcrumbs = useMemo(() => {
    return getBreadcrumbsDashboard(name);
  }, [name]);

  const handleChangeStakeholderVisible = useCallback((values) => {
    dispatch(updateStakeholderAction({ ...values, idProject: id }));
  }, []);

  const handleGuideClose = useCallback(() => {
    dispatch(updateProjectGuideTip(id));
  }, []);

  return (
    <Page
      pageContainer={styles.container}
      whiteTheme
      breadcrumbs={breadcrumbs}
      loader={loading}
    >
      <div className={styles.content}>
        <Market
          id={id}
          data={marketsAndOperationsData(marketsScheme, markets)}
          title={t('markets')}
          guideTip={!firstTip}
          onGuideClose={handleGuideClose}
        />
        <Operations id={id} data={marketsAndOperationsData(operationsScheme, operations)} title={t('ops')} />
        <Trends
          id={id}
          data={trendsData(trendsScheme, trends)}
          title={t('trends')}
          guideTip={firstTip && !secondTip}
          onGuideClose={handleGuideClose}
        />
        <SupplyChain
          dataSupplyChainFirst={stakeholdersData(stakeholdersScheme, supplyChainFirst, 0)}
          dataSupplyChainSecond={stakeholdersData(stakeholdersScheme, supplyChainSecond, 1)}
          dataSupplyChainThird={stakeholdersData(stakeholdersScheme, supplyChainThird, 2)}
          dataSupplyChainFourth={stakeholdersData(stakeholdersScheme, supplyChainFourth, 3)}
          dataSupplyChainFifth={stakeholdersData(stakeholdersScheme, supplyChainFifth, 4)}
          dataSupplyChainSixth={stakeholdersData(stakeholdersScheme, supplyChainSixth, 5)}
          id={id}
          onChangeVisible={handleChangeStakeholderVisible}
        />
        <div className={styles.organizationBlock}>
          <div>{t('organization')}</div>
        </div>
      </div>
    </Page>
  );
};

export default Dashboard;
