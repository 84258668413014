import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, batch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getCurrencyListAction, getIndustriesListAction } from '../../../store/general/actions';
import { getScenariosAction } from '../../../store/projects/actions';
import ProjectsModalAvatar from './ProjectsModalAvatar';
import ProjectModalForm from './ProjectModalForm';
import Button from '../../../components/Button/Button';
import ProjectsEditButtons from './ProjectsEditButtons';
import styles from '../sass/ProjectModalContent.module.scss';

const ProjectModalContent = ({
  project,
  onUpdateProject,
  industriesList,
  onSaveNewProject,
  onDelete,
  onEditProject,
  onDuplicate,
  textError,
  currencyList,
  scenariosList,
  isDuplicate
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    batch(() => {
      if (!industriesList.length) dispatch(getIndustriesListAction());
      if (!currencyList.length) dispatch(getCurrencyListAction());
      if (!scenariosList.length) dispatch(getScenariosAction());
    });
  }, [industriesList, currencyList, scenariosList]);

  return (
    <div className={styles.container}>
      <ProjectsModalAvatar />
      <ProjectModalForm
        onUpdateProject={onUpdateProject}
        industriesList={industriesList}
        currencyList={currencyList}
        scenariosList={scenariosList}
        project={project}
        textError={textError}
        isDuplicate={isDuplicate}
      />
      {!project?.id ? (
        <Button
          text={t('create')}
          style={styles.buttonContainer}
          styleButton={styles.button}
          onClick={onSaveNewProject}
          disabled={!project?.name || !project?.industry?.id || !project?.currency}
        />
      ) : (
        <ProjectsEditButtons
          onDelete={onDelete}
          onDuplicate={onDuplicate}
          onEditProject={onEditProject}
          isDuplicate={isDuplicate}
          disabled={!project?.name || !project?.industry?.id || !project?.currency}
        />
      )}
    </div>
  );
};

ProjectModalContent.propTypes = {
  onUpdateProject: PropTypes.func.isRequired,
  onSaveNewProject: PropTypes.func.isRequired,
  onEditProject: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  industriesList: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
  project: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number,
    industry: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string }),
    avatar: PropTypes.shape({ id: PropTypes.number, url: PropTypes.string }),
    currency: PropTypes.string,
    scenario: PropTypes.number
  }),
  textError: PropTypes.string.isRequired,
  currencyList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    symbol: PropTypes.string
  })).isRequired,
  scenariosList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  isDuplicate: PropTypes.bool.isRequired
};

ProjectModalContent.defaultProps = {
  industriesList: [],
  project: {
    name: '',
    description: '',
    currency: '',
    industry_id: 0,
    id: 0,
    scenario: 0,
  }
};

export default ProjectModalContent;
