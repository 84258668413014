import React, { useState, useCallback, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { convertFromIdToName, deleteSpacesInString } from '../../../utils/helpers';
import SelectInput from '../../../components/SelectInput/SelectInput';
import Input from '../../../components/Input/Input';
import styles from '../sass/M4.module.scss';
import useOnBlur from '../../../hooks/useOnBlur';

const M4Description = ({
  segmentId,
  segmentList,
  driversList,
  driverYId,
  driverXId,
  onChangeSegment,
  onChangeDriverX,
  onChangeDriverY,
  competitorsList,
  our,
  delivers,
  competitorId,
  onChangeDriverOffer,
  onDeleteDriverOffer
}) => {
  const { t } = useTranslation();
  const [ourValue, setOurValue] = useState(our);
  const [deliverValue, setDeliverValue] = useState(delivers);

  useLayoutEffect(() => {
    setOurValue(our);
    setDeliverValue(delivers);
  }, [our, delivers]);

  const handleChangeOur = useCallback((event) => {
    const text = deleteSpacesInString(event.target.value);
    setOurValue(text);
  }, []);

  const handleChangeDeliver = useCallback((event) => {
    const text = deleteSpacesInString(event.target.value);
    setDeliverValue(text);
  }, []);

  const handleBlurOur = useOnBlur(
    our,
    ourValue,
    () => onChangeDriverOffer({
      our: ourValue,
      delivers: deliverValue,
      competitorId
    }),
    onDeleteDriverOffer
  );

  const handleBlurDeliver = useOnBlur(
    delivers,
    deliverValue,
    () => onChangeDriverOffer({
      our: ourValue,
      delivers: deliverValue,
      competitorId
    }),
    () => onChangeDriverOffer({
      our: ourValue,
      delivers: deliverValue,
      competitorId
    }),
  );

  return (
    <div className={styles.descriptionContainer}>
      <SelectInput
        value={convertFromIdToName(segmentList, segmentId)}
        values={segmentList}
        title=""
        onChange={(id) => onChangeSegment(id)}
        styleContainer={styles.selectInput}
        label={t('for')}
      />

      <SelectInput
        value={convertFromIdToName(driversList, driverXId)}
        values={driversList.filter(({ id }) => id !== driverYId)}
        title={t('driver')}
        onChange={(id) => onChangeDriverX(id)}
        styleContainer={`${styles.selectInput} ${styles.grey}`}
        disabled={!driverXId}
        label={t('whoseDecisionsAreDrivenBy')}
      />

      <SelectInput
        value={convertFromIdToName(driversList, driverYId)}
        values={driversList.filter(({ id }) => id !== driverXId)}
        title={t('driver')}
        onChange={(id) => onChangeDriverY(id)}
        styleContainer={`${styles.selectInput} ${styles.grey}`}
        disabled={!driverYId}
        label={t('and')}
      />

      <Input
        onChange={handleChangeOur}
        onBlur={handleBlurOur}
        value={ourValue}
        style={styles.input}
        disabled={!driverYId}
        label={t('our')}
        title={t('ourPlaceholder')}
      />

      <div className={styles.line} />

      <SelectInput
        value={convertFromIdToName(competitorsList, competitorId)}
        values={competitorsList}
        title={t('competitor')}
        onChange={(value) => onChangeDriverOffer({
          our: ourValue,
          delivers: deliverValue,
          competitorId: +value
        })}
        styleContainer={`${styles.selectInput} ${styles.grey}`}
        label={t('unlike')}
      />

      <Input
        onChange={handleChangeOur}
        onBlur={handleBlurOur}
        value={ourValue}
        style={styles.input}
        disabled={!driverYId}
        label={t('our')}
        title={t('ourPlaceholder')}
      />

      <Input
        onChange={handleChangeDeliver}
        onBlur={handleBlurDeliver}
        style={styles.input}
        value={deliverValue}
        disabled={!competitorId}
        label={t('delivers')}
        title={t('deliversPlaceholder')}
      />
    </div>
  );
};

M4Description.propTypes = {
  segmentId: PropTypes.number.isRequired,
  segmentList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })).isRequired,
  driversList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  competitorsList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  driverXId: PropTypes.number.isRequired,
  driverYId: PropTypes.number.isRequired,
  onChangeSegment: PropTypes.func.isRequired,
  onChangeDriverY: PropTypes.func.isRequired,
  onChangeDriverX: PropTypes.func.isRequired,
  onChangeDriverOffer: PropTypes.func.isRequired,
  our: PropTypes.string.isRequired,
  delivers: PropTypes.string.isRequired,
  competitorId: PropTypes.number.isRequired,
  onDeleteDriverOffer: PropTypes.func.isRequired
};

M4Description.defaultProps = {
  driversList: [],
  competitorsList: [],
};

export default M4Description;
