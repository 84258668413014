import { batch } from 'react-redux';
import instance from '../../services/axios';
import {
  CLEAN_AUTH,
  GET_FORGOT_PASSWORD_FAILED,
  GET_FORGOT_PASSWORD_REQUEST,
  GET_FORGOT_PASSWORD_SUCCESS,
  GET_LOGIN_FAILED,
  GET_LOGIN_REQUEST,
  GET_LOGIN_SUCCESS,
  GET_RESET_PASSWORD_FAILED,
  GET_RESET_PASSWORD_REQUEST,
  GET_RESET_PASSWORD_SUCCESS
} from './types';
import {
  FORGOT_PASSWORD_URL, LOGIN_URL, LOGOUT_URL, RESET_PASSWORD_URL 
} from '../../constants/api';
import { cleanUserAction, setRole } from '../user/actions';
import { cleanGeneral } from '../general/actions';
import { ADMIN } from '../../constants/general';
import notify from '../../utils/notify';

export const setLoginSuccess = (data) => ({
  type: GET_LOGIN_SUCCESS, accessToken: data.accessToken, refreshToken: data.refreshToken
});
export const setLoginRequest = () => ({ type: GET_LOGIN_REQUEST });
export const setLoginFailed = (error) => ({ type: GET_LOGIN_FAILED, error });

export const setForgotPasswordRequest = () => ({ type: GET_FORGOT_PASSWORD_REQUEST });
export const setForgotPasswordSuccess = () => ({ type: GET_FORGOT_PASSWORD_SUCCESS });
export const setForgotPasswordFailed = (error) => ({ type: GET_FORGOT_PASSWORD_FAILED, error });

export const setResetPasswordRequest = () => ({ type: GET_RESET_PASSWORD_REQUEST });
export const setResetPasswordSuccess = () => ({ type: GET_RESET_PASSWORD_SUCCESS });
export const setResetPasswordFailed = (error) => ({ type: GET_RESET_PASSWORD_FAILED, error });

export const logOut = () => ({ type: CLEAN_AUTH });

export const logInAction = (password, email, navigate) => {
  return (dispatch) => {
    dispatch(setLoginRequest());
    instance.post(LOGIN_URL, { password, email }, { withoutAuth: true })
      .then(({ data: { data } }) => {
        localStorage.setItem('accessToken', JSON.stringify(data.access_token || ''));
        localStorage.setItem('refreshToken', JSON.stringify(data.refresh_token || ''));
        localStorage.setItem('role', JSON.stringify(data.role || ''));
        batch(() => {
          dispatch(setLoginSuccess({ accessToken: data.access_token, refreshToken: data.refresh_token }));
          dispatch(setRole(data.role));
        });
        navigate(data.role === ADMIN ? '/admin' : '/');
      })
      .catch(({ response: { data: errorData } }) => {
        dispatch(setLoginFailed(errorData));
        notify('error', errorData.message);
      });
  };
};

export const forgotPasswordAction = (email, setFieldError, sendAgain) => {
  return (dispatch) => {
    dispatch(setForgotPasswordRequest());
    instance.post(FORGOT_PASSWORD_URL, { email }, { withoutAuth: true })
      .then(() => {
        localStorage.setItem('email', JSON.stringify(email));
        dispatch(setForgotPasswordSuccess());
        sendAgain();
      })
      .catch(({ response: { data } }) => {
        dispatch(setForgotPasswordFailed(data));
        notify('error', data.message);
        if (data?.errors?.email) {
          setFieldError('email', ...data.errors.email);
        }
      });
  };
};

export const resetPasswordAction = (password, confirmPassword, token, navigate, email) => {
  return (dispatch) => {
    const data = {
      token,
      email,
      password,
      password_confirmation: confirmPassword
    };
    dispatch(setResetPasswordRequest());
    instance.post(RESET_PASSWORD_URL, data, { withoutAuth: true })
      .then(() => {
        dispatch(setResetPasswordSuccess());
        notify('success', 'Success!');
        navigate('/login');
      })
      .catch(({ response: { data: errorData } }) => {
        dispatch(setResetPasswordFailed(errorData));
        notify('error', errorData.message);
      });
  };
};

export const logOutAction = () => {
  return (dispatch) => {
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessToken');
    instance.post(LOGOUT_URL)
      .then(() => {
        batch(() => {
          dispatch(logOut());
          dispatch(cleanGeneral());
          dispatch(cleanUserAction());
        });
      })
      .catch(({ response: { data } }) => notify('error', data.message));
  };
};
