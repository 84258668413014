import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button/Button';
import styles from '../sass/ProjectsEditButtons.module.scss';

import { ReactComponent as Delete } from '../../../assets/icons/delete.svg';
import { ReactComponent as Duplicate } from '../../../assets/icons/duplicate.svg';

const ProjectsEditButtons = ({
  onDelete,
  onDuplicate,
  onEditProject,
  disabled,
  isDuplicate
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.addButtonsContainer}>
      {!isDuplicate && (
        <>
          <Button
            text={t('delete')}
            icon={<Delete />}
            style={styles.addButtons}
            styleButton={styles.deleteButton}
            onClick={onDelete}
            disabled={disabled}
          />
          <Button
            text={t('duplicate')}
            icon={<Duplicate />}
            style={styles.addButtons}
            styleButton={styles.duplicateButton}
            onClick={onDuplicate}
            disabled={disabled}
          />
        </>
      )}
      <Button
        text={t('save')}
        style={styles.addButtons}
        styleButton={styles.saveButton}
        onClick={onEditProject}
        disabled={disabled}
      />
    </div>
  );
};

ProjectsEditButtons.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onEditProject: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isDuplicate: PropTypes.bool.isRequired
};

export default memo(ProjectsEditButtons);
