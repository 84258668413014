import React from 'react';
import { useTranslation } from 'react-i18next';
import Tip from '../Tip/Tip';

const TipOrganizationFinancialDriver = () => {
  const { t } = useTranslation();
  return (
    <Tip>
      <p>
        {t('tipOrganizationFinancialDriver')}
      </p>
    </Tip>
  );
};

export default TipOrganizationFinancialDriver;
