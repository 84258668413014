import React from 'react';
import PropTypes from 'prop-types';
import Photo from '../Photo/Photo';
import Reference from '../Reference/Reference';
import styles from './sass/PhotoWithReference.module.scss';

const PhotoWithReference = ({
  avatar, loadingFile, onUpload, link, onOpenModal, onDelete, onOpen, tipContent
}) => {
  return (
    <div className={styles.infoContainer}>
      <Photo url={avatar} loadingFile={loadingFile} onUpload={onUpload} />
      <Reference
        link={link}
        onOpenModal={onOpenModal}
        onDelete={onDelete}
        onOpen={onOpen}
        tipContent={tipContent}
      />
    </div>
  );
};

PhotoWithReference.propTypes = {
  avatar: PropTypes.string,
  loadingFile: PropTypes.bool.isRequired,
  onUpload: PropTypes.func.isRequired,
  link: PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string
  }),
  onOpenModal: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  tipContent: PropTypes.node.isRequired
};

PhotoWithReference.defaultProps = {
  avatar: null,
  link: {
    id: null,
    url: ''
  }
};

export default PhotoWithReference;
