import React, { useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { cleanEditUsers, editUserAction, getUserAction } from '../../../store/admin/adminEditUser/actions';
import { editUserValidationSchema } from '../../../utils/validation';
import accessLevel from '../../../constants/accessLevel';
import { USER } from '../../../constants/general';
import AdminPage from '../../../layout/admin/AdminPage/AdminPage';
import Title from '../../../components/Title/Title';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import SelectInput from '../../../components/SelectInput/SelectInput';
import styles from './sass/AdminEditUser.module.scss';

const AdminEditUser = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    adminEditUser: {
      user, loading
    }
  } = useSelector(({ adminEditUser }) => ({ adminEditUser }));

  const {
    handleSubmit,
    values: {
      firstName,
      lastName,
      email,
      userType
    },
    errors,
    setFieldValue,
    handleChange,
    validateForm,
  } = useFormik({
    initialValues: {
      firstName: user?.first_name || '',
      lastName: user?.last_name || '',
      email: user?.email || '',
      userType: user?.role || '',
    },
    onSubmit: (values) => validateForm(values).then(() => dispatch(editUserAction(values, id, navigate))),
    validationSchema: editUserValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true
  });

  useEffect(() => {
    if (id) dispatch(getUserAction(id));
    return () => {
      dispatch(cleanEditUsers());
    };
  }, [id]);

  const handleChangeUserType = useCallback((value) => {
    setFieldValue('userType', value);
  }, []);

  const handleBackNavigation = useCallback(() => navigate(-1), []);

  return (
    <AdminPage pageContainer={styles.container} loader={loading} activeTab={USER}>
      <div className={styles.content}>
        <form onSubmit={handleSubmit}>
          <Title text={id ? t('editUser') : t('addUser')} style={styles.title} />
          <Input
            style={styles.inputContainer}
            onChange={handleChange}
            name="firstName"
            title={t('firstName')}
            value={firstName}
            errorText={errors.firstName}
          />
          <Input
            style={styles.inputContainer}
            onChange={handleChange}
            name="lastName"
            title={t('lastName')}
            value={lastName}
            errorText={errors.lastName}
          />
          <Input
            style={styles.inputContainer}
            onChange={handleChange}
            name="email"
            title={`${t('email')} (${t('userName')})`}
            value={email}
            errorText={errors.email}
          />
          <SelectInput
            styleContainer={styles.selectInputContainer}
            styleDropdownContent={styles.dropdownContent}
            onChange={(value) => handleChangeUserType(value)}
            name="userType"
            title={t('userType')}
            checkId={false}
            value={userType}
            values={accessLevel}
            errorText={errors.userType}
          />
          <div className={styles.buttonsContainer}>
            <Button
              text={t('cancel')}
              style={styles.buttonContainer}
              styleButton={styles.button}
              onClick={handleBackNavigation}
            />
            <Button
              text={t('save')}
              type="submit"
              style={styles.buttonContainer}
              styleButton={styles.button}
              onClick={handleSubmit}
            />
          </div>
        </form>
      </div>
    </AdminPage>
  );
};

export default AdminEditUser;
