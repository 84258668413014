import React, { memo } from 'react';
import PropTypes from 'prop-types';
import NoData from '../../../../components/NoData/NoData';
import AdminUserUsersHeader from './AdminUserUsersHeader';
import AdminUserUsersRow from './AdminUserUsersRow';
import styles from '../sass/AdminUsers.module.scss';

const AdminUserUsers = ({
  users,
  onDelete,
  onSwitchUser,
  sortBy,
  sortOrder,
  onSort
}) => {
  return (
    <div className={styles.users}>
      {users.length ? (
        <>
          <AdminUserUsersHeader
            sortBy={sortBy}
            sortOrder={sortOrder}
            onSort={onSort}
          />
          {users.map(({
            id,
            first_name: firstName,
            last_name: lastName,
            email,
            role,
            projects
          }) => (
            <AdminUserUsersRow
              key={id}
              id={id}
              firstName={firstName}
              lastName={lastName}
              email={email}
              role={role}
              projects={projects}
              onDelete={onDelete}
              onSwitchUser={onSwitchUser}
            />
          ))}
        </>
      ) : <NoData />}
    </div>
  );
};

AdminUserUsers.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    role: PropTypes.string,
    projects: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      industry_id: PropTypes.number
    }))
  })),
  onDelete: PropTypes.func.isRequired,
  onSwitchUser: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired
};

AdminUserUsers.defaultProps = {
  users: [],
};

export default memo(AdminUserUsers);
