import {
  SET_PROJECT_FAILED,
  SET_PROJECT_GUIDE_TIP_SUCCESS,
  SET_PROJECT_REQUEST,
  SET_PROJECT_SUCCESS
} from './types';
import instance from '../../services/axios';
import { PROJECTS_URL } from '../../constants/api';
import { FIRST_TIP, SECOND_TIP } from '../../constants/general';

export const setProjectRequest = () => ({ type: SET_PROJECT_REQUEST });
export const setProjectSuccess = (data) => ({ type: SET_PROJECT_SUCCESS, data });
export const setProjectFailed = (error) => ({ type: SET_PROJECT_FAILED, error });

export const setProjectGuideTipSuccess = (tip) => ({ type: SET_PROJECT_GUIDE_TIP_SUCCESS, tip });

export const getProject = (idProject, navigate) => {
  return (dispatch) => {
    dispatch(setProjectRequest());
    instance.get(`${PROJECTS_URL}/${idProject}`)
      .then(({
        data: {
          data: {
            id, name, first_tip: firstTip, second_tip: secondTip
          }
        }
      }) => {
        const project = {
          id,
          name,
          firstTip,
          secondTip
        };
        dispatch(setProjectSuccess(project));
      })
      .catch(({ response: { data } }) => {
        dispatch(setProjectFailed(data));
        navigate('/');
      });
  };
};

export const updateProjectGuideTip = (idProject) => {
  return (dispatch, getState) => {
    const { project: { firstTip, secondTip } } = getState();
    if (!firstTip || !secondTip) {
      const field = !firstTip ? 'first_tip' : 'second_tip';
      dispatch(setProjectGuideTipSuccess(!firstTip ? FIRST_TIP : SECOND_TIP));
      instance.put(`${PROJECTS_URL}/${idProject}`, { [field]: true })
        .catch(({ response: { data } }) => {
          dispatch(setProjectFailed(data));
        });
    }
  };
};
