import * as Yup from 'yup';

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Field is required'),
  password: Yup.string().required('Field is required')
});

export const signUpValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Field is required'),
  firstName: Yup.string().required('Field is required'),
  lastName: Yup.string().required('Field is required'),
  industry: Yup.number().required('Field is required'),
  currency: Yup.string().required('Field is required'),
  projectCompany: Yup.string().required('Field is required'),
  password: Yup
    .string()
    .required('Field is required')
    .matches(
      /^(?=.{8,})/,
      'Must Contain 8 Characters'
    ),
  confirmPassword: Yup
    .string()
    .required('Field is required')
    .matches(
      /^(?=.{8,})/,
      'Must Contain 8 Characters'
    )
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
});

export const editUserValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('Field is required'),
  lastName: Yup.string().required('Field is required'),
  email: Yup.string().email('Invalid email').required('Required'),
  userType: Yup.string().required('Field is required'),
});

export const editScenarioValidationSchema = Yup.object().shape({
  name: Yup.string().required('Field is required'),
  description: Yup.string().required('Field is required'),
});

export const moreZeroRegex = /^[1-9]\d*$/;

export const editTrendValidationSchema = Yup.object().shape({
  trend: Yup.string().required('Field is required'),
  category: Yup.number().nullable().required('Field is required'),
  influence: Yup.number().nullable().required('Field is required'),
  industry: Yup.number().nullable().required('Field is required'),
  message: Yup.string().nullable().notRequired(),
  rank: Yup.string().matches(moreZeroRegex, 'Must be greater than zero').required('Field is required'),
});

export const editStakeholderValidationSchema = Yup.object().shape({
  name: Yup.string().required('Field is required'),
  stakeholderType: Yup.number().nullable().required('Field is required'),
  drivers: Yup.array().of(
    Yup.object().shape({
      id: Yup.number().nullable().notRequired(),
      name: Yup.string().notRequired(),
      type: Yup.string().notRequired(),
      value: Yup.number().nullable().notRequired(),
    }),
  ),
});

export const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Field is required'),
});

export const createPasswordValidationSchema = Yup.object().shape({
  password: Yup
    .string()
    .required('Field is required')
    .matches(
      /^(?=.{8,})/,
      'Must Contain 8 Characters'
    ),
  confirmPassword: Yup
    .string()
    .required('Field is required')
    .matches(
      /^(?=.{8,})/,
      'Must Contain 8 Characters'
    )
    .oneOf([Yup.ref('password')], 'Passwords do not match'),
});

export const numericValidation = /^(\s*|\d+)$/;

export const twoNumberAfterDot = /^[0-9]*(\.[0-9]{0,2})?$/;

export const urlValidation = /^(https?:\/\/|http?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
