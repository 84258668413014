import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button/Button';
import styles from './sass/SimpleHeader.module.scss';

import Logo from '../../assets/icons/logo.svg';

function SimpleHeader({
  onLogout, logout, goBack, onGoBack 
}) {
  const { t } = useTranslation();
  return (
    <header className={styles.header}>
      <div className={`container ${styles.container}`}>
        <img src={Logo} alt="brandLogo" />
        <div className={styles.buttonsActionContainer}>
          {goBack && (
            <Button
              text={t('back')}
              style={styles.buttonContainer}
              styleButton={styles.button}
              onClick={onGoBack}
            />
          )}
          {logout && (
            <Button
              text={t('logout')}
              style={styles.buttonContainer}
              styleButton={styles.button}
              onClick={onLogout}
            />
          )}
        </div>
      </div>
    </header>
  );
}

SimpleHeader.propTypes = {
  onLogout: PropTypes.func.isRequired,
  logout: PropTypes.bool.isRequired,
  goBack: PropTypes.bool.isRequired,
  onGoBack: PropTypes.func.isRequired
};

export default SimpleHeader;
