import React, { useCallback, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { deleteSpacesInString } from '../../../utils/helpers';
import useOnBlur from '../../../hooks/useOnBlur';
import rateOptions from '../../../constants/rateOptions';
import Input from '../../Input/Input';
import SelectInput from '../../SelectInput/SelectInput';
import styles from '../sass/Drivers.module.scss';

const DriversColumnItem = ({
  data,
  onChange,
  type,
  maxLength,
  placeholder
}) => {
  const { t } = useTranslation();
  const [driverItem, setDriverItem] = useState(data);

  const { id, name, date } = data;

  const handleChangeDriverItemName = useCallback((event) => {
    setDriverItem({
      ...driverItem,
      [event.target.name]: deleteSpacesInString(event.target.value)
    });
  }, [driverItem]);

  const handleBlurDriverItemName = useOnBlur(
    name,
    driverItem.name,
    () => {
      onChange(driverItem, type);
      if (!name) {
        setDriverItem({
          ...driverItem,
          name: ''
        });
      }
    },
  );

  useLayoutEffect(() => {
    setDriverItem(data);
  }, [data]);

  return (
    <div className={styles.driversItem}>
      <Input
        name="name"
        title={placeholder}
        onChange={handleChangeDriverItemName}
        style={styles.driversItemDriver}
        value={driverItem.name}
        onBlur={handleBlurDriverItemName}
        maxLength={maxLength}
      />
      <SelectInput
        name="value"
        title={t('rank')}
        value={id || date ? driverItem.value.toString() : ''}
        onChange={(value) => onChange({ ...driverItem, value }, type)}
        styleContainer={styles.driversItemRate}
        values={rateOptions}
        disabled={!driverItem.name}
        checkId={false}
      />
    </div>
  );
};

DriversColumnItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.number,
    type: PropTypes.string,
    date: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string.isRequired
};

DriversColumnItem.defaultProps = {
  data: {
    id: null,
    name: '',
    value: 0,
    type: '',
    date: ''
  },
  maxLength: undefined
};

export default DriversColumnItem;
