import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './sass/ScenarioBreadcrumbs.module.scss';

const ScenarioBreadcrumbs = ({
  breadcrumbs
}) => {
  const scenarios = {
    name: 'Scenarios',
    link: '/admin/dashboard/scenarios'
  };

  return (
    <div className={styles.container}>
      {[scenarios, ...breadcrumbs].map(({ name, link }) => {
        return (
          <React.Fragment key={`${name}-${link}`}>
            {link
              ? <Link to={link}>{name}</Link>
              : <span>{name}</span>}
          </React.Fragment>
        );
      })}
    </div>
  );
};

ScenarioBreadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string
  }))
};

ScenarioBreadcrumbs.defaultProps = {
  breadcrumbs: []
};

export default ScenarioBreadcrumbs;
