import React from 'react';
import PropTypes from 'prop-types';
import { getEmptyArray } from '../../utils/helpers';
import CoreMeaningItem from './components/CoreMeaningItem';
import styles from './sass/CoreMeaning.module.scss';

const CoreMeaning = ({ meaningsList, meanings, onChange }) => {
  return (
    <div className={styles.coreMeaningWrapper}>
      {getEmptyArray(5).map((item, index) => {
        return (
          <CoreMeaningItem
            key={`coreMeaningItem-${index.toString()}`}
            meaningsList={meaningsList}
            data={meanings[index]}
            onChange={onChange}
          />
        );
      })}
      <div className={styles.line} />
    </div>
  );
};

CoreMeaning.propTypes = {
  meaningsList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  meanings: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.number,
    description: PropTypes.string
  })),
  onChange: PropTypes.func.isRequired
};

CoreMeaning.defaultProps = {
  meaningsList: [],
  meanings: []
};

export default CoreMeaning;
