import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  convertFromIdToName,
  deleteSpacesInString
} from '../../../utils/helpers';
import Input from '../../../components/Input/Input';
import SelectInput from '../../../components/SelectInput/SelectInput';
import styles from '../sass/ProjectModalForm.module.scss';

const ProjectModalForm = ({
  onUpdateProject, industriesList, project, textError,
  currencyList, scenariosList, isDuplicate
}) => {
  const { t } = useTranslation();

  const currency = useMemo(() => {
    const currencyItem = currencyList.find(({ symbol }) => symbol === project?.currency);
    return currencyItem ? currencyItem.symbol : '';
  }, [project?.currency, currencyList]);

  return (
    <>
      <Input
        onChange={(e) => onUpdateProject('name', deleteSpacesInString(e.target.value))}
        name="projectName"
        id="projectName"
        title={t('projectName')}
        value={project?.name || ''}
      />
      {!isDuplicate && (
        <SelectInput
          onChange={(idIndustry) => onUpdateProject('industry', {
            id: idIndustry,
            name: convertFromIdToName(industriesList, idIndustry)
          })}
          name="industry"
          title={t('industry')}
          values={industriesList}
          styleDropdownContent={styles.dropdownContent}
          value={convertFromIdToName(industriesList, project?.industry?.id)}
        />
      )}
      <SelectInput
        onChange={(value) => onUpdateProject('currency', value)}
        name="currency"
        title={t('currency')}
        values={currencyList?.map(({ symbol }) => ({ name: symbol }))}
        value={currency}
        checkId={false}
      />
      {textError && <span className={styles.textError}>{textError}</span>}
      <textarea
        name="description"
        id="description"
        rows={6}
        placeholder={t('description')}
        value={project?.description || ''}
        onChange={(e) => onUpdateProject('description', deleteSpacesInString(e.target.value))}
        maxLength={999}
      />

      {(!project?.id || isDuplicate) && !!scenariosList.length && (
        <SelectInput
          onChange={(value) => onUpdateProject('scenario', value)}
          name="scenario"
          title={t('scenario')}
          values={scenariosList}
          value={convertFromIdToName(scenariosList, project?.scenario)}
          styleContainer={styles.selectInput}
        />
      )}
    </>
  );
};

ProjectModalForm.propTypes = {
  onUpdateProject: PropTypes.func.isRequired,
  industriesList: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
  project: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    industry: PropTypes.shape({
      id: PropTypes.number,
    }),
    currency: PropTypes.string,
    scenario: PropTypes.number,
  }),
  textError: PropTypes.string.isRequired,
  currencyList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    symbol: PropTypes.string,
  })).isRequired,
  scenariosList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  isDuplicate: PropTypes.bool.isRequired
};

ProjectModalForm.defaultProps = {
  industriesList: [],
  project: {
    id: 0,
    industry_id: 0,
    scenario: 0,
    name: '',
    description: '',
  }
};

export default memo(ProjectModalForm);
