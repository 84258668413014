import TipEmotionalDriver from '../components/M1Tips/TipEmotionalDriver';
import TipFinancialDriver from '../components/M1Tips/TipFinancialDriver';
import TipFunctionalDriver from '../components/M1Tips/TipFunctionalDriver';
import TipOrganizationEmotionalDriver from '../components/OrganizationTips/TipOrganizationEmotionalDriver';
import TipOrganizationFinancialDriver from '../components/OrganizationTips/TipOrganizationFinancialDriver';
import TipOrganizationFunctionalDriver from '../components/OrganizationTips/TipOrganizationFunctionalDriver';
import TipStakeholderEmotionalDriver from '../components/StakeholderTips/TipStakeholderEmotionalDriver';
import TipStakeholderFinancialDriver from '../components/StakeholderTips/TipStakeholderFinancialDriver';
import TipStakeholderFunctionalDriver from '../components/StakeholderTips/TipStakeholderFunctionalDriver';

export const driversTypeCustomer = {
  emotional: {
    type: 'emotional',
    tip: <TipEmotionalDriver />,
    title: 'emotionalDrivers',
    textButton: 'createNewDriver'
  },
  financial: {
    type: 'financial',
    tip: <TipFinancialDriver />,
    title: 'financialDrivers',
    textButton: 'createNewDriver'
  },
  functional: {
    type: 'functional',
    tip: <TipFunctionalDriver />,
    title: 'functionalDrivers',
    textButton: 'createNewDriver'
  },
};

export const driversTypeScenario = {
  emotional: {
    type: 'emotional',
    title: 'emotionalDrivers',
    textButton: 'createNewDriver'
  },
  financial: {
    type: 'financial',
    title: 'financialDrivers',
    textButton: 'createNewDriver'
  },
  functional: {
    type: 'functional',
    title: 'functionalDrivers',
    textButton: 'createNewDriver'
  },
};

export const driversTypeOrganization = {
  emotional: {
    type: 'emotional',
    tip: <TipOrganizationEmotionalDriver />,
    title: 'emotionalAttr',
    textButton: 'addBrandAttr'
  },
  financial: {
    type: 'financial',
    tip: <TipOrganizationFinancialDriver />,
    title: 'financialAttr',
    textButton: 'addBrandAttr'
  },
  functional: {
    type: 'functional',
    tip: <TipOrganizationFunctionalDriver />,
    title: 'functionalAttr',
    textButton: 'addBrandAttr'
  },
};

export const driversTypeStakeholder = {
  emotional: {
    type: 'emotional',
    tip: <TipStakeholderEmotionalDriver />,
    title: 'emotionalDrivers',
    textButton: 'createNewDriver'
  },
  financial: {
    type: 'financial',
    tip: <TipStakeholderFinancialDriver />,
    title: 'financialDrivers',
    textButton: 'createNewDriver'
  },
  functional: {
    type: 'functional',
    tip: <TipStakeholderFunctionalDriver />,
    title: 'functionalDrivers',
    textButton: 'createNewDriver'
  },
};
