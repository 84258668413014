import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import styles from '../sass/ProjectCard.module.scss';

import ProjectLogo from '../../../assets/icons/projectIcon.svg';
import { ReactComponent as Edit } from '../../../assets/icons/edit.svg';

const ProjectCard = ({ onClick, name, id }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className={styles.container} role="presentation" onClick={() => navigate(`/${id}/dashboard`)}>
      <Button
        text={t('edit')}
        onClick={onClick}
        style={styles.edit}
        styleButton={styles.editButton}
        icon={<Edit />}
      />
      <div className={styles.imgContainer}>
        <img src={ProjectLogo} alt="avatar" />
      </div>
      <div className={styles.text}>{name}</div>
    </div>
  );
};

ProjectCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};

export default memo(ProjectCard);
