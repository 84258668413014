import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/Auth.module.scss';

const AuthLayout = ({ children }) => (
  <div className={styles.container}>
    <div className={styles.content}>
      <div className={styles.wrapper}>
        {children}
      </div>
    </div>
  </div>
);

AuthLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default AuthLayout;
