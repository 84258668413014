export const SET_M3_REQUEST = 'SET_M3_REQUEST';
export const SET_M3_FAILED = 'SET_M3_FAILED';
export const SET_M3_SEGMENT_LIST_SUCCESS = 'SET_M3_SEGMENT_LIST_SUCCESS';
export const SET_M3_TOP_MARKET_DRIVERS_SUCCESS = 'SET_M3_TOP_MARKET_DRIVERS_SUCCESS';
export const SET_M3_ORGANIZATION_DRIVERS_SUCCESS = 'SET_M3_ORGANIZATION_DRIVERS_SUCCESS';
export const SET_M3_COMPETITORS_DRIVERS_SUCCESS = 'SET_M3_COMPETITORS_DRIVERS_SUCCESS';
export const SET_M3_CHANGE_SEGMENT_SUCCESS = 'SET_M3_CHANGE_SEGMENT_SUCCESS';
export const CLEAN_M3 = 'CLEAN_M3';
export const SET_M3_OWN_ORGANIZATION_NAME_SUCCESS = 'SET_M3_OWN_ORGANIZATION_NAME_SUCCESS';
export const SET_M3_OWN_ORGANIZATION_OFFERING_SUCCESS = 'SET_M3_OWN_ORGANIZATION_OFFERING_SUCCESS';
