import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resendEmailAction } from '../../store/signUp/actions';
import Page from '../../layout/Page/Page';
import AuthLayout from '../../layout/AuthLayout/AuthLayout';
import Button from '../../components/Button/Button';
import Title from '../../components/Title/Title';
import styles from './sass/VerifyEmail.module.scss';
import Check from '../../assets/icons/check.svg';

const VerifyEmail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { signUp: { loading } } = useSelector(({ signUp }) => ({ signUp }));

  const handleResendEmail = useCallback(() => {
    dispatch(resendEmailAction());
  }, []);

  const handleNavigation = useCallback(() => navigate('/login'), []);

  return (
    <Page simpleHeader loader={loading} whiteTheme>
      <AuthLayout>
        <img src={Check} className={styles.icon} alt="check-icon" />
        <Title text={t('thankYouForRegistration')} style={styles.title} />
        <div className={styles.text}>{t('youWillGetValidation')}</div>
        <div className={styles.resend}>
          {`${t('ifYouDidNotReceiveIt')} `}
          <span role="presentation" onClick={handleResendEmail}>{t('resendEmail')}</span>
        </div>
        <Button text={t('ok')} style={styles.button} onClick={handleNavigation} />
      </AuthLayout>
    </Page>
  );
};

export default VerifyEmail;
