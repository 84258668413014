import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button/Button';
import O5ItemValuesRow from './O5ItemValuesRow';
import styles from '../sass/O5.module.scss';

import { ReactComponent as PlusIcon } from '../../../assets/icons/plusBlue.svg';

const O5ItemValues = ({
  currency,
  list,
  onChangeRevenue,
  onDeleteRevenue,
}) => {
  const { t } = useTranslation();
  const [newRow, setNewRow] = useState(false);

  const revenueList = useMemo(() => {
    return newRow
      ? [
        ...list,
        {
          id: 0,
          capital_sources_name: null,
          capital_sources_value: 0,
          coast_name: null,
          coast_value: 0,
          revenue_name: null,
          revenue_value: 0,
        }
      ]
      : list;
  }, [list, newRow]);

  return (
    <div className={styles.valuesContainer}>
      {!!revenueList.length && (
        <div className={styles.valuesHeader}>
          <span>{t('revenue')}</span>
          <span>{t('costs')}</span>
          <span>{t('capitalSources')}</span>
        </div>
      )}
      
      {revenueList.map(({
        id,
        revenue_name: revenueName,
        revenue_value: revenueValue,
        coast_name: coastName,
        coast_value: coastValue,
        capital_sources_name: capitalSourcesName,
        capital_sources_value: capitalSourcesValue,
      }) => (
        <O5ItemValuesRow
          key={id}
          id={id}
          currency={currency}
          revenueName={revenueName || ''}
          revenueValue={revenueValue ? revenueValue.toString() : ''}
          coastName={coastName || ''}
          coastValue={coastValue ? coastValue.toString() : ''}
          capitalSourcesName={capitalSourcesName || ''}
          capitalSourcesValue={capitalSourcesValue ? capitalSourcesValue.toString() : ''}
          onChangeRevenue={(values) => onChangeRevenue(values, () => setNewRow(false))}
          onDeleteRevenue={onDeleteRevenue}
        />
      ))}

      <Button
        text={t('addRevenue')}
        onClick={() => setNewRow(true)}
        icon={<PlusIcon />}
        style={styles.buttonContainer}
        disabled={newRow || list.length > 9}
      />
    </div>
  );
};

O5ItemValues.propTypes = {
  currency: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    revenue_name: PropTypes.string,
    revenue_value: PropTypes.number,
    coast_name: PropTypes.string,
    coast_value: PropTypes.number,
    capital_sources_name: PropTypes.string,
    capital_sources_value: PropTypes.number,
  })).isRequired,
  onChangeRevenue: PropTypes.func.isRequired,
  onDeleteRevenue: PropTypes.func.isRequired,
};

export default O5ItemValues;
