import {
  SET_ADMIN_USERS_SUCCESS,
  SET_ADMIN_USERS_REQUEST,
  SET_ADMIN_USERS_FAILED,
  SET_ADMIN_USERS_DELETE_REQUEST,
  SET_ADMIN_USERS_DELETE_FAILED,
  CLEAN_ADMIN_USERS
} from './types';

const initialState = {
  users: [],
  totalCount: 0,
  industriesList: [],
  loading: false,
  error: null,
};

export default function adminUsers(state = initialState, action) {
  switch (action.type) {
    case SET_ADMIN_USERS_SUCCESS:
      return {
        ...state,
        users: action.data,
        totalCount: action.total,
        loading: false,
        error: null,
      };
    case SET_ADMIN_USERS_REQUEST:
    case SET_ADMIN_USERS_DELETE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_ADMIN_USERS_FAILED:
    case SET_ADMIN_USERS_DELETE_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case CLEAN_ADMIN_USERS:
      return initialState;
    default:
      return state;
  }
}
