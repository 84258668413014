import React, { useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  cleanO4,
  createPartnershipKeyAction,
  deletePartnershipKeyAction,
  getPartnershipKeyListAction,
  updatePartnershipKeyAction
} from '../../store/o4/actions';
import { PARTNERSHIP } from '../../constants/general';
import PARTNERSHIPS_VALUES from '../../constants/partnershipsValues';
import { getBreadcrumbs, getSwitchPages } from '../../utils/helpers';
import Page from '../../layout/Page/Page';
import O4Item from './components/O4Item';
import styles from './sass/O4.module.scss';

const O4 = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    o4: {
      offerings, potential_partnership: potential, resource, loading
    },
    project: { name: projectName }
  } = useSelector(({ o4, project }) => ({ o4, project }), shallowEqual);

  const breadcrumbs = useMemo(() => (
    getBreadcrumbs(projectName, `/${id}/dashboard`, 'Business Model')
  ), [projectName, id]);

  const switchPages = useMemo(() => (
    getSwitchPages(`/${id}/o3`, `/${id}/o5`)
  ), [id]);

  useEffect(() => {
    dispatch(getPartnershipKeyListAction(id));
    return () => {
      dispatch(cleanO4());
    };
  }, []);

  const allPartnerships = useMemo(() => {
    const sortResources = [...resource].sort((a, b) => b.value - a.value);
    return [offerings, potential, sortResources];
  }, [offerings, potential, resource]);

  const handleChangePartnershipKey = useCallback((values, type) => {
    if (values.id) {
      dispatch(updatePartnershipKeyAction(id, values, type));
    } else dispatch(createPartnershipKeyAction(id, values, type));
  }, []);

  const handleDeletePartnershipKey = useCallback((idPartnership, type) => {
    dispatch(deletePartnershipKeyAction(id, idPartnership, type));
  }, []);

  return (
    <Page
      pageContainer={styles.container}
      breadcrumbs={breadcrumbs}
      switchPages={switchPages}
      loader={loading}
    >
      <div className={styles.contentContainer}>
        {PARTNERSHIPS_VALUES.map((item, index) => (
          <O4Item
            title={t(item.title)}
            tip={item.tip ? <p>{t(item.tip)}</p> : null}
            data={allPartnerships[index]}
            type={PARTNERSHIP[item.type]}
            onChange={handleChangePartnershipKey}
            onDelete={handleDeletePartnershipKey}
            key={item.title}
            disabled={item.isDisabled}
            placeholder={t(item.placeholder)}
          />
        ))}
      </div>
    </Page>
  );
};

export default O4;
