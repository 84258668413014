import React from 'react';
import PropTypes from 'prop-types';
import TopMarketDrivers from './components/TopMarketDrivers';
import Competitors from './components/Competitors';
import styles from './sass/MarketDrivers.module.scss';

const MarketDrivers = ({
  textHeader,
  topMarketDrivers,
  organizationDrivers,
  competitorsDrivers,
  onUpdate,
  onSelect,
  onUpdateTopMarketDriver,
  onUpdateOwnOrganization,
  onChangeOwnOrganization,
  ownOrganizationName,
  ownOrganizationOffering,
  tipTopMarket,
  onDeleteTopMarketDriver,
  placeholderTopMarketDrivers,
  meaningsList
}) => {
  return (
    topMarketDrivers.length ? (
      <div className={styles.driversContainer}>
        <TopMarketDrivers
          textHeader={textHeader}
          topMarketDrivers={topMarketDrivers}
          onUpdateTopMarketDriver={onUpdateTopMarketDriver}
          tipTopMarket={tipTopMarket}
          onDeleteTopMarketDriver={onDeleteTopMarketDriver}
          placeholderTopMarketDrivers={placeholderTopMarketDrivers}
          meaningsList={meaningsList}
        />
        <Competitors
          organizationDrivers={organizationDrivers}
          competitorsDrivers={competitorsDrivers}
          topMarketDrivers={topMarketDrivers}
          onUpdate={onUpdate}
          onUpdateOwnOrganization={onUpdateOwnOrganization}
          onSelect={onSelect}
          onChangeOwnOrganization={onChangeOwnOrganization}
          ownOrganizationName={ownOrganizationName}
          ownOrganizationOffering={ownOrganizationOffering}
        />
      </div>
    ) : null
  );
};

MarketDrivers.propTypes = {
  textHeader: PropTypes.string.isRequired,
  topMarketDrivers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    style: PropTypes.shape({
      color: PropTypes.string,
      fontWeight: PropTypes.string,
    }),
    name: PropTypes.string,
    value: PropTypes.number,
    meaningId: PropTypes.number
  })),
  organizationDrivers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    value: PropTypes.number,
    driver_id: PropTypes.number
  })),
  competitorsDrivers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    drivers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      driver_id: PropTypes.number,
      value: PropTypes.number
    }))
  })),
  onUpdate: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onUpdateTopMarketDriver: PropTypes.func.isRequired,
  onUpdateOwnOrganization: PropTypes.func.isRequired,
  onChangeOwnOrganization: PropTypes.func.isRequired,
  ownOrganizationName: PropTypes.string.isRequired,
  tipTopMarket: PropTypes.node.isRequired,
  onDeleteTopMarketDriver: PropTypes.func.isRequired,
  ownOrganizationOffering: PropTypes.string.isRequired,
  placeholderTopMarketDrivers: PropTypes.string.isRequired,
  meaningsList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }))
};

MarketDrivers.defaultProps = {
  topMarketDrivers: [],
  organizationDrivers: [],
  competitorsDrivers: [],
  meaningsList: []
};

export default MarketDrivers;
