import { useCallback } from 'react';

const useOnBlur = (prev, current, onChange, onDelete) => {
  return useCallback(() => {
    const defaultDelete = onDelete || onChange;
    if (!current && prev) defaultDelete();
    else if (current !== prev) onChange();
  }, [prev, current, onChange, onDelete]);
};

export default useOnBlur;
