import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/Button/Button';
import styles from '../sass/AdminStakeholders.module.scss';

import { ReactComponent as Edit } from '../../../../assets/icons/edit.svg';
import { ReactComponent as Delete } from '../../../../assets/icons/deleteAdmin.svg';

const AdminStakeholdersRow = ({
  createdAt,
  stakeholder,
  name,
  id,
  onDelete,
}) => {
  const navigate = useNavigate();
  return (
    <div className={`${styles.row} ${styles.line}`}>
      <div className={`${styles.column} ${styles.createDate}`}>
        <span>{createdAt}</span>
      </div>
      <div className={`${styles.column} ${styles.stakeholder}`}>
        <span>{stakeholder}</span>
      </div>
      <div className={`${styles.column} ${styles.stakeholderName}`}>
        <span>{name}</span>
      </div>
      <div className={`${styles.column} ${styles.actions}`}>
        <Button
          icon={<Edit />}
          style={styles.buttonActionContainer}
          styleButton={styles.button}
          onClick={() => navigate(`${id}`)}
        />
        <Button
          icon={<Delete width={12} height={12} />}
          style={styles.buttonActionContainer}
          styleButton={styles.button}
          onClick={() => onDelete(id)}
        />
      </div>
    </div>
  );
};

AdminStakeholdersRow.propTypes = {
  createdAt: PropTypes.string.isRequired,
  stakeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default AdminStakeholdersRow;
