import React, {
  useMemo, useEffect, useState, useCallback
} from 'react';
import { useParams } from 'react-router-dom';
import {
  batch, shallowEqual, useDispatch, useSelector
} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  changeOwnOrganizationAction,
  cleanM3,
  deleteCompetitorAction,
  getAddCompetitorAction,
  getChangeSegmentAction,
  getCompetitorDriversAction,
  getDriversAndOrganizationAction,
  getEditCompetitorAction,
  getSegmentCustomerListsAction,
  getUpdateTopMarketDriverAction,
  handleDeleteTopMarketDriverAction,
  updateCompetitorsDriverAction, updateMeaningAction,
  updateOwnOrganizationDriverAction
} from '../../store/m3/actions';
import { getBreadcrumbs, getSwitchPages } from '../../utils/helpers';
import Page from '../../layout/Page/Page';
import MarketDrivers from '../../components/MarketDrivers/MarketDrivers';
import NoData from '../../components/NoData/NoData';
import Legend from '../../components/Legend/Legend';
import ModalConfirmDelete from '../../components/ModalConfirmDelete/ModalConfirmDelete';
import VideoModal from '../../components/VideoModal/VideoModal';
import M3Header from './components/M3Header';
import M3TipLegend from './components/M3TipLegend';
import M3TipTopMarket from './components/M3TipTopMarket';
import styles from './sass/M3.module.scss';
import { getMeaningListAction } from '../../store/general/actions';

const M3 = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    m3: {
      segmentCustomerLists,
      loading,
      segmentId,
      topMarketDrivers,
      organizationDrivers,
      competitorsDrivers,
      ownOrganizationName,
      ownOrganizationOffering
    },
    project: { name: projectName },
    general: { meaningsList }
  } = useSelector(({ m3, project, general }) => ({ m3, project, general }), shallowEqual);

  const [addCompetitorModal, setAddCompetitorModal] = useState(false);
  const [competitor, setCompetitor] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);

  const breadcrumbs = useMemo(() => (
    getBreadcrumbs(projectName, `/${id}/dashboard`, 'Competitive Analysis')
  ), [projectName, id]);

  const switchPages = useMemo(() => (
    getSwitchPages(`/${id}/m2`, `/${id}/m4`)
  ), [id]);

  useEffect(() => {
    batch(() => {
      dispatch(getSegmentCustomerListsAction(id));
      dispatch(getDriversAndOrganizationAction(id));
      dispatch(getCompetitorDriversAction(id));
      dispatch(getMeaningListAction());
    });
    return () => {
      dispatch(cleanM3());
    };
  }, []);

  const handleAddCompetitorModal = useCallback((value) => {
    if (!value && competitor) setCompetitor(null);
    setAddCompetitorModal(value);
  }, [competitor]);

  const handleAddCompetitor = useCallback((name, offering) => {
    dispatch(getAddCompetitorAction(id, name, offering));
  }, []);

  const handleUpdateCompetitorDrivers = useCallback((driver, competitorId) => {
    dispatch(updateCompetitorsDriverAction(driver, competitorId, id));
  }, []);

  const handleUpdateOwnOrganizationDrivers = useCallback((driver) => {
    dispatch(updateOwnOrganizationDriverAction(driver, id));
  }, []);

  const handleChangeSegment = useCallback((idSegment) => {
    dispatch(getChangeSegmentAction(idSegment, id));
  }, []);

  const handleSelectCompetitor = useCallback((values) => {
    setCompetitor(values);
    handleAddCompetitorModal(true);
  }, []);

  const handleDeleteCompetitor = useCallback((idCompetitor) => {
    dispatch(deleteCompetitorAction(idCompetitor, id));
  }, []);

  const handleEditCompetitor = useCallback((idCompetitor, newValue, newOffering) => {
    dispatch(getEditCompetitorAction(idCompetitor, newValue, newOffering));
  }, []);

  const handleUpdateTopMarketDriver = useCallback((driver) => {
    if (driver?.meaningId) {
      dispatch(updateMeaningAction(id, driver));
    } else {
      dispatch(getUpdateTopMarketDriverAction(id, driver));
    }
  }, []);

  const handleChangeOwnOrganization = useCallback((name, offering) => {
    dispatch(changeOwnOrganizationAction(id, name, offering));
  }, []);

  const handleDeleteTopMarketDriver = useCallback((idDriver) => {
    dispatch(handleDeleteTopMarketDriverAction(id, idDriver));
  }, []);

  const handleOpenVideo = useCallback((url) => {
    setVideoUrl(url);
  }, []);

  const segmentList = useMemo(() => {
    const allSegment = {
      name: 'All',
      id: 0
    };
    return [allSegment, ...segmentCustomerLists];
  }, [segmentCustomerLists]);

  return (
    <Page
      breadcrumbs={breadcrumbs}
      switchPages={switchPages}
      pageContainer={styles.container}
      loader={loading}
    >
      {(!loading || !!topMarketDrivers.length) && (
        <div className={styles.contentContainer}>
          <M3Header
            segmentList={segmentList}
            segmentId={segmentId}
            onAddCompetitor={() => handleAddCompetitorModal(true)}
            onChange={handleChangeSegment}
            disabledAddCompetitor={competitorsDrivers.length > 7 || !topMarketDrivers.length}
          />

          <MarketDrivers
            textHeader={t('topMarketDecisionDrivers')}
            topMarketDrivers={topMarketDrivers}
            organizationDrivers={organizationDrivers}
            competitorsDrivers={competitorsDrivers}
            onUpdate={handleUpdateCompetitorDrivers}
            onUpdateOwnOrganization={handleUpdateOwnOrganizationDrivers}
            onSelect={handleSelectCompetitor}
            onUpdateTopMarketDriver={handleUpdateTopMarketDriver}
            onChangeOwnOrganization={handleChangeOwnOrganization}
            ownOrganizationName={ownOrganizationName}
            ownOrganizationOffering={ownOrganizationOffering}
            tipTopMarket={<M3TipTopMarket />}
            onDeleteTopMarketDriver={handleDeleteTopMarketDriver}
            placeholderTopMarketDrivers={t('driverPlaceholder')}
            meaningsList={meaningsList}
          />
          {!topMarketDrivers.length && (
            <NoData id={id} projectName={projectName} />
          )}
          <Legend tip={<M3TipLegend onOpenVideo={handleOpenVideo} />} />

          {addCompetitorModal && (
            <ModalConfirmDelete
              onClose={() => handleAddCompetitorModal(false)}
              onAdd={handleAddCompetitor}
              onEdit={handleEditCompetitor}
              onDelete={handleDeleteCompetitor}
              value={competitor}
              title={competitor ? t('editCompetitor') : t('addCompetitor')}
              text={t('competitor')}
              isCompetitor
              placeholder={t('competitorNamePlaceholder')}
            />
          )}
        </div>
      )}

      {!!videoUrl && (
        <VideoModal url={videoUrl} onClose={() => setVideoUrl(null)} />
      )}
    </Page>
  );
};

export default M3;
