import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordAction } from '../../store/auth/actions';
import { createPasswordValidationSchema } from '../../utils/validation';
import Page from '../../layout/Page/Page';
import AuthLayout from '../../layout/AuthLayout/AuthLayout';
import Title from '../../components/Title/Title';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import styles from './sass/ResetPassword.module.scss';

const ResetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  const { auth: { loading } } = useSelector(({ auth }) => ({ auth }));

  const email = useMemo(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('email');
  }, []);

  const {
    handleSubmit,
    values: {
      password, confirmPassword
    },
    errors,
    handleChange,
    validateForm,
  } = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    onSubmit: (values) => validateForm(values)
      .then(() => dispatch(resetPasswordAction(password, confirmPassword, token, navigate, email))),
    validationSchema: createPasswordValidationSchema,
    validateOnBlur: false,
    validateOnChange: false
  });

  const handleBackNavigation = useCallback(() => navigate('/login'), []);

  return (
    <Page simpleHeader loader={loading} whiteTheme>
      <AuthLayout>
        <Title text={t('createNewPassword')} />
        <div className={styles.container}>
          <form onSubmit={handleSubmit}>
            <Input
              onChange={handleChange}
              name="password"
              title={t('password')}
              value={password}
              type="password"
              errorText={errors.password}
            />
            <Input
              onChange={handleChange}
              name="confirmPassword"
              title={t('confirmPassword')}
              value={confirmPassword}
              type="password"
              errorText={errors.confirmPassword}
            />
            <Button
              text={t('send')}
              styleButton={styles.button}
              type="submit"
            />
            <Button
              text={t('cancel')}
              style={styles.buttonContainer}
              onClick={handleBackNavigation}
            />
          </form>
        </div>
      </AuthLayout>
    </Page>
  );
};

export default ResetPassword;
