import React from 'react';
import PropTypes from 'prop-types';
import O5ItemHeader from './O5ItemHeader';
import O5ItemValues from './O5ItemValues';
import styles from '../sass/O5.module.scss';

const O5Item = ({
  id,
  name,
  value,
  currency,
  timeIntervalId,
  list,
  timeIntervals,
  onChangeOfferingRevenues,
  onDeleteOfferingRevenues,
  onChangeRevenue,
  onDeleteRevenue,
}) => {
  return (
    <div className={styles.itemContainer}>
      <O5ItemHeader
        id={id}
        name={name}
        value={value}
        timeIntervalId={timeIntervalId}
        timeIntervals={timeIntervals}
        onChangeOfferingRevenues={onChangeOfferingRevenues}
        onDeleteOfferingRevenues={onDeleteOfferingRevenues}
      />
      <O5ItemValues
        list={list}
        currency={currency}
        onChangeRevenue={onChangeRevenue}
        onDeleteRevenue={onDeleteRevenue}
      />
    </div>
  );
};

O5Item.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  timeIntervalId: PropTypes.number.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    revenue_name: PropTypes.string,
    revenue_value: PropTypes.number,
    coast_name: PropTypes.string,
    coast_value: PropTypes.number,
    capital_sources_name: PropTypes.string,
    capital_sources_value: PropTypes.number,
  })).isRequired,
  timeIntervals: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  onChangeOfferingRevenues: PropTypes.func.isRequired,
  onDeleteOfferingRevenues: PropTypes.func.isRequired,
  onChangeRevenue: PropTypes.func.isRequired,
  onDeleteRevenue: PropTypes.func.isRequired,
};

export default O5Item;
