import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/Button/Button';
import styles from '../sass/AdminUsers.module.scss';

import { ReactComponent as Edit } from '../../../../assets/icons/edit.svg';
import { ReactComponent as Delete } from '../../../../assets/icons/deleteAdmin.svg';

const AdminUserUsersRow = ({
  id,
  firstName,
  lastName,
  email,
  role,
  projects,
  onDelete,
  onSwitchUser
}) => {
  const navigate = useNavigate();
  const projectName = projects[0]?.name || '';
  const industryName = projects[0]?.industry.name || '';
  return (
    <div className={`${styles.row} ${styles.line}`}>
      <div
        role="presentation"
        className={`${styles.column} ${styles.name}`}
        onClick={() => onSwitchUser(id)}
      >
        <span>{`${firstName} ${lastName}`}</span>
      </div>
      <div className={`${styles.column} ${styles.email}`}>
        <span title={email}>{email}</span>
      </div>
      <div className={styles.columnSmall}>
        <span>{role}</span>
      </div>
      <div className={styles.column}>
        <span title={industryName}>{industryName}</span>
      </div>
      <div
        className={styles.columnSmall}
        style={{ textTransform: 'uppercase' }}
      >
        <span>{projectName}</span>
      </div>
      <div className={styles.columnAction}>
        <Button
          icon={<Edit />}
          style={styles.buttonActionContainer}
          styleButton={styles.buttonAction}
          onClick={() => navigate(`user/${id}`)}
        />
        <Button
          icon={<Delete width={12} height={12} />}
          style={styles.buttonActionContainer}
          styleButton={styles.buttonAction}
          onClick={() => onDelete({ id, firstName, lastName })}
        />
      </div>
    </div>
  );
};

AdminUserUsersRow.propTypes = {
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  projects: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    industry: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  })).isRequired,
  onDelete: PropTypes.func.isRequired,
  onSwitchUser: PropTypes.func.isRequired
};

export default memo(AdminUserUsersRow);
