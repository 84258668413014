import React, { useMemo, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
  availableAction,
  cleanM2,
  deleteDriverAction,
  getCustomerSegmentsAction,
  loadMoreDetailedProfiles,
  updateDriverAction,
  updateMeaningAction
} from '../../store/m2/actions';
import { getMeaningListAction } from '../../store/general/actions';
import { getBreadcrumbs, getSwitchPages } from '../../utils/helpers';
import Page from '../../layout/Page/Page';
import NoData from '../../components/NoData/NoData';
import M2Item from './components/M2Item';
import styles from './sass/M2.module.scss';

const M2 = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    m2: { loading, customerSegmentsList },
    general: { meaningsList },
    project: { name: projectName }
  } = useSelector(({ m2, general, project }) => ({ m2, general, project }));

  const breadcrumbs = useMemo(() => {
    return getBreadcrumbs(projectName, `/${id}/dashboard`, 'Customer Segments');
  }, [projectName, id]);

  const switchPages = useMemo(() => {
    return getSwitchPages(`/${id}/m1`, `/${id}/m3`);
  }, [id]);

  useEffect(() => {
    batch(() => {
      dispatch(getCustomerSegmentsAction(id));
      dispatch(getMeaningListAction());
    });
    return () => {
      dispatch(cleanM2());
    };
  }, [id]);

  const handleChangeMeaning = useCallback((meaningItem, idProject, idSegment) => {
    dispatch(updateMeaningAction(meaningItem, idProject, idSegment));
  }, []);

  const handleChangeDriver = useCallback((driverItem, idProject, idSegment) => {
    dispatch(updateDriverAction(driverItem, idProject, idSegment));
  }, []);

  const handleLoadMoreDetailedProfiles = useCallback((nextLink, idSegment) => {
    dispatch(loadMoreDetailedProfiles(nextLink, idSegment));
  }, []);

  const handleChangeAvailable = useCallback((availableItem, idProject, idSegment) => {
    dispatch(availableAction(availableItem, idProject, idSegment));
  }, []);

  const handleCreateCustomer = useCallback((idSegment) => {
    navigate(`/${id}/m1`, { state: { idSegment } });
  }, [id]);

  const handleDeleteCustomerDriver = useCallback((idSegment, idDriver) => {
    dispatch(deleteDriverAction(idDriver, id, idSegment));
  }, []);

  return (
    <Page
      pageContainer={styles.container}
      breadcrumbs={breadcrumbs}
      switchPages={switchPages}
      loader={loading}
    >
      <div className={styles.contentContainer}>
        {customerSegmentsList.length ? customerSegmentsList.map(({
          id: idSegment,
          segment_name: segmentName,
          drivers,
          meanings,
          avatar,
          detailedProfiles,
          calculate,
        }) => {
          return (
            <M2Item
              key={idSegment}
              segmentName={segmentName}
              drivers={drivers}
              meanings={meanings}
              meaningsList={meaningsList}
              avatar={avatar?.url}
              onChangeMeaning={(meaningItem) => handleChangeMeaning(meaningItem, id, idSegment)}
              onChangeDriver={(driverItem) => handleChangeDriver(driverItem, id, idSegment)}
              detailedProfiles={detailedProfiles}
              onLoadMore={() => handleLoadMoreDetailedProfiles(detailedProfiles.links.next, idSegment)}
              idProject={id}
              calculate={calculate}
              onChangeAvailable={(availableItem) => handleChangeAvailable(availableItem, id, idSegment)}
              onCreateCustomer={() => handleCreateCustomer(idSegment)}
              onDeleteCustomerDriver={(idDriver) => handleDeleteCustomerDriver(idSegment, idDriver)}
            />
          );
        }) : (
          <NoData id={id} projectName={projectName} />
        )}
      </div>
    </Page>
  );
};

export default M2;
