import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useSelector, useDispatch, batch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getIndustriesList, registration } from '../../store/signUp/actions';
import { signUpValidationSchema } from '../../utils/validation';
import { convertFromIdToName } from '../../utils/helpers';
import { getCurrencyListAction } from '../../store/general/actions';
import Page from '../../layout/Page/Page';
import AuthLayout from '../../layout/AuthLayout/AuthLayout';
import Title from '../../components/Title/Title';
import Button from '../../components/Button/Button';
import RegistrationForm from './components/RegistrationForm';
import styles from './sass/Registration.module.scss';

const Registration = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    signUp: { industriesList, loading },
    general: { currencyList }
  } = useSelector(({ signUp, general }) => ({ signUp, general }));

  const {
    handleSubmit,
    values: {
      email, password, confirmPassword, firstName, industry, lastName, projectCompany, currency
    },
    errors,
    handleChange,
    setFieldValue,
    setFieldError,
    validateForm,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
      projectCompany: '',
      industry: undefined,
      currency: undefined
    },
    onSubmit: (values) => validateForm(values).then(() => {
      const currencyId = currencyList.find(({ symbol }) => symbol === values.currency)?.id;
      dispatch(registration({ ...values, currency: currencyId }, setFieldError, navigate));
    }),
    validationSchema: signUpValidationSchema,
    validateOnBlur: false,
    validateOnChange: false
  });

  useEffect(() => {
    batch(() => {
      dispatch(getIndustriesList());
      dispatch(getCurrencyListAction());
    });
  }, []);

  const handleChangeIndustry = useCallback((value) => {
    setFieldValue('industry', value);
  }, []);

  const handleChangeCurrency = useCallback((value) => {
    setFieldValue('currency', value);
  }, []);

  const handleBackNavigation = useCallback(() => navigate(-1), []);

  return (
    <Page simpleHeader loader={loading} whiteTheme>
      <AuthLayout>
        <Title text={t('registration')} Type="h1" />
        <form onSubmit={handleSubmit}>
          <RegistrationForm
            firstName={firstName}
            lastName={lastName}
            password={password}
            confirmPassword={confirmPassword}
            email={email}
            projectCompany={projectCompany}
            industry={convertFromIdToName(industriesList, industry)}
            currency={currency || ''}
            onChange={handleChange}
            onChangeIndustry={handleChangeIndustry}
            onChangeCurrency={handleChangeCurrency}
            industriesList={industriesList}
            currencyList={currencyList}
            errors={errors}
          />
          <Button type="submit" text={t('register')} styleButton={styles.buttonRegister} />
        </form>
        <Button
          text={t('cancel')}
          style={styles.buttonContainer}
          styleButton={styles.buttonCancel}
          onClick={handleBackNavigation}
        />
      </AuthLayout>
    </Page>
  );
};

export default Registration;
