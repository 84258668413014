import React, {
  useState, useCallback, useLayoutEffect
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { deleteSpacesInString } from '../../../utils/helpers';
import useOnBlur from '../../../hooks/useOnBlur';
import rateOptions from '../../../constants/rateOptions';
import Input from '../../../components/Input/Input';
import SelectInput from '../../../components/SelectInput/SelectInput';
import styles from '../sass/M2.module.scss';

const M2DriversItem = ({ data, onChange, onDeleteCustomerDriver }) => {
  const [driver, setDriver] = useState(data);
  const { t } = useTranslation();

  const { name, id } = data;

  useLayoutEffect(() => {
    setDriver(data);
  }, [data]);

  const handleChangeDriverName = useCallback((event) => {
    setDriver({
      ...driver,
      [event.target.name]: deleteSpacesInString(event.target.value)
    });
  }, [driver]);

  const handleBlurDriverName = useOnBlur(
    name,
    driver.name,
    () => onChange(driver),
    () => onDeleteCustomerDriver(id)
  );

  return (
    <div className={styles.itemContainer}>
      <Input
        style={styles.itemInput}
        value={driver.name}
        onChange={handleChangeDriverName}
        name="name"
        title={t('driverPlaceholder')}
        onBlur={handleBlurDriverName}
        disabled={!name}
      />
      <SelectInput
        title={t('rank')}
        name="value"
        onChange={(value) => onChange({ ...driver, value })}
        values={rateOptions}
        value={!id ? '' : driver.value.toString()}
        checkId={false}
        styleContainer={styles.itemSelectInput}
        disabled={!name}
      />
    </div>
  );
};

M2DriversItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.number,
    worksheet_id: PropTypes.number
  }),
  onChange: PropTypes.func.isRequired,
  onDeleteCustomerDriver: PropTypes.func.isRequired
};

M2DriversItem.defaultProps = {
  data: {
    id: null,
    name: '',
    value: 0,
    worksheet_id: null
  }
};

export default M2DriversItem;
