import {
  CLEAN_O1, SET_O1_FAILED, SET_O1_OFFERINGS_SUCCESS, SET_O1_REQUEST
} from './types';

const initialState = {
  offerings: [],
  loading: false,
  error: null
};

export default function o1(state = initialState, action) {
  switch (action.type) {
    case SET_O1_OFFERINGS_SUCCESS:
      return {
        ...state,
        offerings: action.data,
        loading: false,
        error: null
      };
    case SET_O1_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_O1_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case CLEAN_O1:
      return initialState;
    default:
      return state;
  }
}
