import React from 'react';
import PropTypes from 'prop-types';
import TitleWithTip from '../../../components/TitleWithTip/TitleWithTip';
import Photo from '../../../components/Photo/Photo';
import M7Offering from './M7Offering';
import M7TipContent from './M7TipContent';
import styles from '../sass/M7.module.scss';

const M7Item = ({
  name,
  avatar,
  offerings,
  onChangeOffering,
}) => {
  return (
    <div className={styles.itemContainer}>
      <TitleWithTip text={name} styleContainer={styles.title}>
        <M7TipContent />
      </TitleWithTip>
      <div className={styles.content}>
        <Photo url={avatar} />
        <M7Offering
          offerings={offerings}
          onChangeOffering={onChangeOffering}
        />
      </div>
    </div>
  );
};

M7Item.propTypes = {
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  offerings: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    message_for_offering: PropTypes.string,
    message_value: PropTypes.number,
  })),
  onChangeOffering: PropTypes.func.isRequired
};

M7Item.defaultProps = {
  avatar: '',
  offerings: []
};

export default M7Item;
