import {
  SET_M2_FAILED,
  SET_M2_REQUEST,
  SET_M2_CUSTOMER_SEGMENTS_SUCCESS, CLEAN_M2
} from './types';

const initialState = {
  customerSegmentsList: [],
  loading: false,
  error: null
};

export default function m2(state = initialState, action) {
  switch (action.type) {
    case SET_M2_CUSTOMER_SEGMENTS_SUCCESS:
      return {
        ...state,
        customerSegmentsList: action.data,
        loading: false,
        error: null
      };
    case SET_M2_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_M2_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case CLEAN_M2:
      return initialState;
    default:
      return state;
  }
}
