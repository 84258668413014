import React from 'react';
import PropTypes from 'prop-types';
import { convertFromIdToName } from '../../../../utils/helpers';
import NoData from '../../../../components/NoData/NoData';
import AdminTrendTrendsHeader from './AdminTrendTrendsHeader';
import AdminTrendTrendsRow from './AdminTrendTrendsRow';
import styles from '../sass/AdminTrends.module.scss';

const AdminTrendTrends = ({
  trends,
  onDelete,
  sortBy,
  sortOrder,
  onSort,
  industriesList
}) => {
  return (
    <div className={styles.trends}>
      {trends.length ? (
        <>
          <AdminTrendTrendsHeader
            sortBy={sortBy}
            sortOrder={sortOrder}
            onSort={onSort}
          />
          {trends.map(({
            id,
            influence,
            industry_id: industryId,
            name,
            trend_type: trendType,
            created_at: createdAt,
            value
          }) => (
            <AdminTrendTrendsRow
              key={id}
              id={id}
              influence={influence.name}
              industry={convertFromIdToName(industriesList, industryId)}
              category={trendType.name}
              name={name}
              createdAt={createdAt}
              value={value}
              onDelete={onDelete}
            />
          ))}
        </>
      ) : <NoData />}
    </div>
  );
};

AdminTrendTrends.propTypes = {
  trends: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    influence: PropTypes.shape({
      name: PropTypes.string
    }),
    trend_type: PropTypes.shape({
      name: PropTypes.string
    }),
    name: PropTypes.string,
  })),
  industriesList: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
  onDelete: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
};

AdminTrendTrends.defaultProps = {
  trends: [],
  industriesList: []
};

export default AdminTrendTrends;
