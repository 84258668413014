import React, { useMemo, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  batch, shallowEqual, useDispatch, useSelector 
} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  cleanM4,
  createDriverOfferAction, deleteDriverOfferAction,
  getCompetitorOfferAction,
  getCompetitorsAction,
  getDecisionDriversAction,
  getDriversListAction,
  getSegmentIdAction,
  getSegmentListAction,
  updateDriverOfferAction
} from '../../store/m4/actions';
import { getBreadcrumbs, getSwitchPages } from '../../utils/helpers';
import TitleWithTip from '../../components/TitleWithTip/TitleWithTip';
import Page from '../../layout/Page/Page';
import M4Chart from './components/M4Chart';
import M4Description from './components/M4Description';
import M4TipPositionContent from './components/M4TipPositionContent';
import styles from './sass/M4.module.scss';

const M4 = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    m4: {
      loading,
      segmentCustomerList,
      segmentId,
      driversList,
      decisionDriverX,
      decisionDriverY,
      competitorsList,
      driverOffer
    },
    project: { name: projectName }
  } = useSelector(({ m4, project }) => ({ m4, project }), shallowEqual);

  const breadcrumbs = useMemo(() => {
    return getBreadcrumbs(projectName, `/${id}/dashboard`, 'Positioning Visualizer');
  }, [projectName, id]);

  const switchPages = useMemo(() => {
    return getSwitchPages(`/${id}/m3`, `/${id}/m5`);
  }, [id]);

  useEffect(() => {
    batch(() => {
      dispatch(getDecisionDriversAction(id));
      dispatch(getSegmentListAction(id));
      dispatch(getDriversListAction(id));
      dispatch(getCompetitorsAction(id));
    });
    return () => {
      dispatch(cleanM4());
    };
  }, []);

  const {
    offerId, competitorId, our, delivers
  } = driverOffer;

  const driverXId = decisionDriverX?.id || 0;
  const driverYId = decisionDriverY?.id || 0;
  const competitorDriversX = decisionDriverX?.competitor_drivers || [];
  const competitorDriversY = decisionDriverY?.competitor_drivers || [];

  const segmentList = useMemo(() => {
    const allSegment = {
      name: 'All',
      id: 0
    };
    return [allSegment, ...segmentCustomerList];
  }, [segmentCustomerList]);

  const handleChangeSegment = useCallback((idSegment) => {
    dispatch(getSegmentIdAction(id, idSegment));
  }, []);

  const handleChangeDriverX = useCallback((driverId) => {
    if (driverYId && driverId !== driverXId) dispatch(getDecisionDriversAction(id, segmentId, driverId, driverYId));
  }, [driverYId, driverXId, segmentId]);

  const handleChangeDriverY = useCallback((driverId) => {
    if (driverYId && driverId !== driverYId) dispatch(getDecisionDriversAction(id, segmentId, driverXId, driverId));
  }, [driverYId, driverXId, segmentId]);

  const handleChangeDriverOffer = useCallback((values) => {
    if (!offerId && values) dispatch(createDriverOfferAction(values));
    if (offerId) dispatch(updateDriverOfferAction(values));
  }, [offerId]);

  const handleChangeCompetitor = useCallback((idCompetitor) => {
    dispatch(getCompetitorOfferAction(driverXId, driverYId, idCompetitor));
  }, [driverXId, driverYId]);

  const handleDeleteDriverOffer = useCallback(() => {
    dispatch(deleteDriverOfferAction());
  }, []);

  const handleNextDriverX = useCallback(() => {
    const driverList = driversList.filter((driver) => driver.id !== driverYId);
    if (driverList.length > 1) {
      const index = driverList.findIndex((driver) => driver.id === driverXId);
      const nextDriver = driverList[index + 1];
      if (nextDriver) {
        dispatch(getDecisionDriversAction(id, segmentId, nextDriver.id, driverYId));
      } else {
        dispatch(getDecisionDriversAction(id, segmentId, driverList[0].id, driverYId));
      }
    }
  }, [driversList, driverYId, driverXId, segmentId]);

  const handleNextDriverY = useCallback(() => {
    const driverList = driversList.filter((driver) => driver.id !== driverXId);
    if (driverList.length > 1) {
      const index = driverList.findIndex((driver) => driver.id === driverYId);
      const nextDriver = driverList[index + 1];
      if (nextDriver) {
        dispatch(getDecisionDriversAction(id, segmentId, driverXId, nextDriver.id));
      } else {
        dispatch(getDecisionDriversAction(id, segmentId, driverXId, driverList[0].id));
      }
    }
  }, [driversList, driverYId, driverXId, segmentId]);
  
  return (
    <Page
      breadcrumbs={breadcrumbs}
      switchPages={switchPages}
      pageContainer={styles.container}
      loader={loading}
    >
      <div className={styles.contentWrapper}>
        <TitleWithTip
          text={t('mostAdvantageousPositions')}
          styleContainer={styles.titleContainer}
          styleTitle={styles.title}
        >
          <M4TipPositionContent />
        </TitleWithTip>
        <div className={styles.content}>
          <M4Chart
            decisionDriverX={competitorDriversX}
            decisionDriverY={competitorDriversY}
            driverXId={driverXId}
            driverYId={driverYId}
            driversList={driversList}
            onChangeDriverX={handleChangeDriverX}
            onChangeDriverY={handleChangeDriverY}
            onNextDriverX={handleNextDriverX}
            onNextDriverY={handleNextDriverY}
          />
          <M4Description
            segmentId={segmentId}
            segmentList={segmentList}
            driversList={driversList}
            driverXId={driverXId}
            driverYId={driverYId}
            onChangeSegment={handleChangeSegment}
            onChangeDriverX={handleChangeDriverX}
            onChangeDriverY={handleChangeDriverY}
            competitorsList={competitorsList}
            delivers={delivers}
            our={our}
            competitorId={competitorId}
            onChangeDriverOffer={handleChangeDriverOffer}
            offerId={offerId}
            onChangeCompetitor={handleChangeCompetitor}
            onDeleteDriverOffer={handleDeleteDriverOffer}
          />
        </div>
      </div>
    </Page>
  );
};

export default M4;
