import {
  CLEAN_ADMIN_SCENARIOS,
  SET_ADMIN_SCENARIOS_FAILED,
  SET_ADMIN_SCENARIOS_REQUEST,
  SET_ADMIN_SCENARIOS_SUCCESS
} from './types';

const initialState = {
  scenarios: [],
  totalCount: 0,
  loading: false,
  error: null,
};

export default function adminScenarios(state = initialState, action) {
  switch (action.type) {
    case SET_ADMIN_SCENARIOS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_ADMIN_SCENARIOS_SUCCESS:
      return {
        ...state,
        scenarios: action.data,
        totalCount: action.total,
        loading: false,
        error: null,
      };
    case SET_ADMIN_SCENARIOS_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case CLEAN_ADMIN_SCENARIOS:
      return initialState;
    default:
      return state;
  }
}
