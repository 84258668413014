import React from 'react';
import { useTranslation } from 'react-i18next';
import Tip from '../../../components/Tip/Tip';

const M5TipSegment = () => {
  const { t } = useTranslation();
  return (
    <Tip>
      <p>
        {t('tipM5Segment')}
      </p>
    </Tip>
  );
};

export default M5TipSegment;
