import {
  CLEAN_O4,
  SET_O4_FAILED,
  SET_O4_PARTNERSHIP_KEY_LIST_SUCCESS,
  SET_O4_REQUEST,
  SET_O4_PARTNERSHIP_KEY_SUCCESS
} from './types';

const initialState = {
  offerings: [],
  potential_partnership: [],
  resource: [],
  loading: false,
  error: null
};

export default function o4(state = initialState, action) {
  switch (action.type) {
    case SET_O4_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_O4_PARTNERSHIP_KEY_LIST_SUCCESS:
      return {
        ...state,
        offerings: action.data.offerings,
        potential_partnership: action.data.potential_partnership,
        resource: action.data.resource,
        loading: false,
        error: null
      };
    case SET_O4_PARTNERSHIP_KEY_SUCCESS:
      return {
        ...state,
        [action.key]: action.data,
        loading: false,
        error: null
      };
    case SET_O4_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case CLEAN_O4:
      return initialState;
    default:
      return state;
  }
}
