export const markets = [
  {
    title: 'customers',
    link: '/m1',
    style: {
      backgroundColor: '#AF8CB0',
      backgroundColorItem: '#fff',
      borderRadius: '',
    },
    disabled: false,
    guideText: 'You can start anywhere but starting with Customers will give you the best results.'
  },
  {
    title: 'segments',
    link: '/m2',
    style: {
      backgroundColor: '#AF8CB0',
      backgroundColorItem: '#fff',
      borderRadius: '',
    },
    disabled: false,
  },
  {
    title: 'competitors',
    link: '/m3',
    style: {
      backgroundColor: '#AF8CB0',
      backgroundColorItem: '#fff',
      borderRadius: '',
    },
    disabled: false,
  },
  {
    title: 'position',
    link: '/m4',
    style: {
      backgroundColor: '#AF8CB0',
      backgroundColorItem: '#fff',
      borderRadius: '',
    },
    disabled: false,
  },
  {
    title: 'opportunities (value prop)',
    link: '/m5',
    style: {
      backgroundColor: '#AF8CB0',
      backgroundColorItem: '#fff',
      borderRadius: '',
    },
    disabled: false,
  },
  {
    title: 'offerings',
    link: '/m6',
    style: {
      backgroundColor: '#AF8CB0',
      backgroundColorItem: '#fff',
      borderRadius: '',
    },
    disabled: false,
  },
  {
    title: 'ext. messaging',
    link: '/m7',
    style: {
      backgroundColor: '#AF8CB0',
      borderRadius: '0 13px 13px 0',
      backgroundColorItem: '#fff',
    },
    disabled: false,
  },
  {
    title: 'vision',
    link: '/vision',
    style: {
      backgroundColor: 'transparent',
      backgroundColorItem: '#fff',
      borderRadius: '',
    },
    disabled: false,
  }
];

export const operations = [
  {
    title: 'capabilities',
    link: '/o1',
    style: {
      backgroundColor: '#3D8897',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'priorities',
    link: '/o2',
    style: {
      backgroundColor: '#3D8897',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'partnerships',
    link: '/o3',
    style: {
      backgroundColor: '#3D8897',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'business model',
    link: '/o4',
    style: {
      backgroundColor: '#3D8897',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'financials',
    link: '/o5',
    style: {
      backgroundColor: '#3D8897',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'teams & employees',
    link: '/o6',
    style: {
      backgroundColor: '#3D8897',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'culture/int. messaging',
    link: '/o7',
    style: {
      backgroundColor: '#3D8897',
      borderRadius: '0 13px 13px 0',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'mission',
    link: '/o8',
    style: {
      backgroundColor: 'transparent',
      backgroundColorItem: '#fff',
    }
  }
];

export const trends = [
  {
    title: 'nature',
    name: 'nature',
    link: '/trends',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      borderRadius: '13px 13px 0 0',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'society',
    name: 'society',
    link: '/trends',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      borderRadius: '13px 13px 0 0',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'economy',
    name: 'economy',
    link: '/trends',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      borderRadius: '13px 13px 0 0',
      backgroundColorItem: '#fff',
    },
    guideText: 'You can adjust and customize trends at any time or rely on the ones automatically added.'
  },
  {
    title: 'policy',
    name: 'policy',
    link: '/trends',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      borderRadius: '13px 13px 0 0',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'industry',
    name: 'industry',
    link: '/trends',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      borderRadius: '13px 13px 0 0',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'technology',
    name: 'technology',
    link: '/trends',
    style: {
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'impact',
    name: 'impact',
    link: '/impact',
    style: {
      backgroundColor: 'transparent',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'organization',
    name: 'organization',
    link: '/organization',
    style: {
      backgroundColor: 'transparent',
      backgroundColorItem: '#fff',
    }
  }
];

export const supplyChainFirst = [
  {
    title: 'suppliers',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'neighbors',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'shareholders',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'law firms',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'INDUSTRY ASSOCs',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'end users',
    link: '/stakeholders',
    style: {
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'empty7',
    isEmpty: true,
  },
  {
    title: 'empty8',
    isEmpty: true,
  }
];

export const supplyChainSecond = [
  {
    title: 'manufacturers',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      letterSpacing: '-0.7px',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'NGOs',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'individual/ fam.investors',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'gov local',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'unions',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'other',
    link: '/stakeholders',
    style: {
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'empty1',
    isEmpty: true
  },
  {
    title: 'empty2',
    isEmpty: true
  }
];

export const supplyChainThird = [
  {
    title: 'distributors',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'schools',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'institutional investors/ funds',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'gov regional',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      letterSpacing: '-0.5px',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'lobbyist',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      letterSpacing: '-0.5px',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'empty3',
    isEmpty: true,
  },
  {
    title: 'empty4',
    isEmpty: true,
  },
  {
    title: 'empty5',
    isEmpty: true,
  }
];

export const supplyChainFourth = [
  {
    title: 'retailers',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'media',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'CORPORATE VC',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      backgroundColorItem: '#fff',
      letterSpacing: '-0.5px',
    }
  },
  {
    title: 'gov national',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      letterSpacing: '-0.5px',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'empty5',
    isEmpty: true,
  },
  {
    title: 'empty6',
    isEmpty: true,
  },
  {
    title: 'empty7',
    isEmpty: true,
  },
  {
    title: 'empty8',
    isEmpty: true,
  }
];

export const supplyChainFifth = [
  {
    title: 'WHOLESALE',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'advertisers',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'BANKS',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'gov international',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      letterSpacing: '-0.5px',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'empty5',
    isEmpty: true,
  },
  {
    title: 'empty6',
    isEmpty: true,
  },
  {
    title: 'empty7',
    isEmpty: true,
  },
  {
    title: 'empty8',
    isEmpty: true,
  }
];

export const supplyChainSixth = [
  {
    title: 'OTHER PARTNER',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'promoters',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'RE/INSURERS',
    link: '/stakeholders',
    style: {
      backgroundColor: 'rgba(255,255,255, 0.3)',
      backgroundColorItem: '#fff',
    }
  },
  {
    title: 'empty4',
    isEmpty: true,
  },
  {
    title: 'empty5',
    isEmpty: true,
  },
  {
    title: 'empty6',
    isEmpty: true,
  },
  {
    title: 'empty7',
    isEmpty: true,
  },
  {
    title: 'empty8',
    isEmpty: true,
  }
];
