import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useOnBlur from '../../../hooks/useOnBlur';
import { deleteSpacesInString } from '../../../utils/helpers';
import Input from '../../../components/Input/Input';
import styles from '../sass/Trends.module.scss';

const TrendsItemMessage = ({
  trendMessage,
  onUpdateTrend,
  id
}) => {
  const { t } = useTranslation();
  const [trendMessageValue, setTrendMessageValue] = useState(trendMessage);

  const handleChangeTrendMessage = useCallback((e) => {
    const text = deleteSpacesInString(e.target.value);
    setTrendMessageValue(text);
  }, []);

  const handleBlurTrendMessage = useOnBlur(
    trendMessage,
    trendMessageValue,
    () => onUpdateTrend(trendMessageValue),
    () => onUpdateTrend(trendMessageValue),
  );

  return (
    <div className={styles.trendItemDescriptionContainer}>
      <Input
        onChange={handleChangeTrendMessage}
        style={styles.inputContainer}
        value={trendMessageValue || ''}
        onBlur={handleBlurTrendMessage}
        disabled={!id}
        title={t('messagePlaceholder')}
      />
    </div>
  );
};

TrendsItemMessage.propTypes = {
  trendMessage: PropTypes.string,
  onUpdateTrend: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired
};

TrendsItemMessage.defaultProps = {
  trendMessage: ''
};

export default TrendsItemMessage;
