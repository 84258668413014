import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useOnBlur from '../../../hooks/useOnBlur';
import { deleteSpacesInString } from '../../../utils/helpers';
import rateOptions from '../../../constants/rateOptions';
import Input from '../../../components/Input/Input';
import SelectInput from '../../../components/SelectInput/SelectInput';
import Title from '../../../components/Title/Title';
import styles from '../sass/M5.module.scss';

const M5OpportunityHeader = ({
  id,
  value,
  name,
  onUpdateOpportunity,
  style,
  onDeleteOpportunity,
  title,
  meaningsList,
  meaningId
}) => {
  const { t } = useTranslation();
  const [opportunityName, setOpportunityName] = useState(name);

  const handleNameChange = useCallback((e) => {
    const text = deleteSpacesInString(e.target.value);
    setOpportunityName(text);
  }, []);

  const handleBlurName = useOnBlur(
    name,
    opportunityName,
    () => onUpdateOpportunity({ id, name: opportunityName, value }),
    onDeleteOpportunity
  );

  return (
    <div className={styles.headerContainer}>
      <Title
        text={t(title)}
        Type="h4"
        style={styles.title}
      />
      <div className={styles.wrapper}>
        {meaningId ? (
          <SelectInput
            onChange={(idMeaning) => onUpdateOpportunity({
              id, name, value, meaningId, selectIdMeaning: idMeaning
            })}
            values={meaningsList}
            value={name}
            customStyle={style}
            styleContainer={`${styles.headerSelect} ${styles.headerSelectMeaning}`}
          />
        ) : (
          <Input
            onChange={handleNameChange}
            value={opportunityName}
            style={styles.headerInput}
            onBlur={handleBlurName}
            customStyle={style}
            title={t('typeHere')}
          />
        )}

        <SelectInput
          onChange={(rate) => onUpdateOpportunity({
            id, name, value: +rate, meaningId,
          })}
          styleContainer={styles.headerSelect}
          value={value.toString()}
          title=""
          values={rateOptions}
          checkId={false}
        />
      </div>
    </div>
  );
};

M5OpportunityHeader.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onUpdateOpportunity: PropTypes.func.isRequired,
  style: PropTypes.shape({
    color: PropTypes.string,
    fontWeight: PropTypes.string
  }).isRequired,
  onDeleteOpportunity: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  meaningsList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })).isRequired,
  meaningId: PropTypes.number.isRequired
};

export default M5OpportunityHeader;
