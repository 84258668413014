import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/SortArrow.module.scss';

import { ReactComponent as ArrowIcon } from '../../assets/icons/arrowDown.svg';

const SortArrow = ({ up, style }) => {
  return (
    <div className={`${styles.container} ${style} ${up ? styles.up : ''}`}>
      <ArrowIcon />
    </div>
  );
};

SortArrow.propTypes = {
  up: PropTypes.bool.isRequired,
  style: PropTypes.string,
};

SortArrow.defaultProps = {
  style: '',
};

export default SortArrow;
