import React from 'react';
import PropTypes from 'prop-types';
import { getEmptyArray } from '../../../utils/helpers';
import M2DriversItem from './M2DriversItem';
import styles from '../sass/M2.module.scss';

const M2Drivers = ({ drivers, onChange, onDeleteCustomerDriver }) => {
  return (
    <div className={styles.driverContainer}>
      {getEmptyArray(5).map((item, index) => (
        <M2DriversItem
          data={drivers[index]}
          key={`driver-${index.toString()}`}
          onChange={onChange}
          onDeleteCustomerDriver={onDeleteCustomerDriver}
        />
      ))}
    </div>
  );
};

M2Drivers.propTypes = {
  drivers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.number,
    worksheet_id: PropTypes.number
  })),
  onChange: PropTypes.func.isRequired,
  onDeleteCustomerDriver: PropTypes.func.isRequired
};

M2Drivers.defaultProps = {
  drivers: []
};

export default M2Drivers;
