import React from 'react';
import { useTranslation } from 'react-i18next';
import Tip from '../Tip/Tip';
import styles from './sass/OrganizationTips.module.scss';

const TipOrganizationFunctionalTrigger = () => {
  const { t } = useTranslation();

  return (
    <Tip
      styleTipTextContainer={`${styles.triggerContainer} ${styles.functionalContainer}`}
      styleTriangle={styles.triangle}
    >
      <p>
        {t('tipOrganizationFunctionalTrigger')}
      </p>
    </Tip>
  );
};

export default TipOrganizationFunctionalTrigger;
