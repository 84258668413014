import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useOnBlur from '../../../hooks/useOnBlur';
import { deleteSpacesInString } from '../../../utils/helpers';
import Input from '../../../components/Input/Input';
import SelectInput from '../../../components/SelectInput/SelectInput';
import rateOptions from '../../../constants/rateOptions';
import Button from '../../../components/Button/Button';
import styles from '../sass/O1.module.scss';

import { ReactComponent as PlusIcon } from '../../../assets/icons/plusBlue.svg';

const O1ItemCapabilityItem = ({
  onChangeCapabilities,
  operationalPrioritiesTitle,
  onDeleteCapabilities,
  operationalPrioritiesValue,
  operationalPrioritiesId,
  onClickAdd,
  disabled,
}) => {
  const { t } = useTranslation();

  const [title, setTitle] = useState(operationalPrioritiesTitle);

  const handleChangeTitle = useCallback((e) => {
    const text = deleteSpacesInString(e.target.value);
    setTitle(text);
  }, []);

  const handleBlurTitle = useOnBlur(
    operationalPrioritiesTitle,
    title,
    () => onChangeCapabilities({ title, operationalPrioritiesValue }, operationalPrioritiesId),
    () => onDeleteCapabilities(operationalPrioritiesId)
  );
  return (
    <div className={styles.capabilities}>
      <Input
        onChange={handleChangeTitle}
        style={styles.inputContainer}
        value={title}
        onBlur={handleBlurTitle}
        title={t('operationalCapabilityNeededPlaceholder')}
      />
      <SelectInput
        title={t('rank')}
        onChange={(rate) => onChangeCapabilities({ title, operationalPrioritiesValue: +rate }, operationalPrioritiesId)}
        styleContainer={styles.selectContainer}
        values={rateOptions}
        value={!operationalPrioritiesId ? '' : operationalPrioritiesValue.toString()}
        checkId={false}
        disabled={!operationalPrioritiesId}
      />
      <Button
        icon={<PlusIcon />}
        style={styles.buttonContainer}
        onClick={onClickAdd}
        disabled={disabled}
      />
    </div>
  );
};

O1ItemCapabilityItem.propTypes = {
  onChangeCapabilities: PropTypes.func.isRequired,
  onDeleteCapabilities: PropTypes.func.isRequired,
  operationalPrioritiesTitle: PropTypes.string.isRequired,
  operationalPrioritiesValue: PropTypes.number.isRequired,
  operationalPrioritiesId: PropTypes.number.isRequired,
  onClickAdd: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default O1ItemCapabilityItem;
