import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/Input/Input';
import SelectInput from '../../../components/SelectInput/SelectInput';
import styles from '../sass/RegistrationForm.module.scss';

import { ReactComponent as CloseEyeIcon } from '../../../assets/icons/closeEye.svg';
import { ReactComponent as EyeIcon } from '../../../assets/icons/openEye.svg';

const RegistrationForm = ({
  firstName,
  lastName,
  password,
  confirmPassword,
  email,
  projectCompany,
  industry,
  currency,
  onChange,
  onChangeIndustry,
  onChangeCurrency,
  industriesList,
  errors,
  currencyList,
}) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <div className={styles.container}>
      <Input
        name="firstName"
        title={t('firstName')}
        value={firstName}
        onChange={onChange}
        errorText={errors.firstName}
      />
      <Input
        name="lastName"
        title={t('lastName')}
        value={lastName}
        onChange={onChange}
        errorText={errors.lastName}
      />
      <Input
        name="email"
        title={`${t('email')} (${t('username')})`}
        value={email}
        onChange={onChange}
        errorText={errors.email}
      />
      <Input
        name="password"
        title={t('password')}
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={onChange}
        errorText={errors.password}
        style={styles.passwordInput}
        icon={showPassword ? <CloseEyeIcon /> : <EyeIcon />}
        onIconClick={() => setShowPassword(!showPassword)}
      />
      <Input
        name="confirmPassword"
        title={t('confirmPassword')}
        type={showConfirmPassword ? 'text' : 'password'}
        value={confirmPassword}
        onChange={onChange}
        style={styles.passwordInput}
        errorText={errors.confirmPassword}
        icon={showConfirmPassword ? <CloseEyeIcon /> : <EyeIcon />}
        onIconClick={() => setShowConfirmPassword(!showConfirmPassword)}
      />
      <Input
        name="projectCompany"
        title={t('projectCompany')}
        value={projectCompany}
        onChange={onChange}
        errorText={errors.projectCompany}
      />
      <SelectInput
        name="industry"
        title={t('industry')}
        value={industry}
        styleDropdownContent={styles.dropdownContent}
        values={industriesList}
        onChange={onChangeIndustry}
        errorText={errors.industry}
      />
      <SelectInput
        name="currency"
        title={t('currency')}
        value={currency}
        values={currencyList.map(({ symbol }) => ({ name: symbol }))}
        onChange={(value) => onChangeCurrency(value)}
        errorText={errors.currency}
        checkId={false}
      />
    </div>
  );
};

RegistrationForm.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  password: PropTypes.string,
  confirmPassword: PropTypes.string,
  email: PropTypes.string,
  projectCompany: PropTypes.string,
  industry: PropTypes.string,
  currency: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeIndustry: PropTypes.func.isRequired,
  onChangeCurrency: PropTypes.func.isRequired,
  industriesList: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
  errors: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    confirmPassword: PropTypes.string,
    projectCompany: PropTypes.string,
    industry: PropTypes.string,
    currency: PropTypes.string
  }),
  currencyList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    symbol: PropTypes.string
  })).isRequired,
};

RegistrationForm.defaultProps = {
  firstName: '',
  lastName: '',
  password: '',
  confirmPassword: '',
  email: '',
  projectCompany: '',
  industry: '',
  industriesList: [],
  errors: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    projectCompany: '',
    industry: '',
    currency: ''
  }
};

export default RegistrationForm;
