import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './sass/NoData.module.scss';
import Button from '../Button/Button';

import { ReactComponent as Plus } from '../../assets/icons/plusBlack.svg';

const NoData = ({
  id, projectName, page, textButton
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isVisibleButton = id && projectName;
  return (
    <div className={styles.container}>
      <div className={styles.text}>No Data</div>
      {isVisibleButton && (
        <Button
          text={t(textButton)}
          icon={<Plus />}
          style={styles.buttonContainer}
          styleButton={styles.button}
          onClick={() => navigate(`/${id}/${page}`)}
        />
      )}
    </div>
  );
};

NoData.propTypes = {
  id: PropTypes.string,
  projectName: PropTypes.string,
  page: PropTypes.string,
  textButton: PropTypes.string
};

NoData.defaultProps = {
  id: null,
  projectName: '',
  page: 'm1',
  textButton: 'Add New Customer'
};

export default NoData;
