import React, { useState, useCallback, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useOnBlur from '../../../hooks/useOnBlur';
import { deleteSpacesInString } from '../../../utils/helpers';
import Input from '../../../components/Input/Input';
import SelectInput from '../../../components/SelectInput/SelectInput';
import rateOptions from '../../../constants/rateOptions';
import styles from '../sass/O4.module.scss';

const O4ItemValues = ({
  name,
  value,
  id,
  onChange,
  onDelete,
  type,
  disabled,
  placeholder
}) => {
  const { t } = useTranslation();
  const [priorityName, setPriorityName] = useState('');

  useLayoutEffect(() => {
    setPriorityName(name);
  }, [name]);

  const handleChangeDriverName = useCallback((e) => {
    const text = deleteSpacesInString(e.target.value);
    setPriorityName(text);
  }, []);

  const handleBlurPriorityName = useOnBlur(
    name,
    priorityName,
    () => {
      onChange({
        id,
        name: priorityName,
        value
      }, type);
      if (!name) {
        setPriorityName('');
      }
    },
    () => onDelete(id, type)
  );

  return (
    <div className={styles.valuesContainer}>
      <Input
        onChange={handleChangeDriverName}
        style={styles.inputContainer}
        value={priorityName}
        onBlur={handleBlurPriorityName}
        disabled={disabled && !id}
        title={placeholder}
      />
      <SelectInput
        onChange={(rate) => onChange({ id, name, value: +rate }, type)}
        styleContainer={styles.selectContainer}
        values={rateOptions}
        value={!id ? '' : value.toString()}
        title={t('rank')}
        checkId={false}
        disabled={!id}
      />
    </div>
  );
};

O4ItemValues.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  id: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired
};

O4ItemValues.defaultProps = {
  name: '',
  value: 0,
  id: 0
};

export default O4ItemValues;
