import React from 'react';
import PropTypes from 'prop-types';
import M6ServiceableObtainableMarketPercent from './M6ServiceableObtainableMarketPercent';
import M6ServiceableObtainableMarketValues from './M6ServiceableObtainableMarketValues';
import styles from '../sass/M6.module.scss';

const M6ServiceableObtainableMarket = ({
  somAmount,
  somCurrency,
  somPercentage,
  somQty,
  tamAmount,
  tamQty,
  onChangeServiceableObtainableMarket
}) => {
  return (
    <div className={styles.serviceableObtainableMarket}>
      <M6ServiceableObtainableMarketPercent
        somPercentage={somPercentage}
        tamAmount={tamAmount}
        tamQty={tamQty}
        onChangeServiceableObtainableMarket={onChangeServiceableObtainableMarket}
      />
      <M6ServiceableObtainableMarketValues
        somAmount={somAmount}
        somCurrency={somCurrency}
        somQty={somQty}
        onChangeServiceableObtainableMarket={onChangeServiceableObtainableMarket}
      />
    </div>
  );
};

M6ServiceableObtainableMarket.propTypes = {
  somAmount: PropTypes.number.isRequired,
  somCurrency: PropTypes.string.isRequired,
  somPercentage: PropTypes.number.isRequired,
  somQty: PropTypes.number.isRequired,
  tamAmount: PropTypes.number.isRequired,
  tamQty: PropTypes.number.isRequired,
  onChangeServiceableObtainableMarket: PropTypes.func.isRequired
};

export default M6ServiceableObtainableMarket;
