import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { video5KindsOfValue } from '../../../constants/general';

const StakeholderTipContent = ({ onOpenVideo }) => {
  const { t } = useTranslation();
  return (
    <p>
      {t('tipStakeholder')}
      <button onClick={() => onOpenVideo(video5KindsOfValue)}>{` (${t('link')}).`}</button>
    </p>
  );
};

StakeholderTipContent.propTypes = {
  onOpenVideo: PropTypes.func.isRequired,
};

export default StakeholderTipContent;
