import {
  SET_EMAIL,
  SET_USER_PROFILE_SUCCESS,
  SET_USER_PROFILE_FAILED,
  SET_USER_PROFILE_REQUEST,
  SET_ROLE,
  CLEAN_USER
} from './types';

const initialState = {
  email: JSON.parse(localStorage.getItem('email')) || '',
  profile: null,
  role: JSON.parse(localStorage.getItem('role')) || null,
  loading: false,
  error: null,
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case SET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.email,
        loading: false,
        error: null
      };
    case SET_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case SET_ROLE:
      return {
        ...state,
        role: action.role,
      };
    case SET_USER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_USER_PROFILE_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case CLEAN_USER:
      return initialState;
    default:
      return state;
  }
}
