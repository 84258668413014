import {
  CLEAN_ADMIN_EDIT_SCENARIO,
  SET_ADMIN_EDIT_SCENARIO_FAILED,
  SET_ADMIN_EDIT_SCENARIO_REQUEST,
  SET_ADMIN_EDIT_SCENARIO_SUCCESS,
  SET_ADMIN_SCENARIO_SUCCESS
} from './types';
import instance from '../../../services/axios';
import {
  POST, PUT, ADMIN_SCENARIOS_URL
} from '../../../constants/api';
import notify from '../../../utils/notify';

export const setScenarioRequest = () => ({ type: SET_ADMIN_EDIT_SCENARIO_REQUEST });
export const setScenarioFailed = (error) => ({ type: SET_ADMIN_EDIT_SCENARIO_FAILED, error });
export const setScenarioSuccess = (data) => ({ type: SET_ADMIN_SCENARIO_SUCCESS, data });
export const setEditScenarioSuccess = () => ({ type: SET_ADMIN_EDIT_SCENARIO_SUCCESS });
export const cleanScenario = () => ({ type: CLEAN_ADMIN_EDIT_SCENARIO });

export const editScenarioAction = (values, id, navigate) => {
  const scenario = {
    name: values.name,
    description: values.description,
  };
  return (dispatch) => {
    dispatch(setScenarioRequest());
    instance[id ? PUT : POST](id ? `${ADMIN_SCENARIOS_URL}/${id}` : ADMIN_SCENARIOS_URL, scenario)
      .then(() => {
        dispatch(setEditScenarioSuccess());
        notify('success', 'Success');
        navigate(-1);
      })
      .catch(({ response: { data } }) => {
        dispatch(setScenarioFailed(data));
        notify('error', data.message);
      });
  };
};

export const getScenarioAction = (id) => {
  return (dispatch) => {
    dispatch(setScenarioRequest());
    instance.get(`${ADMIN_SCENARIOS_URL}/${id}`)
      .then(({ data: { data } }) => dispatch(setScenarioSuccess(data)))
      .catch(({ response: { data } }) => {
        dispatch(setScenarioFailed(data));
        notify('error', data.message);
      });
  };
};
