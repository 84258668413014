import React from 'react';
import PropTypes from 'prop-types';
import M6OfferingItemOffer from './M6OfferingItemOffer';
import M6OfferingItemSom from './M6OfferingItemSOM';
import styles from '../sass/M6.module.scss';

const M6OfferingItem = ({
  title,
  value,
  onChangeOffering,
  offeringSomCurrency,
  offeringSomPercentage,
  somQty,
  somAmount,
  projectedIncreaseId,
  onChangeOfferingSOM,
  onDeleteOffering,
  somPercentage,
  tamQty,
  tamAmount
}) => {
  return (
    <div className={styles.offeringItemContainer}>
      <M6OfferingItemOffer
        title={title}
        value={value}
        onChangeOffering={onChangeOffering}
        onDeleteOffering={onDeleteOffering}
      />
      <M6OfferingItemSom
        offeringSomCurrency={offeringSomCurrency}
        offeringSomPercentage={offeringSomPercentage}
        somQty={somQty}
        somAmount={somAmount}
        somPercentage={somPercentage}
        projectedIncreaseId={projectedIncreaseId}
        onChangeOfferingSOM={onChangeOfferingSOM}
        tamQty={tamQty}
        tamAmount={tamAmount}
      />
    </div>
  );
};

M6OfferingItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChangeOffering: PropTypes.func.isRequired,
  offeringSomCurrency: PropTypes.string,
  offeringSomPercentage: PropTypes.number,
  somQty: PropTypes.number.isRequired,
  somAmount: PropTypes.number.isRequired,
  projectedIncreaseId: PropTypes.number,
  onChangeOfferingSOM: PropTypes.func.isRequired,
  onDeleteOffering: PropTypes.func.isRequired,
  somPercentage: PropTypes.number.isRequired,
  tamQty: PropTypes.number.isRequired,
  tamAmount: PropTypes.number.isRequired
};

M6OfferingItem.defaultProps = {
  offeringSomCurrency: 'dollar',
  projectedIncreaseId: null,
  offeringSomPercentage: null
};

export default M6OfferingItem;
