import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import useOutsideClick from '../../hooks/useOutSideClick';
import SelectInputDropDownMenu from '../SelectInputDropDowmMenu/SelectInputDropDownMenu';
import styles from './sass/SelectInput.module.scss';

import { ReactComponent as ArrowDown } from '../../assets/icons/arrowDown.svg';

const SelectInput = ({
  name,
  title,
  styleDropdownContent,
  value,
  onChange,
  values,
  errorText,
  styleContainer,
  checkId,
  styleSelectInputItem,
  iconDropDownItem,
  disabled,
  onChangeInput,
  disabledInput,
  onActionDropDownItem,
  actionDropDown,
  label,
  styleLabel,
  onBlur,
  withOutArrow,
  customStyle,
}) => {
  const [showList, setShowList] = useState(false);

  const handleOpenDropDown = useCallback(() => {
    if (!disabled && !showList) {
      setShowList(true);
    }
  }, [disabled, showList]);

  const handleCloseDropDown = useCallback((outsideClick = true) => {
    if (!disabled && showList) {
      const isOnBlur = !!onBlur && outsideClick;
      if (isOnBlur) onBlur();
      setShowList(false);
    }
  }, [disabled, onBlur, showList]);

  const refContainer = useOutsideClick(handleCloseDropDown, disabled);

  const isVisibleArrow = disabledInput && !withOutArrow;

  return (
    <div className={`${styles.container} ${styleContainer}`} ref={refContainer}>
      {label && <span className={`${styles.label} ${styleLabel}`}>{label}</span>}
      <div className={`${styles.wrapper}`}>
        <div role="presentation" onClick={handleOpenDropDown}>
          <input
            type="text"
            placeholder={title}
            disabled={disabledInput}
            name={name}
            value={value}
            onChange={onChangeInput}
            style={customStyle}
          />
          {!!errorText && <div className={styles.error}>{errorText}</div>}
          {isVisibleArrow && (
            <div className={`${styles.icon} ${errorText ? styles.errorIcon : ''}`}>
              <ArrowDown />
            </div>
          )}
        </div>
        <SelectInputDropDownMenu
          showList={showList}
          onChange={onChange}
          onCloseDropDown={() => handleCloseDropDown(false)}
          checkId={checkId}
          value={value}
          values={values}
          styleDropdownContent={styleDropdownContent}
          styleSelectInputItem={styleSelectInputItem}
          iconDropDownItem={iconDropDownItem}
          onActionDropDownItem={onActionDropDownItem}
          actionDropDown={actionDropDown}
        />
      </div>
    </div>
  );
};

SelectInput.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
  errorText: PropTypes.string,
  styleContainer: PropTypes.string,
  styleDropdownContent: PropTypes.string,
  styleSelectInputItem: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.shape(
    {
      id: PropTypes.number,
      name: PropTypes.string,
      children: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number
      }))
    }
  )),
  onChange: PropTypes.func.isRequired,
  checkId: PropTypes.bool,
  iconDropDownItem: PropTypes.node,
  disabled: PropTypes.bool,
  onActionDropDownItem: PropTypes.func,
  actionDropDown: PropTypes.node,
  disabledInput: PropTypes.bool,
  onChangeInput: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  styleLabel: PropTypes.string,
  onBlur: PropTypes.func,
  withOutArrow: PropTypes.bool,
  customStyle: PropTypes.shape({
    color: PropTypes.string,
    fontWeight: PropTypes.string,
  }),
};

SelectInput.defaultProps = {
  name: '',
  value: '',
  title: '',
  styleDropdownContent: '',
  styleSelectInputItem: '',
  errorText: '',
  styleContainer: '',
  values: [],
  checkId: true,
  iconDropDownItem: null,
  disabled: false,
  onActionDropDownItem: null,
  actionDropDown: null,
  disabledInput: true,
  onChangeInput: undefined,
  label: '',
  styleLabel: '',
  onBlur: undefined,
  withOutArrow: false,
  customStyle: undefined
};

export default SelectInput;
