import {
  CLEAN_M7,
  SET_M7_FAILED,
  SET_M7_REQUEST,
  SET_M7_SEGMENTS_SUCCESS
} from './types';
import instance from '../../services/axios';
import {
  POTENTIAL_CUSTOMER_OFFERING_URL,
  SEGMENT_CUSTOMER_OFFERING_URL,
} from '../../constants/api';
import notify from '../../utils/notify';

export const setM7Request = () => ({ type: SET_M7_REQUEST });
export const setM7Failed = (error) => ({ type: SET_M7_FAILED, error });
export const cleanM7 = () => ({ type: CLEAN_M7 });

export const setSegments = (data) => ({ type: SET_M7_SEGMENTS_SUCCESS, data });

export const getSegmentsAction = (id) => {
  return (dispatch) => {
    dispatch(setM7Request());
    instance.get(`${SEGMENT_CUSTOMER_OFFERING_URL}/${id}`)
      .then(({ data: { data } }) => {
        const filterData = data.filter(({ offerings }) => offerings.length);
        dispatch(setSegments(filterData));
      })
      .catch(({ response: { data } }) => {
        dispatch(setM7Failed(data));
        notify('error', data.message);
      });
  };
};

export const changeOfferingAction = (idSegment, idOffer, values) => {
  return (dispatch, getState) => {
    const { m7: { segments } } = getState();
    dispatch(setM7Request());
    instance.put(`${POTENTIAL_CUSTOMER_OFFERING_URL}/${idOffer}`, values)
      .then(({ data: { data } }) => {
        const copySegments = [...segments];
        const segmentIndex = copySegments.findIndex((item) => item.id === idSegment);
        const offeringIndex = copySegments[segmentIndex].offerings.findIndex((item) => item.id === idOffer);
        copySegments[segmentIndex].offerings[offeringIndex] = {
          ...copySegments[segmentIndex].offerings[offeringIndex],
          message_for_offering: data.message_for_offering,
          message_value: data.message_value,
        };
        dispatch(setSegments(copySegments));
      })
      .catch(({ response: { data } }) => {
        dispatch(setM7Failed(data));
        notify('error', data.message);
      });
  };
};
