import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/SelectInputItem.module.scss';

const SelectInputItem = ({
  value,
  onChange,
  styleSelectInputItem,
  active,
  iconDropDownItem,
  onActionDropDownItem,
  disabled,
  isChild,
  isTitle
}) => {
  return (
    <button
      onClick={onChange}
      className={`
        ${styles.dropdownItem} 
        ${styleSelectInputItem} 
        ${active ? styles.selected : ''} 
        ${isChild ? styles.child : ''}
        ${isTitle ? styles.selectTitle : ''}
      `}
      disabled={disabled || isTitle}
    >
      {value}
      {iconDropDownItem && (
        <span
          role="presentation"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onActionDropDownItem();
          }}
        >
          {iconDropDownItem}
        </span>
      )}
    </button>
  );
};

SelectInputItem.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  styleSelectInputItem: PropTypes.string,
  active: PropTypes.bool,
  iconDropDownItem: PropTypes.node,
  onActionDropDownItem: PropTypes.func,
  disabled: PropTypes.bool,
  isChild: PropTypes.bool,
  isTitle: PropTypes.bool
};

SelectInputItem.defaultProps = {
  styleSelectInputItem: '',
  active: false,
  iconDropDownItem: null,
  onActionDropDownItem: null,
  disabled: false,
  isChild: false,
  isTitle: false
};

export default SelectInputItem;
