import React from 'react';
import styles from '../sass/ProjectsModalAvatar.module.scss';

import ProjectLogo from '../../../assets/icons/projectIcon.svg';

const ProjectsModalAvatar = () => {
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img
          src={ProjectLogo}
          alt="avatar"
          loading="lazy"
        />
      </div>
    </div>
  );
};

export default ProjectsModalAvatar;
