import React from 'react';
import { useTranslation } from 'react-i18next';

const M2TipSegmentContent = () => {
  const { t } = useTranslation();
  return (
    <p>
      {t('tipM2Segment')}
    </p>
  );
};

export default M2TipSegmentContent;
