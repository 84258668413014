import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { SCENARIO, TREND, USER } from '../../../constants/general';
import Button from '../../../components/Button/Button';
import AdminMenu from './components/AdminMenu';
import Tab from './components/Tab';
import styles from './sass/AdminHeader.module.scss';

import Logo from '../../../assets/icons/logo2.svg';

function AdminHeader({ activeTab, onLogout, menuButtons }) {
  const { t } = useTranslation();
  return (
    <header className={styles.header}>
      <div className={`container ${styles.container}`}>
        <div className={styles.logoContainer}>
          <img src={Logo} alt="brandLogo" className={styles.logo} />
          <Button text={t('logout')} style={styles.buttonContainer} styleButton={styles.button} onClick={onLogout} />
        </div>

        <div className={styles.actions}>
          <div className={styles.tabsContainer}>
            <Tab
              text={t('users')}
              link="/admin/dashboard"
              active={activeTab === USER}
            />
            <Tab
              text={t('predefinedTrends')}
              link="/admin/dashboard/trends"
              active={activeTab === TREND}
            />
            <Tab
              text={t('scenarios')}
              link="/admin/dashboard/scenarios"
              active={activeTab === SCENARIO}
            />
          </div>
          <AdminMenu menuButtons={menuButtons} />
        </div>
      </div>
    </header>
  );
}

AdminHeader.propTypes = {
  activeTab: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired,
  menuButtons: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.string,
    iconHover: PropTypes.string
  })).isRequired
};

export default AdminHeader;
