import { useMemo } from 'react';
import axios from './axios';
import { REFRESH_TOKEN_URL } from '../constants/api';
import { logOutAction, setLoginSuccess } from '../store/auth/actions';

const interceptor = (store) => {
  axios.interceptors.request.use(
    (config) => {
      const newConfig = config;

      const { auth: { tokens: { accessToken } } } = store.getState();
      if (!config.withoutAuth && accessToken) {
        newConfig.headers.Authorization = `Bearer ${accessToken}`;
      }
      return newConfig;
    }
  );

  const { dispatch, getState } = store;

  const getRefreshToken = async () => {
    const { auth: { tokens: { refreshToken } } } = getState();

    try {
      return await axios.post(REFRESH_TOKEN_URL, {
        refresh_token: refreshToken,
      }, {
        withoutAuth: true
      });
    } catch (error) {
      dispatch(logOutAction());
      window.location.href = `${window.location.origin}/login`;
      return false;
    }
  };

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const config = error?.config;

      if (error?.response?.status === 401 && !config?.sent) {
        config.sent = true;

        const result = await getRefreshToken();

        if (result) {
          const { data: { data: { access_token: token, refresh_token: refreshToken } } } = result;
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`,
          };
          localStorage.setItem('accessToken', JSON.stringify(token || ''));
          localStorage.setItem('refreshToken', JSON.stringify(refreshToken || ''));
          dispatch(setLoginSuccess({ accessToken: token, refreshToken }));
        }
        return axios(config);
      }
      throw error;
    }
  );
};

const useInterceptor = (store) => {
  return useMemo(() => interceptor(store), [store]);
};

export default useInterceptor;
