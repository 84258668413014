import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as AvatarPlaceholder } from '../../assets/icons/referancePhoto.svg';

const Avatar = ({ url, style, placeholder }) => {
  return (
    url ? (
      <img
        src={url}
        className={style}
        alt="avatar"
        loading="lazy"
      />
    ) : (
      placeholder || <AvatarPlaceholder />
    )
  );
};

Avatar.propTypes = {
  url: PropTypes.string,
  style: PropTypes.string,
  placeholder: PropTypes.node
};

Avatar.defaultProps = {
  url: '',
  style: '',
  placeholder: null
};

export default memo(Avatar);
