import React from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/Stakeholders.module.scss';

const StakeholdersProfilesItem = ({ name, id, onClick }) => {
  return (
    <span
      className={styles.profilesItem}
      role="presentation"
      onClick={() => onClick(id)}
    >
      {name}
    </span>
  );
};

StakeholdersProfilesItem.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default StakeholdersProfilesItem;
