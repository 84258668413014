import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useOnBlur from '../../../hooks/useOnBlur';
import { deleteSpacesInString } from '../../../utils/helpers';
import rateOptions from '../../../constants/rateOptions';
import Input from '../../../components/Input/Input';
import SelectInput from '../../../components/SelectInput/SelectInput';
import styles from '../sass/M6.module.scss';

const M6OfferingItemOffer = ({
  title,
  value,
  onChangeOffering,
  onDeleteOffering
}) => {
  const { t } = useTranslation();
  const [titleValue, setTitleValue] = useState(title);

  const handleChangeTitle = useCallback((event) => {
    const text = deleteSpacesInString(event.target.value);
    setTitleValue(text);
  }, []);

  const handleBlurTitle = useOnBlur(
    title,
    titleValue,
    () => onChangeOffering({ title: titleValue, value }),
    onDeleteOffering
  );

  return (
    <div className={styles.offering}>
      <Input
        onChange={handleChangeTitle}
        value={titleValue}
        style={styles.offeringInput}
        onBlur={handleBlurTitle}
        title={t('offeringPlaceholder')}
      />
      <SelectInput
        onChange={(rate) => onChangeOffering({ title: titleValue, value: +rate })}
        title=""
        values={rateOptions}
        value={value.toString()}
        styleContainer={styles.offeringSelectInput}
        checkId={false}
      />
    </div>
  );
};

M6OfferingItemOffer.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChangeOffering: PropTypes.func.isRequired,
  onDeleteOffering: PropTypes.func.isRequired
};

export default M6OfferingItemOffer;
