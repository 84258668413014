import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/Tip.module.scss';

import { ReactComponent as HelpTip } from '../../assets/icons/helpTip.svg';

const Tip = ({
  styleContainer,
  children,
  styleTip,
  styleTipTextContainer,
  styleTriangle
}) => {
  return (
    <div className={`${styles.container} ${styleContainer}`}>
      <div className={`${styles.tip} ${styleTip}`}>
        <HelpTip />
        <div
          className={`${styles.tipTextContainer} ${styleTipTextContainer}`}
        >
          <div className={styles.text}>
            {children}
          </div>
          <div className={`${styles.triangle} ${styleTriangle}`} />
        </div>
      </div>
    </div>
  );
};

Tip.propTypes = {
  styleContainer: PropTypes.string,
  styleTip: PropTypes.string,
  children: PropTypes.node,
  styleTipTextContainer: PropTypes.string,
  styleTriangle: PropTypes.string
};

Tip.defaultProps = {
  styleContainer: '',
  styleTip: '',
  children: null,
  styleTipTextContainer: '',
  styleTriangle: ''
};

export default Tip;
