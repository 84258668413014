import {
  TAM,
  SAM,
  SOM,
  tamCurrency,
  tamQty,
  tamAmount,
  samPercentage,
  samAmount,
  samCurrency,
  samQty,
  somCurrency,
  somQty,
  somAmount,
  somPercentage
} from './general';
import TipTAM from '../components/M2Tips/TipTAM';
import TipSAM from '../components/M2Tips/TipSAM';
import TipSOM from '../components/M2Tips/TipSOM';

const availableSegment = {
  tam: {
    type: TAM,
    title: 'Total Available Market (TAM)',
    percent: false,
    field: {
      currency: tamCurrency,
      qty: tamQty,
      amount: tamAmount
    },
    tip: <TipTAM />
  },
  sam: {
    type: SAM,
    title: 'Serviceable Available Market (SAM):',
    percent: true,
    field: {
      currency: samCurrency,
      qty: samQty,
      amount: samAmount,
      percentage: samPercentage
    },
    tip: <TipSAM />
  },
  som: {
    type: SOM,
    title: 'Serviceable Obtainable Market (SOM):',
    percent: true,
    field: {
      currency: somCurrency,
      qty: somQty,
      amount: somAmount,
      percentage: somPercentage
    },
    tip: <TipSOM />
  },
};

export default availableSegment;
