import React from 'react';
import PropTypes from 'prop-types';
import { getEmptyArray } from '../../../utils/helpers';
import CompetitorsColumn from './CompetitorsColumn';
import styles from '../sass/MarketDrivers.module.scss';

const Competitors = ({
  organizationDrivers,
  competitorsDrivers,
  topMarketDrivers,
  onUpdate,
  onSelect,
  onUpdateOwnOrganization,
  onChangeOwnOrganization,
  ownOrganizationName,
  ownOrganizationOffering
}) => {
  return (
    <div className={styles.competitorsContainer}>
      <CompetitorsColumn
        data={organizationDrivers}
        onUpdate={onUpdateOwnOrganization}
        title={ownOrganizationName}
        offering={ownOrganizationOffering}
        onChangeOwnOrganization={onChangeOwnOrganization}
      />

      {competitorsDrivers.map(({
        id, name, drivers, offering
      }) => {
        const data = [];
        topMarketDrivers.forEach((item, index) => {
          getEmptyArray(drivers.length || topMarketDrivers.length).forEach((_, i) => {
            if (drivers[i] && drivers[i].driver_id === item.id) {
              data[index] = drivers[i];
            } else if (!data[index]) data[index] = { id: 0, value: 0, driver_id: item.id };
          });
        });
        return (
          <CompetitorsColumn
            key={id}
            title={name}
            offering={offering || ''}
            data={data}
            onUpdate={(driver) => onUpdate(driver, id)}
            onSelect={() => onSelect({ id, name, offering: offering || '' })}
            isCompetitor
          />
        );
      })}
    </div>
  );
};

Competitors.propTypes = {
  organizationDrivers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    value: PropTypes.number,
    driverId: PropTypes.number
  })),
  competitorsDrivers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    drivers: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      driver_id: PropTypes.number,
      value: PropTypes.number
    }))
  })),
  topMarketDrivers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    style: PropTypes.shape({
      color: PropTypes.string,
      fontWeight: PropTypes.string,
    }),
    name: PropTypes.string,
    value: PropTypes.number
  })),
  onUpdate: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onUpdateOwnOrganization: PropTypes.func.isRequired,
  onChangeOwnOrganization: PropTypes.func.isRequired,
  ownOrganizationName: PropTypes.string.isRequired,
  ownOrganizationOffering: PropTypes.string.isRequired
};

Competitors.defaultProps = {
  organizationDrivers: [],
  competitorsDrivers: [],
  topMarketDrivers: []
};

export default Competitors;
