import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { verifyEmailAction } from '../../store/successRegistration/actions';
import Page from '../../layout/Page/Page';
import SuccessRegistrationLoading from './components/SuccessRegistrationLoading';
import SuccessRegistrationSuccess from './components/SuccessRegistrationSuccess';
import SuccessRegistrationError from './components/SuccessRegistrationError';

const SuccessRegistration = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { token } = useParams();

  const {
    successRegistration: { loading, error, status }
  } = useSelector(({ successRegistration }) => ({ successRegistration }));

  useEffect(() => {
    dispatch(verifyEmailAction(token));
  }, []);

  const handleLogin = useCallback(() => {
    navigation('/login');
  }, []);

  return (
    <Page simpleHeader whiteTheme>
      {loading && <SuccessRegistrationLoading />}
      {status && <SuccessRegistrationSuccess onClick={handleLogin} />}
      {error && <SuccessRegistrationError error={error} />}
    </Page>
  );
};

export default SuccessRegistration;
