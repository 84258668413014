import { batch } from 'react-redux';
import {
  CLEAN_M5,
  SET_M5_FAILED,
  SET_M5_OPPORTUNITY_LIST_SUCCESS,
  SET_M5_REQUEST,
  SET_M5_SEGMENT_LIST_SUCCESS,
  SET_M5_SEGMENT_SUCCESS
} from './types';
import { getSegmentsAction } from '../general/actions';
import notify from '../../utils/notify';
import instance from '../../services/axios';
import {
  DRIVERS_URL,
  MEANING_URL,
  OPPORTUNITY_AND_THREAT_URL,
  POTENTIAL_CUSTOMER_OFFERING_URL
} from '../../constants/api';

export const setM5Request = () => ({ type: SET_M5_REQUEST });
export const setM5Failed = (error) => ({ type: SET_M5_FAILED, error });
export const cleanM5 = () => ({ type: CLEAN_M5 });

export const setSegmentList = (data) => ({ type: SET_M5_SEGMENT_LIST_SUCCESS, data });

export const setOpportunityList = (data) => ({ type: SET_M5_OPPORTUNITY_LIST_SUCCESS, data });

export const setSegment = (data) => ({ type: SET_M5_SEGMENT_SUCCESS, data });

export const getSegmentListAction = (id) => {
  return (dispatch) => {
    dispatch(setM5Request());
    getSegmentsAction(id)
      .then(({ data: { data } }) => dispatch(setSegmentList(data)))
      .catch(({ response: { data } }) => {
        dispatch(setM5Failed(data));
        notify('error', data.message);
      });
  };
};

export const getOpportunityListAction = (id, idSegment) => {
  return (dispatch) => {
    dispatch(setM5Request());
    instance.get(`${OPPORTUNITY_AND_THREAT_URL}/${id}/${idSegment || ''}`)
      .then(({ data: { data } }) => {
        dispatch(setOpportunityList(data));
      })
      .catch(({ response: { data } }) => {
        dispatch(setM5Failed(data));
        notify('error', data.message);
      });
  };
};

export const addOfferingAction = (projectId, driverId, title, closePlaceholder) => {
  return (dispatch, getState) => {
    const { m5: { segmentId } } = getState();
    const offering = {
      project_id: +projectId,
      driver_id: driverId,
      title
    };
    dispatch(setM5Request());
    instance.post(POTENTIAL_CUSTOMER_OFFERING_URL, offering)
      .then(() => {
        dispatch(getOpportunityListAction(projectId, segmentId));
        closePlaceholder();
      })
      .catch(({ response: { data } }) => {
        dispatch(setM5Failed(data));
        notify('error', data.message);
      });
  };
};

export const updateOfferingAction = (idOpportunity, values, idProject, closePlaceholder) => {
  return (dispatch, getState) => {
    const { m5: { segmentId } } = getState();
    dispatch(setM5Request());
    const {
      title, value, messageForOffering, id
    } = values;
    instance.put(`${POTENTIAL_CUSTOMER_OFFERING_URL}/${id}`, {
      title,
      value,
      message_for_offering: messageForOffering || undefined
    })
      .then(() => {
        dispatch(getOpportunityListAction(idProject, segmentId));
        closePlaceholder();
      })
      .catch(({ response: { data } }) => {
        dispatch(setM5Failed(data));
        notify('error', data.message);
      });
  };
};

export const deleteOfferingAction = (idOpportunity, idOffering, type) => {
  return (dispatch, getState) => {
    const { m5: { opportunityList } } = getState();
    dispatch(setM5Request());
    instance.delete(`${POTENTIAL_CUSTOMER_OFFERING_URL}/${idOffering}`)
      .then(() => {
        const copyOpportunityList = { ...opportunityList };
        const index = copyOpportunityList[type].findIndex(({ id: idItem }) => idItem === idOpportunity);
        copyOpportunityList[type][index].offerings = copyOpportunityList[type][index].offerings
          .filter((item) => item.id !== idOffering);
        dispatch(setOpportunityList(copyOpportunityList));
      })
      .catch(({ response: { data } }) => {
        dispatch(setM5Failed(data));
        notify('error', data.message);
      });
  };
};

export const getSegmentAction = (id, segmentId) => {
  return (dispatch) => {
    batch(() => {
      dispatch(setSegment(segmentId));
      dispatch(getOpportunityListAction(id, segmentId));
    });
  };
};

export const updateOpportunityAction = (idProject, opportunity) => {
  const { id, name, value } = opportunity;
  return (dispatch, getState) => {
    const { m5: { segmentId } } = getState();
    dispatch(setM5Request());
    instance.put(`${DRIVERS_URL}/${id}`, {
      name,
      value
    })
      .then(() => dispatch(getOpportunityListAction(idProject, segmentId)))
      .catch(({ response: { data } }) => {
        dispatch(setM5Failed(data));
        notify('error', data.message);
      });
  };
};

export const updateOpportunityMeaningAction = (idProject, opportunity) => {
  const { value, meaningId, selectIdMeaning } = opportunity;
  return (dispatch, getState) => {
    const { m5: { segmentId } } = getState();
    dispatch(setM5Request());
    instance.put(`${MEANING_URL}/${meaningId}`, {
      value,
      meaning_id: selectIdMeaning
    })
      .then(() => dispatch(getOpportunityListAction(idProject, segmentId)))
      .catch(({ response: { data } }) => {
        dispatch(setM5Failed(data));
        notify('error', data.message);
      });
  };
};

export const deleteOpportunityAction = (idProject, idOpportunity) => {
  return (dispatch, getState) => {
    const { m5: { segmentId } } = getState();
    dispatch(setM5Request());
    instance.delete(`${DRIVERS_URL}/${idOpportunity}`)
      .then(() => dispatch(getOpportunityListAction(idProject, segmentId)))
      .catch(({ response: { data } }) => {
        dispatch(setM5Failed(data));
        notify('error', data.message);
      });
  };
};
