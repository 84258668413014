import React from 'react';
import PropTypes from 'prop-types';
import rateOptions from '../../../constants/rateOptions';
import SelectInput from '../../SelectInput/SelectInput';
import styles from '../sass/MarketDrivers.module.scss';

const CompetitorsColumnItem = ({ value, onUpdate }) => {
  return (
    <SelectInput
      onChange={(rate) => onUpdate(rate)}
      title="0"
      styleContainer={styles.competitorsColumnItem}
      value={value.toString()}
      values={rateOptions}
      checkId={false}
    />
  );
};

CompetitorsColumnItem.propTypes = {
  value: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired
};

export default CompetitorsColumnItem;
