import {
  CLEAN_M6, SET_M6_FAILED, SET_M6_REQUEST, SET_M6_SEGMENTS_SUCCESS
} from './types';

const initialState = {
  segments: [],
  loading: false,
  error: null
};

export default function m6(state = initialState, action) {
  switch (action.type) {
    case SET_M6_SEGMENTS_SUCCESS:
      return {
        ...state,
        segments: action.data,
        loading: false,
        error: null
      };
    case SET_M6_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_M6_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case CLEAN_M6:
      return initialState;
    default:
      return state;
  }
}
