import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/Button.module.scss';

const Button = ({
  text, style, styleButton, type, onClick, disabled, icon
}) => {
  return (
    <div className={`${styles.container} ${style}`}>
      <button
        className={styleButton}
        type={type}
        onClick={onClick}
        disabled={disabled}
      >
        {icon}
        {text}
      </button>
    </div>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  style: PropTypes.string,
  styleButton: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
};

Button.defaultProps = {
  style: '',
  text: '',
  styleButton: '',
  type: 'button',
  onClick: () => {},
  disabled: false,
  icon: undefined,
};

export default React.memo(Button);
