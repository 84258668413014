import { combineReducers } from 'redux';
import reducers from './translates/reducers';
import auth from './auth/reducers';
import signUp from './signUp/reducers';
import user from './user/reducers';
import successRegistration from './successRegistration/reducers';
import project from './project/reducers';
import projects from './projects/reducers';
import adminUsers from './admin/adminUsers/reducers';
import adminEditUser from './admin/adminEditUser/reducers';
import general from './general/reducers';
import m1 from './m1/reducers';
import m2 from './m2/reducers';
import adminTrends from './admin/adminTrends/reducers';
import adminScenarios from './admin/adminScenarios/reducers';
import adminEditTrend from './admin/adminEditTrend/reducers';
import adminEditScenario from './admin/adminEditScenario/reducers';
import adminStakeholders from './admin/adminStakeholders/reducers';
import adminEditStakeholder from './admin/adminEditStakeholder/reducers';
import m3 from './m3/reducers';
import m4 from './m4/reducers';
import m5 from './m5/reducers';
import m6 from './m6/reducers';
import m7 from './m7/reducers';
import organization from './organization/reducers';
import stakeholders from './stakeholders/reducers';
import trends from './trends/reducers';
import dashboard from './dashboard/reducers';
import o1 from './o1/reducers';
import o2 from './o2/reducers';
import o3 from './o3/reducers';
import o4 from './o4/reducers';
import o5 from './o5/reducers';
import o8 from './o8/reducers';

const rootReducer = combineReducers({
  translates: reducers,
  auth,
  signUp,
  user,
  successRegistration,
  project,
  projects,
  adminUsers,
  adminEditUser,
  adminTrends,
  adminEditTrend,
  adminEditScenario,
  adminScenarios,
  adminStakeholders,
  adminEditStakeholder,
  general,
  m1,
  m2,
  m3,
  m4,
  m5,
  m6,
  m7,
  organization,
  stakeholders,
  trends,
  dashboard,
  o1,
  o2,
  o3,
  o4,
  o5,
  o8,
});

export default rootReducer;
