import {
  CLEAN_ADMIN_EDIT_SCENARIO,
  SET_ADMIN_EDIT_SCENARIO_FAILED,
  SET_ADMIN_EDIT_SCENARIO_REQUEST,
  SET_ADMIN_EDIT_SCENARIO_SUCCESS,
  SET_ADMIN_SCENARIO_SUCCESS
} from './types';

const initialState = {
  scenario: null,
  loading: false,
  error: null,
};

export default function adminEditScenario(state = initialState, action) {
  switch (action.type) {
    case SET_ADMIN_EDIT_SCENARIO_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_ADMIN_SCENARIO_SUCCESS:
      return {
        ...state,
        scenario: action.data,
        loading: false,
        error: null,
      };
    case SET_ADMIN_EDIT_SCENARIO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SET_ADMIN_EDIT_SCENARIO_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case CLEAN_ADMIN_EDIT_SCENARIO:
      return initialState;
    default:
      return state;
  }
}
