import {
  SET_TRENDS_FAILED,
  SET_TRENDS_REQUEST,
  SET_TRENDS_SUCCESS,
  SET_TRENDS_TRENDS_LIST_SUCCESS,
  SET_TRENDS_INFLUENCE_LIST_SUCCESS,
  SET_TRENDS_LINK_SUCCESS,
  CLEAN_TRENDS
} from './types';

const initialState = {
  trendsList: [],
  influenceList: [],
  trendsProject: [],
  link: { id: null, url: '' },
  loading: false,
  error: null
};

export default function trends(state = initialState, action) {
  switch (action.type) {
    case SET_TRENDS_TRENDS_LIST_SUCCESS:
      return {
        ...state,
        trendsList: action.data,
        loading: false,
        error: null
      };
    case SET_TRENDS_SUCCESS:
      return {
        ...state,
        trendsProject: action.data,
        loading: false,
        error: null
      };
    case SET_TRENDS_INFLUENCE_LIST_SUCCESS:
      return {
        ...state,
        influenceList: action.data,
        loading: false,
        error: null
      };
    case SET_TRENDS_LINK_SUCCESS:
      return {
        ...state,
        link: action.data,
        loading: false,
        error: null
      };
    case SET_TRENDS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SET_TRENDS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case CLEAN_TRENDS:
      return initialState;
    default:
      return state;
  }
}
