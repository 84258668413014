import {
  SET_USER_PROFILE_SUCCESS,
  SET_EMAIL,
  SET_USER_PROFILE_FAILED,
  SET_USER_PROFILE_REQUEST,
  SET_ROLE,
  CLEAN_USER
} from './types';
import instance from '../../services/axios';
import { PROFILE_URL } from '../../constants/api';
import { ADMIN } from '../../constants/general';
import notify from '../../utils/notify';

export const setUserProfileSuccess = (data) => ({ type: SET_USER_PROFILE_SUCCESS, data });
export const setUserProfileRequest = () => ({ type: SET_USER_PROFILE_REQUEST });
export const setUserProfileFailed = (error) => ({ type: SET_USER_PROFILE_FAILED, error });
export const setEmail = (email) => ({ type: SET_EMAIL, email });
export const setRole = (role) => ({ type: SET_ROLE, role });
export const cleanUser = () => ({ type: CLEAN_USER });

export const getUserProfileAction = (navigate) => {
  return (dispatch) => {
    dispatch(setUserProfileRequest());
    instance.get(PROFILE_URL)
      .then(({ data: { data } }) => {
        dispatch(setUserProfileSuccess(data));
        if (navigate) navigate(data.role === ADMIN ? '/admin' : '/');
      })
      .catch(({ response: { data } }) => {
        dispatch(setUserProfileFailed(data));
        notify('error', data.message);
      });
  };
};

export const cleanUserAction = () => {
  return (dispatch) => {
    localStorage.removeItem('role');
    localStorage.removeItem('email');
    dispatch(cleanUser());
  };
};
