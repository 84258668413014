import React, {
  useEffect, useMemo, useCallback, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  batch, shallowEqual, useDispatch, useSelector
} from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import useSortTrends from '../../hooks/useSortTrends';
import {
  cleanTrends,
  createTrendAction, createTrendLinkAction,
  deleteTrendAction, deleteTrendLinkAction,
  getTrendAction,
  getTrendEditInfluenceAction,
  getTrendLinkAction,
  getTrendTrendsListAction,
  updateTrendAction, updateTrendLinkAction
} from '../../store/trends/actions';
import {
  convertFromIdToName, getBreadcrumbs, getPrevLinkAndNextLink
} from '../../utils/helpers';
import { SORT_DIRECTION, SORT_KEY_TREND, TRENDS_LIST } from '../../constants/general';
import Page from '../../layout/Page/Page';
import Button from '../../components/Button/Button';
import LinkModal from '../../components/LinkModal/LinkModal';
import ModalDelete from '../../components/ModalDelete/ModalDelete';
import TrendsHeader from './components/TrendsHeader';
import TrendsItemHeader from './components/TrendsItemHeader';
import TrendsItem from './components/TrendsItem';
import styles from './sass/Trends.module.scss';

import { ReactComponent as Plus } from '../../assets/icons/plusBlue.svg';

const Trends = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialType = +searchParams.get('type') || '';

  const {
    trends: {
      loading, trendsList, trendsProject, influenceList, link
    },
    project: { name: projectName, loading: loadingProject }
  } = useSelector(({ trends, project }) => ({ trends, project }), shallowEqual);

  const [newTrend, setNewTrend] = useState(false);
  const [linkModal, setLinkModal] = useState(false);
  const [deleteLinkModal, setDeleteLinkModal] = useState(false);

  const trendTypeId = useMemo(() => initialType || trendsList[0]?.id, [trendsList, initialType]);
  const trendTypeHeaderInfo = useMemo(() => {
    const trendsMapSubtitle = {
      [TRENDS_LIST.NATURE]: t('considerImpacts'),
      [TRENDS_LIST.TECHNOLOGY]: t('considerArtificial'),
    };
    const name = convertFromIdToName(trendsList, trendTypeId);
    const subtitle = trendsMapSubtitle[name] || '';

    return {
      title: `${name} Trends`,
      subtitle
    };
  }, [trendsList, trendTypeId]);

  const breadcrumbs = useMemo(() => (
    getBreadcrumbs(projectName, `/${id}/dashboard`, trendTypeHeaderInfo.title)
  ), [projectName, id, trendTypeHeaderInfo.title]);

  const switchPages = useMemo(() => (
    getPrevLinkAndNextLink(trendsList, id, trendTypeId, 'trends')
  ), [trendsList, trendTypeId, id]);

  useEffect(() => {
    batch(() => {
      dispatch(getTrendTrendsListAction());
      dispatch(getTrendEditInfluenceAction());
    });
    return () => {
      dispatch(cleanTrends());
    };
  }, []);

  useEffect(() => {
    batch(() => {
      dispatch(getTrendAction(id, initialType));
      dispatch(getTrendLinkAction(id, initialType));
    });
  }, [initialType]);

  const handleDeleteTrend = useCallback((idTrend) => {
    dispatch(deleteTrendAction(idTrend));
  }, []);

  const handleUpdateTrend = useCallback((trendId, values) => {
    if (!trendId) {
      dispatch(createTrendAction(id, trendTypeId, values.name, () => setNewTrend(false)));
    } else dispatch(updateTrendAction(trendId, values));
  }, [trendTypeId]);

  const handleChangeTrendType = useCallback((typeId) => {
    setSearchParams({ type: typeId });
  }, []);

  const {
    sortBy,
    sortDirection,
    sortedList,
    handleSort
  } = useSortTrends(SORT_KEY_TREND.VALUE, SORT_DIRECTION.DESC, trendsProject, newTrend);

  const handleVisibleLinkModal = useCallback((value) => {
    setLinkModal(value);
  }, []);

  const handleChangeLink = useCallback((values) => {
    if (values.id) {
      dispatch(updateTrendLinkAction(values));
    } else {
      dispatch(createTrendLinkAction(id, trendTypeId, values.url));
    }
    handleVisibleLinkModal(false);
  }, [trendTypeId]);

  const handleDeleteLink = useCallback(() => {
    dispatch(deleteTrendLinkAction(link.id));
    setDeleteLinkModal(false);
  }, [link]);

  const handleOpenLink = useCallback((url) => {
    window.open(url, '_blank');
  }, []);

  return (
    <Page
      pageContainer={styles.container}
      loader={loading || loadingProject}
      breadcrumbs={breadcrumbs}
      switchPages={switchPages}
    >
      <div className={styles.contentContainer}>
        <TrendsHeader
          trendsList={trendsList}
          id={trendTypeId}
          onChangeTrendType={handleChangeTrendType}
          info={trendTypeHeaderInfo}
          link={link}
          onOpenModal={() => handleVisibleLinkModal(true)}
          onOpen={handleOpenLink}
          onDelete={() => setDeleteLinkModal(true)}
        />

        {!!sortedList.length && (
          <TrendsItemHeader
            sortBy={sortBy}
            sortDirection={sortDirection}
            onSort={handleSort}
          />
        )}

        {sortedList.map(({
          id: idTrend, name, influence_id: influenceId, value, trend_message: trendMessage
        }) => (
          <TrendsItem
            key={idTrend}
            name={name}
            influenceId={influenceId}
            value={value}
            trendMessage={trendMessage || ''}
            influenceList={influenceList}
            onDeleteTrend={handleDeleteTrend}
            onUpdateTrend={(values) => handleUpdateTrend(idTrend, values)}
            id={idTrend}
          />
        ))}
        <Button
          onClick={() => setNewTrend(true)}
          style={styles.trendButtonContainer}
          styleButton={styles.button}
          text={t('createNewTrend')}
          icon={<Plus />}
          disabled={newTrend}
        />

        {linkModal && (
          <LinkModal
            link={link}
            onClose={() => handleVisibleLinkModal(false)}
            onSave={handleChangeLink}
          />
        )}

        {!!deleteLinkModal && (
          <ModalDelete
            onClose={() => setDeleteLinkModal(null)}
            onDelete={handleDeleteLink}
            text={t('link')}
          />
        )}
      </div>
    </Page>
  );
};

export default Trends;
