const adminScenarioHeader = [
  {
    name: 'createDate',
    sortName: 'created_at',
    sortable: true,
  },
  {
    name: 'name',
    sortName: 'name',
    sortable: true
  },
  {
    name: 'description',
    sortName: 'description',
    sortable: true
  },
  {
    name: 'trend',
    sortName: 'trend',
    sortable: false
  },
  {
    name: 'stakeholder',
    sortName: 'stakeholder',
    sortable: false
  },
  {
    name: 'actions',
    sortable: false
  }
];

export default adminScenarioHeader;
