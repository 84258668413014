import React from 'react';
import PropTypes from 'prop-types';

const M4ChartArrowButton = ({
  styleContainer, style, color, onClick
}) => {
  return (
    <div className={styleContainer}>
      <div className={style} role="presentation" onClick={onClick}>
        <span style={{ color }}>&gt;</span>
      </div>
    </div>
  );
};

M4ChartArrowButton.propTypes = {
  styleContainer: PropTypes.string.isRequired,
  style: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default M4ChartArrowButton;
