import { SET_SUCCESS_REGISTRATION_REQUEST, SET_SUCCESS_REGISTRATION_FAILED, SET_SUCCESS_REGISTRATION_SUCCESS } from './types';
import instance from '../../services/axios';
import { VERIFY_EMAIL } from '../../constants/api';

export const setSuccessRegistrationSuccess = (status) => ({ type: SET_SUCCESS_REGISTRATION_SUCCESS, status });
export const setSuccessRegistrationRequest = () => ({ type: SET_SUCCESS_REGISTRATION_REQUEST });
export const setSuccessRegistrationFailed = (error) => ({ type: SET_SUCCESS_REGISTRATION_FAILED, error });

export const verifyEmailAction = (token) => {
  return (dispatch) => {
    dispatch(setSuccessRegistrationRequest());
    instance.post(VERIFY_EMAIL, { token }, { withoutAuth: true })
      .then(() => dispatch(setSuccessRegistrationSuccess('verified')))
      .catch(({ response: { data: { errors: { token: error } } } }) => {
        dispatch(setSuccessRegistrationFailed(...error));
      });
  };
};
