import React from 'react';
import PropTypes from 'prop-types';
import Title from '../Title/Title';
import styles from './sass/Modal.module.scss';

import { ReactComponent as Close } from '../../assets/icons/close.svg';

const Modal = ({
  text, onClose, children, visibleTitle
}) => {
  return (
    <div className={styles.modal}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <div className={styles.header}>
              <Title text={text} visibleTitle={visibleTitle} style={styles.title} />
              <Close className={styles.icon} onClick={onClose} />
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  text: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  visibleTitle: PropTypes.bool
};

Modal.defaultProps = {
  visibleTitle: true,
  text: ''
};

export default Modal;
