import React, {
  useState, useCallback, useLayoutEffect
} from 'react';
import PropTypes from 'prop-types';
import {
  SAM, SOM, TAM
} from '../../../constants/general';
import {
  getAmountValue,
  getCurrencyValue,
  isNumberValueValid,
  getPercentageValue,
  getQtyValue,
  percentage
} from '../../../utils/helpers';
import availableSegment from '../../../constants/availableM2';
import Input from '../../../components/Input/Input';
import M2AvailableItemTitle from './M2AvailableItemTitle';
import styles from '../sass/M2.module.scss';
import CurrencyInput from '../../../components/Input/CurencyInput';

const M2AvailableItem = ({
  type, title, isPercent, calculate, onChangeAvailable, tip
}) => {
  const [availableItem, setAvailableItem] = useState(null);

  const {
    tam_qty: tamQty,
    tam_amount: tamAmount,
    sam_percentage: samPercentage,
    som_percentage: somPercentage,
  } = calculate;

  useLayoutEffect(() => {
    const calculateAsArray = Object.entries(calculate);
    const filtered = calculateAsArray.filter(([key]) => key.includes(type));
    setAvailableItem(Object.fromEntries(filtered));
  }, [calculate, type]);

  const handleChangeAvailableItem = useCallback((event) => {
    const number = event.target.value.replace(/[^0-9.]/g, '');
    if (isNumberValueValid(event.target.name === availableSegment.tam.field.qty, number)) {
      setAvailableItem({
        ...availableItem,
        [event.target.name]: number
      });
    }
  }, [availableItem]);

  const handleBlurAvailableItem = useCallback(() => {
    if (tamQty !== +availableItem.tam_qty || tamAmount !== +availableItem.tam_amount) {
      const value = { tam_qty: +availableItem.tam_qty, tam_amount: +availableItem.tam_amount };
      if (samPercentage) {
        value.sam_qty = percentage(+availableItem.tam_qty, samPercentage);
        value.sam_amount = percentage(+availableItem.tam_amount, samPercentage);
      }
      if (somPercentage) {
        value.som_qty = percentage(+availableItem.tam_qty, somPercentage);
        value.som_amount = percentage(+availableItem.tam_amount, somPercentage);
      }
      return onChangeAvailable(value);
    }
    return null;
  }, [availableItem, calculate, samPercentage, somPercentage, tamAmount, tamQty]);

  const handleBlurPercentage = useCallback(() => {
    if (type === SAM && samPercentage !== +availableItem.sam_percentage) {
      onChangeAvailable({
        ...availableItem,
        sam_percentage: +availableItem.sam_percentage,
        sam_qty: percentage(tamQty, +availableItem.sam_percentage),
        sam_amount: percentage(tamAmount, +availableItem.sam_percentage)
      });
    }
    if (type === SOM && somPercentage !== +availableItem.som_percentage) {
      onChangeAvailable({
        ...availableItem,
        som_percentage: +availableItem.som_percentage,
        som_qty: percentage(tamQty, +availableItem.som_percentage),
        som_amount: percentage(tamAmount, +availableItem.som_percentage)
      });
    }
  }, [availableItem, calculate]);

  return (
    <div className={styles.availableItem}>
      <M2AvailableItemTitle
        title={title}
        isPercent={isPercent}
        disabled={!tamAmount && !tamQty}
        percentageValue={tamAmount || tamQty ? getPercentageValue(type, availableItem) : ''}
        type={type}
        onChange={handleChangeAvailableItem}
        onBlur={handleBlurPercentage}
        tip={tip}
      />
      <div className={styles.availableItemCalculation}>
        <div
          className={`${styles.availableItemCalculationInputContainer} ${type === TAM ? styles.tam : ''}`}
        >
          <Input
            onChange={handleChangeAvailableItem}
            style={styles.availableItemCalculationInput}
            disabled={type !== TAM}
            value={getQtyValue(type, availableItem)}
            name={availableSegment.tam.field.qty}
            onBlur={handleBlurAvailableItem}
          />
          <span>#</span>
        </div>
        <div className={`${styles.availableItemCalculationInputContainer} ${styles.currency}`}>
          <CurrencyInput
            style={styles.availableItemCalculationInput}
            onChange={handleChangeAvailableItem}
            onBlur={handleBlurAvailableItem}
            disabled={type !== TAM}
            value={getAmountValue(type, availableItem)}
            name={availableSegment.tam.field.amount}
            currencySymbol={getCurrencyValue(type, availableItem)}
          />
        </div>
      </div>
    </div>
  );
};

M2AvailableItem.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isPercent: PropTypes.bool.isRequired,
  calculate: PropTypes.shape({
    sam_amount: PropTypes.number,
    sam_currency: PropTypes.string,
    sam_percentage: PropTypes.number,
    sam_qty: PropTypes.number,
    som_amount: PropTypes.number,
    som_currency: PropTypes.string,
    som_percentage: PropTypes.number,
    som_qty: PropTypes.number,
    tam_amount: PropTypes.number,
    tam_currency: PropTypes.string,
    tam_qty: PropTypes.number,
  }),
  onChangeAvailable: PropTypes.func.isRequired,
  tip: PropTypes.node.isRequired
};

M2AvailableItem.defaultProps = {
  calculate: {
    sam_amount: 0,
    sam_currency: '',
    sam_percentage: 0,
    sam_qty: 0,
    som_amount: 0,
    som_currency: '',
    som_percentage: 0,
    som_qty: 0,
    tam_amount: 0,
    tam_currency: '',
    tam_qty: 0,
  }
};

export default M2AvailableItem;
