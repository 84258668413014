import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { convertFromIdToNameChildren } from '../../../../utils/helpers';
import Input from '../../../../components/Input/Input';
import SelectInput from '../../../../components/SelectInput/SelectInput';
import CustomDatePicker from '../../../../components/CustomDatePicker/CustomDatePicker';
import Button from '../../../../components/Button/Button';
import styles from '../sass/AdminStakeholders.module.scss';

const AdminStakeholdersFilter = ({
  onClick,
  onChange,
  driver,
  onChangeStakeholder,
  stakeholdersList,
  stakeholder,
  onChangeDate,
  start,
  end
}) => {
  const { t } = useTranslation();

  return (
    <form
      className={styles.filterContainer}
      onSubmit={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      <Input
        onChange={onChange}
        name="name"
        title={t('searchByStakeholderDriver')}
        style={styles.filter}
        value={driver}
      />
      <SelectInput
        onChange={(id) => onChangeStakeholder(id, 'type')}
        name="stakeholder"
        title={t('stakeholder')}
        styleContainer={`${styles.filter} ${styles.selectInput}`}
        styleDropdownContent={styles.filterDropDown}
        values={stakeholdersList}
        value={convertFromIdToNameChildren(stakeholdersList, stakeholder)}
      />

      <CustomDatePicker
        startDate={start}
        endDate={end}
        onChangeDate={onChangeDate}
      />

      <Button
        text={t('filter')}
        style={styles.buttonFilterContainer}
        styleButton={styles.buttonFilter}
        type="submit"
      />
    </form>
  );
};

AdminStakeholdersFilter.propTypes = {
  onClick: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  driver: PropTypes.string.isRequired,
  onChangeStakeholder: PropTypes.func.isRequired,
  stakeholdersList: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
  stakeholder: PropTypes.number,
  onChangeDate: PropTypes.func.isRequired,
  start: PropTypes.string,
  end: PropTypes.string,
};

AdminStakeholdersFilter.defaultProps = {
  stakeholdersList: [],
  stakeholder: null,
  start: null,
  end: null,
};

export default AdminStakeholdersFilter;
