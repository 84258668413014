import {
  SET_TRENDS_FAILED,
  SET_TRENDS_REQUEST,
  SET_TRENDS_SUCCESS,
  SET_TRENDS_TRENDS_LIST_SUCCESS,
  SET_TRENDS_INFLUENCE_LIST_SUCCESS,
  SET_TRENDS_LINK_SUCCESS,
  CLEAN_TRENDS
} from './types';
import instance from '../../services/axios';
import {
  INFLUENCES_URL,
  TREND_LINKS_URL,
  TREND_WORKSHEETS_URL,
  TRENDS_CATEGORY_URL,
  TRENDS_URL
} from '../../constants/api';
import notify from '../../utils/notify';

export const setTrendsRequest = () => ({ type: SET_TRENDS_REQUEST });
export const setTrendsFailed = (error) => ({ type: SET_TRENDS_FAILED, error });
export const cleanTrends = () => ({ type: CLEAN_TRENDS });

export const setTrendsSuccess = (data) => ({ type: SET_TRENDS_SUCCESS, data });

export const setTrendsTrendsListSuccess = (data) => ({ type: SET_TRENDS_TRENDS_LIST_SUCCESS, data });

export const setTrendsInfluenceList = (data) => ({ type: SET_TRENDS_INFLUENCE_LIST_SUCCESS, data });

export const setTrendsLink = (data) => ({ type: SET_TRENDS_LINK_SUCCESS, data });

export const getTrendTrendsListAction = () => {
  return (dispatch) => {
    dispatch(setTrendsRequest());
    instance.get(TRENDS_CATEGORY_URL)
      .then(({ data: { data } }) => dispatch(setTrendsTrendsListSuccess(data)))
      .catch(({ response: { data } }) => {
        dispatch(setTrendsFailed(data));
        notify('error', data.message);
      });
  };
};

export const getTrendAction = (idProject, idTypeTrend = '') => {
  return (dispatch) => {
    dispatch(setTrendsRequest());
    instance.get(`${TREND_WORKSHEETS_URL}/${idProject}/${idTypeTrend}`)
      .then(({ data: { data } }) => dispatch(setTrendsSuccess(data)))
      .catch(({ response: { data } }) => {
        dispatch(setTrendsFailed(data));
        notify('error', data.message);
      });
  };
};

export const getTrendLinkAction = (idProject, idTypeTrend = '') => {
  return (dispatch) => {
    dispatch(setTrendsRequest());
    instance.get(`${TREND_LINKS_URL}/${idProject}/${idTypeTrend}`)
      .then(({ data: { data } }) => {
        const link = { id: data?.id || null, url: data?.url || '' };
        dispatch(setTrendsLink(link));
      })
      .catch(({ response: { data } }) => {
        dispatch(setTrendsFailed(data));
        notify('error', data.message);
      });
  };
};

export const createTrendLinkAction = (idProject, idTypeTrend, newUrl) => {
  return (dispatch) => {
    const newLink = {
      url: newUrl,
      trend_type_id: +idTypeTrend,
      project_id: +idProject
    };
    dispatch(setTrendsRequest());
    instance.post(TREND_LINKS_URL, newLink)
      .then(({ data: { data: { id, url } } }) => {
        dispatch(setTrendsLink({ id, url }));
      })
      .catch(({ response: { data } }) => {
        dispatch(setTrendsFailed(data));
        notify('error', data.message);
      });
  };
};

export const updateTrendLinkAction = (link) => {
  return (dispatch) => {
    const newLink = {
      url: link.url,
    };
    dispatch(setTrendsRequest());
    instance.put(`${TREND_LINKS_URL}/${link.id}`, newLink)
      .then(({ data: { data: { id, url } } }) => {
        dispatch(setTrendsLink({ id, url }));
      })
      .catch(({ response: { data } }) => {
        dispatch(setTrendsFailed(data));
        notify('error', data.message);
      });
  };
};

export const deleteTrendLinkAction = (id) => {
  return (dispatch) => {
    dispatch(setTrendsRequest());
    instance.delete(`${TREND_LINKS_URL}/${id}`)
      .then(() => {
        dispatch(setTrendsLink({ id: null, url: '' }));
      })
      .catch(({ response: { data } }) => {
        dispatch(setTrendsFailed(data));
        notify('error', data.message);
      });
  };
};

export const getTrendEditInfluenceAction = () => {
  return (dispatch) => {
    dispatch(setTrendsRequest());
    instance.get(INFLUENCES_URL)
      .then(({ data: { data } }) => dispatch(setTrendsInfluenceList(data)))
      .catch(({ response: { data } }) => {
        dispatch(setTrendsFailed(data));
        notify('error', data.message);
      });
  };
};

export const createTrendAction = (idProject, idTypeTrend, name, closePlaceholder) => {
  const newTrend = {
    name,
    trend_type_id: +idTypeTrend,
    project_id: +idProject
  };
  return (dispatch, getState) => {
    const { trends: { trendsProject } } = getState();
    dispatch(setTrendsRequest());
    instance.post(TRENDS_URL, newTrend)
      .then(({ data: { data } }) => {
        dispatch(setTrendsSuccess([...trendsProject, data]));
        closePlaceholder();
      })
      .catch(({ response: { data } }) => {
        dispatch(setTrendsFailed(data));
        notify('error', data.message);
      });
  };
};

export const deleteTrendAction = (idTrend) => {
  return (dispatch, getState) => {
    const { trends: { trendsProject } } = getState();
    dispatch(setTrendsRequest());
    instance.delete(`${TRENDS_URL}/${idTrend}`)
      .then(() => dispatch(setTrendsSuccess(trendsProject.filter((trend) => trend.id !== idTrend))))
      .catch(({ response: { data } }) => {
        dispatch(setTrendsFailed(data));
        notify('error', data.message);
      });
  };
};

export const updateTrendAction = (idTrend, trend) => {
  return (dispatch, getState) => {
    const updateTrend = {
      trend_message: trend.trendMessage || '',
      value: trend.value,
      name: trend.name,
      influence_id: trend.influenceId,
    };
    const { trends: { trendsProject } } = getState();
    dispatch(setTrendsRequest());
    instance.put(`${TRENDS_URL}/${idTrend}`, updateTrend)
      .then(({ data: { data } }) => {
        const newTrendsProject = trendsProject
          .map((trendProject) => (data.id === trendProject.id ? data : trendProject));
        dispatch(setTrendsSuccess(newTrendsProject));
      })
      .catch(({ response: { data } }) => {
        dispatch(setTrendsFailed(data));
        notify('error', data.message);
      });
  };
};
