import instance from '../../services/axios';
import {
  SET_M1_CREATE_WORK_SHEET_SUCCESS,
  SET_M1_LAST_WORK_SHEET_SUCCESS,
  SET_M1_REQUEST,
  SET_M1_FAILED,
  CLEAN_M1,
  SET_M1_AVATAR_SUCCESS,
  SET_M1_WORK_SHEET_SUCCESS,
  SET_M1_WORK_SHEET_SEGMENT_SUCCESS,
  SET_M1_WORK_SHEET_MEANING_SUCCESS,
  SET_M1_WORK_SHEET_LINKS_SUCCESS,
  SET_M1_WORK_SHEET_DRIVERS_SUCCESS,
  SET_M1_WORK_SHEET_TRIGGERS_SUCCESS,
  SET_M1_SELECT_WORK_SHEET_SUCCESS,
  SET_M1_SEGMENT_LIST_SUCCESS
} from './types';
import {
  DRIVERS_URL,
  LAST_WORK_SHEET_URL,
  MEANING_URL,
  POST,
  PUT, SEGMENT_LIST_URL,
  SEGMENTS_URL,
  TRIGGERS_URL,
  WORK_SHEET_LINK_URL,
  WORK_SHEET_URL,
} from '../../constants/api';
import notify from '../../utils/notify';
import { assignFile } from '../general/actions';

export const setM1Request = () => ({ type: SET_M1_REQUEST });
export const setM1Failed = (error) => ({ type: SET_M1_FAILED, error });

export const setSegmentListSuccess = (data) => ({ type: SET_M1_SEGMENT_LIST_SUCCESS, data });
export const setCreateWorkSheetSuccess = (id, name, segment) => ({
  type: SET_M1_CREATE_WORK_SHEET_SUCCESS, id, name, segment
});
export const setLastWorkSheetSuccess = (data) => ({ type: SET_M1_LAST_WORK_SHEET_SUCCESS, data });
export const setSelectWorkSheetSuccess = (data) => ({ type: SET_M1_SELECT_WORK_SHEET_SUCCESS, data });
export const setAvatarSuccess = (data) => ({ type: SET_M1_AVATAR_SUCCESS, data });
export const setWorkSheetSuccess = (field, data) => ({ type: SET_M1_WORK_SHEET_SUCCESS, field, data });
export const setWorkSheetSegmentSuccess = (data) => ({ type: SET_M1_WORK_SHEET_SEGMENT_SUCCESS, data });
export const setWorkSheetMeaningSuccess = (data) => ({ type: SET_M1_WORK_SHEET_MEANING_SUCCESS, data });
export const setWorkSheetLinksSuccess = (data) => ({ type: SET_M1_WORK_SHEET_LINKS_SUCCESS, data });
export const setWorkSheetDriversSuccess = (data) => ({ type: SET_M1_WORK_SHEET_DRIVERS_SUCCESS, data });
export const setWorkSheetTriggersSuccess = (data) => ({ type: SET_M1_WORK_SHEET_TRIGGERS_SUCCESS, data });

export const cleanM1 = () => ({ type: CLEAN_M1 });

export const getSegmentListAction = (id) => {
  return (dispatch) => {
    dispatch(setM1Request());
    instance.get(`${SEGMENT_LIST_URL}/${id}`)
      .then(({ data: { data } }) => {
        dispatch(setSegmentListSuccess(data));
      })
      .catch(({ response: { data } }) => {
        dispatch(setM1Failed(data));
        notify('error', data.message);
      });
  };
};

export const createWorkSheetAction = (id, idSegment) => {
  return (dispatch) => {
    dispatch(setM1Request());
    instance.post(WORK_SHEET_URL, {
      full_name: 'Default name',
      project_id: +id,
      segment_id: idSegment
    })
      .then(({ data: { data } }) => {
        dispatch(setCreateWorkSheetSuccess(data.id, data.full_name, data.segment_id));
      })
      .catch(({ response: { data } }) => {
        dispatch(setM1Failed(data));
        notify('error', data.message);
      });
  };
};

export const updateWorkSheetAction = (field, value) => {
  return (dispatch, getState) => {
    const fieldForUpdate = field === 'name' ? 'full_name' : field;
    const { m1: { id } } = getState();
    dispatch(setM1Request());
    instance.put(`${WORK_SHEET_URL}/${id}`, {
      [fieldForUpdate]: value
    })
      .then(() => dispatch(setWorkSheetSuccess(field, value)))
      .catch(({ response: { data } }) => {
        dispatch(setM1Failed(data));
        notify('error', data.message);
      });
  };
};

export const updateWorkSheetSegmentAction = (idSegment) => {
  return (dispatch, getState) => {
    const { m1: { id } } = getState();
    dispatch(setM1Request());
    instance.put(`${WORK_SHEET_URL}/${id}`, { segment_id: idSegment })
      .then(() => dispatch(setWorkSheetSegmentSuccess(idSegment)))
      .catch(({ response: { data } }) => dispatch(setM1Failed(data)));
  };
};

export const getLastWorkSheet = (idProject) => {
  return (dispatch) => {
    dispatch(setM1Request());
    instance.get(`${LAST_WORK_SHEET_URL}/${idProject}`)
      .then(({ data: { data } }) => {
        if (data?.id) {
          dispatch(setLastWorkSheetSuccess(data));
        } else dispatch(createWorkSheetAction(idProject));
      })
      .catch(({ response: { data } }) => {
        if (data?.message.includes('No query results')) {
          dispatch(createWorkSheetAction(idProject));
        } else {
          dispatch(setM1Failed(data));
          notify('error', data.message);
        }
      });
  };
};

export const createSegmentAction = (newSegment, idProject, idSegment) => {
  return (dispatch, getState) => {
    const { m1: { segmentList } } = getState();
    dispatch(setM1Request());
    instance[idSegment ? PUT : POST](
      idSegment
        ? `${SEGMENTS_URL}/${idSegment}`
        : SEGMENTS_URL,
      { name: newSegment, project_id: +idProject }
    )
      .then(({ data: { data: { id, name, value } } }) => {
        if (idSegment) {
          const updateSegmentList = segmentList
            .map((item) => (item.id === idSegment ? { id, name, value } : item));
          dispatch(setSegmentListSuccess(updateSegmentList));
        } else {
          dispatch(setSegmentListSuccess([...segmentList, { id, name, value }]));
        }
      })
      .catch(({ response: { data } }) => {
        dispatch(setM1Failed(data));
        notify('error', data.message);
      });
  };
};

export const deleteSegmentAction = (idSegment) => {
  return (dispatch, getState) => {
    const { m1: { segmentList } } = getState();
    dispatch(setM1Request());
    instance.delete(`${SEGMENTS_URL}/${idSegment}`)
      .then(() => {
        const updateList = segmentList.filter(({ id }) => id !== idSegment);
        dispatch(setSegmentListSuccess(updateList));
      })
      .catch(({ response: { data } }) => {
        dispatch(setM1Failed(data));
        notify('error', data.message);
      });
  };
};

export const updateMeaningAction = (values) => {
  return (dispatch, getState) => {
    const {
      id, name, value, description
    } = values;
    const { m1: { meanings, id: idWorkSheep } } = getState();
    dispatch(setM1Request());
    instance[id ? PUT : POST](id ? `${MEANING_URL}/${id}` : MEANING_URL, {
      meaning_id: Number.isNaN(+name) ? undefined : +name,
      worksheet_id: +idWorkSheep,
      value: +value,
      description
    })
      .then(({ data: { data } }) => {
        const newMeaning = {
          id: data.id,
          description: data.description,
          name: data.meaning.name,
          value: data.value,
          worksheet_id: data.worksheet_id
        };
        if (id) {
          const newMeanings = meanings
            .map((item) => (item.id === id ? newMeaning : item))
            .sort((a, b) => b.value - a.value);
          dispatch(setWorkSheetMeaningSuccess(newMeanings));
        } else {
          const newMeanings = [...meanings, newMeaning].sort((a, b) => b.value - a.value);
          dispatch(setWorkSheetMeaningSuccess(newMeanings));
        }
      })
      .catch(({ response: { data } }) => {
        dispatch(setM1Failed(data));
        notify('error', data.message);
      });
  };
};

export const uploadPhotoAction = (data) => {
  return (dispatch, getState) => {
    const { m1: { id } } = getState();
    dispatch(setM1Request());
    assignFile(data.id, 'Worksheet', id, 'avatar')
      .then(() => {
        dispatch(setAvatarSuccess(data));
      })
      .catch(({ response: { data: error } }) => {
        dispatch(setM1Failed(error));
        notify('error', error.message);
      });
  };
};

export const updateLinkAction = (values) => {
  return (dispatch, getState) => {
    const { id, url } = values;
    const { m1: { id: idWorkSheet } } = getState();
    dispatch(setM1Request());
    if (id) {
      instance.delete(`${WORK_SHEET_LINK_URL}/${id}`)
        .then(() => {
          instance.post(WORK_SHEET_LINK_URL, { url, worksheet_id: idWorkSheet })
            .then(({ data: { data } }) => dispatch(setWorkSheetLinksSuccess([data])))
            .catch(({ response: { data } }) => {
              dispatch(setM1Failed(data));
              notify('error', data.message);
            });
        })
        .catch(({ response: { data } }) => {
          dispatch(setM1Failed(data));
          notify('error', data.message);
        });
    } else {
      instance.post(WORK_SHEET_LINK_URL, { url, worksheet_id: idWorkSheet })
        .then(({ data: { data } }) => dispatch(setWorkSheetLinksSuccess([data])))
        .catch(({ response: { data } }) => {
          dispatch(setM1Failed(data));
          notify('error', data.message);
        });
    }
  };
};

export const deleteLinkAction = (values) => {
  return (dispatch) => {
    const { id } = values;
    dispatch(setM1Request());
    instance.delete(`${WORK_SHEET_LINK_URL}/${id}`)
      .then(() => dispatch(setWorkSheetLinksSuccess([])))
      .catch(({ response: { data } }) => {
        dispatch(setM1Failed(data));
        notify('error', data.message);
      });
  };
};

const deleteDriverAction = (idDriver) => {
  return (dispatch, getState) => {
    const { m1: { drivers } } = getState();
    dispatch(setM1Request());
    instance.delete(`${DRIVERS_URL}/${idDriver}`)
      .then(() => {
        const newDrivers = drivers.filter((item) => (item.id !== idDriver));
        dispatch(setWorkSheetDriversSuccess(newDrivers));
      })
      .catch(({ response: { data: error } }) => {
        dispatch(setM1Failed(error));
        notify('error', error.message);
      });
  };
};

export const updateDriverAction = (values, type, closePlaceholderDriver) => {
  const { name, value, id } = values;
  return (dispatch, getState) => {
    const { m1: { drivers, id: idWorkSheet } } = getState();
    if (id && !name) {
      dispatch(deleteDriverAction(id));
    } else {
      dispatch(setM1Request());
      instance[id ? PUT : POST](id ? `${DRIVERS_URL}/${id}` : DRIVERS_URL, {
        name,
        value: +value,
        type,
        worksheet_id: idWorkSheet
      })
        .then(({ data: { data } }) => {
          if (id) {
            const newDrivers = drivers.map((item) => (item.id === id ? data : item));
            dispatch(setWorkSheetDriversSuccess(newDrivers));
          } else {
            dispatch(setWorkSheetDriversSuccess([...drivers, data]));
          }
          if (closePlaceholderDriver) closePlaceholderDriver();
        })
        .catch(({ response: { data: error } }) => {
          dispatch(setM1Failed(error));
          notify('error', error.message);
          if (closePlaceholderDriver) closePlaceholderDriver();
        });
    }
  };
};

export const deleteTriggerAction = (idTrigger) => {
  return (dispatch, getState) => {
    const { m1: { triggers } } = getState();
    dispatch(setM1Request());
    instance.delete(`${TRIGGERS_URL}/${idTrigger}`)
      .then(() => {
        const newTriggers = triggers.filter((item) => (item.id !== idTrigger));
        dispatch(setWorkSheetTriggersSuccess(newTriggers));
      })
      .catch(({ response: { data: error } }) => {
        dispatch(setM1Failed(error));
        notify('error', error.message);
      });
  };
};

export const updateTriggerAction = (values, type) => {
  const { id, description } = values;
  return (dispatch, getState) => {
    const { m1: { id: idWorkSheet, triggers } } = getState();
    if (id && !description) {
      dispatch(deleteTriggerAction(id));
    } else {
      dispatch(setM1Request());
      instance[id ? PUT : POST](id ? `${TRIGGERS_URL}/${id}` : TRIGGERS_URL, {
        type, description, worksheet_id: idWorkSheet
      })
        .then(({ data: { data } }) => {
          if (id) {
            const newTriggers = triggers.map((item) => (item.id === id ? data : item));
            dispatch(setWorkSheetTriggersSuccess(newTriggers));
          } else {
            dispatch(setWorkSheetTriggersSuccess([...triggers, data]));
          }
        })
        .catch(({ response: { data: error } }) => {
          dispatch(setM1Failed(error));
          notify('error', error.message);
        });
    }
  };
};

export const deleteWorkSheetAction = (id) => {
  return (dispatch, getState) => {
    const { m1: { id: idWorkSheet } } = getState();
    dispatch(setM1Request());
    instance.delete(`${WORK_SHEET_URL}/${idWorkSheet}`)
      .then(() => dispatch(getLastWorkSheet(id)))
      .catch(({ response: { data: error } }) => {
        dispatch(setM1Failed(error));
        notify('error', error.message);
      });
  };
};

export const getWorkSheetAction = (idWorkSheet) => {
  return (dispatch) => {
    dispatch(setM1Request());
    instance.get(`${WORK_SHEET_URL}/${idWorkSheet}`)
      .then(({ data: { data } }) => dispatch(setSelectWorkSheetSuccess(data)))
      .catch(({ response: { data: error } }) => {
        dispatch(setM1Failed(error));
        notify('error', error.message);
      });
  };
};
