import {
  SET_PROJECTS_SUCCESS,
  SET_PROJECTS_REQUEST,
  SET_PROJECTS_FAILED,
  SET_PROJECTS_PROJECT,
  SET_PROJECTS_PROJECT_CLEAN,
  SET_PROJECTS_NEW_PROJECT_SUCCESS,
  SET_PROJECTS_EDIT_PROJECT,
  SET_PROJECTS_EDIT_PROJECT_SUCCESS,
  SET_PROJECTS_DELETE_SUCCESS,
  SET_PROJECTS_DUPLICATE_SUCCESS,
  SET_PROJECTS_SCENARIOS_SUCCESS,
  CLEAN_PROJECTS
} from './types';

const initialState = {
  projectsList: [],
  industriesList: [],
  scenariosList: [],
  project: null,
  nextLink: null,
  loading: false,
  error: null,
};

export default function projects(state = initialState, action) {
  switch (action.type) {
    case SET_PROJECTS_SUCCESS:
      return {
        ...state,
        projectsList: action.data,
        nextLink: action.nextLink,
        loading: false,
        error: null
      };
    case SET_PROJECTS_DUPLICATE_SUCCESS:
    case SET_PROJECTS_NEW_PROJECT_SUCCESS:
      return {
        ...state,
        projectsList: [...state.projectsList, action.data],
        project: null,
        loading: false,
        error: null
      };
    case SET_PROJECTS_EDIT_PROJECT_SUCCESS:
      return {
        ...state,
        projectsList: action.data,
        project: null,
        loading: false,
        error: null
      };
    case SET_PROJECTS_DELETE_SUCCESS:
      return {
        ...state,
        projectsList: action.data,
        project: null,
        loading: false,
        error: null
      };
    case SET_PROJECTS_EDIT_PROJECT:
      return {
        ...state,
        project: action.data
      };
    case SET_PROJECTS_PROJECT:
      return {
        ...state,
        project: {
          ...state.project,
          [action.field]: action.value
        }
      };
    case SET_PROJECTS_PROJECT_CLEAN:
      return {
        ...state,
        project: null
      };
    case SET_PROJECTS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_PROJECTS_SCENARIOS_SUCCESS: {
      return {
        ...state,
        scenariosList: action.data,
        loading: false,
        error: null
      };
    }
    case SET_PROJECTS_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case CLEAN_PROJECTS:
      return initialState;
    default:
      return state;
  }
}
