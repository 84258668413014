import React from 'react';
import PropTypes from 'prop-types';
import { getEmptyArray } from '../../../utils/helpers';
import TitleWithTip from '../../../components/TitleWithTip/TitleWithTip';
import O4ItemValues from './O4ItemValues';
import styles from '../sass/O4.module.scss';

const O4Item = ({
  title,
  tip,
  data,
  onChange,
  type,
  onDelete,
  disabled,
  placeholder
}) => {
  return (
    <div className={styles.itemContainer}>
      <TitleWithTip
        text={title}
        styleContainer={styles.titleContainer}
        styleTitle={styles.title}
      >
        {tip}
      </TitleWithTip>

      {getEmptyArray(10).map((item, index) => (
        <O4ItemValues
          key={`${type}-${index.toString()}`}
          name={data[index]?.name || ''}
          value={data[index]?.value}
          id={data[index]?.id}
          onChange={onChange}
          onDelete={onDelete}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
        />
      ))}
    </div>
  );
};

O4Item.propTypes = {
  title: PropTypes.string.isRequired,
  tip: PropTypes.node,
  data: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    name: PropTypes.string,
    id: PropTypes.number
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired
};

O4Item.defaultProps = {
  tip: null
};

export default O4Item;
