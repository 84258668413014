import { useMemo } from 'react';

import Menu1 from '../assets/icons/menu1.svg';
import Menu2 from '../assets/icons/menu2.svg';
import Menu3 from '../assets/icons/menu3.svg';
import Menu4 from '../assets/icons/menu4.svg';
import Menu5 from '../assets/icons/menu5.svg';
import Menu6 from '../assets/icons/menu6.svg';
import Menu6Admin from '../assets/icons/menu6Admin.svg';
import Menu7 from '../assets/icons/menu7.svg';
import Menu1hover from '../assets/icons/menu1hover.svg';
import Menu2hover from '../assets/icons/menu2hover.svg';
import Menu3hover from '../assets/icons/menu3hover.svg';
import Menu4hover from '../assets/icons/menu4hover.svg';
import Menu5hover from '../assets/icons/menu5hover.svg';
import Menu6hover from '../assets/icons/menu6hover.svg';
import Menu7hover from '../assets/icons/menu7hover.svg';

const useMenu = (
  onClickMenu1,
  onClickMenu2,
  onClickMenu3,
  onClickMenu4,
  onClickMenu5,
  onClickMenu6,
  onClickMenu7,
  isAdmin = false
) => {
  return useMemo(() => (
    [
      {
        label: 'Menu1',
        onClick: onClickMenu1,
        icon: Menu1,
        iconHover: Menu1hover
      },
      {
        label: 'Menu2',
        onClick: onClickMenu2,
        icon: Menu2,
        iconHover: Menu2hover
      },
      {
        label: 'Menu3',
        onClick: onClickMenu3,
        icon: Menu3,
        iconHover: Menu3hover
      },
      {
        label: 'Menu4',
        onClick: onClickMenu4,
        icon: Menu4,
        iconHover: Menu4hover
      },
      {
        label: 'Menu5',
        onClick: onClickMenu5,
        icon: Menu5,
        iconHover: Menu5hover
      },
      {
        label: 'Menu6',
        onClick: onClickMenu6,
        icon: isAdmin ? Menu6Admin : Menu6,
        iconHover: Menu6hover
      },
      {
        label: 'Menu7',
        onClick: onClickMenu7,
        icon: Menu7,
        iconHover: Menu7hover
      }
    ]
  ), [
    onClickMenu1,
    onClickMenu2,
    onClickMenu3,
    onClickMenu4,
    onClickMenu5,
    onClickMenu6,
    onClickMenu7
  ]);
};

export default useMenu;
