import React from 'react';
import { useTranslation } from 'react-i18next';

const O1TipContent = () => {
  const { t } = useTranslation();
  return (
    <>
      <p>{t('tipO1_1')}</p>
      <p>
        {t('tipO1_2')}
      </p>
    </>
  );
};

export default O1TipContent;
