import { batch } from 'react-redux';
import {
  CLEAN_O2,
  SET_02_ORGANIZATION_DRIVERS_SUCCESS,
  SET_O2_COMPETITORS_DRIVERS_SUCCESS,
  SET_O2_FAILED,
  SET_O2_OWN_ORGANIZATION_NAME_SUCCESS,
  SET_O2_REQUEST,
  SET_O2_TOP_MARKET_DRIVERS_SUCCESS,
  SET_O2_OWN_ORGANIZATION_OFFERING_SUCCESS
} from './types';
import instance from '../../services/axios';
import {
  COMPETITOR_DRIVER_LIST_URL,
  COMPETITORS_URL,
  DRIVER_ORGANIZATION_URL,
  DRIVERS_URL,
  MARKET_OPERATIONAL_PRIORITY_URL,
  MEANING_URL,
  WORKSHEET_ORGANIZATION_URL
} from '../../constants/api';
import notify from '../../utils/notify';
import { getDriversAndOrganization, updateCompetitorsDriver } from '../general/actions';

export const setO2Request = () => ({ type: SET_O2_REQUEST });
export const setO2Failed = (error) => ({ type: SET_O2_FAILED, error });
export const cleanO2 = () => ({ type: CLEAN_O2 });

export const setTopMarketDriversSuccess = (data) => ({ type: SET_O2_TOP_MARKET_DRIVERS_SUCCESS, data });

export const setOrganizationDriversSuccess = (data) => ({ type: SET_02_ORGANIZATION_DRIVERS_SUCCESS, data });

export const setCompetitorsDriversSuccess = (data) => ({ type: SET_O2_COMPETITORS_DRIVERS_SUCCESS, data });

export const setOwnOrganizationNameSuccess = (data) => ({ type: SET_O2_OWN_ORGANIZATION_NAME_SUCCESS, data });

export const setOwnOrganizationOfferingSuccess = (data) => ({ type: SET_O2_OWN_ORGANIZATION_OFFERING_SUCCESS, data });

export const getDriversAndOrganizationAction = (id) => {
  return (dispatch) => {
    const url = MARKET_OPERATIONAL_PRIORITY_URL;
    dispatch(setO2Request());
    getDriversAndOrganization(url, id)
      .then(({
        topMarketDrivers, organizationDrivers, ownOrganizationName, ownOrganizationOffering
      }) => {
        batch(() => {
          dispatch(setTopMarketDriversSuccess(topMarketDrivers));
          dispatch(setOrganizationDriversSuccess(organizationDrivers));
          dispatch(setOwnOrganizationNameSuccess(ownOrganizationName));
          dispatch(setOwnOrganizationOfferingSuccess(ownOrganizationOffering));
        });
      })
      .catch(({ response: { data } }) => {
        dispatch(setO2Failed(data));
        notify('error', data.message);
      });
  };
};

export const getCompetitorDrivers = (id) => {
  return (dispatch) => {
    dispatch(setO2Request());
    instance.get(`${COMPETITOR_DRIVER_LIST_URL}/${id}`)
      .then(({ data: { data } }) => {
        dispatch(setCompetitorsDriversSuccess(data));
      })
      .catch(({ response: { data } }) => {
        dispatch(setO2Failed(data));
        notify('error', data.message);
      });
  };
};

export const changeOwnOrganizationAction = (idProject, name, offering) => {
  return (dispatch) => {
    dispatch(setO2Request());
    instance.put(`${WORKSHEET_ORGANIZATION_URL}/${idProject}`, { full_name: name, offering })
      .then(() => {
        batch(() => {
          dispatch(setOwnOrganizationNameSuccess(name));
          dispatch(setOwnOrganizationOfferingSuccess(offering));
        });
      })
      .catch(({ response: { data } }) => {
        dispatch(setO2Failed(data));
        notify('error', data.message);
      });
  };
};

export const getAddCompetitorAction = (id, name, offering) => {
  return (dispatch, getState) => {
    const { o2: { competitorsDrivers } } = getState();
    dispatch(setO2Request());
    instance.post(COMPETITORS_URL, {
      name, project_id: +id, offering
    })
      .then(({ data: { data } }) => {
        dispatch(setCompetitorsDriversSuccess([...competitorsDrivers, data]));
      })
      .catch(({ response: { data } }) => {
        dispatch(setO2Failed(data));
        notify('error', data.message);
      });
  };
};

export const getEditCompetitorAction = (id, name, offering) => {
  return (dispatch, getState) => {
    const { o2: { competitorsDrivers } } = getState();
    dispatch(setO2Request());
    instance.put(`${COMPETITORS_URL}/${id}`, { name, offering })
      .then(() => {
        const updateCompetitors = competitorsDrivers
          .map((item) => (item.id === id ? { ...item, name, offering } : item));
        dispatch(setCompetitorsDriversSuccess(updateCompetitors));
      })
      .catch(({ response: { data } }) => {
        dispatch(setO2Failed(data));
        notify('error', data.message);
      });
  };
};

export const deleteCompetitorAction = (id, idProject) => {
  return (dispatch, getState) => {
    const { o2: { competitorsDrivers } } = getState();
    dispatch(setO2Request());
    instance.delete(`${COMPETITORS_URL}/${id}`)
      .then(() => {
        const updateCompetitors = competitorsDrivers.filter(({ id: idCustomer }) => idCustomer !== id);
        batch(() => {
          dispatch(setCompetitorsDriversSuccess(updateCompetitors));
          dispatch(getDriversAndOrganizationAction(idProject));
        });
      })
      .catch(({ response: { data } }) => {
        dispatch(setO2Failed(data));
        notify('error', data.message);
      });
  };
};

export const updateCompetitorsDriverAction = (driver, competitorId, idProject) => {
  return (dispatch, getState) => {
    const { o2: { competitorsDrivers } } = getState();
    const copyCompetitorsDrivers = [...competitorsDrivers];
    const indexCompetitor = competitorsDrivers.findIndex(({ id }) => id === competitorId);
    dispatch(setO2Request());
    updateCompetitorsDriver(driver, competitorId)
      .then((data) => {
        if (competitorId && !driver.id) {
          copyCompetitorsDrivers[indexCompetitor] = {
            ...copyCompetitorsDrivers[indexCompetitor],
            drivers: [...copyCompetitorsDrivers[indexCompetitor].drivers, data]
          };
          batch(() => {
            dispatch(setCompetitorsDriversSuccess(copyCompetitorsDrivers));
            dispatch(getDriversAndOrganizationAction(idProject));
          });
        } else {
          copyCompetitorsDrivers[indexCompetitor] = {
            ...copyCompetitorsDrivers[indexCompetitor],
            drivers: copyCompetitorsDrivers[indexCompetitor].drivers
              .map((i) => (i.driver_id === data.driver_id ? data : i))
          };
          batch(() => {
            dispatch(setCompetitorsDriversSuccess(copyCompetitorsDrivers));
            dispatch(getDriversAndOrganizationAction(idProject));
          });
        }
      })
      .catch(({ response: { data } }) => {
        dispatch(setO2Failed(data));
        notify('error', data.message);
      });
  };
};

export const updateOwnOrganizationDriverAction = (driver, idProject) => {
  return (dispatch) => {
    const { driverId, rate } = driver;
    dispatch(setO2Request());
    instance.put(`${DRIVER_ORGANIZATION_URL}/${driverId}`, { value: +rate })
      .then(() => dispatch(getDriversAndOrganizationAction(idProject)))
      .catch(({ response: { data } }) => {
        dispatch(setO2Failed(data));
        notify('error', data.message);
      });
  };
};

export const updateMeaningAction = (idProject, meaning) => {
  const { value, meaningId, selectIdMeaning } = meaning;
  return (dispatch) => {
    dispatch(setO2Request());
    instance.put(`${MEANING_URL}/${meaningId}`, {
      value,
      meaning_id: selectIdMeaning
    })
      .then(() => dispatch(getDriversAndOrganizationAction(idProject)))
      .catch(({ response: { data } }) => {
        dispatch(setO2Failed(data));
        notify('error', data.message);
      });
  };
};

export const getUpdateTopMarketDriverAction = (idProject, driver) => {
  const { id, name, value } = driver;
  return (dispatch) => {
    dispatch(setO2Request());
    instance.put(`${DRIVERS_URL}/${id}`, {
      name,
      value
    })
      .then(() => dispatch(getDriversAndOrganizationAction(idProject)))
      .catch(({ response: { data } }) => {
        dispatch(setO2Failed(data));
        notify('error', data.message);
      });
  };
};

export const deleteTopMarketDriverAction = (id, idDriver) => {
  return (dispatch) => {
    dispatch(setO2Request());
    instance.delete(`${DRIVERS_URL}/${idDriver}`)
      .then(() => dispatch(getDriversAndOrganizationAction(id)))
      .catch(({ response: { data } }) => {
        dispatch(setO2Failed(data));
        notify('error', data.message);
      });
  };
};
