import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getEmptyArray } from '../../../utils/helpers';
import Button from '../../Button/Button';
import DriversColumnItem from './DriversColumnItem';
import DriversColumnTitle from './DriversColumnTitle';
import styles from '../sass/Drivers.module.scss';

import { ReactComponent as Plus } from '../../../assets/icons/plusBlue.svg';

const DriversColumn = ({
  drivers,
  title,
  type,
  onChange,
  tip,
  textButton,
  maxLength,
  placeholder
}) => {
  const { t } = useTranslation();

  const [newDriver, setNewDriver] = useState(false);

  const driversList = useMemo(() => {
    return newDriver ? [...drivers, {
      id: null, name: '', value: 0, type
    }] : drivers;
  }, [drivers, newDriver]);

  return (
    <div className={styles.driversItemContainer}>
      <DriversColumnTitle title={title} tip={tip} />

      {driversList.length > 4 ? (
        driversList.map((driver, index) => (
          <DriversColumnItem
            key={`driver-${index.toString()}-${driver?.id?.toString()}-${driver?.date}`}
            data={driver}
            onChange={(driverItem, initialType) => {
              onChange(driverItem, initialType, () => setNewDriver(false));
            }}
            type={type}
            maxLength={maxLength}
            placeholder={placeholder}
          />
        ))
      ) : (
        getEmptyArray(5).map((item, index) => (
          <DriversColumnItem
            key={`emptyDriver-${index.toString()}`}
            data={driversList[index]}
            onChange={onChange}
            type={type}
            maxLength={maxLength}
            placeholder={placeholder}
          />
        ))
      )}

      <Button
        text={t(textButton)}
        style={styles.driversButtonContainer}
        styleButton={styles.driversButton}
        icon={<Plus />}
        disabled={drivers.length < 5 || newDriver}
        onClick={() => setNewDriver(true)}
      />
    </div>
  );
};

DriversColumn.propTypes = {
  drivers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.number,
    type: PropTypes.string,
    date: PropTypes.string
  })),
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  tip: PropTypes.node,
  textButton: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string.isRequired
};

DriversColumn.defaultProps = {
  drivers: [],
  maxLength: undefined,
  tip: undefined
};

export default DriversColumn;
