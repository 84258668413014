import React from 'react';
import PropTypes from 'prop-types';
import Title from '../../Title/Title';
import Input from '../../Input/Input';
import styles from '../sass/MarketDrivers.module.scss';

const CompetitorsColumnTitleValue = ({
  isCompetitor,
  text,
  onChange,
  onBlur,
}) => {
  return (
    isCompetitor
      ? (
        <Title
          style={styles.text}
          text={text}
        />
      )
      : (
        <Input
          onChange={onChange}
          value={text}
          style={styles.titleInput}
          onBlur={onBlur}
        />
      )
  );
};

CompetitorsColumnTitleValue.propTypes = {
  isCompetitor: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export default CompetitorsColumnTitleValue;
