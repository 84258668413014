import React from 'react';
import PropTypes from 'prop-types';
import Title from '../../Title/Title';
import styles from '../sass/Drivers.module.scss';

const DriversColumnTitle = ({ title, tip }) => {
  return (
    <div className={styles.driversItemTitleContainer}>
      <Title
        text={title}
        style={styles.driversItemTitle}
      />
      {tip}
    </div>
  );
};

DriversColumnTitle.propTypes = {
  title: PropTypes.string.isRequired,
  tip: PropTypes.node
};

DriversColumnTitle.defaultProps = {
  tip: undefined
};

export default DriversColumnTitle;
