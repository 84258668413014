import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../../utils/helpers';
import styles from '../sass/Header.module.scss';

const BreadCrumbs = ({ breadcrumbs }) => {
  return (
    breadcrumbs.length ? (
      <div className={styles.breadCrumbsContainer}>
        {breadcrumbs.map((item, index) => {
          const name = item.name ? capitalizeFirstLetter(item.name) : '';
          return (
            <React.Fragment key={`${item.name}${index.toString()}`}>
              {item.link
                ? <Link to={item.link}>{name}</Link>
                : <span>{name}</span>}
            </React.Fragment>
          );
        })}
      </div>
    ) : null
  );
};

BreadCrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string
  }))
};

BreadCrumbs.defaultProps = {
  breadcrumbs: []
};

export default BreadCrumbs;
