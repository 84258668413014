import React from 'react';
import PropTypes from 'prop-types';
import { Outlet, Navigate } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { USER } from '../constants/general';

const RequireAuth = ({ admin }) => {
  const {
    auth: { tokens: { accessToken } },
    user: { role }
  } = useSelector(({ auth, user }) => ({ auth, user }), shallowEqual);

  const isNotValidRole = admin && role === USER;

  return (
    !accessToken || isNotValidRole ? <Navigate to="/login" /> : <Outlet />
  );
};

RequireAuth.propTypes = {
  admin: PropTypes.bool
};

RequireAuth.defaultProps = {
  admin: false
};

export default RequireAuth;
