import {
  CLEAN_O8, SET_O8_FAILED, SET_O8_MISSION_STATEMENT_SUCCESS, SET_O8_REQUEST 
} from './types';

const initialState = {
  description: '',
  missionStatementId: 0,
  loading: false,
  error: null
};

export default function o8(state = initialState, action) {
  switch (action.type) {
    case SET_O8_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_O8_MISSION_STATEMENT_SUCCESS:
      return {
        ...state,
        description: action.description,
        missionStatementId: action.id,
        loading: false,
        error: null
      };
    case SET_O8_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case CLEAN_O8:
      return initialState;
    default:
      return state;
  }
}
