import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Tip from '../Tip/Tip';
import Button from '../Button/Button';
import styles from './sass/Reference.module.scss';

import { ReactComponent as LinkIcon } from '../../assets/icons/link.svg';
import { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import { ReactComponent as Play } from '../../assets/icons/play.svg';
import { ReactComponent as Plus } from '../../assets/icons/plusBlue.svg';

const Reference = ({
  link,
  onOpenModal,
  onDelete,
  onOpen,
  tipContent,
  styleContainer,
  styleWrapper,
}) => {
  const { t } = useTranslation();
  return (
    <div className={`${styles.linkContainer} ${styleContainer}`}>
      <div className={`${styles.linkWrapper} ${styleWrapper}`}>
        <div className={styles.linkContent} role="presentation" onClick={() => link?.url && onOpen(link.url)}>
          <LinkIcon />
          <span>{link.url || t('linkEvidence')}</span>
        </div>
        {link.url ? (
          <div className={styles.linkAction}>
            <Button
              style={styles.linkButtonContainer}
              styleButton={styles.linkButton}
              icon={<Delete width={14} height={14} />}
              onClick={onDelete}
            />
            <Button
              style={styles.linkButtonContainer}
              styleButton={styles.linkButton}
              icon={<Edit width={14} height={14} />}
              onClick={onOpenModal}
            />
            <Button
              style={styles.linkButtonContainer}
              styleButton={styles.linkButton}
              icon={<Play width={14} height={14} />}
              onClick={() => onOpen(link.url)}
            />
          </div>
        ) : (
          <div className={styles.linkAction}>
            <Button
              style={styles.linkButtonContainer}
              styleButton={styles.linkAddButton}
              icon={<Plus />}
              text={t('add')}
              onClick={onOpenModal}
            />
          </div>
        ) }
      </div>

      {tipContent && (
        <Tip styleContainer={styles.tipContainer}>
          {tipContent}
        </Tip>
      )}
    </div>
  );
};

Reference.propTypes = {
  link: PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string
  }),
  onOpenModal: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  tipContent: PropTypes.node,
  styleContainer: PropTypes.string,
  styleWrapper: PropTypes.string
};

Reference.defaultProps = {
  link: {
    id: null,
    url: ''
  },
  tipContent: null,
  styleContainer: '',
  styleWrapper: ''
};

export default Reference;
