import {
  CLEAN_O3,
  SET_O3_FAILED,
  SET_O3_PRIORITIES_SUCCESS,
  SET_O3_REQUEST,
  SET_O3_STAKEHOLDERS_LIST_SUCCESS
} from './types';
import instance from '../../services/axios';
import {
  DRIVERS_URL, MEANING_URL,
  PARTNERSHIP_OPPORTUNITY_URL,
  STAKEHOLDER_TYPES_URL, STAKEHOLDER_URL, WORK_SHEET_URL
} from '../../constants/api';
import notify from '../../utils/notify';

export const setO3Request = () => ({ type: SET_O3_REQUEST });
export const setO3Failed = (error) => ({ type: SET_O3_FAILED, data: error });
export const cleanO3 = () => ({ type: CLEAN_O3 });

export const setStakeholdersList = (data) => ({ type: SET_O3_STAKEHOLDERS_LIST_SUCCESS, data });

export const setPriorities = (data) => ({ type: SET_O3_PRIORITIES_SUCCESS, data });

export const getStakeholdersListAction = (id) => {
  return (dispatch) => {
    dispatch(setO3Request());
    instance.get(`${STAKEHOLDER_TYPES_URL}/${id}`)
      .then(({ data: { data } }) => dispatch(setStakeholdersList(data)))
      .catch(({ response: { data } }) => {
        dispatch(setO3Failed(data));
        notify('error', data.message);
      });
  };
};

export const getPrioritiesAction = (id, closeStakeholder) => {
  return (dispatch) => {
    dispatch(setO3Request());
    instance.get(`${PARTNERSHIP_OPPORTUNITY_URL}/${id}`)
      .then(({ data: { data } }) => {
        dispatch(setPriorities(data));
        if (closeStakeholder) {
          closeStakeholder();
        }
      })
      .catch(({ response: { data } }) => {
        dispatch(setO3Failed(data));
        notify('error', data.message);
      });
  };
};

export const changePriorityAction = (id, priorityId, priority) => {
  const { name, value } = priority;
  return (dispatch) => {
    dispatch(setO3Request());
    instance.put(`${DRIVERS_URL}/${priorityId}`, {
      name,
      value
    })
      .then(() => dispatch(getPrioritiesAction(id)))
      .catch(({ response: { data } }) => {
        dispatch(setO3Failed(data));
        notify('error', data.message);
      });
  };
};

export const updatePriorityMeaningAction = (id, priority) => {
  const { value, meaningId, selectIdMeaning } = priority;
  return (dispatch) => {
    dispatch(setO3Request());
    instance.put(`${MEANING_URL}/${meaningId}`, {
      value,
      meaning_id: selectIdMeaning
    })
      .then(() => dispatch(getPrioritiesAction(id)))
      .catch(({ response: { data } }) => {
        dispatch(setO3Failed(data));
        notify('error', data.message);
      });
  };
};

export const deletePriorityAction = (id, priorityId) => {
  return (dispatch) => {
    dispatch(setO3Request());
    instance.delete(`${DRIVERS_URL}/${priorityId}`)
      .then(() => dispatch(getPrioritiesAction(id)))
      .catch(({ response: { data } }) => {
        dispatch(setO3Failed(data));
        notify('error', data.message);
      });
  };
};

export const changePartnershipAction = (priorityId, partnership, id, closeStakeholder) => {
  return (dispatch) => {
    const updatedPartnership = {
      full_name: partnership.name,
      value: partnership.value,
      stakeholder_id: partnership?.stakeholderTypeId || undefined,
      driver_id: partnership?.isChoose ? priorityId : undefined
    };
    dispatch(setO3Request());
    instance.put(`${WORK_SHEET_URL}/${partnership.id}`, updatedPartnership)
      .then(() => {
        dispatch(getPrioritiesAction(id, closeStakeholder));
      })
      .catch(({ response: { data } }) => {
        dispatch(setO3Failed(data));
        notify('error', data.message);
      });
  };
};

export const createPartnershipAction = (id, priorityId, partnership, key, closeStakeholder) => {
  return (dispatch, getState) => {
    const { o3: { priorities } } = getState();
    const newPartnership = {
      project_id: +id,
      driver_id: priorityId,
      value: 0,
      stakeholder_id: partnership.stakeholderTypeId || undefined,
      full_name: partnership.name,
    };
    dispatch(setO3Request());
    instance.post(`${WORK_SHEET_URL}/stakeholder`, newPartnership)
      .then(({ data: { data } }) => {
        const newPriorities = { ...priorities };
        const index = newPriorities[key].findIndex((item) => item.id === priorityId);
        newPriorities[key][index][partnership.type].push({
          id: data.id,
          name: partnership.name,
          value: partnership.value,
          stakeholder_type_id: partnership.stakeholderTypeId,
        });
        closeStakeholder();
        dispatch(setPriorities(newPriorities));
      })
      .catch(({ response: { data } }) => {
        dispatch(setO3Failed(data));
        notify('error', data.message);
      });
  };
};

export const deletePartnershipAction = (priorityId, partnershipId, partnershipType, key) => {
  return (dispatch, getState) => {
    const { o3: { priorities } } = getState();
    dispatch(setO3Request());
    instance.delete(`${STAKEHOLDER_URL}/${partnershipId}/${priorityId}`)
      .then(() => {
        const newPriorities = { ...priorities };
        const index = newPriorities[key].findIndex((item) => item.id === priorityId);
        newPriorities[key][index][partnershipType] = newPriorities[key][index][partnershipType]
          .filter((item) => item.id !== partnershipId);
        dispatch(setPriorities(newPriorities));
      })
      .catch(({ response: { data } }) => {
        dispatch(setO3Failed(data));
        notify('error', data.message);
      });
  };
};

export const getStakeholderPartnerListAction = (idProject, idStakeholder, name, setPartnerList, driver) => {
  instance.get(
    `${STAKEHOLDER_URL}/${idProject}/search?name=${name}&stakeholder_id=${idStakeholder}&except=${driver}`
  )
    .then(({ data: { data } }) => {
      setPartnerList(data);
    })
    .catch(({ response: { data } }) => {
      notify('error', data.message);
    });
};
