import React from 'react';
import { useTranslation } from 'react-i18next';
import Tip from '../Tip/Tip';
import styles from './sass/M1Tips.module.scss';

const TipFinancialTrigger = () => {
  const { t } = useTranslation();
  return (
    <Tip
      styleTipTextContainer={styles.triggerContainer}
      styleTriangle={styles.triangle}
    >
      <p>
        {t('tipFinancialTrigger')}
      </p>
    </Tip>
  );
};

export default TipFinancialTrigger;
