import React from 'react';
import PropTypes from 'prop-types';
import style from './sass/Switcher.module.scss';

const Switcher = ({ isChecked, onClick, id }) => {
  return (
    <label htmlFor={id} className={style.container}>
      <input
        id={id}
        type="checkbox"
        onChange={onClick}
        className={style.input}
        checked={isChecked}
      />
      <span className={style.switcher} />
    </label>
  );
};

Switcher.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};

export default Switcher;
