export const ROLE = 'role';

export const USER = 'user';

export const ADMIN = 'admin';

export const TREND = 'trend';
export const SCENARIO = 'scenario';

export const PAGE_SIZE = 15;

export const TAM = 'tam';
export const SAM = 'sam';
export const SOM = 'som';

export const tamCurrency = 'tam_currency';
export const tamQty = 'tam_qty';
export const tamAmount = 'tam_amount';

export const samCurrency = 'sam_currency';
export const samAmount = 'sam_amount';
export const samPercentage = 'sam_percentage';
export const samQty = 'sam_qty';

export const somCurrency = 'som_currency';
export const somPercentage = 'som_percentage';
export const somAmount = 'som_amount';
export const somQty = 'som_qty';

export const video5KindsOfValue = 'https://www.youtube.com/watch?v=RZ9vVq2YqDA';
export const videoSWOTAnalysis = 'https://www.youtube.com/watch?v=M5QcLPn0-3U';

export const PARTNERSHIP = {
  OFFERINGS: 'offerings',
  POTENTIAL: 'potential_partnership',
  RESOURCE: 'resource',
};

export const GREEN = 'green';
export const RED = 'red';

export const EMPTY_O1_ITEM = {
  id: 0,
  title: '',
  value: 0
};

export const EMPTY_O3_ITEM = {
  id: 0,
  name: '',
  value: 0,
  stakeholder_type_id: 0
};

export const EMPTY_TREND_ITEM = {
  id: 0,
  name: '',
  influence_id: 0,
  value: 0,
  trend_message: ''
};

export const SORT_KEY_TREND = {
  NAME: 'name',
  INFLUENCE_NAME: 'influence_name',
  VALUE: 'value',
};

export const SORT_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc',
};

export const FIRST_TIP = 'firstTip';
export const SECOND_TIP = 'secondTip';

export const SUPPORT_EMAIL = 'info@strat.tools';

export const TRENDS_LIST = {
  NATURE: 'nature',
  SOCIETY: 'society',
  ECONOMY: 'economy',
  INDUSTRY: 'industry',
  TECHNOLOGY: 'technology',
  POLICY: 'policy',
};
