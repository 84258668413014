import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../../components/Input/Input';
import styles from '../sass/M6.module.scss';

const M6ServiceableObtainableMarketValues = ({
  somAmount, somCurrency, somQty
}) => {
  return (
    <div className={styles.valuesContainer}>
      <div className={styles.valuesInputContainer}>
        <Input
          onChange={() => {}}
          value={somQty ? somQty.toString() : ''}
          style={styles.valuesInput}
          disabled
        />
        <span>#</span>
      </div>
      <div className={styles.valuesInputContainer}>
        <Input
          onChange={() => {}}
          value={somAmount ? somAmount.toString() : ''}
          style={styles.valuesInput}
          disabled
        />
        <span>{somCurrency}</span>
      </div>
    </div>
  );
};

M6ServiceableObtainableMarketValues.propTypes = {
  somAmount: PropTypes.number.isRequired,
  somCurrency: PropTypes.string.isRequired,
  somQty: PropTypes.number.isRequired,
};

export default M6ServiceableObtainableMarketValues;
