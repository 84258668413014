import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import accessLevel from '../../../../constants/accessLevel';
import { convertFromIdToName } from '../../../../utils/helpers';
import Input from '../../../../components/Input/Input';
import SelectInput from '../../../../components/SelectInput/SelectInput';
import Button from '../../../../components/Button/Button';
import styles from '../sass/AdminUsers.module.scss';

const AdminUserFilter = ({
  industriesList,
  onChange,
  name,
  role,
  industry,
  project,
  onChangeRoleIndustry,
  onClick
}) => {
  const { t } = useTranslation();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onClick();
      }}
      className={styles.filterContainer}
    >
      <Input
        onChange={onChange}
        name="name"
        title={t('searchByUser')}
        style={styles.filter}
        value={name}
      />
      <SelectInput
        onChange={(id) => onChangeRoleIndustry('role', id)}
        name="role"
        title={t('searchByAccessLevel')}
        styleContainer={`${styles.filter} ${styles.selectInput}`}
        values={accessLevel}
        value={role}
        checkId={false}
        styleDropdownContent={styles.accessLevel}
      />
      <SelectInput
        onChange={(id) => onChangeRoleIndustry('industry', id)}
        name="industry"
        title={t('searchByIndustry')}
        styleContainer={styles.filter}
        values={industriesList}
        value={convertFromIdToName(industriesList, industry)}
      />
      <Input
        onChange={onChange}
        name="project"
        title={t('searchByProject')}
        style={styles.filter}
        value={project}
      />
      <Button
        text={t('filter')}
        style={styles.buttonFilterContainer}
        styleButton={styles.buttonFilter}
        type="submit"
      />
    </form>
  );
};

AdminUserFilter.propTypes = {
  industriesList: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onChangeRoleIndustry: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  industry: PropTypes.number,
};

AdminUserFilter.defaultProps = {
  industriesList: [],
  industry: null
};

export default memo(AdminUserFilter);
