export const SET_GENERAL_REQUEST = 'SET_GENERAL_REQUEST';
export const SET_GENERAL_INDUSTRIES_LIST_SUCCESS = 'SET_GENERAL_INDUSTRIES_LIST_SUCCESS';
export const SET_GENERAL_FAILED = 'SET_GENERAL_FAILED';
export const SET_GENERAL_MEANING_LIST_SUCCESS = 'SET_GENERAL_MEANING_LIST_SUCCESS';
export const SET_GENERAL_CATEGORY_LIST_SUCCESS = 'SET_GENERAL_CATEGORY_LIST_SUCCESS';
export const SET_USER_ID_FOW_SWITCH_SUCCESS = 'SET_USER_ID_FOW_SWITCH_SUCCESS';
export const SET_EXPORT_DATA_SUCCESS = 'SET_EXPORT_DATA_SUCCESS';
export const SET_CURRENCY_LIST_SUCCESS = 'SET_CURRENCY_LIST_SUCCESS';
export const SET_FROM_PATH = 'SET_FROM_PATH';
export const CLEAN_GENERAL = 'CLEAN_GENERAL';
