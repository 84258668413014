import React from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/Header.module.scss';

const Menu = ({ menuButtons }) => {
  return (
    <div className={styles.menuContainer}>
      {menuButtons.map(({
        label, onClick, icon, iconHover
      }) => (
        <button
          key={label}
          className={styles.menuContainerItem}
          onClick={onClick}
        >
          <img src={icon} alt={label} />
          <img src={iconHover} className={styles.imageHover} alt={label} />
        </button>
      ))}
    </div>
  );
};

Menu.propTypes = {
  menuButtons: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.string,
    iconHover: PropTypes.string
  })).isRequired
};

export default Menu;
