import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useOnBlur from '../../../hooks/useOnBlur';
import { deleteSpacesInString } from '../../../utils/helpers';
import CompetitorsColumnTitleValue from './CompetitorsColumnTitleValue';

import styles from '../sass/MarketDrivers.module.scss';

import EditIcon from '../../../assets/icons/blackEdit.svg';

const CompetitorsColumnTitle = ({
  title,
  offering,
  isCompetitor,
  onSelect,
  onChangeOwnOrganization
}) => {
  const { t } = useTranslation();

  const [titleValue, setTitleValue] = useState(title);
  const [offeringValue, setOfferingValue] = useState(offering);

  const handleChangeTitle = useCallback((e) => {
    const text = deleteSpacesInString(e.target.value);
    setTitleValue(text);
  }, []);

  const handleChangeOffering = useCallback((e) => {
    const text = deleteSpacesInString(e.target.value);
    setOfferingValue(text);
  }, []);

  const handleBlurTitle = useOnBlur(
    title,
    titleValue,
    () => onChangeOwnOrganization(titleValue, offeringValue),
    () => setTitleValue(title)
  );

  const handleBlurOffering = useOnBlur(
    offering,
    offeringValue,
    () => onChangeOwnOrganization(titleValue, offeringValue),
    () => onChangeOwnOrganization(titleValue, offeringValue)
  );

  return (
    <div
      className={
        `${styles.competitorsColumnTitle} ${!isCompetitor ? styles.titleOrganization : ''}`
      }
    >
      <span
        className={`${styles.subTitle} ${isCompetitor ? styles.subTitleCompetitor : ''}`}
      >
        {t('company')}
      </span>

      <CompetitorsColumnTitleValue
        isCompetitor={isCompetitor}
        text={isCompetitor ? title : titleValue}
        onChange={handleChangeTitle}
        onBlur={handleBlurTitle}
      />

      <span
        className={`${styles.subTitle} ${isCompetitor ? styles.subTitleCompetitor : ''}`}
      >
        {t('offering')}
      </span>

      <CompetitorsColumnTitleValue
        isCompetitor={isCompetitor}
        text={isCompetitor ? offering : offeringValue}
        onChange={handleChangeOffering}
        onBlur={handleBlurOffering}
      />

      {isCompetitor && (
        <span role="presentation" className={styles.edit} onClick={onSelect}>
          <img src={EditIcon} alt="" />
        </span>
      )}

    </div>
  );
};

CompetitorsColumnTitle.propTypes = {
  title: PropTypes.string.isRequired,
  offering: PropTypes.string.isRequired,
  isCompetitor: PropTypes.bool.isRequired,
  onSelect: PropTypes.func,
  onChangeOwnOrganization: PropTypes.func
};

CompetitorsColumnTitle.defaultProps = {
  onSelect: undefined,
  onChangeOwnOrganization: undefined
};

export default CompetitorsColumnTitle;
