import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SORT_DIRECTION, SORT_KEY_TREND } from '../../../constants/general';
import SortArrow from '../../../components/SortArrow/SortArrow';
import styles from '../sass/Trends.module.scss';

const TrendsItemHeader = ({ sortBy, sortDirection, onSort }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.trendsItemHeaderContainer}>
      <div className={styles.trendsItemHeaderValues}>
        <button className={styles.trend} onClick={() => onSort(SORT_KEY_TREND.NAME)}>
          <span>{t('trend')}</span>
          {sortBy === SORT_KEY_TREND.NAME && <SortArrow up={sortDirection === SORT_DIRECTION.ASC} />}
        </button>

        <button className={styles.influence} onClick={() => onSort(SORT_KEY_TREND.INFLUENCE_NAME)}>
          <span>{t('influence')}</span>
          {sortBy === SORT_KEY_TREND.INFLUENCE_NAME && <SortArrow up={sortDirection === SORT_DIRECTION.ASC} />}
        </button>

        <button className={styles.rank} onClick={() => onSort(SORT_KEY_TREND.VALUE)}>
          <span>{t('rank')}</span>
          {sortBy === SORT_KEY_TREND.VALUE && <SortArrow up={sortDirection === SORT_DIRECTION.ASC} />}
        </button>
      </div>
      <div className={styles.trendsItemHeaderMessage}>{t('message')}</div>
    </div>
  );
};

TrendsItemHeader.propTypes = {
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired
};

export default TrendsItemHeader;
