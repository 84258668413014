import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Input from '../../Input/Input';
import Title from '../../Title/Title';
import styles from '../sass/ModalConfirmDelete.module.scss';

const Header = ({
  accessDelete,
  value,
  onChange,
  text,
  offeringValue,
  isCompetitor,
  onChangeOffering,
  placeholder
}) => {
  const { t } = useTranslation();

  return (
    !accessDelete ? (
      <>
        <Input
          value={value}
          onChange={onChange}
          title={placeholder}
        />
        {isCompetitor && (
          <Input
            value={offeringValue}
            onChange={onChangeOffering}
            title={t('competitorOfferingPlaceholder')}
          />
        )}
      </>
    ) : (
      <Title
        text={`${t('areYouSure')} ${text}?`}
        style={styles.competitorModalTitle}
      />
    )
  );
};

Header.propTypes = {
  accessDelete: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  offeringValue: PropTypes.string,
  isCompetitor: PropTypes.bool.isRequired,
  onChangeOffering: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired
};

Header.defaultProps = {
  offeringValue: '',
};

export default Header;
