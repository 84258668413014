const PARTNERSHIPS_VALUES = [
  {
    title: 'keyOfferings',
    placeholder: 'keyOfferingsPlaceholder',
    type: 'OFFERINGS',
    tip: 'tipO4',
    isDisabled: true
  },
  {
    title: 'keyPartnerships',
    placeholder: 'keyPartnershipsPlaceholder',
    type: 'POTENTIAL',
    tip: '',
    isDisabled: true
  },
  {
    title: 'keyResources',
    placeholder: 'keyResourcesPlaceholder',
    type: 'RESOURCE',
    tip: '',
    isDisabled: false
  },
];

export default PARTNERSHIPS_VALUES;
