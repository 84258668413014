const adminScenarioDriversHeader = [
  {
    name: 'createDate',
    sortName: 'created_at',
    sortable: true
  },
  {
    name: 'stakeholder',
    sortName: 'stakeholder_type',
    sortable: true
  },
  {
    name: 'stakeholderName',
    sortName: 'name',
    sortable: true
  },
  {
    name: 'actions',
    sortable: false
  }
];

export default adminScenarioDriversHeader;
