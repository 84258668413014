import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import O5ItemValuesRowItem from './O5ItemValuesRowItem';
import styles from '../sass/O5.module.scss';

const O5ItemValuesRow = ({
  id,
  currency,
  revenueName,
  revenueValue,
  coastName,
  coastValue,
  capitalSourcesName,
  capitalSourcesValue,
  onChangeRevenue,
  onDeleteRevenue,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.valuesRowContainer}>
      <O5ItemValuesRowItem
        currency={currency}
        value={revenueValue}
        name={revenueName}
        onChange={(name, value) => {
          onChangeRevenue({
            revenueId: id,
            revenueName: name,
            revenueValue: value,
            coastName,
            coastValue,
            capitalSourcesName,
            capitalSourcesValue,
          });
        }}
        onDelete={() => onDeleteRevenue(id)}
        disabledName={false}
        disabledValue={!id}
        placeholder={t('revenueSteam')}
      />
      <O5ItemValuesRowItem
        currency={currency}
        value={coastValue}
        name={coastName}
        onChange={(name, value) => {
          onChangeRevenue({
            revenueId: id,
            coastName: name,
            coastValue: value,
            revenueName,
            revenueValue,
            capitalSourcesName,
            capitalSourcesValue
          });
        }}
        onDelete={(name, value) => {
          onChangeRevenue({
            revenueId: id,
            coastName: name,
            coastValue: value,
            revenueName,
            revenueValue,
            capitalSourcesName,
            capitalSourcesValue
          });
        }}
        disabledName={!id}
        disabledValue={!id}
        placeholder={t('operationalCost')}
      />
      <O5ItemValuesRowItem
        currency={currency}
        value={capitalSourcesValue}
        name={capitalSourcesName}
        onChange={(name, value) => {
          onChangeRevenue({
            revenueId: id,
            capitalSourcesName: name,
            capitalSourcesValue: value,
            revenueName,
            revenueValue,
            coastName,
            coastValue,
          });
        }}
        onDelete={(name, value) => {
          onChangeRevenue({
            revenueId: id,
            capitalSourcesName: name,
            capitalSourcesValue: value,
            revenueName,
            revenueValue,
            coastName,
            coastValue,
          });
        }}
        disabledName={!id}
        disabledValue={!id}
        placeholder={t('capitalSource')}
      />
    </div>
  );
};

O5ItemValuesRow.propTypes = {
  id: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  revenueName: PropTypes.string.isRequired,
  revenueValue: PropTypes.string.isRequired,
  coastName: PropTypes.string.isRequired,
  coastValue: PropTypes.string.isRequired,
  capitalSourcesName: PropTypes.string.isRequired,
  capitalSourcesValue: PropTypes.string.isRequired,
  onChangeRevenue: PropTypes.func.isRequired,
  onDeleteRevenue: PropTypes.func.isRequired,
};

export default O5ItemValuesRow;
