import React from 'react';
import PropTypes from 'prop-types';
import Photo from '../../../components/Photo/Photo';
import TitleWithTip from '../../../components/TitleWithTip/TitleWithTip';
import M6ServiceableObtainableMarket from './M6ServiceableObtainableMarket';
import M6Offering from './M6Offering';
import M6TipContent from './M6TipContent';
import styles from '../sass/M6.module.scss';

const M6Item = ({
  name,
  avatar,
  somAmount,
  somPercentage,
  somQty,
  somCurrency,
  tamQty,
  tamAmount,
  offerings,
  onChangeServiceableObtainableMarket,
  onChangeOffering,
  onChangeOfferingSOM,
  onDeleteOffering
}) => {
  return (
    <div className={styles.M6ItemContainer}>
      <TitleWithTip
        text={name}
        styleContainer={styles.title}
      >
        <M6TipContent />
      </TitleWithTip>
      <M6ServiceableObtainableMarket
        somAmount={somAmount}
        somPercentage={somPercentage}
        somQty={somQty}
        somCurrency={somCurrency}
        tamQty={tamQty}
        tamAmount={tamAmount}
        onChangeServiceableObtainableMarket={onChangeServiceableObtainableMarket}
      />
      <div className={styles.M6ItemContent}>
        <div className={styles.avatarContainer}>
          <Photo url={avatar} />
        </div>
        <M6Offering
          offerings={offerings}
          onChangeOffering={onChangeOffering}
          onDeleteOffering={onDeleteOffering}
          somQty={somQty}
          somAmount={somAmount}
          somPercentage={somPercentage}
          onChangeOfferingSOM={onChangeOfferingSOM}
          tamQty={tamQty}
          tamAmount={tamAmount}
        />
      </div>
    </div>
  );
};

M6Item.propTypes = {
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  somAmount: PropTypes.number,
  somPercentage: PropTypes.number,
  somQty: PropTypes.number,
  somCurrency: PropTypes.string,
  tamQty: PropTypes.number,
  tamAmount: PropTypes.number,
  offerings: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.number,
    projected_increase_id: PropTypes.number,
    som_currency: PropTypes.string,
    som_percentage: PropTypes.number,
    id: PropTypes.number
  })),
  onChangeServiceableObtainableMarket: PropTypes.func.isRequired,
  onChangeOffering: PropTypes.func.isRequired,
  onChangeOfferingSOM: PropTypes.func.isRequired,
  onDeleteOffering: PropTypes.func.isRequired
};

M6Item.defaultProps = {
  avatar: '',
  somAmount: 0,
  somPercentage: 0,
  somQty: 0,
  somCurrency: 'dollar',
  tamQty: 0,
  tamAmount: 0,
  offerings: []
};

export default M6Item;
