import React from 'react';
import PropTypes from 'prop-types';
import SelectInputItem from '../SelectInputItem/SelectInputItem';
import styles from './sass/SelectInputDropDownMenu.module.scss';

const SelectInputDropDownMenu = ({
  showList,
  styleDropdownContent,
  styleSelectInputItem,
  values,
  onChange,
  onCloseDropDown,
  value,
  checkId,
  dropDownRef,
  iconDropDownItem,
  onActionDropDownItem,
  actionDropDown
}) => {
  return (
    (showList && values.length) || (showList && actionDropDown) ? (
      <div ref={dropDownRef} className={`${styles.container} ${styleDropdownContent}`}>
        {values.map(({ id, name, children }) => (
          <div key={`${name}${id}`}>
            <SelectInputItem
              onChange={() => {
                onChange(checkId ? id : name);
                onCloseDropDown();
              }}
              value={name}
              active={value === name}
              styleSelectInputItem={styleSelectInputItem}
              iconDropDownItem={iconDropDownItem}
              onActionDropDownItem={() => onActionDropDownItem(id)}
              isTitle={children && !!children.length}
            />
            {children && children.map(({ id: idChild, name: nameChild, disabled }) => (
              <SelectInputItem
                key={`${nameChild}${idChild}`}
                onChange={() => {
                  onChange(checkId ? idChild : nameChild);
                  onCloseDropDown();
                }}
                value={nameChild}
                active={value === nameChild}
                styleSelectInputItem={styleSelectInputItem}
                iconDropDownItem={iconDropDownItem}
                onActionDropDownItem={() => onActionDropDownItem(idChild)}
                isChild
                disabled={disabled}
              />
            ))}
          </div>
        ))}
        {actionDropDown}
      </div>
    )
      : null
  );
};

SelectInputDropDownMenu.propTypes = {
  showList: PropTypes.bool.isRequired,
  styleDropdownContent: PropTypes.string,
  styleSelectInputItem: PropTypes.string,
  value: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.shape(
    {
      id: PropTypes.number,
      name: PropTypes.string,
      children: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        disabled: PropTypes.bool
      }))
    }
  )),
  onChange: PropTypes.func.isRequired,
  onCloseDropDown: PropTypes.func.isRequired,
  checkId: PropTypes.bool,
  iconDropDownItem: PropTypes.node,
  dropDownRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  onActionDropDownItem: PropTypes.func,
  actionDropDown: PropTypes.node,
};

SelectInputDropDownMenu.defaultProps = {
  styleDropdownContent: '',
  styleSelectInputItem: '',
  values: [],
  value: '',
  checkId: true,
  dropDownRef: null,
  iconDropDownItem: null,
  onActionDropDownItem: null,
  actionDropDown: null,
};

export default SelectInputDropDownMenu;
