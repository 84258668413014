import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import StakeholderEdinIcon from './StakeholderEdinIcon';
import styles from '../sass/Dashboard.module.scss';

import { ReactComponent as CloseIcon } from '../../../assets/icons/closeRoundIcon.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/checkIRoundcon.svg';

const SupplyChainRowItem = ({
  title,
  isEmpty,
  style,
  visible,
  disabled,
  link,
  id,
  itemId,
  onChangeVisible,
}) => {
  const navigate = useNavigate();
  const [hoverItemId, setHoverItemId] = useState(null);
  const [timeOut, setTimeOut] = useState(0);

  const handleMouseEnter = useCallback((item) => {
    setTimeOut(setTimeout(() => setHoverItemId(item), 1800));
  }, []);

  const handleMouseLeave = useCallback(() => {
    clearTimeout(timeOut);
    setHoverItemId(null);
  }, [timeOut]);

  return (
    <div
      key={title}
      className={`${styles.itemContainer} ${isEmpty && styles.empty}`}
      style={{
        backgroundColor: style?.backgroundColor,
        borderRadius: style?.borderRadius,
      }}
    >
      <div
        className={styles.wrapperButton}
        onMouseEnter={() => handleMouseEnter(itemId)}
        onMouseLeave={handleMouseLeave}
      >
        <div
          role="presentation"
          className={`${styles.item} ${!visible ? styles.visible : ''}`}
          style={{
            backgroundColor: style?.backgroundColorItem,
            letterSpacing: style?.letterSpacing,
          }}
          onClick={() => !disabled && navigate(`/${id}${link}`)}
        >
          <span>{title}</span>
        </div>
        {hoverItemId === itemId && (
          <StakeholderEdinIcon
            Icon={visible ? CloseIcon : CheckIcon}
            onChange={() => onChangeVisible({ idStakeholder: itemId, visible: !visible })}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};

SupplyChainRowItem.propTypes = {
  title: PropTypes.string.isRequired,
  isEmpty: PropTypes.bool,
  style: PropTypes.shape({
    backgroundColor: PropTypes.string,
    borderRadius: PropTypes.string,
    letterSpacing: PropTypes.string,
    backgroundColorItem: PropTypes.string,
  }),
  visible: PropTypes.bool,
  disabled: PropTypes.bool,
  link: PropTypes.string,
  id: PropTypes.string.isRequired,
  itemId: PropTypes.number,
  onChangeVisible: PropTypes.func.isRequired,
};

SupplyChainRowItem.defaultProps = {
  isEmpty: false,
  visible: true,
  disabled: false,
  itemId: undefined,
  style: undefined,
  link: '',
};

export default SupplyChainRowItem;
