import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import instance from '../services/axios';
import { UPLOAD_FILES_URL } from '../constants/api';
import notify from '../utils/notify';

const useUpload = (file, callback) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [loadingFile, setLoadingFile] = useState(false);

  useEffect(() => {
    if (file) {
      setLoadingFile(true);
      const formData = new FormData();
      formData.append('file', file);
      instance.post(UPLOAD_FILES_URL, formData)
        .then(({ data: { data } }) => {
          setResponse(data);
          if (callback) {
            callback(data);
          }
          setLoadingFile(false);
        })
        .catch(({ response: { data } }) => {
          setError(data);
          setLoadingFile(false);
          notify('error', data?.message || t('somethingWentWrong'));
        });
    }
  }, [file]);

  return { loadingFile, response, error };
};

export default useUpload;
