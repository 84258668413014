import instance from '../../services/axios';
import {
  SET_GENERAL_REQUEST,
  SET_GENERAL_INDUSTRIES_LIST_SUCCESS,
  SET_GENERAL_FAILED,
  SET_GENERAL_MEANING_LIST_SUCCESS,
  SET_GENERAL_CATEGORY_LIST_SUCCESS,
  SET_USER_ID_FOW_SWITCH_SUCCESS,
  CLEAN_GENERAL,
  SET_EXPORT_DATA_SUCCESS,
  SET_CURRENCY_LIST_SUCCESS,
  SET_FROM_PATH
} from './types';
import {
  COMPETITOR_DRIVERS_URL,
  CURRENCY_LIST_URL,
  EXPORT_URL,
  INDUSTRIES_LIST,
  MEANING_URL, POST, PUT,
  SEGMENT_CUSTOMER_LISTS_URL,
  TRENDS_CATEGORY_URL,
  UPLOAD_FILES_URL
} from '../../constants/api';
import notify from '../../utils/notify';

export const setGeneralRequest = () => ({ type: SET_GENERAL_REQUEST });
export const setIndustriesListSuccess = (data) => ({ type: SET_GENERAL_INDUSTRIES_LIST_SUCCESS, data });
export const setGeneralFailed = (error) => ({ type: SET_GENERAL_FAILED, error });
export const setMeaningListSuccess = (data) => ({ type: SET_GENERAL_MEANING_LIST_SUCCESS, data });
export const setCategoryListSuccess = (data) => ({ type: SET_GENERAL_CATEGORY_LIST_SUCCESS, data });
export const setUserIdForSwitchSuccess = (data) => ({ type: SET_USER_ID_FOW_SWITCH_SUCCESS, data });
export const setExportDataSuccess = () => ({ type: SET_EXPORT_DATA_SUCCESS });
export const setCurrencyListSuccess = (data) => ({ type: SET_CURRENCY_LIST_SUCCESS, data });
export const setFromPath = (data) => ({ type: SET_FROM_PATH, data });
export const cleanGeneral = () => ({ type: CLEAN_GENERAL });

export const getIndustriesListAction = () => {
  return (dispatch) => {
    dispatch(setGeneralRequest());
    instance.get(INDUSTRIES_LIST)
      .then(({ data: { data } }) => dispatch(setIndustriesListSuccess(data)))
      .catch(({ response: { data: errorData } }) => dispatch(setGeneralFailed(errorData)));
  };
};

export const assignFile = (fileId, model, modelId, type) => {
  return instance.post(`${UPLOAD_FILES_URL}/${fileId}/assign`, {
    model, model_id: modelId, type
  });
};

export const getMeaningListAction = () => {
  return (dispatch) => {
    dispatch(setGeneralRequest());
    instance.get(MEANING_URL)
      .then(({ data: { data } }) => dispatch(setMeaningListSuccess(data)))
      .catch(({ response: { data: errorData } }) => dispatch(setGeneralFailed(errorData)));
  };
};

export const getTrendCategoryAction = () => {
  return (dispatch) => {
    dispatch(setGeneralRequest());
    instance.get(TRENDS_CATEGORY_URL)
      .then(({ data: { data } }) => dispatch(setCategoryListSuccess(data)))
      .catch(({ response: { data } }) => {
        dispatch(setGeneralFailed(data));
        notify('error', data.message);
      });
  };
};

export const getSegmentsAction = (id) => {
  return instance.get(`${SEGMENT_CUSTOMER_LISTS_URL}/${id}`);
};

export const setUserIdForSwitchAction = (id, navigate) => {
  return (dispatch) => {
    dispatch(setUserIdForSwitchSuccess(id));
    navigate('/');
  };
};

export const getDriversAndOrganization = (url, id, segment) => {
  return instance.get(`${url}/${id}/${segment || ''}`)
    .then(({ data: { data } }) => {
      const topMarketDrivers = [];
      const organizationDrivers = [];
      const ownOrganizationName = data[0]?.organization_name || '';
      const ownOrganizationOffering = data[0]?.organization_offering || '';
      data.forEach(({
        driver_value: driverValue,
        id: idDriver,
        name,
        organization_id: organizationId,
        organization_value: organizationValue,
        style,
        meaning_id: meaningId
      }) => {
        const dataTopMarket = {
          id: idDriver,
          name,
          value: driverValue,
          style,
          meaningId
        };
        const dataOrganization = {
          id: organizationId,
          value: organizationValue,
          driver_id: idDriver,
          meaningId
        };
        topMarketDrivers.push(dataTopMarket);
        organizationDrivers.push(dataOrganization);
      });
      return {
        topMarketDrivers, organizationDrivers, ownOrganizationName, ownOrganizationOffering
      };
    });
};

export const updateCompetitorsDriver = (driver, competitorId) => {
  return instance[driver.id ? PUT : POST](!driver.id
    ? COMPETITOR_DRIVERS_URL
    : `${COMPETITOR_DRIVERS_URL}/${driver.id}`, {
    driver_id: !driver.id ? driver.driverId : undefined,
    value: +driver.rate,
    competitor_id: !driver.id ? competitorId : undefined
  }).then(({ data: { data } }) => data);
};

export const exportFileAction = (id, type, name) => {
  return (dispatch) => {
    dispatch(setGeneralRequest());
    instance.post(
      EXPORT_URL,
      { project_id: id, type },
      { responseType: 'blob' }
    )
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `${name}.${type}`;
        link.click();
        link.remove();
        dispatch(setExportDataSuccess());
      })
      .catch(({ response: { data } }) => {
        dispatch(setGeneralFailed(data));
        notify('error', data.message);
      });
  };
};

export const getCurrencyListAction = () => {
  return (dispatch) => {
    dispatch(setGeneralRequest());
    instance.get(CURRENCY_LIST_URL)
      .then(({ data: { data } }) => dispatch(setCurrencyListSuccess(data)))
      .catch(({ response: { data } }) => {
        dispatch(setGeneralFailed(data));
        notify('error', data.message);
      });
  };
};
