import React, { useMemo, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  changeOfferingAction,
  changeServiceableSOMAction,
  cleanM6,
  createOfferingSOMAction,
  deleteOfferingAction,
  getSegmentsAction,
  updateOfferingSOMAction
} from '../../store/m6/actions';
import { getBreadcrumbs, getSwitchPages } from '../../utils/helpers';
import Page from '../../layout/Page/Page';
import NoData from '../../components/NoData/NoData';
import M6Item from './components/M6Item';
import styles from './sass/M6.module.scss';

const M6 = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    m6: { loading, segments },
    project: { name: projectName }
  } = useSelector(({ m6, project }) => ({ m6, project }), shallowEqual);

  const breadcrumbs = useMemo(
    () => getBreadcrumbs(projectName, `/${id}/dashboard`, 'Offerings'),
    [projectName, id]
  );

  const switchPages = useMemo(
    () => getSwitchPages(`/${id}/m5`, `/${id}/m7`),
    [id]
  );

  useEffect(() => {
    dispatch(getSegmentsAction(id));
    return () => {
      dispatch(cleanM6());
    };
  }, []);

  const handleChangeServiceableObtainableMarket = useCallback((values, idSegment) => {
    dispatch(changeServiceableSOMAction(values, id, idSegment));
  }, []);
  
  const handleChangeOffering = useCallback((idSegment, idOffer, values) => {
    dispatch(changeOfferingAction(id, idSegment, idOffer, values));
  }, []);

  const handleDeleteOffering = useCallback((idSegment, idOffer) => {
    dispatch(deleteOfferingAction(id, idSegment, idOffer));
  }, []);

  const handleChangeOfferingSOM = useCallback((idSegment, idOffer, values) => {
    if (!values.projectedIncreaseId) {
      dispatch(createOfferingSOMAction(idSegment, idOffer, values));
    } else dispatch(updateOfferingSOMAction(idSegment, idOffer, values));
  }, []);

  return (
    <Page
      pageContainer={styles.container}
      breadcrumbs={breadcrumbs}
      switchPages={switchPages}
      loader={loading}
    >
      <div className={styles.contentContainer}>
        {segments.length ? segments.map(({
          id: idSegment,
          name,
          avatar,
          som_percentage: somPercentage,
          som_amount: somAmount,
          som_currency: somCurrency,
          som_qty: somQty,
          tam_qty: tamQty,
          tam_amount: tamAmount,
          offerings
        }) => (offerings.length && (
        <M6Item
          key={idSegment}
          name={name}
          avatar={avatar?.url}
          somPercentage={somPercentage}
          somAmount={somAmount}
          somCurrency={somCurrency}
          somQty={somQty}
          tamQty={tamQty}
          tamAmount={tamAmount}
          offerings={offerings}
          onChangeServiceableObtainableMarket={(values) => handleChangeServiceableObtainableMarket(values, idSegment)}
          onChangeOffering={(idOffer, values) => handleChangeOffering(idSegment, idOffer, values)}
          onChangeOfferingSOM={(idOffer, values) => handleChangeOfferingSOM(idSegment, idOffer, values)}
          onDeleteOffering={(idOffer) => handleDeleteOffering(idSegment, idOffer)}
        />
        ))) : (
          <NoData
            id={id}
            projectName={projectName}
            page="m5"
            textButton={t('youNeedToAddOffering')}
          />
        )}
      </div>
    </Page>
  );
};

export default M6;
