import { batch } from 'react-redux';
import instance from '../../services/axios';
import {
  SET_STAKEHOLDERS_AVATAR_SUCCESS,
  SET_STAKEHOLDERS_WORK_SHEET_NAME_SUCCESS,
  SET_STAKEHOLDERS_WORK_SHEET_MEANING_SUCCESS,
  SET_STAKEHOLDERS_WORK_SHEET_LINKS_SUCCESS,
  SET_STAKEHOLDERS_WORK_SHEET_DRIVERS_SUCCESS,
  SET_STAKEHOLDERS_WORK_SHEET_TRIGGERS_SUCCESS,
  SET_STAKEHOLDERS_WORK_SHEET_SUCCESS,
  SET_STAKEHOLDERS_REQUEST,
  SET_STAKEHOLDERS_FAILED,
  CLEAN_STAKEHOLDERS,
  SET_STAKEHOLDERS_LIST_SUCCESS,
  SET_STAKEHOLDERS_PROFILES_SUCCESS,
  SET_STAKEHOLDERS_RANK_SUCCESS
} from './types';
import {
  DRIVERS_URL,
  LAST_WORK_SHEET_URL,
  MEANING_URL,
  POST,
  PUT, STAKEHOLDER_TYPES_URL,
  TRIGGERS_URL,
  WORK_SHEET_LINK_URL,
  WORK_SHEET_URL, WORKSHEET_LINKED_LIST_URL,
} from '../../constants/api';
import notify from '../../utils/notify';
import { assignFile } from '../general/actions';

export const setStakeholdersRequest = () => ({ type: SET_STAKEHOLDERS_REQUEST });
export const setStakeholdersFailed = (error) => ({ type: SET_STAKEHOLDERS_FAILED, error });
export const cleanStakeholders = () => ({ type: CLEAN_STAKEHOLDERS });

export const setStakeholdersWorkSheetNameSuccess = (data) => ({ type: SET_STAKEHOLDERS_WORK_SHEET_NAME_SUCCESS, data });

export const setStakeholdersWorkSheetRankSuccess = (data) => ({ type: SET_STAKEHOLDERS_RANK_SUCCESS, data });

export const setStakeholdersWorkSheetSuccess = (data) => ({ type: SET_STAKEHOLDERS_WORK_SHEET_SUCCESS, data });

export const setStakeholdersWorkSheetMeaningSuccess = (data) => ({
  type: SET_STAKEHOLDERS_WORK_SHEET_MEANING_SUCCESS, data
});

export const setStakeholdersAvatarSuccess = (data) => ({ type: SET_STAKEHOLDERS_AVATAR_SUCCESS, data });

export const setStakeholdersWorkSheetLinksSuccess = (data) => ({
  type: SET_STAKEHOLDERS_WORK_SHEET_LINKS_SUCCESS, data
});

export const setStakeholdersWorkSheetDriversSuccess = (data) => ({
  type: SET_STAKEHOLDERS_WORK_SHEET_DRIVERS_SUCCESS, data
});

export const setStakeholdersWorkSheetTriggersSuccess = (data) => ({
  type: SET_STAKEHOLDERS_WORK_SHEET_TRIGGERS_SUCCESS, data
});

export const setStakeholdersList = (data) => ({ type: SET_STAKEHOLDERS_LIST_SUCCESS, data });

export const setStakeholderProfilesAction = (data, nextLink, totalCount) => ({
  type: SET_STAKEHOLDERS_PROFILES_SUCCESS, data, nextLink, totalCount
});

export const getStakeholdersList = (id) => {
  return (dispatch) => {
    dispatch(setStakeholdersRequest());
    instance.get(`${STAKEHOLDER_TYPES_URL}/${id}`)
      .then(({ data: { data } }) => {
        const stakeholdersList = data.map((stakeholder) => (
          { ...stakeholder, children: stakeholder.children.map((child) => ({ ...child, name: child.full_name })) }));
        dispatch(setStakeholdersList(stakeholdersList));
      })
      .catch(({ response: { data } }) => {
        dispatch(setStakeholdersFailed(data));
        notify('error', data.message);
      });
  };
};

export const updateStakeholdersWorkSheetNameAction = (value) => {
  return (dispatch, getState) => {
    const {
      stakeholders: {
        id, profiles, nextLinkProfiles, totalCountProfiles
      }
    } = getState();
    dispatch(setStakeholdersRequest());
    instance.put(`${WORK_SHEET_URL}/${id}`, { full_name: value })
      .then(() => {
        const newProfiles = profiles.map((profile) => (id === profile.id ? { ...profile, full_name: value } : profile));
        batch(() => {
          dispatch(setStakeholdersWorkSheetNameSuccess(value));
          dispatch(setStakeholderProfilesAction(newProfiles, nextLinkProfiles, totalCountProfiles));
        });
      })
      .catch(({ response: { data } }) => {
        dispatch(setStakeholdersFailed(data));
        notify('error', data.message);
      });
  };
};

export const setStakeholdersRankAction = (value) => {
  return (dispatch, getState) => {
    const { stakeholders: { id } } = getState();
    dispatch(setStakeholdersRequest());
    instance.put(`${WORK_SHEET_URL}/${id}/`, { value })
      .then(({ data: { data } }) => {
        dispatch(setStakeholdersWorkSheetRankSuccess(data.value));
      })
      .catch(({ response: { data: error } }) => {
        dispatch(setStakeholdersFailed(error));
        notify('error', error.message);
      });
  };
};

export const getStakeholderProfilesAction = (projectId, stakeholder) => {
  return (dispatch) => {
    instance.get(`${WORKSHEET_LINKED_LIST_URL}/${projectId}/stakeholder/${stakeholder.stakeholder_id}`)
      .then(({ data: { data, links, meta } }) => {
        batch(() => {
          dispatch(setStakeholderProfilesAction(data, links.next, meta.total));
          dispatch(setStakeholdersWorkSheetSuccess(stakeholder));
        });
      })
      .catch(({ response: { data: error } }) => {
        dispatch(setStakeholdersFailed(error));
        notify('error', error.message);
      });
  };
};

export const createStakeholderAction = (id, idStakeholder) => {
  return (dispatch) => {
    dispatch(setStakeholdersRequest());
    instance.post(`${WORK_SHEET_URL}/stakeholder`, {
      stakeholder_id: idStakeholder || undefined,
      project_id: +id,
      full_name: 'Default name'
    })
      .then(({ data: { data } }) => {
        dispatch(getStakeholderProfilesAction(id, data));
      })
      .catch(({ response: { data: error } }) => {
        dispatch(setStakeholdersFailed(error));
        notify('error', error.message);
      });
  };
};

export const getLastStakeholdersWorkSheetAction = (idProject, stakeholderId = '') => {
  return (dispatch) => {
    dispatch(setStakeholdersRequest());
    instance.get(`${LAST_WORK_SHEET_URL}/${idProject}/stakeholder/${stakeholderId}`)
      .then(({ data: { data } }) => {
        if (data?.id) {
          batch(() => {
            dispatch(getStakeholderProfilesAction(idProject, data));
          });
        } else dispatch(createStakeholderAction(idProject, stakeholderId));
      })
      .catch(({ response: { data } }) => {
        dispatch(setStakeholdersFailed(data));
        notify('error', data.message);
      });
  };
};

export const updateStakeholdersMeaningAction = (values) => {
  return (dispatch, getState) => {
    const {
      id, name, value, description
    } = values;
    const { stakeholders: { meanings, id: idWorkSheep } } = getState();
    dispatch(setStakeholdersRequest());
    instance[id ? PUT : POST](id ? `${MEANING_URL}/${id}` : MEANING_URL, {
      meaning_id: Number.isNaN(+name) ? undefined : +name,
      worksheet_id: +idWorkSheep,
      value: +value,
      description
    })
      .then(({ data: { data } }) => {
        const newMeaning = {
          id: data.id,
          description: data.description,
          name: data.meaning.name,
          value: data.value,
          worksheet_id: data.worksheet_id
        };
        if (id) {
          const newMeanings = meanings.map((item) => (item.id === id ? newMeaning : item));
          dispatch(setStakeholdersWorkSheetMeaningSuccess(newMeanings));
        } else dispatch(setStakeholdersWorkSheetMeaningSuccess([...meanings, newMeaning]));
      })
      .catch(({ response: { data } }) => {
        dispatch(setStakeholdersFailed(data));
        notify('error', data.message);
      });
  };
};

export const uploadStakeholdersPhotoAction = (data) => {
  return (dispatch, getState) => {
    const { stakeholders: { id } } = getState();
    dispatch(setStakeholdersRequest());
    assignFile(data.id, 'Worksheet', id, 'avatar')
      .then(() => {
        dispatch(setStakeholdersAvatarSuccess(data));
      })
      .catch(({ response: { data: error } }) => {
        dispatch(setStakeholdersFailed(error));
        notify('error', error.message);
      });
  };
};

export const updateStakeholdersLinkAction = (values) => {
  return (dispatch, getState) => {
    const { id, url } = values;
    const { stakeholders: { id: idWorkSheet } } = getState();
    dispatch(setStakeholdersRequest());
    if (id) {
      instance.delete(`${WORK_SHEET_LINK_URL}/${id}`)
        .then(() => {
          instance.post(WORK_SHEET_LINK_URL, { url, worksheet_id: idWorkSheet })
            .then(({ data: { data } }) => dispatch(setStakeholdersWorkSheetLinksSuccess([data])))
            .catch(({ response: { data } }) => {
              dispatch(setStakeholdersFailed(data));
              notify('error', data.message);
            });
        })
        .catch(({ response: { data } }) => {
          dispatch(setStakeholdersFailed(data));
          notify('error', data.message);
        });
    } else {
      instance.post(WORK_SHEET_LINK_URL, { url, worksheet_id: idWorkSheet })
        .then(({ data: { data } }) => dispatch(setStakeholdersWorkSheetLinksSuccess([data])))
        .catch(({ response: { data } }) => {
          dispatch(setStakeholdersFailed(data));
          notify('error', data.message);
        });
    }
  };
};

export const deleteStakeholdersLinkAction = (values) => {
  return (dispatch) => {
    const { id } = values;
    dispatch(setStakeholdersRequest());
    instance.delete(`${WORK_SHEET_LINK_URL}/${id}`)
      .then(() => dispatch(setStakeholdersWorkSheetLinksSuccess([])))
      .catch(({ response: { data } }) => {
        dispatch(setStakeholdersFailed(data));
        notify('error', data.message);
      });
  };
};

const deleteStakeholdersDriverAction = (idDriver) => {
  return (dispatch, getState) => {
    const { stakeholders: { drivers } } = getState();
    dispatch(setStakeholdersRequest());
    instance.delete(`${DRIVERS_URL}/${idDriver}`)
      .then(() => {
        const newDrivers = drivers.filter((item) => (item.id !== idDriver));
        dispatch(setStakeholdersWorkSheetDriversSuccess(newDrivers));
      })
      .catch(({ response: { data: error } }) => {
        dispatch(setStakeholdersFailed(error));
        notify('error', error.message);
      });
  };
};

export const updateStakeholdersDriverAction = (values, type) => {
  const { name, value, id } = values;
  return (dispatch, getState) => {
    const { stakeholders: { drivers, id: idWorkSheet } } = getState();
    if (id && !name) {
      dispatch(deleteStakeholdersDriverAction(id));
    } else {
      dispatch(setStakeholdersRequest());
      instance[id ? PUT : POST](id ? `${DRIVERS_URL}/${id}` : DRIVERS_URL, {
        name,
        value: +value,
        type,
        worksheet_id: idWorkSheet
      })
        .then(({ data: { data } }) => {
          if (id) {
            const newDrivers = drivers.map((item) => (item.id === id ? data : item));
            dispatch(setStakeholdersWorkSheetDriversSuccess(newDrivers));
          } else {
            dispatch(setStakeholdersWorkSheetDriversSuccess([...drivers, data]));
          }
        })
        .catch(({ response: { data: error } }) => {
          dispatch(setStakeholdersFailed(error));
          notify('error', error.message);
        });
    }
  };
};

export const deleteStakeholdersTriggerAction = (idTrigger) => {
  return (dispatch, getState) => {
    const { stakeholders: { triggers } } = getState();
    dispatch(setStakeholdersRequest());
    instance.delete(`${TRIGGERS_URL}/${idTrigger}`)
      .then(() => {
        const newTriggers = triggers.filter((item) => (item.id !== idTrigger));
        dispatch(setStakeholdersWorkSheetTriggersSuccess(newTriggers));
      })
      .catch(({ response: { data: error } }) => {
        dispatch(setStakeholdersFailed(error));
        notify('error', error.message);
      });
  };
};

export const updateStakeholdersTriggerAction = (values, type) => {
  const { id, description } = values;
  return (dispatch, getState) => {
    const { stakeholders: { id: idWorkSheet, triggers } } = getState();
    if (id && !description) {
      dispatch(deleteStakeholdersTriggerAction(id));
    } else {
      dispatch(setStakeholdersRequest());
      instance[id ? PUT : POST](id ? `${TRIGGERS_URL}/${id}` : TRIGGERS_URL, {
        type, description, worksheet_id: idWorkSheet
      })
        .then(({ data: { data } }) => {
          if (id) {
            const newTriggers = triggers.map((item) => (item.id === id ? data : item));
            dispatch(setStakeholdersWorkSheetTriggersSuccess(newTriggers));
          } else {
            dispatch(setStakeholdersWorkSheetTriggersSuccess([...triggers, data]));
          }
        })
        .catch(({ response: { data: error } }) => {
          dispatch(setStakeholdersFailed(error));
          notify('error', error.message);
        });
    }
  };
};

export const changeStakeholderAction = (idStakeholder, setSearchParams) => {
  return (dispatch, getState) => {
    const { stakeholders: { id } } = getState();
    dispatch(setStakeholdersRequest());
    instance.put(`${WORK_SHEET_URL}/${id}`, { stakeholder_id: idStakeholder })
      .then(() => setSearchParams({ type: idStakeholder }))
      .catch(({ response: { data: error } }) => {
        dispatch(setStakeholdersFailed(error));
        notify('error', error.message);
      });
  };
};

export const deleteStakeholderAction = (idProject) => {
  return (dispatch, getState) => {
    const { stakeholders: { id, stakeholderId } } = getState();
    dispatch(setStakeholdersRequest());
    instance.delete(`${WORK_SHEET_URL}/${id}`)
      .then(() => dispatch(getLastStakeholdersWorkSheetAction(idProject, stakeholderId)))
      .catch(({ response: { data: error } }) => {
        dispatch(setStakeholdersFailed(error));
        notify('error', error.message);
      });
  };
};

export const getStakeholdersWorkSheetAction = (id) => {
  return (dispatch) => {
    dispatch(setStakeholdersRequest());
    instance.get(`${WORK_SHEET_URL}/${id}`)
      .then(({ data: { data } }) => dispatch(setStakeholdersWorkSheetSuccess(data)))
      .catch(({ response: { data: error } }) => {
        dispatch(setStakeholdersFailed(error));
        notify('error', error.message);
      });
  };
};

export const loadMoreStakeholdersProfilesAction = () => {
  return (dispatch, getState) => {
    const { stakeholders: { profiles, nextLinkProfiles } } = getState();
    dispatch(setStakeholdersRequest());
    instance.get(nextLinkProfiles)
      .then(({ data: { data, links, meta } }) => {
        dispatch(setStakeholderProfilesAction([...profiles, ...data], links.next, meta.total));
      })
      .catch(({ response: { data: error } }) => {
        dispatch(setStakeholdersFailed(error));
        notify('error', error.message);
      });
  };
};
