import {
  CLEAN_ADMIN_TRENDS,
  SET_ADMIN_TRENDS_DELETE_FAILED,
  SET_ADMIN_TRENDS_DELETE_REQUEST,
  SET_ADMIN_TRENDS_FAILED,
  SET_ADMIN_TRENDS_REQUEST,
  SET_ADMIN_TRENDS_SCENARIO_SUCCESS,
  SET_ADMIN_TRENDS_SUCCESS
} from './types';

const initialState = {
  trends: [],
  scenario: null,
  scenariosPath: null,
  totalCount: 0,
  loading: false,
  error: null,
};

export default function adminTrends(state = initialState, action) {
  switch (action.type) {
    case SET_ADMIN_TRENDS_REQUEST:
    case SET_ADMIN_TRENDS_DELETE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_ADMIN_TRENDS_SUCCESS:
      return {
        ...state,
        trends: action.data,
        totalCount: action.total,
        loading: false,
        error: null,
      };
    case SET_ADMIN_TRENDS_FAILED:
    case SET_ADMIN_TRENDS_DELETE_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case SET_ADMIN_TRENDS_SCENARIO_SUCCESS:
      return {
        ...state,
        scenario: action.data,
        loading: false,
        error: null,
      };
    case CLEAN_ADMIN_TRENDS:
      return initialState;
    default:
      return state;
  }
}
