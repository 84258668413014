import React from 'react';
import PropTypes from 'prop-types';
import { convertFromIdToName } from '../../../utils/helpers';
import TitleWithTip from '../../../components/TitleWithTip/TitleWithTip';
import Reference from '../../../components/Reference/Reference';
import SelectInput from '../../../components/SelectInput/SelectInput';
import TrendsHeaderTipContent from './TrendsHeaderTipContent';
import Title from '../../../components/Title/Title';
import styles from '../sass/Trends.module.scss';

const TrendsHeader = ({
  trendsList,
  id,
  onChangeTrendType,
  info,
  onOpenModal,
  link,
  onOpen,
  onDelete
}) => {
  return (
    <div className={styles.headerContainer}>
      <div className={styles.titleContainer}>
        <div className={styles.titleWrapper}>
          <TitleWithTip
            text={info.title}
            styleContainer={styles.title}
            styleTitle={styles.titleText}
            styleTipTextContainer={styles.tipTextContainer}
            styleTriangle={styles.triangle}
          >
            <TrendsHeaderTipContent />
          </TitleWithTip>
          {!!info.subtitle && <Title text={info.subtitle} style={styles.subtitle} Type="h4" />}
        </div>
        <Reference
          onOpenModal={onOpenModal}
          onDelete={onDelete}
          onOpen={onOpen}
          styleContainer={styles.referenceContainer}
          styleWrapper={styles.referenceWrapper}
          link={link}
        />
      </div>
      <SelectInput
        onChange={(trendTypeId) => onChangeTrendType(trendTypeId)}
        title=""
        styleContainer={styles.selectContainer}
        values={trendsList}
        value={convertFromIdToName(trendsList, id)}
        styleSelectInputItem={styles.selectInputItem}
      />
    </div>
  );
};

TrendsHeader.propTypes = {
  trendsList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  id: PropTypes.number,
  onChangeTrendType: PropTypes.func.isRequired,
  onOpenModal: PropTypes.func.isRequired,
  info: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string
  }).isRequired,
  link: PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string
  }),
  onOpen: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

TrendsHeader.defaultProps = {
  trendsList: [],
  id: 0,
  link: {
    id: null,
    url: ''
  }
};

export default TrendsHeader;
