import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from './components/BreadCrumbs';
import SwitchPages from './components/SwitchPages';
import Button from '../../components/Button/Button';
import Menu from './components/Menu';
import styles from './sass/Header.module.scss';

import Logo from '../../assets/icons/logo.svg';
import LogoWhite from '../../assets/icons/logo2.svg';

function Header({
  whiteTheme,
  breadcrumbs,
  switchPages,
  newAction,
  onLogout,
  menuButtons
}) {
  const { t } = useTranslation();
  return (
    <header className={`${styles.header} ${whiteTheme && styles.whiteHeader}`}>
      <div className={`container ${styles.container}`}>
        <div className={styles.logoContainer}>
          <img src={whiteTheme ? Logo : LogoWhite} alt="brandLogo" className={styles.logo} />
          <Button text={t('logout')} style={styles.buttonContainer} styleButton={styles.button} onClick={onLogout} />
        </div>
        <div className={styles.actions}>
          <div className={styles.navigationContainer}>
            <BreadCrumbs breadcrumbs={breadcrumbs} />
            {newAction}
            <SwitchPages switchPages={switchPages} />
          </div>
          <Menu menuButtons={menuButtons} />
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  whiteTheme: PropTypes.bool,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string
  })),
  switchPages: PropTypes.shape({
    prevLink: PropTypes.string,
    nextLink: PropTypes.string
  }),
  newAction: PropTypes.node,
  onLogout: PropTypes.func.isRequired,
  menuButtons: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
    icon: PropTypes.string,
    iconHover: PropTypes.string
  })).isRequired
};

Header.defaultProps = {
  whiteTheme: false,
  breadcrumbs: [],
  switchPages: null,
  newAction: null
};

export default Header;
