import React, { useState, useCallback, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useOnBlur from '../../../hooks/useOnBlur';
import { deleteSpacesInString, isNumberValueValid } from '../../../utils/helpers';
import Input from '../../../components/Input/Input';
import styles from '../sass/M1.module.scss';

const M1CustomerProfile = ({
  customerAge,
  customerOccupation,
  customerLocation,
  customerBiography,
  onChange
}) => {
  const { t } = useTranslation();

  const [age, setAge] = useState('');
  const [occupation, setOccupation] = useState('');
  const [location, setLocation] = useState('');
  const [biography, setBiography] = useState('');

  useLayoutEffect(() => {
    setAge(customerAge);
    setOccupation(customerOccupation);
    setLocation(customerLocation);
    setBiography(customerBiography);
  }, [customerAge, customerOccupation, customerLocation, customerBiography]);

  const handleChangeAge = useCallback((e) => {
    const text = deleteSpacesInString(e.target.value);
    if (isNumberValueValid(true, text)) {
      setAge(text);
    }
  }, []);

  const handleChangeOccupation = useCallback((e) => {
    const text = deleteSpacesInString(e.target.value);
    setOccupation(text);
  }, []);

  const handleChangeLocation = useCallback((e) => {
    const text = deleteSpacesInString(e.target.value);
    setLocation(text);
  }, []);

  const handleChangeBiography = useCallback((e) => {
    const text = deleteSpacesInString(e.target.value);
    setBiography(text);
  }, []);

  const handleBlurAge = useOnBlur(
    customerAge,
    age,
    () => onChange('age', +age),
  );

  const handleBlurOccupation = useOnBlur(
    customerOccupation,
    occupation,
    () => onChange('occupation', occupation),
  );

  const handleBlurLocation = useOnBlur(
    customerLocation,
    location,
    () => onChange('location', location),
  );

  const handleBlurBiography = useOnBlur(
    customerBiography,
    biography,
    () => onChange('biography', biography),
  );

  return (
    <div className={styles.customerProfileContainer}>
      <Input
        title={t('agePlaceholder')}
        label={t('age')}
        value={age}
        style={styles.input}
        styleLabel={styles.label}
        onChange={handleChangeAge}
        onBlur={handleBlurAge}
      />
      <Input
        title={t('occupationPlaceholder')}
        label={t('occupation')}
        value={occupation}
        style={styles.input}
        styleLabel={styles.label}
        onChange={handleChangeOccupation}
        onBlur={handleBlurOccupation}
      />
      <Input
        title={t('locationPlaceholder')}
        label={t('location')}
        value={location}
        style={styles.input}
        styleLabel={styles.label}
        onChange={handleChangeLocation}
        onBlur={handleBlurLocation}
      />
      <span className={styles.biographyLabel}>{t('biography')}</span>
      <textarea
        placeholder={t('biographyPlaceholder')}
        value={biography}
        onChange={handleChangeBiography}
        onBlur={handleBlurBiography}
      />
    </div>
  );
};

M1CustomerProfile.propTypes = {
  customerAge: PropTypes.string.isRequired,
  customerOccupation: PropTypes.string.isRequired,
  customerLocation: PropTypes.string.isRequired,
  customerBiography: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default M1CustomerProfile;
