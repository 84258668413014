import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import useOnBlur from '../../../hooks/useOnBlur';
import { deleteSpacesInString, isNumberValueValid } from '../../../utils/helpers';
import Input from '../../../components/Input/Input';
import styles from '../sass/O5.module.scss';

const O5ItemValuesRowItem = ({
  currency,
  value,
  name,
  onChange,
  onDelete,
  disabledName,
  disabledValue,
  placeholder,
}) => {
  const [revenueValue, setRevenueValue] = useState(value);
  const [revenueName, setRevenueName] = useState(name);

  const handleChangeRevenueValue = useCallback((e) => {
    const text = deleteSpacesInString(e.target.value);
    if (isNumberValueValid(false, text)) {
      setRevenueValue(text);
    }
  }, []);

  const handleChangeRevenueName = useCallback((e) => {
    const text = deleteSpacesInString(e.target.value);
    setRevenueName(text);
  }, []);

  const handleBlurName = useOnBlur(
    name,
    revenueName,
    () => onChange(revenueName, revenueValue),
    () => onDelete(revenueName, revenueValue)
  );

  const handleBlurValue = useOnBlur(
    value,
    +revenueValue ? revenueValue.toString() : '',
    () => onChange(revenueName, revenueValue),
    () => onChange(revenueName, revenueValue)
  );

  return (
    <div className={styles.rowItemContainer}>
      <Input
        onChange={handleChangeRevenueName}
        onBlur={handleBlurName}
        style={styles.inputContainer}
        value={revenueName}
        disabled={disabledName}
        title={placeholder}
      />
      <Input
        onChange={handleChangeRevenueValue}
        onBlur={handleBlurValue}
        style={`${styles.inputContainer} ${styles.currency}`}
        value={revenueValue}
        disabled={disabledValue}
      />
      <span>{currency}</span>
    </div>
  );
};

O5ItemValuesRowItem.propTypes = {
  currency: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabledName: PropTypes.bool.isRequired,
  disabledValue: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default O5ItemValuesRowItem;
