import {
  CLEAN_DASHBOARD,
  SET_DASHBOARD_FAILED,
  SET_DASHBOARD_REQUEST,
  SET_DASHBOARD_SUCCESS
} from './types';
import instance from '../../services/axios';
import { DASHBOARD_COLOR_SCHEME_URL, VISIBLE_STAKEHOLDER_URL } from '../../constants/api';
import notify from '../../utils/notify';

export const setDashboardRequest = () => ({ type: SET_DASHBOARD_REQUEST });
export const setDashboardSuccess = (data) => ({ type: SET_DASHBOARD_SUCCESS, data });
export const setDashboardFailed = (error) => ({ type: SET_DASHBOARD_FAILED, error });
export const cleanDashboard = () => ({ type: CLEAN_DASHBOARD });

export const getDashboardAction = (id) => {
  return (dispatch) => {
    dispatch(setDashboardRequest());
    instance.get(`${DASHBOARD_COLOR_SCHEME_URL}/${id}`)
      .then(({ data: { data } }) => {
        dispatch(setDashboardSuccess(data));
      })
      .catch(({ response: { data } }) => {
        dispatch(setDashboardFailed(data));
        notify('error', data.message);
      });
  };
};

export const updateStakeholderAction = (values) => {
  const {
    idProject, idStakeholder, visible
  } = values;
  return (dispatch) => {
    dispatch(setDashboardRequest());
    instance.put(VISIBLE_STAKEHOLDER_URL, {
      project_id: +idProject,
      stakeholder_id: idStakeholder,
      visible
    })
      .then(() => {
        dispatch(getDashboardAction(idProject));
      })
      .catch(({ response: { data } }) => {
        dispatch(setDashboardFailed(data));
        notify('error', data.message);
      });
  };
};
