import React, { useCallback, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { deleteSpacesInString } from '../../../utils/helpers';
import useOnBlur from '../../../hooks/useOnBlur';
import rateOptions from '../../../constants/rateOptions';
import Input from '../../Input/Input';
import SelectInput from '../../SelectInput/SelectInput';
import styles from '../sass/MarketDrivers.module.scss';

const TopMarketDriversItem = ({
  name,
  value,
  style,
  id,
  onUpdateTopMarketDriver,
  onDeleteTopMarketDriver,
  placeholderTopMarketDrivers,
  meaningsList,
  meaningId
}) => {
  const [driver, setDriver] = useState(name);

  const handleChangeDriver = useCallback((e) => {
    const text = deleteSpacesInString(e.target.value);
    setDriver(text);
  }, []);

  const handleBlurChangeDriver = useOnBlur(
    name,
    driver,
    () => onUpdateTopMarketDriver({ name: driver, id, value }),
    () => onDeleteTopMarketDriver(id)
  );

  useLayoutEffect(() => {
    setDriver(name);
  }, [name]);

  return (
    <div className={styles.topMarketDriversItemContainer}>
      {meaningId ? (
        <SelectInput
          values={meaningsList}
          value={driver}
          onChange={(idMeaning) => onUpdateTopMarketDriver({
            name, id, value, meaningId, selectIdMeaning: idMeaning
          })}
          styleContainer={`${styles.selectInputContainer} ${styles.selectInputMeaning}`}
          customStyle={style}
        />
      ) : (
        <Input
          onChange={handleChangeDriver}
          onBlur={handleBlurChangeDriver}
          title={placeholderTopMarketDrivers}
          style={styles.inputContainer}
          value={driver}
          customStyle={style}
        />
      )}
      <SelectInput
        onChange={(rate) => onUpdateTopMarketDriver({
          name, id, value: +rate, meaningId
        })}
        styleContainer={styles.selectInputContainer}
        value={value.toString()}
        values={rateOptions}
        checkId={false}
      />
    </div>
  );
};

TopMarketDriversItem.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  style: PropTypes.shape({
    color: PropTypes.string,
    fontWeight: PropTypes.string,
  }).isRequired,
  id: PropTypes.number.isRequired,
  onUpdateTopMarketDriver: PropTypes.func.isRequired,
  onDeleteTopMarketDriver: PropTypes.func.isRequired,
  placeholderTopMarketDrivers: PropTypes.string.isRequired,
  meaningsList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  meaningId: PropTypes.number.isRequired
};

export default TopMarketDriversItem;
