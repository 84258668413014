import React from 'react';
import PropTypes from 'prop-types';
import AdminStakeholdersHeader from './AdminStakeholdersHeader';
import AdminStakeholdersRow from './AdminStakeholdersRow';
import NoData from '../../../../components/NoData/NoData';
import styles from '../sass/AdminStakeholders.module.scss';

const AdminStakeholdersList = ({
  sortBy,
  sortOrder,
  onSort,
  stakeholders,
  onDelete,
}) => {
  return (
    <div className={styles.drivers}>
      {stakeholders.length ? (
        <>
          <AdminStakeholdersHeader
            sortBy={sortBy}
            sortOrder={sortOrder}
            onSort={onSort}
          />

          {stakeholders.map(({
            id,
            name,
            created_at: createdAt,
            stakeholder_type: { full_name: stakeholderTypeFullName },
          }) => (
            <AdminStakeholdersRow
              key={id}
              createdAt={createdAt}
              stakeholder={stakeholderTypeFullName}
              name={name}
              onDelete={onDelete}
              id={id}
            />
          ))}
        </>
      ) : <NoData />}
    </div>
  );
};

AdminStakeholdersList.propTypes = {
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
  stakeholders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    stakeholder_type: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      full_name: PropTypes.string.isRequired,
    }).isRequired,
    created_at: PropTypes.string.isRequired,
  })).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default AdminStakeholdersList;
