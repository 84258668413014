import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { forgotPasswordAction } from '../../store/auth/actions';
import { forgotPasswordValidationSchema } from '../../utils/validation';
import Page from '../../layout/Page/Page';
import AuthLayout from '../../layout/AuthLayout/AuthLayout';
import Title from '../../components/Title/Title';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import styles from './sass/ForgotPassword.module.scss';

const ForgotPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth: { loading } } = useSelector(({ auth }) => ({ auth }));
  const [sendAgain, setSendAgain] = useState(false);

  const {
    handleSubmit,
    values: {
      email
    },
    errors,
    setFieldError,
    handleChange,
    validateForm,
  } = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values) => validateForm(values).then(() => {
      dispatch(forgotPasswordAction(values.email, setFieldError, () => setSendAgain(true)));
    }),
    validationSchema: forgotPasswordValidationSchema,
    validateOnBlur: false,
    validateOnChange: false
  });

  const handleForgotPassword = useCallback(() => {
    handleSubmit();
  }, []);

  const handleBackNavigation = useCallback(() => navigate(-1), []);

  return (
    <Page simpleHeader loader={loading} whiteTheme>
      <AuthLayout>
        <Title text={t('restorePassword')} Type="h1" />
        <div className={styles.container}>
          <form onSubmit={handleSubmit}>
            <Input
              onChange={handleChange}
              name="email"
              title={t('email')}
              value={email}
              errorText={errors.email}
            />
            {sendAgain && (
              <>
                <div className={styles.text}>{t('youWillGetValidation')}</div>
                <div className={styles.resend}>
                  {`${t('ifYouDidNotReceiveIt')} `}
                  <span role="presentation" onClick={handleForgotPassword}>{t('resendEmail')}</span>
                </div>
              </>
            )}
            <Button
              text={t('send')}
              style={styles.buttonContainer}
              type="submit"
              disabled={sendAgain}
            />
            <Button text={t('cancel')} style={styles.buttonCancel} onClick={handleBackNavigation} />
          </form>
        </div>
      </AuthLayout>
    </Page>
  );
};

export default ForgotPassword;
