import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../Button/Button';
import styles from '../sass/ModalConfirmDelete.module.scss';

import { ReactComponent as Delete } from '../../../assets/icons/deleteAdmin.svg';

const EditButtons = ({ onClickDelete, onClickSave, disabled }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.competitorModalEditButtonsContainer}>
      <Button
        onClick={onClickDelete}
        text={t('delete')}
        style={styles.editButtonContainer}
        styleButton={styles.deleteButton}
        icon={<Delete />}
      />
      <Button
        onClick={onClickSave}
        text={t('save')}
        style={styles.editButtonContainer}
        styleButton={styles.editButton}
        disabled={disabled}
      />
    </div>
  );
};

EditButtons.propTypes = {
  onClickDelete: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default EditButtons;
