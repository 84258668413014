import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { logInAction } from '../../store/auth/actions';
import { loginValidationSchema } from '../../utils/validation';
import Page from '../../layout/Page/Page';
import AuthLayout from '../../layout/AuthLayout/AuthLayout';
import Title from '../../components/Title/Title';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import LoginHeader from './components/LoginHeader';
import styles from './sass/Login.module.scss';

import { ReactComponent as CloseEyeIcon } from '../../assets/icons/closeEye.svg';
import { ReactComponent as EyeIcon } from '../../assets/icons/openEye.svg';

const Login = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth: { loading } } = useSelector(({ auth }) => ({ auth }));
  const [showPassword, setShowPassword] = useState(false);

  const {
    handleSubmit,
    values: {
      email, password
    },
    errors,
    handleChange,
    validateForm,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => validateForm(values)
      .then(() => dispatch(logInAction(values.password, values.email, navigate))),
    validationSchema: loginValidationSchema,
    validateOnBlur: false,
    validateOnChange: false
  });

  return (
    <Page simpleHeader loader={loading} whiteTheme>
      <AuthLayout>
        <LoginHeader />
        <form onSubmit={handleSubmit}>
          <Title text={t('login')} Type="h1" />
          <div className={styles.inputContainer}>
            <Input
              onChange={handleChange}
              name="email"
              title={`${t('email')} (${t('username')})`}
              value={email}
              errorText={errors.email}
            />
            <Input
              onChange={handleChange}
              name="password"
              title={t('password')}
              type={showPassword ? 'text' : 'password'}
              value={password}
              errorText={errors.password}
              style={styles.passwordInput}
              icon={showPassword ? <CloseEyeIcon /> : <EyeIcon />}
              onIconClick={() => setShowPassword(!showPassword)}
            />
            <div className={styles.forgotPass}>
              <Link to="/forgot-password">{t('forgotPassword')}</Link>
            </div>
            <Button text={t('login')} style={styles.button} type="submit" />
            <div className={styles.createAccount}>
              {t('didNotRegistered')}
              <Link to="/registration">{` ${t('createAnAccount')}`}</Link>
            </div>
          </div>
        </form>
      </AuthLayout>
    </Page>
  );
};

export default Login;
