import React from 'react';
import { useTranslation } from 'react-i18next';
import Tip from '../../../components/Tip/Tip';

const O2TipTopMarket = () => {
  const { t } = useTranslation();

  return (
    <Tip>
      <p>
        {t('tip02')}
      </p>
    </Tip>
  );
};

export default O2TipTopMarket;
