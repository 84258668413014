import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { formatSelectedDateToString } from '../../../../utils/helpers';
import Switcher from '../../../../components/Switcher/Switcher';
import Button from '../../../../components/Button/Button';
import styles from '../sass/AdminScenarios.module.scss';

import { ReactComponent as Edit } from '../../../../assets/icons/edit.svg';
import { ReactComponent as Delete } from '../../../../assets/icons/deleteAdmin.svg';

const AdminScenarioRow = ({
  id, name, description, createdAt,
  isActive, stakeholdersCount, trendsCount, onDelete,
  onChangeStatus, onSaveScenariosPath,
}) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const date = formatSelectedDateToString(new Date(createdAt), 'mm/dd/yyyy');

  return (
    <div className={`${styles.row} ${styles.line}`}>
      <div className={styles.column}>
        <span>{date}</span>
      </div>

      <div className={styles.column}>
        <span>{name}</span>
      </div>

      <div className={`${styles.column} ${styles.description}`}>
        <span>{description}</span>
      </div>

      <div className={styles.column}>
        <Link
          className={styles.link}
          to={`${pathname}/${id}/trends`}
          onClick={() => onSaveScenariosPath(`${pathname}${search}`)}
        >
          {trendsCount}
        </Link>
      </div>

      <div className={styles.column}>
        <Link
          className={styles.link}
          to={`${pathname}/${id}/stakeholders`}
          onClick={() => onSaveScenariosPath(`${pathname}${search}`)}
        >
          {stakeholdersCount}
        </Link>
      </div>

      <div className={`${styles.column} ${styles.actions}`}>
        <Switcher
          onClick={() => onChangeStatus(id, !isActive)}
          isChecked={isActive}
          id={id}
        />
        <Button
          icon={<Edit />}
          style={styles.buttonActionContainer}
          styleButton={styles.buttonAction}
          onClick={() => navigate(`${id}`)}
        />
        <Button
          icon={<Delete width={12} height={12} />}
          style={styles.buttonActionContainer}
          styleButton={styles.buttonAction}
          onClick={() => onDelete(id)}
        />
      </div>
    </div>
  );
};

AdminScenarioRow.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  stakeholdersCount: PropTypes.number.isRequired,
  trendsCount: PropTypes.number.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onSaveScenariosPath: PropTypes.func.isRequired,
};

export default AdminScenarioRow;
