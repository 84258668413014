export const SET_PROJECTS_SUCCESS = 'SET_PROJECTS_SUCCESS';
export const SET_PROJECTS_REQUEST = 'SET_PROJECTS_REQUEST';
export const SET_PROJECTS_FAILED = 'GET_PROJECTS_FAILED';
export const SET_PROJECTS_PROJECT = 'SET_PROJECTS_PROJECT';
export const SET_PROJECTS_PROJECT_CLEAN = 'SET_PROJECTS_PROJECT_CLEAN';
export const SET_PROJECTS_NEW_PROJECT_SUCCESS = 'SET_PROJECTS_NEW_PROJECT_SUCCESS';
export const SET_PROJECTS_EDIT_PROJECT = 'SET_PROJECTS_EDIT_PROJECT';
export const SET_PROJECTS_EDIT_PROJECT_SUCCESS = 'SET_PROJECTS_EDIT_PROJECT_SUCCESS';
export const SET_PROJECTS_DELETE_SUCCESS = 'SET_PROJECTS_DELETE_SUCCESS';
export const SET_PROJECTS_DUPLICATE_SUCCESS = 'SET_PROJECTS_DUPLICATE_SUCCESS';
export const SET_PROJECTS_SCENARIOS_SUCCESS = 'SET_PROJECTS_SCENARIOS_SUCCESS';
export const CLEAN_PROJECTS = 'CLEAN_PROJECTS';
