import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { deleteSpacesInString } from '../../utils/helpers';
import { urlValidation } from '../../utils/validation';
import Input from '../Input/Input';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import styles from './sass/LinkModal.module.scss';

const LinkModal = ({
  link, onClose, onSave
}) => {
  const { t } = useTranslation();
  const [editLink, setEditLink] = useState(link);

  const handleChangeLink = useCallback((event) => {
    const url = deleteSpacesInString(event.target.value);
    setEditLink({
      ...editLink,
      url
    });
  }, [editLink]);

  useEffect(() => {
    setEditLink(link);
  }, [link]);
  return (
    <Modal onClose={onClose} text={editLink.id ? t('editLink') : t('addLink')}>
      <Input
        value={editLink.url}
        name="url"
        title={t('typeHere')}
        onChange={handleChangeLink}
        style={styles.linkModal}
      />
      <Button
        text={t('save')}
        style={styles.linkModalButtonContainer}
        styleButton={styles.linkModalButton}
        onClick={() => onSave(editLink)}
        disabled={!editLink.url || !urlValidation.test(editLink.url)}
      />
    </Modal>
  );
};

LinkModal.propTypes = {
  link: PropTypes.shape({
    id: PropTypes.number,
    url: PropTypes.string
  }),
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

LinkModal.defaultProps = {
  link: {
    id: null,
    url: ''
  }
};

export default LinkModal;
