import PropTypes from 'prop-types';
import CurrencyInput3rdParty from 'react-currency-input-field';
import styles from './sass/Input.module.scss';

const CurrencyInput = ({
  name,
  title,
  value,
  onChange,
  onBlur,
  errorText,
  style,
  disabled,
  customStyle,
  label,
  maxLength,
  styleLabel,
  decimalsLimit,
  groupSeparator,
  decimalSeparator,
  currencySymbol
}) => {
  return (
    <div className={`${styles.container} ${style}`}>
      {label && <span className={`${styles.label} ${styleLabel}`}>{label}</span>}
      <div className={styles.inputContainer}>
        <CurrencyInput3rdParty
          placeholder={title}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          maxLength={maxLength}
          decimalsLimit={decimalsLimit}
          decimalSeparator={decimalSeparator}
          groupSeparator={groupSeparator}
          style={customStyle}
        />
        {currencySymbol && <span>{currencySymbol}</span>}
      </div>
      {!!errorText && <div className={styles.error}>{errorText}</div>}
    </div>
  );
};

CurrencyInput.propTypes = {
  errorText: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  customStyle: PropTypes.shape({
    color: PropTypes.string,
    fontWeight: PropTypes.string,
  }),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  maxLength: PropTypes.number,
  styleLabel: PropTypes.string,
  decimalsLimit: PropTypes.number,
  groupSeparator: PropTypes.string,
  decimalSeparator: PropTypes.string,
  currencySymbol: PropTypes.string,
};

CurrencyInput.defaultProps = {
  value: '',
  errorText: '',
  style: '',
  title: '',
  name: '',
  onBlur: undefined,
  disabled: false,
  customStyle: undefined,
  label: '',
  maxLength: undefined,
  styleLabel: '',
  currencySymbol: '$',
  decimalsLimit: 0,
  groupSeparator: ' ',
  decimalSeparator: '.',
};

export default CurrencyInput;
