import React from 'react';
import PropTypes from 'prop-types';
import Tip from '../Tip/Tip';
import styles from './sass/TitleWithTip.module.scss';

const TitleWithTip = ({
  styleContainer, styleTitle, text, children, styleTipTextContainer, styleTriangle
}) => {
  return (
    <div className={`${styles.container} ${styleContainer}`}>
      <div className={`${styles.title} ${styleTitle}`}>{text}</div>
      {children && (
        <Tip styleTipTextContainer={styleTipTextContainer} styleTriangle={styleTriangle}>
          {children}
        </Tip>
      )}
    </div>
  );
};

TitleWithTip.propTypes = {
  styleContainer: PropTypes.string,
  styleTitle: PropTypes.string,
  text: PropTypes.string.isRequired,
  children: PropTypes.node,
  styleTipTextContainer: PropTypes.string,
  styleTriangle: PropTypes.string
};

TitleWithTip.defaultProps = {
  styleContainer: '',
  styleTitle: '',
  children: null,
  styleTipTextContainer: '',
  styleTriangle: ''
};

export default TitleWithTip;
