import {
  SET_M1_CREATE_WORK_SHEET_SUCCESS,
  SET_M1_LAST_WORK_SHEET_SUCCESS,
  SET_M1_REQUEST,
  SET_M1_FAILED,
  CLEAN_M1,
  SET_M1_AVATAR_SUCCESS,
  SET_M1_WORK_SHEET_SUCCESS,
  SET_M1_WORK_SHEET_SEGMENT_SUCCESS,
  SET_M1_WORK_SHEET_MEANING_SUCCESS,
  SET_M1_WORK_SHEET_LINKS_SUCCESS,
  SET_M1_WORK_SHEET_DRIVERS_SUCCESS,
  SET_M1_WORK_SHEET_TRIGGERS_SUCCESS,
  SET_M1_SELECT_WORK_SHEET_SUCCESS,
  SET_M1_SEGMENT_LIST_SUCCESS
} from './types';

const initialState = {
  segmentList: [],
  segment: undefined,
  name: '',
  id: undefined,
  avatars: null,
  age: null,
  occupation: null,
  location: null,
  biography: null,
  meanings: [],
  drivers: [],
  triggers: [],
  links: [],
  loading: false,
  error: null
};

export default function m1(state = initialState, action) {
  switch (action.type) {
    case SET_M1_SEGMENT_LIST_SUCCESS:
      return {
        ...state,
        segmentList: action.data,
        loading: false,
        error: null
      };
    case SET_M1_CREATE_WORK_SHEET_SUCCESS:
      return {
        ...state,
        name: action.name,
        id: action.id,
        segment: action.segment,
        meanings: [],
        drivers: [],
        triggers: [],
        links: [],
        avatars: null,
        age: null,
        occupation: null,
        location: null,
        biography: null,
        loading: false,
        error: null
      };
    case SET_M1_AVATAR_SUCCESS:
      return {
        ...state,
        avatars: action.data,
        loading: false,
        error: null
      };
    case SET_M1_WORK_SHEET_SUCCESS:
      return {
        ...state,
        [action.field]: action.data,
        loading: false,
        error: null
      };
    case SET_M1_WORK_SHEET_SEGMENT_SUCCESS:
      return {
        ...state,
        segment: action.data,
        loading: false,
        error: null
      };
    case SET_M1_WORK_SHEET_MEANING_SUCCESS:
      return {
        ...state,
        meanings: action.data,
        loading: false,
        error: null
      };
    case SET_M1_WORK_SHEET_LINKS_SUCCESS:
      return {
        ...state,
        links: action.data,
        loading: false,
        error: null
      };
    case SET_M1_WORK_SHEET_DRIVERS_SUCCESS:
      return {
        ...state,
        drivers: action.data,
        loading: false,
        error: null
      };
    case SET_M1_WORK_SHEET_TRIGGERS_SUCCESS:
      return {
        ...state,
        triggers: action.data,
        loading: false,
        error: null
      };
    case SET_M1_SELECT_WORK_SHEET_SUCCESS:
    case SET_M1_LAST_WORK_SHEET_SUCCESS:
      return {
        ...state,
        id: action.data.id,
        name: action.data.full_name,
        avatars: action.data.avatars,
        segment: action.data.segment_id,
        meanings: action.data.meanings,
        drivers: action.data.drivers,
        triggers: action.data.triggers,
        links: action.data.links,
        age: action.data.age,
        occupation: action.data.occupation,
        location: action.data.location,
        biography: action.data.biography,
        loading: false,
        error: null
      };
    case SET_M1_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_M1_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case CLEAN_M1:
      return initialState;
    default:
      return state;
  }
}
