import React, {
  useState, useCallback, useEffect, useMemo
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  cleanProjects,
  deleteProjectAction,
  duplicateProjectAction,
  getProjectsAction,
  getProjectsEditProjectAction,
  getProjectsNewProjectAction,
  setProjectsEditProject,
  setProjectsProject
} from '../../store/projects/actions';
import { USER } from '../../constants/general';
import Page from '../../layout/Page/Page';
import ProjectCard from './components/ProjectCard';
import ProjectAddCard from './components/ProjectAddCard';
import ModalDelete from '../../components/ModalDelete/ModalDelete';
import Modal from '../../components/Modal/Modal';
import ProjectModalContent from './components/ProjectModalContent';
import styles from './sass/Projects.module.scss';

const Projects = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    projects: {
      projectsList, loading, project, nextLink, error, scenariosList
    },
    general: { industriesList, userIdForSwitch, currencyList },
    user: { role }
  } = useSelector(({ projects, general, user }) => ({ projects, general, user }));

  const [modal, setModal] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [fetching, setFetching] = useState(false);

  const handleScroll = useCallback((event) => {
    const height = event.target.documentElement.scrollHeight
      - (event.target.documentElement.scrollTop + window.innerHeight);
    if (height < 70) {
      setFetching(true);
    }
  }, []);

  useEffect(() => {
    if (role !== USER && !userIdForSwitch) {
      navigate('/admin');
    } else {
      dispatch(getProjectsAction(userIdForSwitch, null));
      document.addEventListener('scroll', handleScroll);
    }
    return () => {
      dispatch(cleanProjects());
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (fetching && nextLink) {
      dispatch(getProjectsAction(null, () => setFetching(false)));
    }
  }, [fetching, nextLink]);

  const textError = useMemo(() => {
    const message = error?.message || '';
    return message.includes('must be a string') || message.includes('than 1000 characters') ? message : '';
  }, [error]);

  const modalTitle = useMemo(() => {
    let text = t('addNewProject');
    if (project?.id) {
      text = isDuplicate ? t('duplicateProject') : t('editProject');
    }
    return text;
  }, [project?.id, isDuplicate]);

  const handleOpenModal = useCallback((item) => {
    setModal(true);
    dispatch(setProjectsEditProject(item));
  }, []);

  const handleCloseModal = useCallback(() => {
    setModal(false);
    if (isDuplicate) setIsDuplicate(false);
  }, [isDuplicate]);

  const handleUpdateProject = useCallback((field, value) => {
    dispatch(setProjectsProject(field, value));
  }, []);

  const handleSaveNewProject = useCallback(() => {
    dispatch(getProjectsNewProjectAction(role !== USER && userIdForSwitch, handleCloseModal));
  }, [userIdForSwitch, role]);

  const handleDeleteProject = useCallback(() => {
    dispatch(deleteProjectAction());
    setModalDelete(false);
    handleCloseModal();
  }, []);

  const handleDuplicateProject = useCallback(() => {
    setIsDuplicate(true);
  }, []);

  const handleEditProject = useCallback(() => {
    if (isDuplicate) {
      dispatch(duplicateProjectAction(handleCloseModal));
    } else {
      dispatch(getProjectsEditProjectAction(handleCloseModal));
    }
  }, [handleCloseModal, isDuplicate]);

  return (
    <Page
      simpleHeader
      whiteTheme
      pageContainer={styles.pageContainer}
      loader={loading}
      logout
      goBack={role !== USER}
    >
      <div className={styles.wrapper}>
        {(!loading || !!projectsList.length) && (
          <>
            {projectsList.map((item) => (
              <ProjectCard
                key={`${item.id}${item.name}`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleOpenModal(item);
                }}
                name={item.name}
                id={item.id}
              />
            ))}
            <ProjectAddCard onClick={() => handleOpenModal(null)} />
          </>
        )}
      </div>

      {modal && (
        <Modal
          onClose={handleCloseModal}
          text={modalTitle}
        >
          <ProjectModalContent
            onUpdateProject={handleUpdateProject}
            industriesList={industriesList}
            scenariosList={scenariosList}
            currencyList={currencyList}
            onSaveNewProject={handleSaveNewProject}
            onEditProject={handleEditProject}
            project={project}
            onDelete={() => setModalDelete(true)}
            onDuplicate={handleDuplicateProject}
            textError={textError}
            isDuplicate={isDuplicate}
          />
        </Modal>
      )}

      {modalDelete && (
        <ModalDelete
          onDelete={handleDeleteProject}
          onClose={() => setModalDelete(false)}
          text={t('project')}
        />
      )}
    </Page>
  );
};

export default Projects;
