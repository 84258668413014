const outputData = {
  summary: {
    label: 'Summary',
    docx: {
      label: 'World Document (DOCX)',
      value: 'docx'
    },
    pptx: {
      label: 'Presentation (PPTX)',
      value: 'pptx'
    }
  },
  allData: {
    label: 'All Data',
    xlsx: {
      label: 'Spreadsheet (XLSX)',
      value: 'xlsx'
    },
  }
};

export default outputData;
