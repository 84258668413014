import React, {
  useState, useLayoutEffect, useCallback, useEffect
} from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import useOnBlur from '../../../hooks/useOnBlur';
import useDebounce from '../../../hooks/useDebounce';
import { getStakeholderPartnerListAction } from '../../../store/o3/actions';
import { PARTNERSHIP } from '../../../constants/general';
import { deleteSpacesInString } from '../../../utils/helpers';
import O3StakeholderType from './O3StakeholderType';
import O3PartnershipItem from './O3PartnershipItem';
import styles from '../sass/O3.module.scss';

const O3PotentialPartnershipItem = ({
  partnershipId,
  partnershipName,
  partnershipValue,
  partnershipStakeholderTypeId,
  stakeholdersList,
  onChangePartnership,
  onSetNewPartnership,
  onDeletePartnership,
  idPriority
}) => {
  const { id: idProject } = useParams();
  const [stakeholderType, setStakeholderType] = useState(0);
  const [stakeholderPartnershipName, setStakeholderPartnershipName] = useState('');
  const [completed, setCompleted] = useState(true);
  const [stakeholderPartnerList, setStakeholderPartnerList] = useState([]);

  const debounceValue = useDebounce(stakeholderPartnershipName, 500);

  useLayoutEffect(() => {
    setStakeholderType(partnershipStakeholderTypeId);
    setStakeholderPartnershipName(partnershipName);
  }, [partnershipStakeholderTypeId, partnershipName]);

  useEffect(() => {
    if (!completed && debounceValue && !partnershipId) {
      getStakeholderPartnerListAction(
        idProject,
        stakeholderType,
        debounceValue,
        setStakeholderPartnerList,
        idPriority
      );
    }
  }, [completed, debounceValue, idPriority]);

  const handleChangePartnershipName = useCallback((e) => {
    const text = deleteSpacesInString(e.target.value);
    setStakeholderPartnershipName(text);
    setCompleted(false);
  }, []);

  const handleBlurPartnershipName = useOnBlur(
    partnershipName,
    stakeholderPartnershipName,
    () => onChangePartnership({
      name: stakeholderPartnershipName,
      value: partnershipValue,
      id: partnershipId,
      stakeholderTypeId: stakeholderType,
      type: PARTNERSHIP.POTENTIAL
    }, () => onSetNewPartnership(false)),
    () => onDeletePartnership(partnershipId, PARTNERSHIP.POTENTIAL)
  );

  const handleResetStakeholderPartnerList = useCallback(() => {
    if (stakeholderPartnerList.length) {
      setStakeholderPartnerList([]);
    }
  }, [stakeholderPartnerList]);

  return (
    <div className={styles.potentialPartnershipsValues}>
      <O3StakeholderType
        stakeholderTypeId={stakeholderType}
        stakeholdersList={stakeholdersList}
        id={partnershipId}
        name={partnershipName}
        value={partnershipValue}
        onChangePartnership={(name, value, id, stakeholderTypeId) => onChangePartnership({
          name, value, id, stakeholderTypeId, type: PARTNERSHIP.POTENTIAL
        }, () => onSetNewPartnership(false))}
        onSelectStakeholderType={setStakeholderType}
        onResetStakeholderPartnerList={handleResetStakeholderPartnerList}
      />

      <O3PartnershipItem
        name={stakeholderPartnershipName || ''}
        value={partnershipValue}
        id={partnershipId}
        stakeholderTypeId={stakeholderType}
        onChangePartnership={(name, value, id, stakeholderTypeId, isChoose) => {
          onChangePartnership({
            name, value, id, stakeholderTypeId, type: PARTNERSHIP.POTENTIAL, isChoose
          }, () => onSetNewPartnership(false));
        }}
        onChangePartnershipName={handleChangePartnershipName}
        onBlurPartnershipName={handleBlurPartnershipName}
        stakeholderPartnerList={stakeholderPartnerList}
      />
    </div>
  );
};

O3PotentialPartnershipItem.propTypes = {
  partnershipId: PropTypes.number.isRequired,
  partnershipName: PropTypes.string.isRequired,
  partnershipValue: PropTypes.number.isRequired,
  partnershipStakeholderTypeId: PropTypes.number.isRequired,
  stakeholdersList: PropTypes.arrayOf(PropTypes.shape(
    {
      id: PropTypes.number,
      name: PropTypes.string,
      children: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number
      }))
    }
  )).isRequired,
  onChangePartnership: PropTypes.func.isRequired,
  onSetNewPartnership: PropTypes.func.isRequired,
  onDeletePartnership: PropTypes.func.isRequired,
  idPriority: PropTypes.number.isRequired
};

export default O3PotentialPartnershipItem;
