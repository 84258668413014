import React, { useCallback, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { deleteSpacesInString } from '../../utils/helpers';
import Modal from '../Modal/Modal';
import Header from './components/Header';
import Button from '../Button/Button';
import EditButtons from './components/EditButtons';
import DeleteButtons from './components/DeleteButtons';
import styles from './sass/ModalConfirmDelete.module.scss';

const ModalConfirmDelete = ({
  onClose,
  onAdd,
  value,
  onEdit,
  onDelete,
  title,
  text,
  isCompetitor,
  placeholder
}) => {
  const { t } = useTranslation();
  const [newValue, setNewValue] = useState('');
  const [newOffering, setNewOffering] = useState('');
  const [accessDelete, setAccessDelete] = useState(false);

  useLayoutEffect(() => {
    if (value) {
      setNewValue(value.name);
      setNewOffering(value.offering);
    }
  }, [value]);

  const accessEdit = !isCompetitor
    ? newValue !== value?.name
    : newValue !== value?.name || newOffering !== value?.offering;

  const handleChangeValue = useCallback((event) => {
    const name = deleteSpacesInString(event.target.value);
    setNewValue(name);
  }, []);

  const handleChangeOffering = useCallback((event) => {
    const name = deleteSpacesInString(event.target.value);
    setNewOffering(name);
  }, []);

  const handleClose = useCallback(() => {
    onClose();
  }, []);

  const handleChangeAccess = useCallback((accessValue) => {
    setAccessDelete(accessValue);
  }, []);

  return (
    <Modal visibleTitle={!accessDelete} text={title} onClose={handleClose}>
      <div className={styles.competitorModalContainer}>
        <Header
          value={newValue}
          offeringValue={newOffering}
          onChange={handleChangeValue}
          onChangeOffering={handleChangeOffering}
          accessDelete={accessDelete}
          text={text}
          isCompetitor={isCompetitor}
          placeholder={placeholder}
        />

        {!value && (
          <Button
            onClick={() => {
              onAdd(newValue, newOffering);
              handleClose();
            }}
            text={t('add')}
            style={styles.competitorModalButtonContainer}
            styleButton={styles.competitorModalButton}
            disabled={!newValue}
          />
        )}

        {value && !accessDelete && (
          <EditButtons
            onClickDelete={() => handleChangeAccess(true)}
            onClickSave={() => {
              if (accessEdit) {
                onEdit(value.id, newValue, newOffering);
              }
              handleClose();
            }}
            disabled={!newValue}
          />
        )}

        {value && accessDelete && (
          <DeleteButtons
            onClickNo={() => handleChangeAccess(false)}
            onClickYes={() => {
              onDelete(value.id);
              handleClose();
            }}
          />
        )}
      </div>
    </Modal>
  );
};

ModalConfirmDelete.propTypes = {
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  value: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.number,
    offering: PropTypes.string
  }),
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isCompetitor: PropTypes.bool,
  placeholder: PropTypes.string.isRequired
};

ModalConfirmDelete.defaultProps = {
  value: undefined,
  isCompetitor: false,
};

export default ModalConfirmDelete;
