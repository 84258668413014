import {
  CLEAN_M5,
  SET_M5_FAILED,
  SET_M5_OPPORTUNITY_LIST_SUCCESS,
  SET_M5_REQUEST,
  SET_M5_SEGMENT_LIST_SUCCESS,
  SET_M5_SEGMENT_SUCCESS
} from './types';

const initialState = {
  opportunityList: {},
  segmentsList: [],
  segmentId: 0,
  loading: false,
  error: null
};

export default function m5(state = initialState, action) {
  switch (action.type) {
    case SET_M5_SEGMENT_LIST_SUCCESS:
      return {
        ...state,
        segmentsList: action.data,
        loading: false,
        error: null
      };
    case SET_M5_OPPORTUNITY_LIST_SUCCESS:
      return {
        ...state,
        opportunityList: action.data,
        loading: false,
        error: null
      };
    case SET_M5_SEGMENT_SUCCESS:
      return {
        ...state,
        segmentId: action.data,
        loading: false,
        error: null
      };
    case SET_M5_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_M5_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case CLEAN_M5:
      return initialState;
    default:
      return state;
  }
}
