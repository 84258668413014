import {
  CLEAN_ADMIN_TRENDS,
  SET_ADMIN_TRENDS_DELETE_FAILED,
  SET_ADMIN_TRENDS_DELETE_REQUEST,
  SET_ADMIN_TRENDS_FAILED,
  SET_ADMIN_TRENDS_REQUEST,
  SET_ADMIN_TRENDS_SCENARIO_SUCCESS,
  SET_ADMIN_TRENDS_SUCCESS,
} from './types';
import instance from '../../../services/axios';
import { ADMIN_SCENARIOS_URL, ADMIN_TRENDS_URL } from '../../../constants/api';
import notify from '../../../utils/notify';
import { getSearchParams } from '../../../utils/helpers';

export const setAdminTrendsSuccess = (data, total) => ({ type: SET_ADMIN_TRENDS_SUCCESS, data, total });
export const setAdminTrendsRequest = () => ({ type: SET_ADMIN_TRENDS_REQUEST });
export const setAdminTrendsFailed = (error) => ({ type: SET_ADMIN_TRENDS_FAILED, error });
export const setTrendsDeleteRequest = () => ({ type: SET_ADMIN_TRENDS_DELETE_REQUEST });
export const setTrendsDeleteFailed = (error) => ({ type: SET_ADMIN_TRENDS_DELETE_FAILED, error });
export const setAdminTrendsScenarioSuccess = (data) => ({ type: SET_ADMIN_TRENDS_SCENARIO_SUCCESS, data });
export const cleanAdminTrends = () => ({ type: CLEAN_ADMIN_TRENDS });

export const getTrendsAction = (filterParam, setSearchParams) => {
  const getParams = new URLSearchParams({
    ...getSearchParams(filterParam)
  }).toString();
  return (dispatch) => {
    dispatch(setAdminTrendsRequest());
    instance.get(`${ADMIN_TRENDS_URL}?${getParams}`)
      .then(({ data: { data, meta: { total } } }) => {
        if (setSearchParams) setSearchParams(getParams);
        dispatch(setAdminTrendsSuccess(data, total));
      })
      .catch(({ response: { data } }) => {
        dispatch(setAdminTrendsFailed(data));
        notify('error', data.message);
      });
  };
};

export const getScenarioTrendsAction = (scenarioId, filterParam, setSearchParams) => {
  const getParams = new URLSearchParams({
    ...getSearchParams(filterParam)
  }).toString();
  return (dispatch) => {
    dispatch(setAdminTrendsRequest());
    instance.get(`${ADMIN_SCENARIOS_URL}/${scenarioId}/scenario_trends?${getParams}`)
      .then(({ data: { data, meta: { total } } }) => {
        if (setSearchParams) setSearchParams(getParams);
        dispatch(setAdminTrendsSuccess(data, total));
      })
      .catch(({ response: { data } }) => {
        dispatch(setAdminTrendsFailed(data));
        notify('error', data.message);
      });
  };
};

export const deleteTrendAction = (id, params, setSearchParams) => {
  return (dispatch) => {
    dispatch(setTrendsDeleteRequest());
    instance.delete(`${ADMIN_TRENDS_URL}/${id}`)
      .then(() => dispatch(getTrendsAction(params, setSearchParams)))
      .catch(({ response: { data } }) => {
        dispatch(setTrendsDeleteFailed(data));
        notify('error', data.message);
      });
  };
};

export const deleteScenarioTrendAction = (scenarioId, id, params, setSearchParams) => {
  return (dispatch) => {
    dispatch(setTrendsDeleteRequest());
    instance.delete(`${ADMIN_SCENARIOS_URL}/${scenarioId}/scenario_trends/${id}`)
      .then(() => dispatch(getScenarioTrendsAction(scenarioId, params, setSearchParams)))
      .catch(({ response: { data } }) => {
        dispatch(setTrendsDeleteFailed(data));
        notify('error', data.message);
      });
  };
};

export const getScenarioAction = (id) => {
  return (dispatch) => {
    dispatch(setAdminTrendsRequest());
    instance.get(`${ADMIN_SCENARIOS_URL}/${id}`)
      .then(({ data: { data } }) => dispatch(setAdminTrendsScenarioSuccess(data)))
      .catch(({ response: { data } }) => {
        dispatch(setAdminTrendsFailed(data));
        notify('error', data.message);
      });
  };
};
