const adminUsersHeader = [
  {
    name: 'fullName',
    style: 'column',
    sortName: 'name',
    sortable: true,
  },
  {
    name: 'email',
    style: 'column',
    sortName: 'email',
    sortable: true
  },
  {
    name: 'access',
    style: 'columnSmall',
    sortName: 'role',
    sortable: true
  },
  {
    name: 'industry',
    style: 'column',
    sortName: 'industry',
    sortable: true
  },
  {
    name: 'project',
    style: 'columnSmall',
    sortName: 'project',
    sortable: true
  },
  {
    name: 'actions',
    style: 'columnAction',
    sortable: false
  },
];

export default adminUsersHeader;
