import { useState, useMemo } from 'react';
import { EMPTY_TREND_ITEM, SORT_DIRECTION } from '../constants/general';

const useSortTrends = (initialSortBy, initialSortDirection, list, isNewTrend) => {
  const [sortBy, setSortBy] = useState(initialSortBy);
  const [sortDirection, setSortDirection] = useState(initialSortDirection);

  const handleSort = (column) => {
    if (column === sortBy) {
      setSortDirection(sortDirection === SORT_DIRECTION.DESC ? SORT_DIRECTION.ASC : SORT_DIRECTION.DESC);
    } else {
      setSortBy(column);
      setSortDirection(SORT_DIRECTION.DESC);
    }
  };
  
  const sortedList = useMemo(() => {
    const copyList = [...list];
    copyList.sort((a, b) => {
      const aValue = a[sortBy];
      const bValue = b[sortBy];
      if (aValue < bValue) return sortDirection === SORT_DIRECTION.ASC ? -1 : 1;
      if (aValue > bValue) return sortDirection === SORT_DIRECTION.ASC ? 1 : -1;
      return 0;
    });
    return isNewTrend ? [...copyList, EMPTY_TREND_ITEM] : copyList;
  }, [list, sortBy, sortDirection, isNewTrend]);

  return {
    sortBy,
    sortDirection,
    sortedList,
    handleSort
  };
};

export default useSortTrends;
