import React, { useEffect, useMemo, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  cleanEditStakeholder,
  editScenarioStakeholder,
  getEditStakeholderListAction,
  getEditStakeholderScenarioAction,
  getStakeholderAction
} from '../../../store/admin/adminEditStakeholder/actions';
import { SCENARIO } from '../../../constants/general';
import { driversTypeScenario } from '../../../constants/drivers';
import { editStakeholderValidationSchema } from '../../../utils/validation';
import { convertFromIdToNameChildren } from '../../../utils/helpers';
import AdminPage from '../../../layout/admin/AdminPage/AdminPage';
import ScenarioBreadcrumbs from '../../../components/ScenarioBreadcumbs/ScenarioBreadcrumbs';
import Title from '../../../components/Title/Title';
import SelectInput from '../../../components/SelectInput/SelectInput';
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import Drivers from '../../../components/Drivers/Drivers';
import styles from './sass/AdminEdirStakeholder.module.scss';

const AdminEditStakeholder = () => {
  const { stakeholderId, id: scenarioId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    adminEditStakeholder: {
      loading, stakeholdersList, stakeholder: stakeholderData, scenario
    },
  } = useSelector(({ adminEditStakeholder }) => ({ adminEditStakeholder }));

  const {
    handleSubmit,
    values: {
      name,
      stakeholderType,
      drivers
    },
    errors,
    setFieldValue,
    handleChange,
    validateForm,
  } = useFormik({
    initialValues: {
      name: stakeholderData?.name || '',
      stakeholderType: stakeholderData?.stakeholder_type_id || null,
      drivers: stakeholderData?.drivers || [],
    },
    onSubmit: (values) => validateForm(values)
      .then(() => {
        const data = {
          ...values,
          drivers: values.drivers
            .filter((driver) => !!driver.name)
            .map((driver) => ({
              id: driver.id || undefined,
              name: driver.name,
              value: driver.value,
              type: driver.type,
            }))
        };
        dispatch(editScenarioStakeholder(data, scenarioId, stakeholderId, navigate));
      }),
    validationSchema: editStakeholderValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true
  });

  useEffect(() => {
    batch(() => {
      if (stakeholderId) dispatch(getStakeholderAction(stakeholderId, scenarioId));
      dispatch(getEditStakeholderScenarioAction(scenarioId));
      dispatch(getEditStakeholderListAction(scenarioId));
    });
    return () => {
      dispatch(cleanEditStakeholder());
    };
  }, [stakeholderId, scenarioId]);

  const breadcrumbs = useMemo(() => [
    {
      name: scenario?.name || t('scenario'),
      link: `/admin/dashboard/scenarios/${scenarioId}`
    },
    {
      name: t('stakeholders'),
      link: `/admin/dashboard/scenarios/${scenarioId}/stakeholders`
    },
    {
      name: stakeholderData?.name || t('newScenarioDecisionDriver'),
      link: ''
    }
  ], [scenario?.name, stakeholderData?.name, scenarioId]);

  const handleChangeField = useCallback((field, value) => {
    setFieldValue(field, value);
  }, []);

  const handleBackNavigation = useCallback(() => navigate(-1), []);

  const handleChangeDriver = useCallback((values, type, closePlaceholderDriver) => {
    if (values.id) {
      const newDrivers = drivers.map((driver) => (driver.id === values.id
        ? { ...driver, ...values, value: +values.value }
        : driver));
      setFieldValue('drivers', newDrivers);
    } else if (!values?.date) {
      setFieldValue('drivers', [...drivers, { ...values, type, date: new Date().toISOString() }]);
    } else if (values?.date) {
      const newDrivers = drivers.map((driver) => (driver.date === values.date
        ? { ...driver, ...values, value: +values.value }
        : driver));
      setFieldValue('drivers', newDrivers);
    }
    if (closePlaceholderDriver) {
      closePlaceholderDriver();
    }
  }, [drivers]);

  return (
    <AdminPage pageContainer={styles.container} loader={loading} activeTab={SCENARIO}>
      <div className={styles.content}>
        <ScenarioBreadcrumbs breadcrumbs={breadcrumbs} />
        <form onSubmit={() => {}}>
          <Title text={t(stakeholderId ? 'editStakeholder' : 'addStakeholder')} style={styles.title} />

          <div className={styles.stakeholderContainer}>
            <Input
              onChange={handleChange}
              name="name"
              value={name}
              title={t('stakeholderName')}
              errorText={errors.name}
              style={styles.inputContainer}
            />

            <SelectInput
              values={stakeholdersList}
              value={convertFromIdToNameChildren(stakeholdersList, stakeholderType)}
              errorText={errors.stakeholderType}
              onChange={(value) => handleChangeField('stakeholderType', value)}
              title={t('stakeholder')}
            />
          </div>

          <div className={styles.driversContainer}>
            <Drivers
              drivers={drivers || []}
              onChange={handleChangeDriver}
              driversTypes={driversTypeScenario}
              maxLength={250}
              placeholder={t('driverPlaceholder')}
              withOutEmptyBlock
            />
          </div>

          <div className={styles.buttonsContainer}>
            <Button
              text={t('cancel')}
              style={styles.buttonContainer}
              styleButton={styles.button}
              onClick={handleBackNavigation}
            />
            <Button
              text={t('save')}
              type="submit"
              style={styles.buttonContainer}
              styleButton={styles.button}
              onClick={handleSubmit}
            />
          </div>
        </form>
      </div>
    </AdminPage>
  );
};

export default AdminEditStakeholder;
