import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from '../sass/AdminHeader.module.scss';

const Tab = ({ text, link, active }) => {
  return (
    <Link className={active ? styles.activeTab : ''} to={link}>
      {text}
    </Link>
  );
};

Tab.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
};

export default Tab;
