import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from '../sass/CustomDatePicker.module.scss';

import { ReactComponent as CloseIcon } from '../../../assets/icons/closeGray.svg';

const CustomDatePickerInput = forwardRef(({ value, onClick, onClear }, ref) => {
  const { t } = useTranslation();

  const formattedValue = useMemo(() => {
    const [beforeDash, afterDash] = value.split(' - ');
    return beforeDash ? `${beforeDash} - ${afterDash || beforeDash}` : value;
  }, [value]);

  return (
    <>
      <input
        type="text"
        value={formattedValue}
        onClick={onClick}
        ref={ref}
        readOnly
        className={styles.datePicker}
        placeholder={t('createDate')}
      />
      {value && <CloseIcon className={styles.closeIcon} onClick={onClear} />}
    </>
  );
});

CustomDatePickerInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  onClear: PropTypes.func,
};

CustomDatePickerInput.defaultProps = {
  value: '',
  onClick: null,
  onClear: null,
};

export default CustomDatePickerInput;
