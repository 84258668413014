import TipEmotionalTrigger from '../components/M1Tips/TipEmotionalTrigger';
import TipFinancialTrigger from '../components/M1Tips/TipFinancialTrigger';
import TipFunctionalTrigger from '../components/M1Tips/TipFunctionalTrigger';
import TipOrganizationEmotionalTrigger from '../components/OrganizationTips/TipOrganizationEmotionalTrigger';
import TipOrganizationFinancialTrigger from '../components/OrganizationTips/TipOrganizationFinancialTrigger';
import TipOrganizationFunctionalTrigger from '../components/OrganizationTips/TipOrganizationFunctionalTrigger';
import TipStakeholderEmotionalTrigger from '../components/StakeholderTips/TipStakeholderEmotionalTrigger';
import TipStakeholderFinancialTrigger from '../components/StakeholderTips/TipStakeholderFinancialTrigger';
import TipStakeholderFunctionalTrigger from '../components/StakeholderTips/TipStakeholderFunctionalTrigger';

export const triggersTypeCustomer = {
  emotional: { type: 'emotional', tip: <TipEmotionalTrigger /> },
  financial: { type: 'financial', tip: <TipFinancialTrigger /> },
  functional: { type: 'functional', tip: <TipFunctionalTrigger /> },
};

export const triggersTypeOrganization = {
  emotional: { type: 'emotional', tip: <TipOrganizationEmotionalTrigger /> },
  financial: { type: 'financial', tip: <TipOrganizationFinancialTrigger /> },
  functional: { type: 'functional', tip: <TipOrganizationFunctionalTrigger /> },
};

export const triggersTypeStakeholder = {
  emotional: { type: 'emotional', tip: <TipStakeholderEmotionalTrigger /> },
  financial: { type: 'financial', tip: <TipStakeholderFinancialTrigger /> },
  functional: { type: 'functional', tip: <TipStakeholderFunctionalTrigger /> },
};
