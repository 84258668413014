import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { convertFromIdToName } from '../../../utils/helpers';
import Title from '../../../components/Title/Title';
import SelectInput from '../../../components/SelectInput/SelectInput';
import Button from '../../../components/Button/Button';
import M3TipCompetitor from './M3TipCompetitor';
import styles from '../sass/M3.module.scss';

import { ReactComponent as Plus } from '../../../assets/icons/plusBlue.svg';

const M3Header = ({
  segmentList, segmentId, onAddCompetitor, onChange, disabledAddCompetitor
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Title style={styles.headerTitle} text={t('selectedCustomerSegment')} />
      <div className={styles.headerAction}>
        <SelectInput
          title={t('all')}
          onChange={(value) => onChange(value)}
          styleContainer={styles.selectContainer}
          values={segmentList}
          value={convertFromIdToName(segmentList, segmentId)}
        />
        <div className={styles.buttonWithTip}>
          <Button
            text={t('addCompetitor')}
            icon={<Plus />}
            style={styles.buttonContainer}
            styleButton={styles.button}
            onClick={onAddCompetitor}
            disabled={disabledAddCompetitor}
          />
          <M3TipCompetitor />
        </div>
      </div>
    </>
  );
};

M3Header.propTypes = {
  segmentList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })).isRequired,
  segmentId: PropTypes.number.isRequired,
  onAddCompetitor: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  disabledAddCompetitor: PropTypes.bool.isRequired
};

export default M3Header;
