import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { EMPTY_O3_ITEM } from '../../../constants/general';
import Button from '../../../components/Button/Button';
import O3PotentialPartnershipItem from './O3PotentialPartnershipItem';
import styles from '../sass/O3.module.scss';

import { ReactComponent as PlusIcon } from '../../../assets/icons/plusBlue.svg';

const O3PotentialPartnerships = ({
  potentialPartnership,
  stakeholdersList,
  onChangePartnership,
  onDeletePartnership,
  idPriority
}) => {
  const { t } = useTranslation();
  const [newPartnership, setNewPartnership] = useState(false);

  const partnershipList = useMemo(() => {
    return newPartnership ? [...potentialPartnership, EMPTY_O3_ITEM] : potentialPartnership;
  }, [potentialPartnership, newPartnership]);

  return (
    <div className={styles.potentialPartnershipsContainer}>
      <div className={styles.potentialPartnershipsHeader}>
        <span>{t('stakeholderType')}</span>
        <span>{t('potentialPartnerships')}</span>
      </div>

      {partnershipList.map((item) => (
        <O3PotentialPartnershipItem
          key={`${item.id.toString()}-partnerships`}
          partnershipId={item.id}
          partnershipName={item.name}
          partnershipValue={item.value}
          partnershipStakeholderTypeId={item.stakeholder_type_id}
          stakeholdersList={stakeholdersList}
          onChangePartnership={onChangePartnership}
          onDeletePartnership={onDeletePartnership}
          onSetNewPartnership={setNewPartnership}
          idPriority={idPriority}
        />
      ))}

      <Button
        text={t('createNewPartnership')}
        onClick={() => setNewPartnership(true)}
        icon={<PlusIcon />}
        style={styles.buttonContainer}
        styleButton={styles.button}
        disabled={newPartnership || partnershipList.length > 9}
      />
    </div>
  );
};

O3PotentialPartnerships.propTypes = {
  potentialPartnership: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.number,
    stakeholder_type_id: PropTypes.number,
  })),
  stakeholdersList: PropTypes.arrayOf(PropTypes.shape(
    {
      id: PropTypes.number,
      name: PropTypes.string,
      children: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number
      }))
    }
  )),
  onChangePartnership: PropTypes.func.isRequired,
  onDeletePartnership: PropTypes.func.isRequired,
  idPriority: PropTypes.number.isRequired,
};

O3PotentialPartnerships.defaultProps = {
  potentialPartnership: [],
  stakeholdersList: [],
};

export default O3PotentialPartnerships;
