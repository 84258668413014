import React from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/Dashboard.module.scss';

const StakeholderEdinIcon = ({ Icon, onChange }) => {
  return (
    <Icon
      className={styles.buttonIcon}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onChange();
      }}
    />
  );
};

StakeholderEdinIcon.propTypes = {
  Icon: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default StakeholderEdinIcon;
