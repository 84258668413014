import React from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/M4.module.scss';

const M4ChartCustomLabelItem = ({ name, active, onClick }) => {
  return (
    <div role="presentation" onClick={onClick} className={`${styles.customLabelItem} ${active && styles.active}`}>
      {name}
    </div>
  );
};

M4ChartCustomLabelItem.propTypes = {
  name: PropTypes.string,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

M4ChartCustomLabelItem.defaultProps = {
  name: ''
};

export default M4ChartCustomLabelItem;
