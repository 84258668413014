import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Title from '../Title/Title';
import legend from '../../constants/legend';
import styles from './sass/Legend.module.scss';

const Legend = ({ tip }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.legendContainer}>
      <Title style={styles.title} text={t('legend')} Type="h3" />
      {legend.map(({ name, style }) => (
        <div className={`${styles.legendItem} ${styles[style]}`} key={name}>
          {name}
        </div>
      ))}
      {tip}
    </div>
  );
};

Legend.propTypes = {
  tip: PropTypes.node,
};

Legend.defaultProps = {
  tip: null,
};

export default Legend;
