import React, { useCallback, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useOnBlur from '../../../hooks/useOnBlur';
import { deleteSpacesInString } from '../../../utils/helpers';
import Title from '../../Title/Title';
import styles from '../sass/Triggers.module.scss';

const TriggersColumn = ({
  trigger,
  type,
  onChange,
  title,
  tip
}) => {
  const [triggerItem, setTriggerItem] = useState(trigger);
  const { t } = useTranslation();

  useLayoutEffect(() => {
    setTriggerItem(trigger);
  }, [trigger]);

  const handleChangeDescription = useCallback((event) => {
    const description = deleteSpacesInString(event.target.value);
    setTriggerItem({ ...triggerItem, description });
  }, [triggerItem]);

  const handleBlurTriggersDescription = useOnBlur(
    trigger.description,
    triggerItem.description,
    () => onChange(triggerItem, type),
    () => onChange(triggerItem, type),
  );

  return (
    <div className={styles.triggersItemContainer}>
      <div className={styles.triggersItemTitleContainer}>
        <Title
          text={title}
          style={styles.triggersItemTitle}
        />
        {tip}
      </div>

      <textarea
        rows={4}
        placeholder={t('triggerPlaceholder')}
        value={triggerItem?.description}
        onChange={handleChangeDescription}
        onBlur={handleBlurTriggersDescription}
      />
    </div>
  );
};

TriggersColumn.propTypes = {
  trigger: PropTypes.shape({
    description: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.number
  }),
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  tip: PropTypes.node.isRequired,
};

TriggersColumn.defaultProps = {
  trigger: {
    description: '',
    type: '',
    id: null
  }
};

export default TriggersColumn;
