import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useOnBlur from '../../../hooks/useOnBlur';
import rateOptions from '../../../constants/rateOptions';
import Input from '../../../components/Input/Input';
import SelectInput from '../../../components/SelectInput/SelectInput';
import styles from '../sass/M7.module.scss';

const M7OfferingItem = ({ message, messageValue, onChangeOffering }) => {
  const { t } = useTranslation();

  const [messageForOffering, setMessageForOffering] = useState(message);

  const handleChangeMessageForOffering = useCallback((e) => {
    setMessageForOffering(e.target.value);
  }, []);

  const handleBlurMessageForOffering = useOnBlur(
    message,
    messageForOffering,
    () => onChangeOffering({ message_for_offering: messageForOffering, message_value: messageValue }),
    () => onChangeOffering({ message_for_offering: messageForOffering, message_value: messageValue }),
  );
  
  return (
    <div className={styles.offering}>
      <Input
        value={messageForOffering}
        onChange={handleChangeMessageForOffering}
        onBlur={handleBlurMessageForOffering}
        style={styles.offeringInput}
        title={t('messagePlaceholder')}
      />
      <SelectInput
        onChange={(rate) => onChangeOffering({ message_for_offering: messageForOffering, message_value: +rate })}
        title=""
        styleContainer={styles.offeringSelect}
        checkId={false}
        values={rateOptions}
        value={messageValue.toString()}
      />
    </div>
  );
};

M7OfferingItem.propTypes = {
  message: PropTypes.string.isRequired,
  messageValue: PropTypes.number.isRequired,
  onChangeOffering: PropTypes.func.isRequired
};

export default M7OfferingItem;
