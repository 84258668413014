import React from 'react';
import { useTranslation } from 'react-i18next';
import Tip from '../Tip/Tip';

const TipStakeholderEmotionalDriver = () => {
  const { t } = useTranslation();
  return (
    <Tip>
      <p>
        {t('tipStakeholderEmotionalDriver')}
      </p>
    </Tip>
  );
};

export default TipStakeholderEmotionalDriver;
