import {
  BrowserRouter, Routes, Route, Navigate,
} from 'react-router-dom';
import useInterceptor from './services/interceptor';
import Registration from './pages/Registration/Registration';
import Projects from './pages/Projects/Projects';
import Login from './pages/Login/Login';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import VerifyEmail from './pages/VerifyEmail/VerifyEmail';
import SuccessRegistration from './pages/SuccessRegistration/SuccessRegistration';
import RequireAuth from './routes/RequireAuth';
import Project from './routes/Project';
import Dashboard from './pages/Dashboard/Dashboard';
import AdminUsers from './pages/Admin/AdminUsers/AdminUsers';
import AdminEditUser from './pages/Admin/AdminEditUser/AdminEditUser';
import AdminTrends from './pages/Admin/AdminTrends/AdminTrends';
import AdminEditTrend from './pages/Admin/AdminEditTrend/AdminEditTrend';
import AdminScenarios from './pages/Admin/AdminScenarios/AdminScenarios';
import AdminEditScenario from './pages/Admin/AdminEditScenario/AdminEditScenario';
import AdminScenarioTrends from './pages/Admin/AdminTrends/AdminScenarioTrends';
import AdminStakeholders from './pages/Admin/AdminStakeholders/AdminStakeholders';
import AdminEditStakeholder from './pages/Admin/AdminEditStakeholder/AdminEditStakeholder';
import M1 from './pages/M1/M1';
import M2 from './pages/M2/M2';
import M3 from './pages/M3/M3';
import M4 from './pages/M4/M4';
import M5 from './pages/M5/M5';
import M6 from './pages/M6/M6';
import M7 from './pages/M7/M7';
import Organization from './pages/Organization/Organization';
import Stakeholders from './pages/Stakeholders/Stakeholders';
import Trends from './pages/Trends/Trends';
import O1 from './pages/O1/O1';
import O2 from './pages/O2/O2';
import O3 from './pages/O3/O3';
import O4 from './pages/O4/O4';
import O5 from './pages/O5/O5';
import O8 from './pages/O8/O8';
import store from './store/store';
import './styles/globals.scss';

function App() {
  useInterceptor(store);
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<RequireAuth />}>
          <Route path="/" element={(<Projects />)} />
          <Route path="/:id" element={<Project />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="m1" element={<M1 />} />
            <Route path="m2" element={<M2 />} />
            <Route path="m3" element={<M3 />} />
            <Route path="m4" element={<M4 />} />
            <Route path="m5" element={<M5 />} />
            <Route path="m6" element={<M6 />} />
            <Route path="m7" element={<M7 />} />
            <Route path="O1" element={<O1 />} />
            <Route path="O2" element={<O2 />} />
            <Route path="O3" element={<O3 />} />
            <Route path="O4" element={<O4 />} />
            <Route path="O5" element={<O5 />} />
            <Route path="O8" element={<O8 />} />
            <Route path="organization" element={<Organization />} />
            <Route path="stakeholders" element={<Stakeholders />} />
            <Route path="trends" element={<Trends />} />
          </Route>
        </Route>

        <Route path="/registration" element={<Registration />} />
        <Route path="/login" element={<Login />} />
        <Route path="/verify" element={<VerifyEmail />} />
        <Route path="/verify-email/:token" element={<SuccessRegistration />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        
        <Route path="/admin" element={<RequireAuth admin />}>
          <Route index element={<Navigate replace to="dashboard" />} />
          <Route path="dashboard" element={<AdminUsers />} />
          <Route path="dashboard/user" element={<AdminEditUser />} />
          <Route path="dashboard/user/:id" element={<AdminEditUser />} />
          <Route path="dashboard/trends" element={<AdminTrends />} />
          <Route path="dashboard/trends/create" element={<AdminEditTrend />} />
          <Route path="dashboard/trends/:trendId" element={<AdminEditTrend />} />
          <Route path="dashboard/scenarios" element={<AdminScenarios />} />
          <Route path="dashboard/scenarios/create" element={<AdminEditScenario />} />
          <Route path="dashboard/scenarios/:id" element={<AdminEditScenario />} />
          <Route path="dashboard/scenarios/:id/trends" element={<AdminScenarioTrends />} />
          <Route path="dashboard/scenarios/:id/trends/create" element={<AdminEditTrend />} />
          <Route path="dashboard/scenarios/:id/trends/:trendId" element={<AdminEditTrend />} />
          <Route path="dashboard/scenarios/:id/stakeholders" element={<AdminStakeholders />} />
          <Route path="dashboard/scenarios/:id/stakeholders/:stakeholderId" element={<AdminEditStakeholder />} />
          <Route path="dashboard/scenarios/:id/stakeholders/create" element={<AdminEditStakeholder />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
