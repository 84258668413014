import {
  SET_ADMIN_STAKEHOLDER_EDIT_REQUEST,
  SET_ADMIN_STAKEHOLDER_STAKEHOLDERS_LIST_SUCCESS,
  SET_ADMIN_STAKEHOLDER_EDIT_SUCCESS,
  SET_ADMIN_STAKEHOLDER_SUCCESS,
  SET_ADMIN_STAKEHOLDER_EDIT_FAILED,
  SET_ADMIN_STAKEHOLDER_SCENARIO_SUCCESS,
  CLEAN_EDIT_STAKEHOLDER,
} from './types';

const initialState = {
  stakeholdersList: [],
  stakeholder: null,
  scenario: null,
  loading: false,
  error: null,
};

export default function adminEditStakeholder(state = initialState, action) {
  switch (action.type) {
    case SET_ADMIN_STAKEHOLDER_EDIT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_ADMIN_STAKEHOLDER_STAKEHOLDERS_LIST_SUCCESS:
      return {
        ...state,
        stakeholdersList: action.data,
        loading: false,
        error: null,
      };
    case SET_ADMIN_STAKEHOLDER_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SET_ADMIN_STAKEHOLDER_SUCCESS:
      return {
        ...state,
        stakeholder: action.data,
        loading: false,
        error: null,
      };
    case SET_ADMIN_STAKEHOLDER_EDIT_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case SET_ADMIN_STAKEHOLDER_SCENARIO_SUCCESS:
      return {
        ...state,
        scenario: action.data,
        loading: false,
        error: null,
      };
    case CLEAN_EDIT_STAKEHOLDER:
      return initialState;
    default:
      return state;
  }
}
