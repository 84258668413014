import {
  CLEAN_EDIT_TREND,
  SET_ADMIN_TREND_EDIT_FAILED,
  SET_ADMIN_TREND_EDIT_INFLUENCE_SUCCESS,
  SET_ADMIN_TREND_EDIT_REQUEST,
  SET_ADMIN_TREND_EDIT_SUCCESS,
  SET_ADMIN_TREND_SUCCESS,
  SET_ADMIN_TREND_EDIT_SCENARIO_SUCCESS
} from './types';

const initialState = {
  influenceList: [],
  trend: null,
  scenario: null,
  loading: false,
  error: null,
};

export default function adminEditTrend(state = initialState, action) {
  switch (action.type) {
    case SET_ADMIN_TREND_EDIT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_ADMIN_TREND_EDIT_INFLUENCE_SUCCESS:
      return {
        ...state,
        influenceList: action.data,
        loading: false,
        error: null,
      };
    case SET_ADMIN_TREND_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SET_ADMIN_TREND_SUCCESS:
      return {
        ...state,
        trend: action.data,
        loading: false,
        error: null,
      };
    case SET_ADMIN_TREND_EDIT_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case SET_ADMIN_TREND_EDIT_SCENARIO_SUCCESS:
      return {
        ...state,
        scenario: action.data,
        loading: false,
        error: null,
      };
    case CLEAN_EDIT_TREND:
      return initialState;
    default:
      return state;
  }
}
