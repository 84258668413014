import React from 'react';
import styles from '../sass/M5.module.scss';
import Title from '../../../components/Title/Title';

const M5OfferingTitle = () => {
  return (
    <div className={styles.offeringTitleContainer}>
      <Title text="Potential Customer Offering:" Type="h4" style={styles.title} />
      <Title text="Potential Messaging for Offering:" Type="h4" style={styles.title} />
    </div>
  );
};

export default M5OfferingTitle;
