import { batch } from 'react-redux';
import {
  SET_M3_FAILED,
  SET_M3_REQUEST,
  SET_M3_SEGMENT_LIST_SUCCESS,
  SET_M3_TOP_MARKET_DRIVERS_SUCCESS,
  SET_M3_ORGANIZATION_DRIVERS_SUCCESS,
  SET_M3_COMPETITORS_DRIVERS_SUCCESS,
  SET_M3_CHANGE_SEGMENT_SUCCESS,
  CLEAN_M3,
  SET_M3_OWN_ORGANIZATION_NAME_SUCCESS,
  SET_M3_OWN_ORGANIZATION_OFFERING_SUCCESS
} from './types';
import instance from '../../services/axios';
import {
  COMPETITOR_DRIVER_LIST_URL,
  COMPETITORS_URL,
  DRIVER_ORGANIZATION_URL,
  DRIVERS_AND_ORGANIZATION_URL,
  DRIVERS_URL,
  MEANING_URL,
  WORKSHEET_ORGANIZATION_URL
} from '../../constants/api';
import notify from '../../utils/notify';
import { getDriversAndOrganization, getSegmentsAction, updateCompetitorsDriver } from '../general/actions';

export const setM3Request = () => ({ type: SET_M3_REQUEST });
export const setM3Failed = (error) => ({ type: SET_M3_FAILED, error });
export const cleanM3 = () => ({ type: CLEAN_M3 });

export const setSegmentCustomerListsSuccess = (data) => ({ type: SET_M3_SEGMENT_LIST_SUCCESS, data });

export const setTopMarketDriversSuccess = (data) => ({ type: SET_M3_TOP_MARKET_DRIVERS_SUCCESS, data });

export const setOrganizationDriversSuccess = (data) => ({ type: SET_M3_ORGANIZATION_DRIVERS_SUCCESS, data });

export const setCompetitorsDriversSuccess = (data) => ({ type: SET_M3_COMPETITORS_DRIVERS_SUCCESS, data });

export const setChangeSegmentSuccess = (data) => ({ type: SET_M3_CHANGE_SEGMENT_SUCCESS, data });

export const setOwnOrganizationNameSuccess = (data) => ({ type: SET_M3_OWN_ORGANIZATION_NAME_SUCCESS, data });

export const setOwnOrganizationOfferingSuccess = (data) => ({ type: SET_M3_OWN_ORGANIZATION_OFFERING_SUCCESS, data });

export const getSegmentCustomerListsAction = (id) => {
  return (dispatch) => {
    dispatch(setM3Request());
    getSegmentsAction(id)
      .then(({ data: { data } }) => dispatch(setSegmentCustomerListsSuccess(data)))
      .catch(({ response: { data } }) => {
        dispatch(setM3Failed(data));
        notify('error', data.message);
      });
  };
};

export const getDriversAndOrganizationAction = (id, segment) => {
  return (dispatch) => {
    const url = DRIVERS_AND_ORGANIZATION_URL;
    dispatch(setM3Request());
    getDriversAndOrganization(url, id, segment)
      .then(({
        topMarketDrivers, organizationDrivers, ownOrganizationName, ownOrganizationOffering
      }) => {
        batch(() => {
          dispatch(setTopMarketDriversSuccess(topMarketDrivers));
          dispatch(setOrganizationDriversSuccess(organizationDrivers));
          dispatch(setOwnOrganizationNameSuccess(ownOrganizationName));
          dispatch(setOwnOrganizationOfferingSuccess(ownOrganizationOffering));
        });
      })
      .catch(({ response: { data } }) => {
        dispatch(setM3Failed(data));
        notify('error', data.message);
      });
  };
};

export const getCompetitorDriversAction = (id) => {
  return (dispatch) => {
    dispatch(setM3Request());
    instance.get(`${COMPETITOR_DRIVER_LIST_URL}/${id}`)
      .then(({ data: { data } }) => {
        dispatch(setCompetitorsDriversSuccess(data));
      })
      .catch(({ response: { data } }) => {
        dispatch(setM3Failed(data));
        notify('error', data.message);
      });
  };
};

export const getAddCompetitorAction = (id, name, offering) => {
  return (dispatch, getState) => {
    const { m3: { competitorsDrivers } } = getState();
    dispatch(setM3Request());
    instance.post(COMPETITORS_URL, {
      name, project_id: +id, offering
    })
      .then(({ data: { data } }) => {
        dispatch(setCompetitorsDriversSuccess([...competitorsDrivers, data]));
      })
      .catch(({ response: { data } }) => {
        dispatch(setM3Failed(data));
        notify('error', data.message);
      });
  };
};

export const getEditCompetitorAction = (id, name, offering) => {
  return (dispatch, getState) => {
    const { m3: { competitorsDrivers } } = getState();
    dispatch(setM3Request());
    instance.put(`${COMPETITORS_URL}/${id}`, { name, offering })
      .then(() => {
        const updateCompetitors = competitorsDrivers
          .map((item) => (item.id === id ? { ...item, name, offering } : item));
        dispatch(setCompetitorsDriversSuccess(updateCompetitors));
      })
      .catch(({ response: { data } }) => {
        dispatch(setM3Failed(data));
        notify('error', data.message);
      });
  };
};

export const deleteCompetitorAction = (id, idProject) => {
  return (dispatch, getState) => {
    const { m3: { competitorsDrivers, segmentId } } = getState();
    dispatch(setM3Request());
    instance.delete(`${COMPETITORS_URL}/${id}`)
      .then(() => {
        const updateCompetitors = competitorsDrivers.filter(({ id: idCustomer }) => idCustomer !== id);
        batch(() => {
          dispatch(setCompetitorsDriversSuccess(updateCompetitors));
          dispatch(getDriversAndOrganizationAction(idProject, segmentId));
        });
      })
      .catch(({ response: { data } }) => {
        dispatch(setM3Failed(data));
        notify('error', data.message);
      });
  };
};

export const updateCompetitorsDriverAction = (driver, competitorId, idProject) => {
  return (dispatch, getState) => {
    const { m3: { competitorsDrivers, segmentId } } = getState();
    const copyCompetitorsDrivers = [...competitorsDrivers];
    const indexCompetitor = competitorsDrivers.findIndex(({ id }) => id === competitorId);
    dispatch(setM3Request());
    updateCompetitorsDriver(driver, competitorId)
      .then((data) => {
        if (competitorId && !driver.id) {
          copyCompetitorsDrivers[indexCompetitor] = {
            ...copyCompetitorsDrivers[indexCompetitor],
            drivers: [...copyCompetitorsDrivers[indexCompetitor].drivers, data]
          };
          batch(() => {
            dispatch(setCompetitorsDriversSuccess(copyCompetitorsDrivers));
            dispatch(getDriversAndOrganizationAction(idProject, segmentId));
          });
        } else {
          copyCompetitorsDrivers[indexCompetitor] = {
            ...copyCompetitorsDrivers[indexCompetitor],
            drivers: copyCompetitorsDrivers[indexCompetitor].drivers
              .map((i) => (i.driver_id === data.driver_id ? data : i))
          };
          batch(() => {
            dispatch(setCompetitorsDriversSuccess(copyCompetitorsDrivers));
            dispatch(getDriversAndOrganizationAction(idProject, segmentId));
          });
        }
      })
      .catch(({ response: { data } }) => {
        dispatch(setM3Failed(data));
        notify('error', data.message);
      });
  };
};

export const updateOwnOrganizationDriverAction = (driver, idProject) => {
  return (dispatch, getState) => {
    const { m3: { segmentId } } = getState();
    const { driverId, rate } = driver;
    dispatch(setM3Request());
    instance.put(`${DRIVER_ORGANIZATION_URL}/${driverId}`, { value: +rate })
      .then(() => dispatch(getDriversAndOrganizationAction(idProject, segmentId)))
      .catch(({ response: { data } }) => {
        dispatch(setM3Failed(data));
        notify('error', data.message);
      });
  };
};

export const getChangeSegmentAction = (idSegment, id) => {
  return (dispatch) => {
    batch(() => {
      dispatch(setChangeSegmentSuccess(idSegment));
      dispatch(getDriversAndOrganizationAction(id, idSegment));
    });
  };
};

export const updateMeaningAction = (idProject, meaning) => {
  const { value, meaningId, selectIdMeaning } = meaning;
  return (dispatch, getState) => {
    const { m3: { segmentId } } = getState();
    dispatch(setM3Request());
    instance.put(`${MEANING_URL}/${meaningId}`, {
      value,
      meaning_id: selectIdMeaning
    })
      .then(() => dispatch(getDriversAndOrganizationAction(idProject, segmentId)))
      .catch(({ response: { data } }) => {
        dispatch(setM3Failed(data));
        notify('error', data.message);
      });
  };
};

export const getUpdateTopMarketDriverAction = (idProject, driver) => {
  const { id, name, value } = driver;
  return (dispatch, getState) => {
    const { m3: { segmentId } } = getState();
    dispatch(setM3Request());
    instance.put(`${DRIVERS_URL}/${id}`, {
      name,
      value
    })
      .then(() => dispatch(getDriversAndOrganizationAction(idProject, segmentId)))
      .catch(({ response: { data } }) => {
        dispatch(setM3Failed(data));
        notify('error', data.message);
      });
  };
};

export const handleDeleteTopMarketDriverAction = (id, idDriver) => {
  return (dispatch, getState) => {
    const { m3: { segmentId } } = getState();
    dispatch(setM3Request());
    instance.delete(`${DRIVERS_URL}/${idDriver}`)
      .then(() => dispatch(getDriversAndOrganizationAction(id, segmentId)))
      .catch(({ response: { data } }) => {
        dispatch(setM3Failed(data));
        notify('error', data.message);
      });
  };
};

export const changeOwnOrganizationAction = (idProject, value, offering) => {
  return (dispatch) => {
    dispatch(setM3Request());
    instance.put(`${WORKSHEET_ORGANIZATION_URL}/${idProject}`, { full_name: value, offering })
      .then(() => {
        batch(() => {
          dispatch(setOwnOrganizationNameSuccess(value));
          dispatch(setOwnOrganizationOfferingSuccess(offering));
        });
      })
      .catch(({ response: { data } }) => {
        dispatch(setM3Failed(data));
        notify('error', data.message);
      });
  };
};
