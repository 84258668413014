import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from '../sass/Header.module.scss';

import ArrowLeft from '../../../assets/icons/arrowLeft.svg';
import ArrowRight from '../../../assets/icons/arrowRight.svg';

const SwitchPages = ({ switchPages }) => {
  if (!switchPages) return null;
  return (
    <div className={styles.switchPagesContainer}>
      <Link to={switchPages.prevLink}>
        <img src={ArrowLeft} alt="ArrowLeft" />
      </Link>
      <Link to={switchPages.nextLink}>
        <img src={ArrowRight} alt="ArrowLeft" />
      </Link>
    </div>
  );
};

SwitchPages.propTypes = {
  switchPages: PropTypes.shape({
    prevLink: PropTypes.string,
    nextLink: PropTypes.string
  }),
};

SwitchPages.defaultProps = {
  switchPages: null
};

export default SwitchPages;
