import React, { useLayoutEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { convertFromIdToName, convertFromIdToNameChildren, deleteSpacesInString } from '../../utils/helpers';
import useOnBlur from '../../hooks/useOnBlur';
import Input from '../Input/Input';
import Tip from '../Tip/Tip';
import SelectInput from '../SelectInput/SelectInput';
import Button from '../Button/Button';
import styles from './sass/StakeholderHeader.module.scss';

import { ReactComponent as PlusIcon } from '../../assets/icons/plusBlack.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';

const StakeholderHeader = ({
  segmentList,
  onChangeName,
  initialName,
  initialSegment,
  onChangeSegment,
  onOpenModal,
  disabled,
  onEditSegment,
  withSegment,
  title,
  tipContent,
  stakeholderId,
  stakeholdersList,
  onChangeStakeholderType
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');

  useLayoutEffect(() => {
    setName(initialName);
  }, [initialName]);

  const handleChangeName = useCallback((event) => {
    const text = deleteSpacesInString(event.target.value);
    setName(text);
  }, []);

  const handleBlurName = useOnBlur(
    initialName,
    name,
    () => onChangeName(name),
    () => setName(initialName)
  );

  return (
    <div className={styles.stakeholderContainer}>
      <div className={styles.stakeholder}>
        <div className={styles.segmentsInputContainer}>
          <Input
            title={t('stakeholderName')}
            name="stakeholder"
            onChange={handleChangeName}
            onBlur={handleBlurName}
            style={styles.segmentsInput}
            value={name}
          />
          <Tip styleContainer={styles.tipSegment}>
            {tipContent}
          </Tip>
        </div>
        {withSegment && (
          <SelectInput
            title={t('segments')}
            name="segments"
            onChange={onChangeSegment}
            value={convertFromIdToName(segmentList, initialSegment)}
            styleContainer={styles.segmentsSelectInput}
            values={segmentList}
            disabled={disabled}
            actionDropDown={(
              <Button
                text={t('createNewSegment')}
                style={styles.buttonActionContainer}
                styleButton={styles.button}
                icon={<PlusIcon />}
                onClick={onOpenModal}
              />
            )}
            onActionDropDownItem={onEditSegment}
            iconDropDownItem={<EditIcon />}
          />
        )}
      </div>
      {title ? (
        <div className={styles.customer}>{title}</div>
      ) : (
        <SelectInput
          styleContainer={styles.stakeholderSelectInput}
          onChange={onChangeStakeholderType}
          title=""
          value={convertFromIdToNameChildren(stakeholdersList, stakeholderId)}
          values={stakeholdersList}
        />
      )}
    </div>
  );
};

StakeholderHeader.propTypes = {
  segmentList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  onChangeName: PropTypes.func.isRequired,
  onChangeSegment: PropTypes.func,
  initialName: PropTypes.string,
  initialSegment: PropTypes.number,
  onOpenModal: PropTypes.func,
  onEditSegment: PropTypes.func,
  disabled: PropTypes.bool,
  withSegment: PropTypes.bool,
  title: PropTypes.string,
  tipContent: PropTypes.node.isRequired,
  stakeholderId: PropTypes.number,
  stakeholdersList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
  })),
  onChangeStakeholderType: PropTypes.func,
};

StakeholderHeader.defaultProps = {
  segmentList: [],
  initialName: '',
  initialSegment: undefined,
  disabled: false,
  onOpenModal: undefined,
  withSegment: false,
  onEditSegment: undefined,
  onChangeSegment: undefined,
  title: '',
  stakeholderId: undefined,
  stakeholdersList: [],
  onChangeStakeholderType: undefined,
};

export default StakeholderHeader;
