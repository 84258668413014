import React from 'react';
import PropTypes from 'prop-types';
import { usePagination, DOTS } from '../../hooks/usePagination';
import styles from './sass/Pagination.module.scss';
import { ReactComponent as Arrow } from '../../assets/icons/arrowPagination.svg';

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  return (
    <div className={styles.container}>
      <div
        role="presentation"
        className={`${styles.item} ${currentPage === 1 && styles.disabled}`}
        style={{ transform: 'rotate(180deg)' }}
        onClick={() => !(currentPage === 1) && onPrevious()}
      >
        <Arrow />
      </div>
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return (
            <div
              key={Math.random()}
              className={styles.item}
            >
              &#8230;
            </div>
          );
        }
        return (
          <div
            key={Math.random()}
            role="presentation"
            onClick={() => onPageChange(pageNumber)}
            className={`${styles.item} ${pageNumber === currentPage && styles.selected}`}
          >
            {pageNumber}
          </div>
        );
      })}
      <div
        role="presentation"
        onClick={() => !(currentPage === lastPage) && onNext()}
        className={`${styles.item} ${currentPage === lastPage && styles.disabled}`}
      >
        <Arrow />
      </div>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  siblingCount: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};

Pagination.defaultProps = {
  siblingCount: 1
};

export default Pagination;
