export const MAIN_API_URL = process.env.REACT_APP_MAIN_API_URL;

export const SIGN_UP_URL = `${MAIN_API_URL}/register`;
export const LOGIN_URL = `${MAIN_API_URL}/login`;
export const LOGOUT_URL = `${MAIN_API_URL}/logout`;
export const REFRESH_TOKEN_URL = `${MAIN_API_URL}/refresh`;
export const FORGOT_PASSWORD_URL = `${MAIN_API_URL}/forgot-password`;
export const RESET_PASSWORD_URL = `${MAIN_API_URL}/reset-password`;
export const RESEND_EMAIL_URL = `${MAIN_API_URL}/verify-email-resend`;
export const VERIFY_EMAIL = `${MAIN_API_URL}/verify-email`;

export const PROJECTS_URL = `${MAIN_API_URL}/projects`;

export const INDUSTRIES_LIST = `${MAIN_API_URL}/industries`;

export const UPLOAD_FILES_URL = `${MAIN_API_URL}/upload-files`;

export const USERS_URL = `${MAIN_API_URL}/users`;

export const STAKEHOLDER_TYPES_URL = `${MAIN_API_URL}/stakeholder-types`;

export const WORK_SHEET_URL = `${MAIN_API_URL}/worksheets`;
export const LAST_WORK_SHEET_URL = `${MAIN_API_URL}/worksheets-last`;
export const WORK_SHEET_LINK_URL = `${MAIN_API_URL}/worksheet-link`;
export const WORKSHEET_SEGMENTS_URL = `${MAIN_API_URL}/worksheet-segments`;
export const WORKSHEET_PROFILES_URL = `${MAIN_API_URL}/worksheet-profiles`;
export const WORKSHEET_CALCULATE_URL = `${MAIN_API_URL}/worksheet-calculate`;
export const SEGMENT_LIST_URL = `${MAIN_API_URL}/segment-lists`;
export const WORKSHEET_LINKED_LIST_URL = `${MAIN_API_URL}/worksheet-linked-list`;
export const WORKSHEET_ORGANIZATION_URL = `${MAIN_API_URL}/worksheet-organisation`;

export const DRIVERS_URL = `${MAIN_API_URL}/drivers`;

export const TRIGGERS_URL = `${MAIN_API_URL}/triggers`;

export const SEGMENTS_URL = `${MAIN_API_URL}/segments`;

export const MEANING_URL = `${MAIN_API_URL}/meanings`;

export const ADMIN_TRENDS_URL = `${MAIN_API_URL}/admin-trends`;
export const ADMIN_SCENARIOS_URL = `${MAIN_API_URL}/scenarios`;
export const ADMIN_SCENARIO_STAKEHOLDERS_URL = `${MAIN_API_URL}/scenario_stakeholders`;
export const TRENDS_CATEGORY_URL = `${MAIN_API_URL}/admin-trends/list-categories`;
export const TREND_WORKSHEETS_URL = `${MAIN_API_URL}/trend-worksheets`;
export const TRENDS_URL = `${MAIN_API_URL}/trends`;
export const TREND_LINKS_URL = `${MAIN_API_URL}/trend-links`;

export const INFLUENCES_URL = `${MAIN_API_URL}/influences`;

export const PROFILE_URL = `${MAIN_API_URL}/profile`;

export const SEGMENT_CUSTOMER_LISTS_URL = `${MAIN_API_URL}/segment-customer-lists`;
export const DRIVERS_AND_ORGANIZATION_URL = `${MAIN_API_URL}/drivers-and-organization`;
export const DRIVER_ORGANIZATION_URL = `${MAIN_API_URL}/driver-organisation`;
export const COMPETITOR_DRIVER_LIST_URL = `${MAIN_API_URL}/competitor-driver-list`;
export const COMPETITORS_URL = `${MAIN_API_URL}/competitors`;
export const COMPETITOR_DRIVERS_URL = `${MAIN_API_URL}/competitor-drivers`;

export const COMPETITIVE_ANALYSIS_DRIVERS_URL = `${MAIN_API_URL}/competitive-analysis-drivers`;
export const SEGMENT_DRIVERS_LIST_URL = `${MAIN_API_URL}/segment-driver-list`;
export const PROJECT_COMPETITORS_LIST_URL = `${MAIN_API_URL}/project-competitors`;
export const COMPETITOR_DRIVER_OFFER_URL = `${MAIN_API_URL}/competitor-driver-offer`;
export const COMPETITOR_OFFER_URL = `${MAIN_API_URL}/competitor-offer-list`;

export const OPPORTUNITY_AND_THREAT_URL = `${MAIN_API_URL}/opportunity-and-threat`;
export const POTENTIAL_CUSTOMER_OFFERING_URL = `${MAIN_API_URL}/potential-customer-offering`;

export const SEGMENT_CUSTOMER_OFFERING_URL = `${MAIN_API_URL}/segment-customer-offering`;
export const PROJECTED_INCREASE_URL = `${MAIN_API_URL}/projected-increase`;

export const CAPABILITIES_WORKSHEET_URL = `${MAIN_API_URL}/capabilities-worksheet`;
export const OPERATIONAL_PRIORITY_URL = `${MAIN_API_URL}/operational-priority`;

export const MARKET_OPERATIONAL_PRIORITY_URL = `${MAIN_API_URL}/market-operational-priority`;

export const DASHBOARD_COLOR_SCHEME_URL = `${MAIN_API_URL}/dashboard-color-scheme`;
export const VISIBLE_STAKEHOLDER_URL = `${MAIN_API_URL}/visible-stakeholder`;

export const PARTNERSHIP_OPPORTUNITY_URL = `${MAIN_API_URL}/partnership-opportunity`;
export const MISSION_STATEMENT_URL = `${MAIN_API_URL}/mission-statement`;
export const PARTNERSHIP_KEY_URL = `${MAIN_API_URL}/partnership-key`;
export const BUSINESS_MODEL_LIST_URL = `${MAIN_API_URL}/business-model`;

export const MISSION_STATEMENT_SHOW_URL = `${MAIN_API_URL}/mission-statement-show`;

export const OFFERING_REVENUES_LIST_URL = `${MAIN_API_URL}/offering-revenues-list`;
export const TIME_INTERVALS_URL = `${MAIN_API_URL}/time-intervals`;
export const OFFERING_REVENUES_URL = `${MAIN_API_URL}/offering-revenues`;

export const EXPORT_URL = `${MAIN_API_URL}/export`;

export const STAKEHOLDER_URL = `${MAIN_API_URL}/stakeholder`;

export const RESOURCES_URL = `${MAIN_API_URL}/resources`;

export const CURRENCY_LIST_URL = `${MAIN_API_URL}/currency-list`;

export const SCENARIOS_URL = `${MAIN_API_URL}/scenarios`;

export const POST = 'post';
export const PUT = 'put';
export const GET = 'GET';
export const PATCH = 'PATCH';
export const DELETE = 'DELETE';

/* Client Errors */
export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const NOT_FOUND = 404;
export const FORBIDDEN = 403;
export const VALIDATION_ERROR = 422;

/* Successfully */
export const OK = 200;
export const CREATED = 201;
export const DELETED = 204;
