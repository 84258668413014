import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  batch, shallowEqual, useDispatch, useSelector
} from 'react-redux';
import {
  addOfferingAction,
  cleanM5,
  deleteOfferingAction,
  deleteOpportunityAction,
  getOpportunityListAction,
  getSegmentAction,
  getSegmentListAction,
  updateOfferingAction,
  updateOpportunityAction,
  updateOpportunityMeaningAction
} from '../../store/m5/actions';
import { getMeaningListAction } from '../../store/general/actions';
import { getBreadcrumbs, getSwitchPages } from '../../utils/helpers';
import Page from '../../layout/Page/Page';
import NoData from '../../components/NoData/NoData';
import M5Header from './components/M5Header';
import M5Opportunity from './components/M5Opportunity';
import styles from './sass/M5.module.scss';

const M5 = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    m5: {
      loading,
      segmentsList,
      segmentId,
      opportunityList
    },
    project: { name: projectName },
    general: { meaningsList }
  } = useSelector(({ m5, project, general }) => ({ m5, project, general }), shallowEqual);

  const breadcrumbs = useMemo(() => {
    return getBreadcrumbs(projectName, `/${id}/dashboard`, 'Opportunities');
  }, [projectName, id]);

  const switchPages = useMemo(() => {
    return getSwitchPages(`/${id}/m4`, `/${id}/m6`);
  }, [id]);

  useEffect(() => {
    batch(() => {
      dispatch(getSegmentListAction(id));
      dispatch(getOpportunityListAction(id));
      dispatch(getMeaningListAction());
    });
    return () => {
      dispatch(cleanM5());
    };
  }, []);

  const segmentList = useMemo(() => {
    const allSegment = {
      name: 'All',
      id: 0
    };
    return [allSegment, ...segmentsList];
  }, [segmentsList]);

  const handleChangeSegment = useCallback((idSegment) => {
    dispatch(getSegmentAction(id, idSegment));
  }, []);

  const handleUpdateOffering = useCallback((idOpportunity, values, closePlaceholder) => {
    if (!values.id) {
      dispatch(addOfferingAction(id, idOpportunity, values.title, closePlaceholder));
    } else dispatch(updateOfferingAction(idOpportunity, values, id, closePlaceholder));
  }, []);

  const handleDeleteOffering = useCallback((idOpportunity, idOffering, type) => {
    dispatch(deleteOfferingAction(idOpportunity, idOffering, type));
  }, []);

  const handleUpdateOpportunity = useCallback((projectId, opportunity) => {
    if (opportunity?.meaningId) {
      dispatch(updateOpportunityMeaningAction(projectId, opportunity));
    } else {
      dispatch(updateOpportunityAction(projectId, opportunity));
    }
  }, []);

  const handleDeleteOpportunity = useCallback((idOpportunity) => {
    dispatch(deleteOpportunityAction(id, idOpportunity));
  }, []);

  return (
    <Page
      pageContainer={styles.container}
      breadcrumbs={breadcrumbs}
      switchPages={switchPages}
      loader={loading}
    >
      <div className={styles.contentContainer}>
        <M5Header
          segmentList={segmentList}
          segmentId={segmentId}
          onChangeSegment={handleChangeSegment}
        />
        {Object.keys(opportunityList).some((key) => !!opportunityList[key].length)
          ? Object.keys(opportunityList).map((key) => (
            opportunityList[key].map(({
              id: idOpportunity,
              name,
              value,
              offerings,
              style,
              meaning_id: meaningId
            }) => {
              return (
                <M5Opportunity
                  key={idOpportunity}
                  id={idOpportunity}
                  title={key}
                  name={name}
                  value={value}
                  offerings={offerings}
                  onUpdateOffering={(values, closePlaceholder) => {
                    handleUpdateOffering(idOpportunity, values, closePlaceholder);
                  }}
                  onDeleteOffering={(idOffering) => handleDeleteOffering(idOpportunity, idOffering, key)}
                  onUpdateOpportunity={(values) => handleUpdateOpportunity(id, values)}
                  style={style}
                  onDeleteOpportunity={() => handleDeleteOpportunity(idOpportunity)}
                  meaningsList={meaningsList}
                  meaningId={meaningId}
                />
              );
            })
          )) : <NoData id={id} projectName={projectName} />}
      </div>
    </Page>
  );
};

export default M5;
