import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { convertFromIdToName } from '../../../../utils/helpers';
import Input from '../../../../components/Input/Input';
import SelectInput from '../../../../components/SelectInput/SelectInput';
import Button from '../../../../components/Button/Button';
import CustomDatePicker from '../../../../components/CustomDatePicker/CustomDatePicker';
import styles from '../sass/AdminTrends.module.scss';
import 'react-datepicker/dist/react-datepicker.css';

const AdminTrendFilter = ({
  categoryList,
  industriesList,
  onChange,
  trend,
  category,
  industry,
  start,
  end,
  onChangeCategoryIndustry,
  onChangeDate,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <form
      className={styles.filterContainer}
      onSubmit={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      <Input
        onChange={onChange}
        name="name"
        title={t('searchByTrend')}
        style={styles.filter}
        value={trend}
      />
      <SelectInput
        onChange={(id) => onChangeCategoryIndustry(id, 'industry')}
        name="industry"
        title={t('industry')}
        styleContainer={`${styles.filter} ${styles.selectInput}`}
        styleDropdownContent={styles.filterDropDown}
        values={industriesList}
        value={convertFromIdToName(industriesList, industry)}
      />
      <SelectInput
        onChange={(id) => onChangeCategoryIndustry(id, 'category')}
        name="category"
        title={t('category')}
        styleContainer={`${styles.filter} ${styles.selectInput}`}
        styleDropdownContent={styles.filterDropDown}
        values={categoryList}
        value={convertFromIdToName(categoryList, category)}
      />
      <CustomDatePicker
        startDate={start}
        endDate={end}
        onChangeDate={onChangeDate}
      />
      <Button
        text={t('filter')}
        style={styles.buttonFilterContainer}
        styleButton={styles.buttonFilter}
        type="submit"
      />
    </form>
  );
};

AdminTrendFilter.propTypes = {
  categoryList: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
  industriesList: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number, name: PropTypes.string })),
  onChange: PropTypes.func.isRequired,
  onChangeCategoryIndustry: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  trend: PropTypes.string.isRequired,
  category: PropTypes.number,
  industry: PropTypes.number,
  start: PropTypes.string,
  end: PropTypes.string,
};

AdminTrendFilter.defaultProps = {
  categoryList: [],
  industriesList: [],
  category: null,
  industry: null,
  start: null,
  end: null,
};

export default AdminTrendFilter;
