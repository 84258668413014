import {
  SET_GENERAL_FAILED,
  SET_GENERAL_REQUEST,
  SET_GENERAL_INDUSTRIES_LIST_SUCCESS,
  SET_GENERAL_MEANING_LIST_SUCCESS,
  SET_GENERAL_CATEGORY_LIST_SUCCESS,
  SET_USER_ID_FOW_SWITCH_SUCCESS,
  CLEAN_GENERAL,
  SET_EXPORT_DATA_SUCCESS,
  SET_CURRENCY_LIST_SUCCESS,
  SET_FROM_PATH
} from './types';

const initialState = {
  industriesList: [],
  meaningsList: [],
  categoryList: [],
  currencyList: [],
  userIdForSwitch: null,
  fromPath: null,
  loading: false,
  error: null,
};

export default function general(state = initialState, action) {
  switch (action.type) {
    case SET_GENERAL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_GENERAL_INDUSTRIES_LIST_SUCCESS:
      return {
        ...state,
        industriesList: action.data,
        loading: false,
        error: null,
      };
    case SET_GENERAL_MEANING_LIST_SUCCESS:
      return {
        ...state,
        meaningsList: action.data,
        loading: false,
        error: null,
      };
    case SET_GENERAL_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        categoryList: action.data,
        loading: false,
        error: null,
      };
    case SET_USER_ID_FOW_SWITCH_SUCCESS:
      return {
        ...state,
        userIdForSwitch: action.data,
      };
    case SET_EXPORT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case SET_CURRENCY_LIST_SUCCESS:
      return {
        ...state,
        currencyList: action.data,
        loading: false,
        error: null,
      };
    case SET_FROM_PATH:
      return {
        ...state,
        fromPath: action.data,
      };
    case SET_GENERAL_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case CLEAN_GENERAL:
      return initialState;
    default:
      return state;
  }
}
