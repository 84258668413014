import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { convertFromIdToName, deleteSpacesInString } from '../../../utils/helpers';
import useOnBlur from '../../../hooks/useOnBlur';
import rateOptions from '../../../constants/rateOptions';
import Input from '../../../components/Input/Input';
import SelectInput from '../../../components/SelectInput/SelectInput';
import TitleWithTip from '../../../components/TitleWithTip/TitleWithTip';
import styles from '../sass/O5.module.scss';

const O5ItemHeader = ({
  id,
  name,
  value,
  timeIntervalId,
  timeIntervals,
  onChangeOfferingRevenues,
  onDeleteOfferingRevenues
}) => {
  const { t } = useTranslation();
  const [offeringRevenuesName, setOfferingRevenuesName] = useState(name);

  const handleChangeOfferingRevenuesName = useCallback((e) => {
    const text = deleteSpacesInString(e.target.value);
    setOfferingRevenuesName(text);
  }, []);

  const handleBlurOfferingRevenuesName = useOnBlur(
    name,
    offeringRevenuesName,
    () => onChangeOfferingRevenues({
      offeringRevenueId: id,
      name: offeringRevenuesName,
      refresh: false,
    }),
    () => onDeleteOfferingRevenues(id),
  );

  return (
    <div className={styles.headerContainer}>
      <div className={styles.offering}>
        <Input
          value={offeringRevenuesName}
          onChange={handleChangeOfferingRevenuesName}
          onBlur={handleBlurOfferingRevenuesName}
          style={styles.inputContainer}
          styleLabel={styles.label}
          label={(
            <TitleWithTip
              text={t('offering')}
              styleContainer={`${styles.titleContainer} ${styles.titleWithOutTip}`}
              styleTitle={styles.title}
            />
          )}
        />
        <SelectInput
          value={value.toString()}
          onChange={(rate) => onChangeOfferingRevenues({
            offeringRevenueId: id,
            value: +rate,
            refresh: true,
          })}
          values={rateOptions}
          styleContainer={styles.selectContainer}
          checkId={false}
        />
      </div>
      <div className={styles.currency}>
        <SelectInput
          value={convertFromIdToName(timeIntervals, timeIntervalId)}
          onChange={(idTimeIntervals) => onChangeOfferingRevenues({
            offeringRevenueId: id,
            timeIntervalId: idTimeIntervals,
          })}
          values={timeIntervals}
          styleContainer={styles.selectContainer}
          styleLabel={styles.label}
          label={t('timeInterval')}
        />
      </div>
      <div className={styles.empty} />
    </div>
  );
};

O5ItemHeader.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  timeIntervalId: PropTypes.number.isRequired,
  timeIntervals: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  onChangeOfferingRevenues: PropTypes.func.isRequired,
  onDeleteOfferingRevenues: PropTypes.func.isRequired,
};

export default O5ItemHeader;
