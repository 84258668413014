import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CustomDatePicker from '../../../../components/CustomDatePicker/CustomDatePicker';
import Button from '../../../../components/Button/Button';
import Input from '../../../../components/Input/Input';
import styles from '../sass/AdminScenarios.module.scss';

const AdminScenariosFilter = ({
  start,
  end,
  name,
  onChangeDate,
  onChange,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <form
      className={styles.filterContainer}
      onSubmit={(e) => {
        e.preventDefault();
        onClick();
      }}
    >
      <Input
        onChange={onChange}
        name="name"
        title={t('searchByScenario')}
        style={styles.filter}
        value={name}
      />
      <CustomDatePicker
        startDate={start}
        endDate={end}
        onChangeDate={onChangeDate}
      />
      <Button
        text={t('filter')}
        style={styles.buttonFilterContainer}
        styleButton={styles.buttonFilter}
        type="submit"
      />
    </form>
  );
};

AdminScenariosFilter.propTypes = {
  start: PropTypes.string,
  end: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChangeDate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

AdminScenariosFilter.defaultProps = {
  start: null,
  end: null,
};

export default AdminScenariosFilter;
