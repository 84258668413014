import React, { useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  batch, shallowEqual, useDispatch, useSelector
} from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getBreadcrumbs, getSwitchPages } from '../../utils/helpers';
import {
  changePartnershipAction,
  changePriorityAction,
  cleanO3,
  createPartnershipAction,
  deletePartnershipAction,
  deletePriorityAction,
  getPrioritiesAction,
  getStakeholdersListAction,
  updatePriorityMeaningAction
} from '../../store/o3/actions';
import { getMeaningListAction } from '../../store/general/actions';
import Page from '../../layout/Page/Page';
import NoData from '../../components/NoData/NoData';
import O3Item from './components/O3Item';
import styles from './sass/O3.module.scss';

const O3 = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    o3: {
      priorities, stakeholdersList, loading
    },
    project: { name: projectName },
    general: { meaningsList }
  } = useSelector(({ o3, project, general }) => ({ o3, project, general }), shallowEqual);

  const breadcrumbs = useMemo(() => (
    getBreadcrumbs(projectName, `/${id}/dashboard`, 'Partnerships')
  ), [projectName, id]);

  const switchPages = useMemo(() => (
    getSwitchPages(`/${id}/o2`, `/${id}/o4`)
  ), [id]);

  useEffect(() => {
    batch(() => {
      dispatch(getStakeholdersListAction(id));
      dispatch(getPrioritiesAction(id));
      dispatch(getMeaningListAction());
    });
    return () => {
      dispatch(cleanO3());
    };
  }, []);

  const handleChangePriority = useCallback((priorityId, values) => {
    if (values?.meaningId) {
      dispatch(updatePriorityMeaningAction(id, values));
    } else {
      dispatch(changePriorityAction(id, priorityId, values));
    }
  }, []);

  const handleDeletePriority = useCallback((priorityId) => {
    dispatch(deletePriorityAction(id, priorityId));
  }, []);

  const handleChangePartnership = useCallback((idPriority, partnership, key, closeStakeholder) => {
    if (partnership.id) {
      dispatch(changePartnershipAction(idPriority, partnership, id, closeStakeholder));
    } else dispatch(createPartnershipAction(id, idPriority, partnership, key, closeStakeholder));
  }, []);

  const handleDeletePartnership = useCallback((idPriority, partnershipId, partnershipType, key) => {
    dispatch(deletePartnershipAction(idPriority, partnershipId, partnershipType, key));
  }, []);

  return (
    <Page
      pageContainer={styles.container}
      breadcrumbs={breadcrumbs}
      switchPages={switchPages}
      loader={loading}
    >
      <div className={styles.contentContainer}>
        {Object.keys(priorities).some((key) => !!priorities[key].length) ? Object.keys(priorities).map((key) => (
          priorities[key].map(({
            id: idPriority,
            driver_value: priorityValue,
            name,
            style,
            potential_partnership: potentialPartnership,
            meaning_id: meaningId
          }) => (
            <O3Item
              title={key}
              key={idPriority}
              value={priorityValue}
              name={name}
              style={style}
              idPriority={idPriority}
              potentialPartnership={potentialPartnership}
              onChangePriority={(values) => handleChangePriority(idPriority, values)}
              onDeletePriority={() => handleDeletePriority(idPriority)}
              stakeholdersList={stakeholdersList}
              meaningsList={meaningsList}
              meaningId={meaningId}
              onChangePartnership={(partnership, closePlaceholder) => {
                handleChangePartnership(idPriority, partnership, key, closePlaceholder);
              }}
              onDeletePartnership={(partnershipId, partnershipType) => {
                handleDeletePartnership(idPriority, partnershipId, partnershipType, key);
              }}
            />
          ))
        )) : <NoData id={id} projectName={projectName} page="o2" textButton={t('AddOpportunityThreat')} />}
      </div>
    </Page>
  );
};

export default O3;
