import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  batch, shallowEqual, useDispatch, useSelector
} from 'react-redux';
import { driversTypeStakeholder } from '../../constants/drivers';
import { triggersTypeStakeholder } from '../../constants/triggers';
import { getMeaningListAction } from '../../store/general/actions';
import {
  cleanStakeholders,
  deleteStakeholdersLinkAction,
  getStakeholdersList,
  getLastStakeholdersWorkSheetAction,
  updateStakeholdersDriverAction,
  updateStakeholdersLinkAction,
  updateStakeholdersMeaningAction,
  updateStakeholdersTriggerAction,
  updateStakeholdersWorkSheetNameAction,
  uploadStakeholdersPhotoAction,
  changeStakeholderAction,
  createStakeholderAction,
  deleteStakeholderAction,
  getStakeholdersWorkSheetAction,
  loadMoreStakeholdersProfilesAction,
  setStakeholdersRankAction
} from '../../store/stakeholders/actions';
import {
  convertFromIdToNameChildren, getBreadcrumbs, getPrevLinkAndNextLink
} from '../../utils/helpers';
import useUpload from '../../hooks/useUpload';
import NewAction from '../../layout/Header/components/NewAction';
import Page from '../../layout/Page/Page';
import PhotoWithReference from '../../components/PhotoWithReference/PhotoWithReference';
import StakeholderHeader from '../../components/StakeholderHeader/StakeholderHeader';
import Tip from '../../components/Tip/Tip';
import ModalDelete from '../../components/ModalDelete/ModalDelete';
import Triggers from '../../components/Triggers/Triggers';
import CoreMeaning from '../../components/CoreMeaning/CoreMeaning';
import VideoModal from '../../components/VideoModal/VideoModal';
import LinkModal from '../../components/LinkModal/LinkModal';
import Drivers from '../../components/Drivers/Drivers';
import StakeholderTipContent from './components/StakeholderTipContent';
import StakeholderTipLinkContent from './components/StakeholderTipLinkContent';
import StakeholderHeaderTipContent from './components/StakeholderHeaderTipContent';
import StakeholdersProfiles from './components/StakeholdersProfiles';
import StakeholdersRank from './components/StakeholdersRank';
import styles from './sass/Stakeholders.module.scss';

const Stakeholders = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const initialType = +searchParams.get('type') || '';

  const {
    stakeholders: {
      loading,
      id: idWorkSheet,
      name,
      avatars,
      meanings,
      drivers,
      triggers,
      links,
      stakeholdersList,
      stakeholderId,
      profiles,
      nextLinkProfiles,
      rank
    },
    general: { meaningsList },
    project: { name: projectName }
  } = useSelector(({ stakeholders, general, project }) => ({ stakeholders, general, project }), shallowEqual);

  const [selectedFile, setSelectedFile] = useState(null);
  const [linkModal, setLinkModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [deleteLinkModal, setDeleteLinkModal] = useState(null);

  const breadcrumbs = useMemo(() => {
    const title = convertFromIdToNameChildren(stakeholdersList, stakeholderId);
    return getBreadcrumbs(projectName, `/${id}/dashboard`, title);
  }, [projectName, id, stakeholdersList, stakeholderId]);

  const switchPages = useMemo(() => {
    const list = stakeholdersList
      .flatMap(({ children }) => children)
      .filter(({ disable }) => !disable);
    return getPrevLinkAndNextLink(list, id, stakeholderId, 'stakeholders');
  }, [stakeholdersList, stakeholderId, id]);

  useEffect(() => {
    batch(() => {
      dispatch(getMeaningListAction());
      dispatch(getStakeholdersList(id));
    });
    return () => {
      dispatch(cleanStakeholders());
    };
  }, []);

  useEffect(() => {
    dispatch(getLastStakeholdersWorkSheetAction(id, initialType));
  }, [initialType]);

  const handleUpdateStakeholdersWorkSheetName = useCallback((value) => {
    dispatch(updateStakeholdersWorkSheetNameAction(value));
  }, []);

  const handleUpdateStakeholdersWorkSheetRank = useCallback((value) => {
    dispatch(setStakeholdersRankAction(value));
  }, []);

  const handleUpdatePhoto = useCallback((data) => {
    dispatch(uploadStakeholdersPhotoAction(data));
    setSelectedFile(null);
  }, []);

  const handleFileSelect = useCallback((event) => {
    setSelectedFile(event.target.files[0]);
  }, []);

  const handleVisibleLinkModal = useCallback((value) => {
    setLinkModal(value);
  }, []);

  const handleChangeLink = useCallback((values) => {
    dispatch(updateStakeholdersLinkAction(values));
    handleVisibleLinkModal(false);
  }, []);

  const handleDeleteLink = useCallback((values) => {
    dispatch(deleteStakeholdersLinkAction(values));
    setDeleteLinkModal(null);
  }, []);

  const handleOpenLink = useCallback((url) => {
    window.open(url, '_blank');
  }, []);

  const handleChangeMeaning = useCallback((meaningItem) => {
    dispatch(updateStakeholdersMeaningAction(meaningItem));
  }, []);

  const handleChangeDriver = useCallback((values, type) => {
    dispatch(updateStakeholdersDriverAction(values, type));
  }, []);

  const handleChangeTrigger = useCallback((values, type) => {
    dispatch(updateStakeholdersTriggerAction(values, type));
  }, []);

  const handleChangeStakeholderType = useCallback((value) => {
    if (value !== stakeholderId) dispatch(changeStakeholderAction(value, setSearchParams));
  }, [stakeholderId]);

  const handleCreateStakeholder = useCallback(() => {
    dispatch(createStakeholderAction(id, stakeholderId));
  }, [stakeholderId, profiles]);

  const deleteStakeholder = useCallback(() => {
    dispatch(deleteStakeholderAction(id));
    setDeleteModal(false);
  }, []);

  const getStakeholder = useCallback((value) => {
    dispatch(getStakeholdersWorkSheetAction(value));
  }, []);

  const profilesList = useMemo(() => {
    return profiles.filter((profile) => profile.id !== idWorkSheet);
  }, [profiles, idWorkSheet]);

  const loadMoreProfiles = useCallback(() => {
    dispatch(loadMoreStakeholdersProfilesAction());
  }, []);

  const handleOpenVideo = useCallback((url) => {
    setVideoUrl(url);
  }, []);

  const { loadingFile } = useUpload(selectedFile, (data) => handleUpdatePhoto(data));

  return (
    <Page
      pageContainer={styles.container}
      breadcrumbs={breadcrumbs}
      switchPages={switchPages}
      newAction={<NewAction onClick={handleCreateStakeholder} text={t('newStakeholder')} />}
      loader={loading}
    >
      <div className={styles.content}>
        <StakeholderHeader
          initialName={name}
          onChangeName={handleUpdateStakeholdersWorkSheetName}
          tipContent={<StakeholderHeaderTipContent />}
          stakeholderId={stakeholderId}
          stakeholdersList={stakeholdersList}
          onChangeStakeholderType={handleChangeStakeholderType}
        />
        <Tip styleContainer={styles.tipStakeholder}>
          <StakeholderTipContent onOpenVideo={handleOpenVideo} />
        </Tip>
        <div className={styles.coreMeaningContainer}>
          <PhotoWithReference
            avatar={avatars?.url}
            loadingFile={loadingFile}
            onUpload={handleFileSelect}
            link={links[0]}
            onOpenModal={() => handleVisibleLinkModal(true)}
            onDelete={() => setDeleteLinkModal(links[0])}
            onOpen={handleOpenLink}
            tipContent={<StakeholderTipLinkContent />}
          />
          <CoreMeaning
            meaningsList={meaningsList}
            meanings={meanings}
            onChange={handleChangeMeaning}
          />
        </div>

        <Drivers
          drivers={drivers}
          onChange={handleChangeDriver}
          driversTypes={driversTypeStakeholder}
          maxLength={250}
          placeholder={t('driverPlaceholder')}
        />

        <Triggers
          triggers={triggers}
          onChange={handleChangeTrigger}
          triggersTypes={triggersTypeStakeholder}
          onDeleteWorksheet={() => setDeleteModal(true)}
        />

        <StakeholdersRank
          onChange={handleUpdateStakeholdersWorkSheetRank}
          value={rank ? rank.toString() : '0'}
        />

        <StakeholdersProfiles
          profiles={profilesList}
          idWorkSheet={idWorkSheet}
          nextLinkProfiles={nextLinkProfiles}
          onClick={getStakeholder}
          onLoadMore={loadMoreProfiles}
          title={convertFromIdToNameChildren(stakeholdersList, stakeholderId)}
        />
      </div>

      {linkModal && (
        <LinkModal
          link={links[0]}
          onClose={() => handleVisibleLinkModal(false)}
          onSave={handleChangeLink}
        />
      )}

      {deleteModal && (
        <ModalDelete
          onDelete={deleteStakeholder}
          onClose={() => setDeleteModal(false)}
          text={t('stakeholder')}
        />
      )}

      {!!videoUrl && (
        <VideoModal url={videoUrl} onClose={() => setVideoUrl(null)} />
      )}

      {!!deleteLinkModal && (
        <ModalDelete
          onClose={() => setDeleteLinkModal(null)}
          onDelete={() => handleDeleteLink(deleteLinkModal)}
          text={t('link')}
        />
      )}
    </Page>
  );
};

export default Stakeholders;
