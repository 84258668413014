import {
  CLEAN_O8, SET_O8_FAILED, SET_O8_MISSION_STATEMENT_SUCCESS, SET_O8_REQUEST 
} from './types';
import instance from '../../services/axios';
import {
  MISSION_STATEMENT_SHOW_URL, MISSION_STATEMENT_URL, POST, PUT 
} from '../../constants/api';
import notify from '../../utils/notify';

export const setO8Request = () => ({ type: SET_O8_REQUEST });
export const setO8Failed = (error) => ({ type: SET_O8_FAILED, error });
export const cleanO8 = () => ({ type: CLEAN_O8 });

export const setMissionStatement = (description, id) => ({ type: SET_O8_MISSION_STATEMENT_SUCCESS, description, id });

export const getMissionStatementAction = (id) => {
  return (dispatch) => {
    dispatch(setO8Request());
    instance.get(`${MISSION_STATEMENT_SHOW_URL}/${id}`)
      .then(({ data: { data } }) => {
        const description = data?.description || '';
        const missionStatementId = data?.id || 0;
        dispatch(setMissionStatement(description, missionStatementId));
      })
      .catch(({ response: { data } }) => {
        dispatch(setO8Failed(data));
        notify('error', data.message);
      });
  };
};

export const updateMissionStatementAction = (id, missionStatementId, description) => {
  return (dispatch) => {
    dispatch(setO8Request());
    const dataObj = {
      project_id: !missionStatementId ? +id : undefined,
      description
    };
    const method = missionStatementId ? PUT : POST;
    instance[method](`${MISSION_STATEMENT_URL}/${missionStatementId || ''}`, dataObj)
      .then(({ data: { data } }) => {
        dispatch(setMissionStatement(data.description, data.id));
      })
      .catch(({ response: { data } }) => {
        dispatch(setO8Failed(data));
        notify('error', data.message);
      });
  };
};

export const deleteMissionStatementAction = (id) => {
  return (dispatch) => {
    dispatch(setO8Request());
    instance.delete(`${MISSION_STATEMENT_URL}/${id}`)
      .then(() => {
        dispatch(setMissionStatement('', 0));
      })
      .catch(({ response: { data } }) => {
        dispatch(setO8Failed(data));
        notify('error', data.message);
      });
  };
};
