import React, { useMemo, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  changeO1OfferingAction,
  changeO1OperationalPriorityAction,
  cleanO1,
  deleteOfferingAction,
  deleteOperationalPriorityAction,
  getO1Offerings
} from '../../store/o1/actions';
import { EMPTY_O1_ITEM } from '../../constants/general';
import { getBreadcrumbs, getSwitchPages } from '../../utils/helpers';
import Page from '../../layout/Page/Page';
import NoData from '../../components/NoData/NoData';
import O1Header from './components/O1Header';
import O1Item from './components/O1Item';
import styles from './sass/O1.module.scss';

const O1 = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    o1: { loading, offerings },
    project: { name: projectName }
  } = useSelector(({ o1, project }) => ({ o1, project }), shallowEqual);

  const breadcrumbs = useMemo(() => (
    getBreadcrumbs(projectName, `/${id}/dashboard`, 'Capabilities')
  ), [projectName, id]);

  const switchPages = useMemo(() => (
    getSwitchPages(`/${id}/m7`, `/${id}/o2`)
  ), [id]);

  useEffect(() => {
    dispatch(getO1Offerings(id));
    return () => {
      dispatch(cleanO1());
    };
  }, []);

  const handleChangeOffering = useCallback((idOffering, values) => {
    dispatch(changeO1OfferingAction(id, idOffering, values));
  }, []);

  const handleDeleteOffering = useCallback((idOffering) => {
    dispatch(deleteOfferingAction(id, idOffering));
  }, []);

  const handleChangeCapabilities = useCallback((idOffering, idOperational, values, closePlaceholder) => {
    dispatch(changeO1OperationalPriorityAction(idOffering, idOperational, values, closePlaceholder));
  }, []);

  const handleDeleteCapabilities = useCallback((idOffering, idOperational) => {
    dispatch(deleteOperationalPriorityAction(idOffering, idOperational));
  }, []);

  return (
    <Page
      pageContainer={styles.container}
      breadcrumbs={breadcrumbs}
      switchPages={switchPages}
      loader={loading}
    >
      <div className={styles.contentContainer}>
        {offerings.length ? (
          <>
            <O1Header />
            {offerings.map(({
              id: idOffering,
              title,
              value,
              operational_priorities: operationalPriorities
            }) => (
              <O1Item
                key={idOffering}
                title={title}
                value={value}
                operationalPriorities={operationalPriorities.length ? operationalPriorities : [EMPTY_O1_ITEM]}
                onChangeOffering={(values) => handleChangeOffering(idOffering, values)}
                onChangeCapabilities={(values, operationalPrioritiesId, closePlaceholder) => {
                  handleChangeCapabilities(idOffering, operationalPrioritiesId, values, closePlaceholder);
                }}
                onDeleteOffering={() => handleDeleteOffering(idOffering)}
                onDeleteCapabilities={(operationalPrioritiesId) => {
                  handleDeleteCapabilities(idOffering, operationalPrioritiesId);
                }}
              />
            ))}
          </>
        ) : <NoData id={id} projectName={projectName} />}
      </div>
    </Page>
  );
};

export default O1;
