import React from 'react';
import PropTypes from 'prop-types';
import availableSegment from '../../../constants/availableM2';
import M2AvailableItem from './M2AvailableItem';
import styles from '../sass/M2.module.scss';

const M2Available = ({ calculate, onChangeAvailable }) => {
  return (
    <div className={styles.availableContainer}>
      {Object.keys(availableSegment).map((item) => {
        return (
          <M2AvailableItem
            key={item}
            calculate={calculate || undefined}
            type={availableSegment[item].type}
            title={availableSegment[item].title}
            isPercent={availableSegment[item].percent}
            onChangeAvailable={onChangeAvailable}
            tip={availableSegment[item].tip}
          />
        );
      })}
    </div>
  );
};

M2Available.propTypes = {
  calculate: PropTypes.shape({
    sam_amount: PropTypes.number,
    sam_currency: PropTypes.string,
    sam_percentage: PropTypes.number,
    sam_qty: PropTypes.number,
    som_amount: PropTypes.number,
    som_currency: PropTypes.string,
    som_percentage: PropTypes.number,
    som_qty: PropTypes.number,
    tam_amount: PropTypes.number,
    tam_currency: PropTypes.string,
    tam_qty: PropTypes.number,
  }),
  onChangeAvailable: PropTypes.func.isRequired
};

M2Available.defaultProps = {
  calculate: null
};

export default M2Available;
