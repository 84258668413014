import React from 'react';
import PropTypes from 'prop-types';
import Photo from '../../../components/Photo/Photo';
import TitleWithTip from '../../../components/TitleWithTip/TitleWithTip';
import M2Drivers from './M2Drivers';
import M2Meaning from './M2Meaning';
import M2Profiles from './M2Profiles';
import M2Available from './M2Available';
import styles from '../sass/M2.module.scss';
import M2TipSegmentContent from './M2TipSegmentContent';

const M2Item = ({
  segmentName,
  drivers,
  meanings,
  meaningsList,
  avatar,
  onChangeMeaning,
  onChangeDriver,
  detailedProfiles,
  onLoadMore,
  idProject,
  calculate,
  onChangeAvailable,
  onCreateCustomer,
  onDeleteCustomerDriver,
}) => {
  return (
    <div className={styles.M2ItemContainer}>
      <TitleWithTip
        text={segmentName}
        styleContainer={styles.titleContainer}
        styleTitle={styles.title}
      >
        <M2TipSegmentContent />
      </TitleWithTip>
      <div className={styles.content}>
        <div className={styles.avatarContainer}>
          <Photo url={avatar} />
        </div>
        <M2Drivers
          drivers={drivers}
          onChange={onChangeDriver}
          onDeleteCustomerDriver={onDeleteCustomerDriver}
        />
        <M2Meaning
          meanings={meanings}
          meaningsList={meaningsList}
          onChange={onChangeMeaning}
        />
        <M2Profiles
          detailedProfiles={detailedProfiles}
          onLoadMore={onLoadMore}
          idProject={idProject}
          onCreateCustomer={onCreateCustomer}
        />
      </div>
      <M2Available
        calculate={calculate}
        onChangeAvailable={onChangeAvailable}
      />
    </div>
  );
};

M2Item.propTypes = {
  segmentName: PropTypes.string.isRequired,
  drivers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.number,
    worksheet_id: PropTypes.number
  })),
  meanings: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    worksheet_id: PropTypes.number,
    value: PropTypes.number,
    description: PropTypes.string
  })),
  meaningsList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  avatar: PropTypes.string,
  onChangeMeaning: PropTypes.func.isRequired,
  onChangeDriver: PropTypes.func.isRequired,
  detailedProfiles: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      full_name: PropTypes.string
    })),
    links: PropTypes.shape({
      next: PropTypes.string
    })
  }).isRequired,
  onLoadMore: PropTypes.func.isRequired,
  idProject: PropTypes.string.isRequired,
  calculate: PropTypes.shape({
    sam_amount: PropTypes.number,
    sam_currency: PropTypes.string,
    sam_percentage: PropTypes.number,
    sam_qty: PropTypes.number,
    som_amount: PropTypes.number,
    som_currency: PropTypes.string,
    som_percentage: PropTypes.number,
    som_qty: PropTypes.number,
    tam_amount: PropTypes.number,
    tam_currency: PropTypes.string,
    tam_qty: PropTypes.number,
  }),
  onChangeAvailable: PropTypes.func.isRequired,
  onCreateCustomer: PropTypes.func.isRequired,
  onDeleteCustomerDriver: PropTypes.func.isRequired,
};

M2Item.defaultProps = {
  drivers: [],
  meanings: [],
  meaningsList: [],
  avatar: '',
  calculate: null
};

export default M2Item;
