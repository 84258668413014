import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button/Button';
import styles from '../sass/AdminEditTrend.module.scss';

const AdminEditTrendButtons = ({ onGoBack, handleSubmit }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.buttonsContainer}>
      <Button
        text={t('cancel')}
        style={styles.buttonContainer}
        styleButton={styles.button}
        onClick={onGoBack}
      />
      <Button
        text={t('save')}
        style={styles.buttonContainer}
        styleButton={styles.button}
        onClick={handleSubmit}
        type="submit"
      />
    </div>
  );
};

AdminEditTrendButtons.propTypes = {
  onGoBack: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default AdminEditTrendButtons;
