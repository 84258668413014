import React from 'react';
import { useTranslation } from 'react-i18next';

const M4TipPositionContent = () => {
  const { t } = useTranslation();
  return (
    <>
      <p>
        {t('tipM4Position1')}
      </p>
      <p>{t('tipM4Position2')}</p>
    </>
  );
};

export default M4TipPositionContent;
