import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/RadioButton.module.scss';

const RadioButton = ({
  label,
  name,
  checked,
  onChange,
  disabled,
  styleContainer,
  id
}) => {
  return (
    <div className={`${styles.container} ${styleContainer}`}>
      <input
        id={id}
        type="radio"
        name={name}
        onChange={onChange}
        checked={checked}
        value={name}
        disabled={disabled}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

RadioButton.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  styleContainer: PropTypes.string,
  id: PropTypes.string
};

RadioButton.defaultProps = {
  name: '',
  disabled: false,
  styleContainer: '',
  id: ''
};

export default RadioButton;
