import React from 'react';
import { useTranslation } from 'react-i18next';

const TrendsHeaderTipContent = () => {
  const { t } = useTranslation();
  return (
    <>
      <p>
        {t('tipTrendHeader1')}
      </p>
      <p>
        {t('tipTrendHeader2')}
      </p>
    </>
  );
};

export default TrendsHeaderTipContent;
