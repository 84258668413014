import {
  CLEAN_O4,
  SET_O4_FAILED,
  SET_O4_PARTNERSHIP_KEY_LIST_SUCCESS,
  SET_O4_REQUEST,
  SET_O4_PARTNERSHIP_KEY_SUCCESS
} from './types';
import instance from '../../services/axios';
import {
  BUSINESS_MODEL_LIST_URL,
  POTENTIAL_CUSTOMER_OFFERING_URL,
  RESOURCES_URL,
  WORK_SHEET_URL
} from '../../constants/api';
import { PARTNERSHIP } from '../../constants/general';
import notify from '../../utils/notify';

export const setO4Request = () => ({ type: SET_O4_REQUEST });
export const setO4Failed = (error) => ({ type: SET_O4_FAILED, error });
export const cleanO4 = () => ({ type: CLEAN_O4 });

export const setPartnershipKeyList = (data) => ({ type: SET_O4_PARTNERSHIP_KEY_LIST_SUCCESS, data });

export const setPartnershipKey = (data, key) => ({ type: SET_O4_PARTNERSHIP_KEY_SUCCESS, data, key });

export const getPartnershipKeyListAction = (id, type) => {
  return (dispatch) => {
    dispatch(setO4Request());
    instance.get(`${BUSINESS_MODEL_LIST_URL}/${id}/${type || ''}`)
      .then(({ data: { data } }) => {
        if (!type) {
          dispatch(setPartnershipKeyList(data));
        } else dispatch(setPartnershipKey(data[type], type));
      })
      .catch(({ response: { data } }) => {
        dispatch(setO4Failed(data));
        notify('error', data.message);
      });
  };
};

export const createPartnershipKeyAction = (id, partnershipValues, type) => {
  return (dispatch, getState) => {
    const { o4 } = getState();
    const newPartnership = {
      project_id: +id,
      name: partnershipValues.name,
      value: partnershipValues.value,
      type
    };
    dispatch(setO4Request());
    instance.post(RESOURCES_URL, newPartnership)
      .then(({ data: { data } }) => {
        const copyPartnershipKey = [...o4[type], { ...data, value: 0 }];
        dispatch(setPartnershipKey(copyPartnershipKey, type));
      })
      .catch(({ response: { data } }) => {
        dispatch(setO4Failed(data));
        notify('error', data.message);
      });
  };
};

export const deletePartnershipKeyAction = (id, idPartnership, type) => {
  return (dispatch, getState) => {
    const { o4 } = getState();
    dispatch(setO4Request());
    const getUrl = () => {
      switch (type) {
        case PARTNERSHIP.OFFERINGS:
          return `${POTENTIAL_CUSTOMER_OFFERING_URL}/${idPartnership}`;
        case PARTNERSHIP.POTENTIAL:
          return `${WORK_SHEET_URL}/${idPartnership}`;
        case PARTNERSHIP.RESOURCE:
          return `${RESOURCES_URL}/${idPartnership}`;
        default:
          return PARTNERSHIP.OFFERINGS;
      }
    };
    instance.delete(getUrl())
      .then(() => {
        if (type === PARTNERSHIP.POTENTIAL || type === PARTNERSHIP.OFFERINGS) {
          dispatch(getPartnershipKeyListAction(id, type));
        } else {
          const copyPartnershipKey = o4[type].filter((item) => item.id !== idPartnership);
          dispatch(setPartnershipKey(copyPartnershipKey, type));
        }
      })
      .catch(({ response: { data } }) => {
        dispatch(setO4Failed(data));
        notify('error', data.message);
      });
  };
};

export const updatePartnershipKeyAction = (id, partnershipValues, type) => {
  return (dispatch, getState) => {
    const { o4 } = getState();
    const newData = type === PARTNERSHIP.OFFERINGS
      ? {
        title: partnershipValues.name,
        value: partnershipValues.value,
      }
      : {
        name: partnershipValues.name,
        value: partnershipValues.value,
        type
      };
    const getUrl = () => {
      switch (type) {
        case PARTNERSHIP.OFFERINGS:
          return `${POTENTIAL_CUSTOMER_OFFERING_URL}/${partnershipValues.id}`;
        case PARTNERSHIP.POTENTIAL:
          return `${WORK_SHEET_URL}/${partnershipValues.id}`;
        case PARTNERSHIP.RESOURCE:
          return `${RESOURCES_URL}/${partnershipValues.id}`;
        default:
          return PARTNERSHIP.OFFERINGS;
      }
    };
    dispatch(setO4Request());
    instance.put(getUrl(), newData)
      .then(({ data: { data } }) => {
        if (type === PARTNERSHIP.POTENTIAL || type === PARTNERSHIP.OFFERINGS) {
          dispatch(getPartnershipKeyListAction(id, type));
        } else {
          const copyPartnershipKey = o4[type].map((item) => (item.id === data.id ? data : item));
          dispatch(setPartnershipKey(copyPartnershipKey, type));
        }
      })
      .catch(({ response: { data } }) => {
        dispatch(setO4Failed(data));
        notify('error', data.message);
      });
  };
};
