import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { formatDateFromStringToISO, formatSelectedDateToString } from '../../utils/helpers';
import CustomDatePickerInput from './components/CustomDatePickerInput';
import styles from './sass/CustomDatePicker.module.scss';
import 'react-datepicker/dist/react-datepicker.css';

const CustomDatePicker = ({ startDate, endDate, onChangeDate }) => {
  const [startDatePicker, setStartDatePicker] = useState(formatDateFromStringToISO(startDate));
  const [endDatePicker, setEndDatePicker] = useState(formatDateFromStringToISO(endDate));

  const ref = useRef(null);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDatePicker(start);
    setEndDatePicker(end);
    onChangeDate(formatSelectedDateToString(start), formatSelectedDateToString(end));
  };

  const onClear = () => {
    setStartDatePicker(null);
    setEndDatePicker(null);
    onChangeDate(null, null);
  };

  return (
    <div className={styles.container}>
      <DatePicker
        selected={startDatePicker}
        onChange={onChange}
        startDate={startDatePicker}
        endDate={endDatePicker}
        selectsRange
        dateFormat="MM/dd/yyyy"
        customInput={<CustomDatePickerInput ref={ref} onClear={onClear} />}
      />
    </div>
  );
};

CustomDatePicker.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onChangeDate: PropTypes.func.isRequired,
};

CustomDatePicker.defaultProps = {
  startDate: null,
  endDate: null,
};

export default CustomDatePicker;
