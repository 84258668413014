import React from 'react';
import { useTranslation } from 'react-i18next';
import Tip from '../../../components/Tip/Tip';
import styles from '../sass/M3.module.scss';

const M3TipCompetitor = () => {
  const { t } = useTranslation();
  return (
    <Tip styleTipTextContainer={styles.tipTextContainer} styleTriangle={styles.triangle}>
      <p>{t('tip3Competitor')}</p>
    </Tip>
  );
};

export default M3TipCompetitor;
