import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import styles from './sass/ModalDelete.module.scss';

const ModalDelete = ({
  onClose, onDelete, text
}) => {
  const { t } = useTranslation();
  return (
    <Modal onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.subtitle}>
          {`${t('areYouSure')} ${text}?`}
        </div>
        <div className={styles.buttonContainer}>
          <Button
            text={t('no')}
            onClick={onClose}
            style={styles.button}
          />
          <Button
            text={t('yes')}
            onClick={onDelete}
            style={styles.button}
            styleButton={styles.yesButton}
          />
        </div>
      </div>
    </Modal>
  );
};

ModalDelete.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default ModalDelete;
