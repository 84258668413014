import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useParams } from 'react-router-dom';
import {
  batch, shallowEqual, useDispatch, useSelector
} from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getBreadcrumbs, getSwitchPages } from '../../utils/helpers';
import {
  getCompetitorDrivers,
  getDriversAndOrganizationAction,
  changeOwnOrganizationAction,
  getAddCompetitorAction,
  getEditCompetitorAction,
  deleteCompetitorAction,
  updateCompetitorsDriverAction,
  updateOwnOrganizationDriverAction,
  getUpdateTopMarketDriverAction,
  cleanO2,
  deleteTopMarketDriverAction,
  updateMeaningAction
} from '../../store/o2/actions';
import { getMeaningListAction } from '../../store/general/actions';
import Page from '../../layout/Page/Page';
import MarketDrivers from '../../components/MarketDrivers/MarketDrivers';
import Legend from '../../components/Legend/Legend';
import ModalConfirmDelete from '../../components/ModalConfirmDelete/ModalConfirmDelete';
import O2Header from './components/O2Header';
import O2TipTopMarket from './components/O2TipTopMarket';
import NoData from '../../components/NoData/NoData';
import styles from './sass/O2.module.scss';

const O2 = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    o2: {
      loading,
      topMarketDrivers,
      organizationDrivers,
      competitorsDrivers,
      ownOrganizationName,
      ownOrganizationOffering
    },
    project: { name: projectName },
    general: { meaningsList }
  } = useSelector(({ o2, project, general }) => ({ o2, project, general }), shallowEqual);

  const [addCompetitorModal, setAddCompetitorModal] = useState(false);
  const [competitor, setCompetitor] = useState(null);

  const breadcrumbs = useMemo(() => (
    getBreadcrumbs(projectName, `/${id}/dashboard`, 'Priorities')
  ), [projectName, id]);

  const switchPages = useMemo(() => (
    getSwitchPages(`/${id}/o1`, `/${id}/o3`)
  ), [id]);

  useEffect(() => {
    batch(() => {
      dispatch(getDriversAndOrganizationAction(id));
      dispatch(getCompetitorDrivers(id));
      dispatch(getMeaningListAction());
    });
    return () => {
      dispatch(cleanO2());
    };
  }, []);

  const handleChangeOwnOrganization = useCallback((name, offering) => {
    dispatch(changeOwnOrganizationAction(id, name, offering));
  }, []);

  const handleAddCompetitorModal = useCallback((value) => {
    if (!value && competitor) setCompetitor(null);
    setAddCompetitorModal(value);
  }, [competitor]);

  const handleAddCompetitor = useCallback((name, offering) => {
    dispatch(getAddCompetitorAction(id, name, offering));
  }, []);

  const handleEditCompetitor = useCallback((idCompetitor, newValue, newOffering) => {
    dispatch(getEditCompetitorAction(idCompetitor, newValue, newOffering));
  }, []);

  const handleDeleteCompetitor = useCallback((idCompetitor) => {
    dispatch(deleteCompetitorAction(idCompetitor, id));
  }, []);

  const handleSelectCompetitor = useCallback((values) => {
    setCompetitor(values);
    handleAddCompetitorModal(true);
  }, []);

  const handleUpdateCompetitorDrivers = useCallback((driver, competitorId) => {
    dispatch(updateCompetitorsDriverAction(driver, competitorId, id));
  }, []);

  const handleUpdateOwnOrganizationDrivers = useCallback((driver) => {
    dispatch(updateOwnOrganizationDriverAction(driver, id));
  }, []);

  const handleUpdateTopMarketDriver = useCallback((driver) => {
    if (driver?.meaningId) {
      dispatch(updateMeaningAction(id, driver));
    } else {
      dispatch(getUpdateTopMarketDriverAction(id, driver));
    }
  }, []);

  const handleDeleteTopMarketDriver = useCallback((driverId) => {
    dispatch(deleteTopMarketDriverAction(id, driverId));
  }, []);

  return (
    <Page
      pageContainer={styles.container}
      switchPages={switchPages}
      breadcrumbs={breadcrumbs}
      loader={loading}
    >
      {topMarketDrivers.length ? (
        <div className={styles.contentContainer}>
          <O2Header
            onAddCompetitor={() => handleAddCompetitorModal(true)}
            disabledAddCompetitor={competitorsDrivers.length > 7}
          />

          <MarketDrivers
            textHeader={t('topOperationalCapabilities')}
            topMarketDrivers={topMarketDrivers}
            organizationDrivers={organizationDrivers}
            competitorsDrivers={competitorsDrivers}
            onUpdate={handleUpdateCompetitorDrivers}
            onUpdateOwnOrganization={handleUpdateOwnOrganizationDrivers}
            onSelect={handleSelectCompetitor}
            onUpdateTopMarketDriver={handleUpdateTopMarketDriver}
            onChangeOwnOrganization={handleChangeOwnOrganization}
            ownOrganizationName={ownOrganizationName}
            ownOrganizationOffering={ownOrganizationOffering}
            tipTopMarket={<O2TipTopMarket />}
            onDeleteTopMarketDriver={handleDeleteTopMarketDriver}
            placeholderTopMarketDrivers={t('capabilityPlaceholder')}
            meaningsList={meaningsList}
          />

          <Legend />

          {addCompetitorModal && (
            <ModalConfirmDelete
              onClose={() => handleAddCompetitorModal(false)}
              onAdd={handleAddCompetitor}
              onEdit={handleEditCompetitor}
              onDelete={handleDeleteCompetitor}
              value={competitor}
              title={competitor ? t('editCompetitor') : t('addCompetitor')}
              text={t('competitor')}
              isCompetitor
              placeholder={t('competitorNamePlaceholder')}
            />
          )}
        </div>
      ) : <NoData id={id} projectName={projectName} />}
    </Page>
  );
};

export default O2;
