import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Avatar from '../Avatar/Avatar';
import styles from './sass/Photo.module.scss';

const Photo = ({
  url, loadingFile, onUpload, placeholder
}) => {
  const { t } = useTranslation();
  const inputFile = useRef(null);
  const handleUpload = useCallback(() => {
    if (onUpload) inputFile.current.click();
  }, [onUpload]);
  return (
    <div
      className={`${styles.container} ${url ? styles.containerWithUrl : ''} ${onUpload ? styles.cursorPointer : ''}`}
      role="presentation"
      onClick={handleUpload}
    >
      {loadingFile ? t('loading') : (
        <>
          <Avatar url={url} placeholder={placeholder} style={styles.avatar} />
          {!url && !placeholder && <div className={styles.text}>{t('referancePhoto')}</div>}
        </>
      )}
      <input type="file" accept="image/*" ref={inputFile} onChange={onUpload} />
    </div>
  );
};

Photo.propTypes = {
  url: PropTypes.string,
  loadingFile: PropTypes.bool,
  onUpload: PropTypes.func,
  placeholder: PropTypes.node
};

Photo.defaultProps = {
  url: '',
  loadingFile: false,
  onUpload: null,
  placeholder: null
};

export default Photo;
