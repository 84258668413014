import {
  CLEAN_M6,
  SET_M6_FAILED,
  SET_M6_REQUEST,
  SET_M6_SEGMENTS_SUCCESS
} from './types';
import instance from '../../services/axios';
import {
  POTENTIAL_CUSTOMER_OFFERING_URL,
  PROJECTED_INCREASE_URL,
  SEGMENT_CUSTOMER_OFFERING_URL,
  WORKSHEET_CALCULATE_URL
} from '../../constants/api';
import notify from '../../utils/notify';

export const setM6Request = () => ({ type: SET_M6_REQUEST });
export const setM6Failed = (error) => ({ type: SET_M6_FAILED, error });
export const cleanM6 = () => ({ type: CLEAN_M6 });

export const setSegments = (data) => ({ type: SET_M6_SEGMENTS_SUCCESS, data });

export const getSegmentsAction = (id, idSegment) => {
  return (dispatch, getState) => {
    const { m6: { segments } } = getState();
    dispatch(setM6Request());
    instance.get(`${SEGMENT_CUSTOMER_OFFERING_URL}/${id}/${idSegment || ''}`)
      .then(({ data: { data } }) => {
        if (!idSegment) {
          const filterData = data.filter(({ offerings }) => offerings.length);
          dispatch(setSegments(filterData));
        } else if (data?.offerings.length) {
          const newSegments = segments.map((segment) => (segment.id === data.id ? data : segment));
          dispatch(setSegments(newSegments));
        } else {
          const newSegments = segments.filter((segment) => segment.id !== data.id);
          dispatch(setSegments(newSegments));
        }
      })
      .catch(({ response: { data } }) => {
        dispatch(setM6Failed(data));
        notify('error', data.message);
      });
  };
};

export const changeServiceableSOMAction = (values, idProject, idSegment) => {
  return (dispatch, getState) => {
    const { m6: { segments } } = getState();
    const newValues = {
      som_currency: values?.somCurrency,
      som_percentage: values?.somPercentage,
      som_qty: values?.somQty,
      som_amount: values?.somAmount,
    };
    dispatch(setM6Request());
    instance.put(`${WORKSHEET_CALCULATE_URL}/${idProject}/${idSegment}`, newValues)
      .then(({ data: { data } }) => {
        const newSegments = segments.map((item) => (item.id === idSegment
          ? {
            ...item,
            som_currency: data.som_currency,
            som_percentage: data.som_percentage,
            som_qty: data.som_qty,
            som_amount: data.som_amount
          }
          : item));
        dispatch(setSegments(newSegments));
      })
      .catch(({ response: { data } }) => {
        dispatch(setM6Failed(data));
        notify('error', data.message);
      });
  };
};

export const changeOfferingAction = (id, idSegment, idOffer, values) => {
  return (dispatch) => {
    dispatch(setM6Request());
    instance.put(`${POTENTIAL_CUSTOMER_OFFERING_URL}/${idOffer}`, values)
      .then(() => dispatch(getSegmentsAction(id, idSegment)))
      .catch(({ response: { data } }) => {
        dispatch(setM6Failed(data));
        notify('error', data.message);
      });
  };
};

export const deleteOfferingAction = (id, idSegment, idOffer) => {
  return (dispatch) => {
    dispatch(setM6Request());
    instance.delete(`${POTENTIAL_CUSTOMER_OFFERING_URL}/${idOffer}`)
      .then(() => dispatch(getSegmentsAction(id, idSegment)))
      .catch(({ response: { data } }) => {
        dispatch(setM6Failed(data));
        notify('error', data.message);
      });
  };
};

export const createOfferingSOMAction = (idSegment, idOffer, values) => {
  return (dispatch, getState) => {
    const { m6: { segments } } = getState();
    dispatch(setM6Request());
    const newOfferingSOM = {
      potential_customer_offering_id: idOffer,
      som_currency: values.somCurrency,
      som_percentage: values.somPercentage,
      som_qty: values.somQty,
      som_amount: values.somAmount,
    };
    instance.post(PROJECTED_INCREASE_URL, newOfferingSOM)
      .then(({ data: { data } }) => {
        const copySegments = [...segments];
        const segmentIndex = copySegments.findIndex((item) => item.id === idSegment);
        const offeringIndex = copySegments[segmentIndex].offerings.findIndex((item) => item.id === idOffer);
        copySegments[segmentIndex].offerings[offeringIndex] = {
          ...copySegments[segmentIndex].offerings[offeringIndex],
          som_currency: values.somCurrency,
          som_percentage: values.somPercentage,
          projected_increase_id: data.id
        };
        dispatch(setSegments(copySegments));
      })
      .catch(({ response: { data } }) => {
        dispatch(setM6Failed(data));
        notify('error', data.message);
      });
  };
};

export const updateOfferingSOMAction = (idSegment, idOffer, values) => {
  return (dispatch, getState) => {
    const { m6: { segments } } = getState();
    dispatch(setM6Request());
    const newOfferingSOM = {
      som_currency: values.somCurrency,
      som_percentage: values.somPercentage || 0,
      som_qty: values.somQty,
      som_amount: values.somAmount,
    };
    instance.put(`${PROJECTED_INCREASE_URL}/${values.projectedIncreaseId}`, newOfferingSOM)
      .then(() => {
        const copySegments = [...segments];
        const segmentIndex = copySegments.findIndex((item) => item.id === idSegment);
        const offeringIndex = copySegments[segmentIndex].offerings.findIndex((item) => item.id === idOffer);
        copySegments[segmentIndex].offerings[offeringIndex] = {
          ...copySegments[segmentIndex].offerings[offeringIndex],
          som_currency: values.somCurrency,
          som_percentage: values.somPercentage,
        };
        dispatch(setSegments(copySegments));
      })
      .catch(({ response: { data } }) => {
        dispatch(setM6Failed(data));
        notify('error', data.message);
      });
  };
};
