import React from 'react';
import PropTypes from 'prop-types';
import styles from '../sass/M4.module.scss';

const M4ChartCustomizedTooltip = (props) => {
  const {
    active, payload, data
  } = props;

  if (active && payload && payload.length) {
    const {
      payload: {
        name, competitorId, x, y
      }
    } = payload[0];

    const otherCompetitors = data.filter((item) => (
      item.competitorId !== competitorId && item.x === x && item.y === y
    ));

    return (
      <div className={styles.tooltip}>
        <div className={styles.containerTooltip}>
          <span className={`${styles.name} ${!competitorId ? styles.ownOrganization : ''}`}>{name}</span>
          {otherCompetitors.map((item) => (
            <span className={styles.name} key={item.name}>
              {item.name}
            </span>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

M4ChartCustomizedTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(PropTypes.shape({
    payload: PropTypes.shape({
      name: PropTypes.string,
      competitorId: PropTypes.number,
      x: PropTypes.number,
      y: PropTypes.number
    })
  })),
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    competitorId: PropTypes.number,
    x: PropTypes.number,
    y: PropTypes.number
  })),
};

M4ChartCustomizedTooltip.defaultProps = {
  active: undefined,
  payload: [],
  data: []
};

export default M4ChartCustomizedTooltip;
