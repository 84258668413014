import React, { useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  batch, shallowEqual, useDispatch, useSelector
} from 'react-redux';
import {
  addRevenueAction,
  changeOfferingRevenueAction,
  changeRevenueAction,
  cleanO5,
  deleteOfferingRevenueAction,
  deleteRevenueAction,
  getOfferingRevenuesAction,
  getTimeIntervalsAction
} from '../../store/o5/actions';
import { getBreadcrumbs, getSwitchPages } from '../../utils/helpers';
import Page from '../../layout/Page/Page';
import NoData from '../../components/NoData/NoData';
import O5Item from './components/O5Item';
import styles from './sass/O5.module.scss';

const O5 = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    o5: {
      offeringRevenues, timeIntervals, loading
    },
    project: { name: projectName }
  } = useSelector(({ o5, project }) => ({ o5, project }), shallowEqual);

  const breadcrumbs = useMemo(() => (
    getBreadcrumbs(projectName, `/${id}/dashboard`, 'Financials')
  ), [projectName, id]);

  const switchPages = useMemo(() => (
    getSwitchPages(`/${id}/o4`, `/${id}/o8`)
  ), [id]);

  useEffect(() => {
    batch(() => {
      dispatch(getOfferingRevenuesAction(id));
      dispatch(getTimeIntervalsAction());
    });
    return () => {
      dispatch(cleanO5());
    };
  }, []);

  const handleChangeOfferingRevenues = useCallback((values) => {
    dispatch(changeOfferingRevenueAction(id, values));
  }, []);

  const handleDeleteOfferingRevenues = useCallback((offeringRevenueId) => {
    dispatch(deleteOfferingRevenueAction(id, offeringRevenueId));
  }, []);

  const handleChangeRevenue = useCallback((offeringRevenueId, values, closePlaceholder) => {
    if (values.revenueId) {
      dispatch(changeRevenueAction(offeringRevenueId, values));
    } else {
      dispatch(addRevenueAction(offeringRevenueId, values.revenueName, closePlaceholder));
    }
  }, []);

  const handleDeleteRevenue = useCallback((offeringRevenueId, revenueId) => {
    dispatch(deleteRevenueAction(offeringRevenueId, revenueId));
  }, []);

  return (
    <Page
      pageContainer={styles.container}
      breadcrumbs={breadcrumbs}
      switchPages={switchPages}
      loader={loading}
    >
      <div className={styles.contentContainer}>
        {offeringRevenues.length ? offeringRevenues.map(({
          id: offeringId,
          name,
          value,
          currency,
          time_interval_id: timeIntervalId,
          list
        }) => (
          <O5Item
            key={offeringId}
            id={offeringId}
            name={name}
            value={value}
            currency={currency}
            timeIntervalId={timeIntervalId || 0}
            list={list}
            timeIntervals={timeIntervals}
            onChangeOfferingRevenues={handleChangeOfferingRevenues}
            onDeleteOfferingRevenues={handleDeleteOfferingRevenues}
            onChangeRevenue={(values, closePlaceholder) => handleChangeRevenue(offeringId, values, closePlaceholder)}
            onDeleteRevenue={(revenueId) => handleDeleteRevenue(offeringId, revenueId)}
          />
        )) : <NoData id={id} projectName={projectName} />}
      </div>
    </Page>
  );
};

export default O5;
