import {
  CLEAN_O5,
  SET_O5_FAILED,
  SET_O5_OFFERING_REVENUES_SUCCESS,
  SET_O5_REQUEST,
  SET_O5_TIME_INTERVALS_SUCCESS
} from './types';

const initialState = {
  offeringRevenues: [],
  timeIntervals: [],
  loading: false,
  error: null
};

export default function o5(state = initialState, action) {
  switch (action.type) {
    case SET_O5_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_O5_OFFERING_REVENUES_SUCCESS:
      return {
        ...state,
        offeringRevenues: action.data,
        loading: false,
        error: null
      };
    case SET_O5_TIME_INTERVALS_SUCCESS:
      return {
        ...state,
        timeIntervals: action.data,
        loading: false,
        error: null
      };
    case SET_O5_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case CLEAN_O5:
      return initialState;
    default:
      return state;
  }
}
