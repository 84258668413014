import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/Input.module.scss';

const Input = ({
  type,
  name,
  title,
  value,
  onChange,
  onBlur,
  errorText,
  style,
  disabled,
  customStyle,
  label,
  icon,
  onIconClick,
  maxLength,
  styleLabel
}) => {
  return (
    <div className={`${styles.container} ${style}`}>
      {label && <span className={`${styles.label} ${styleLabel}`}>{label}</span>}
      <div className={styles.inputContainer}>
        <input
          type={type}
          placeholder={title}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
          style={customStyle}
          maxLength={maxLength}
        />
        {icon && (
          <button
            onClick={onIconClick}
            className={styles.icon}
            type="button"
          >
            {icon}
          </button>
        )}
      </div>
      {!!errorText && <div className={styles.error}>{errorText}</div>}
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.string,
  errorText: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  customStyle: PropTypes.shape({
    color: PropTypes.string,
    fontWeight: PropTypes.string,
  }),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.node,
  onIconClick: PropTypes.func,
  maxLength: PropTypes.number,
  styleLabel: PropTypes.string
};

Input.defaultProps = {
  type: 'text',
  value: '',
  errorText: '',
  style: '',
  title: '',
  name: '',
  onBlur: undefined,
  disabled: false,
  customStyle: undefined,
  label: '',
  icon: undefined,
  onIconClick: undefined,
  maxLength: undefined,
  styleLabel: ''
};

export default Input;
