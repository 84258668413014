export const SET_ORGANIZATION_WORK_SHEET_SUCCESS = 'SET_ORGANIZATION_WORK_SHEET_SUCCESS';
export const SET_ORGANIZATION_REQUEST = 'SET_ORGANIZATION_REQUEST';
export const SET_ORGANIZATION_FAILED = 'SET_ORGANIZATION_FAILED';
export const CLEAN_ORGANIZATION = 'CLEAN_ORGANIZATION';
export const SET_ORGANIZATION_AVATAR_SUCCESS = 'SET_ORGANIZATION_AVATAR_SUCCESS';
export const SET_ORGANIZATION_WORK_SHEET_NAME_SUCCESS = 'SET_ORGANIZATION_WORK_SHEET_NAME_SUCCESS';
export const SET_ORGANIZATION_WORK_SHEET_MEANING_SUCCESS = 'SET_ORGANIZATION_WORK_SHEET_MEANING_SUCCESS';
export const SET_ORGANIZATION_WORK_SHEET_LINKS_SUCCESS = 'SET_ORGANIZATION_WORK_SHEET_LINKS_SUCCESS';
export const SET_ORGANIZATION_WORK_SHEET_DRIVERS_SUCCESS = 'SET_ORGANIZATION_WORK_SHEET_DRIVERS_SUCCESS';
export const SET_ORGANIZATION_WORK_SHEET_TRIGGERS_SUCCESS = 'SET_ORGANIZATION_WORK_SHEET_TRIGGERS_SUCCESS';
