import React from 'react';
import PropTypes from 'prop-types';
import { getEmptyArray } from '../../../utils/helpers';
import M2MeaningItem from './M2MeaningItem';
import styles from '../sass/M2.module.scss';

const M2Meaning = ({ meanings, meaningsList, onChange }) => {
  return (
    <div className={styles.stakeholderContainer}>
      {getEmptyArray(5).map((item, index) => (
        <M2MeaningItem
          data={meanings[index]}
          key={Math.random()}
          meaningsList={meaningsList}
          onChange={onChange}
        />
      ))}
    </div>
  );
};

M2Meaning.propTypes = {
  meanings: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    worksheet_id: PropTypes.number,
    value: PropTypes.number,
    description: PropTypes.string
  })),
  meaningsList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  onChange: PropTypes.func.isRequired
};

M2Meaning.defaultProps = {
  meanings: [],
  meaningsList: []
};

export default M2Meaning;
