import React, {
  useEffect, useCallback, useState, useMemo
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  batch, shallowEqual, useDispatch, useSelector
} from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
  updateDriverAction,
  getLastWorkSheet,
  updateWorkSheetAction,
  updateWorkSheetSegmentAction,
  uploadPhotoAction,
  updateMeaningAction,
  updateTriggerAction,
  createWorkSheetAction,
  updateLinkAction,
  deleteLinkAction,
  createSegmentAction,
  cleanM1,
  deleteWorkSheetAction,
  getWorkSheetAction,
  deleteSegmentAction,
  getSegmentListAction
} from '../../store/m1/actions';
import { getMeaningListAction } from '../../store/general/actions';
import useUpload from '../../hooks/useUpload';
import { driversTypeCustomer } from '../../constants/drivers';
import { triggersTypeCustomer } from '../../constants/triggers';
import { getBreadcrumbs, getSwitchPages } from '../../utils/helpers';
import Page from '../../layout/Page/Page';
import Tip from '../../components/Tip/Tip';
import NewAction from '../../layout/Header/components/NewAction';
import ModalDelete from '../../components/ModalDelete/ModalDelete';
import LinkModal from '../../components/LinkModal/LinkModal';
import PhotoWithReference from '../../components/PhotoWithReference/PhotoWithReference';
import StakeholderHeader from '../../components/StakeholderHeader/StakeholderHeader';
import ModalConfirmDelete from '../../components/ModalConfirmDelete/ModalConfirmDelete';
import CoreMeaning from '../../components/CoreMeaning/CoreMeaning';
import Drivers from '../../components/Drivers/Drivers';
import Triggers from '../../components/Triggers/Triggers';
import M1CustomerTipContent from './components/M1CustomerTipContent';
import M1LinkTipContent from './components/M1LinkTipContent';
import VideoModal from '../../components/VideoModal/VideoModal';
import M1CustomerProfile from './components/M1CustomerProfile';
import styles from './sass/M1.module.scss';

const M1 = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { state } = useLocation();

  const {
    m1: {
      segmentList,
      loading,
      name,
      segment,
      avatars,
      meanings,
      drivers,
      triggers,
      links,
      age,
      occupation,
      location,
      biography,
    },
    general: { meaningsList },
    project: { name: projectName }
  } = useSelector(({ m1, general, project }) => ({ m1, general, project }), shallowEqual);

  const [selectedFile, setSelectedFile] = useState(null);
  const [linkModal, setLinkModal] = useState(false);
  const [segmentModal, setSegmentModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLinkModal, setDeleteLinkModal] = useState(null);
  const [selectSegment, setSelectSegment] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);

  const breadcrumbs = useMemo(() => {
    return getBreadcrumbs(projectName, `/${id}/dashboard`, 'Customer Value');
  }, [projectName, id]);

  const switchPages = useMemo(() => {
    return getSwitchPages(`/${id}/dashboard`, `/${id}/m2`);
  }, [id]);

  useEffect(() => {
    batch(() => {
      if (state?.worksheet) dispatch(getWorkSheetAction(state.worksheet));
      if (state?.idSegment) dispatch(createWorkSheetAction(id, state.idSegment));
      if (!state) dispatch(getLastWorkSheet(id));
      dispatch(getSegmentListAction(id));
      dispatch(getMeaningListAction());
    });
    return () => {
      dispatch(cleanM1());
    };
  }, []);

  const handleUpdateWorkSheep = useCallback((field, value) => {
    dispatch(updateWorkSheetAction(field, value));
  }, []);

  const handleUpdateWorkSheepSegment = useCallback((idSegment) => {
    dispatch(updateWorkSheetSegmentAction(idSegment));
  }, []);

  const handleChangeMeaning = useCallback((meaningItem) => {
    dispatch(updateMeaningAction(meaningItem));
  }, []);

  const handleUpdatePhoto = useCallback((data) => {
    dispatch(uploadPhotoAction(data));
    setSelectedFile(null);
  }, []);

  const handleFileSelect = useCallback((event) => {
    setSelectedFile(event.target.files[0]);
  }, []);

  const handleChangeDriver = useCallback((values, type, closePlaceholderDriver) => {
    dispatch(updateDriverAction(values, type, closePlaceholderDriver));
  }, []);

  const handleChangeTrigger = useCallback((values, type) => {
    dispatch(updateTriggerAction(values, type));
  }, []);

  const handleCreateNewCustomer = useCallback(() => {
    dispatch(createWorkSheetAction(id));
  }, [id]);

  const handleVisibleLinkModal = useCallback((value) => {
    setLinkModal(value);
  }, []);

  const handleChangeLink = useCallback((values) => {
    dispatch(updateLinkAction(values));
    handleVisibleLinkModal(false);
  }, []);

  const handleDeleteLink = useCallback((values) => {
    dispatch(deleteLinkAction(values));
    setDeleteLinkModal(null);
  }, []);

  const handleOpenLink = useCallback((url) => {
    window.open(url, '_blank');
  }, []);

  const handleVisibleSegmentModal = useCallback((value) => {
    if (!value) setSelectSegment(null);
    setSegmentModal(value);
  }, []);

  const handleEditSegment = useCallback((idSegment, value) => {
    if (value) dispatch(createSegmentAction(value, id, idSegment));
    handleVisibleSegmentModal(false);
  }, [id]);

  const createSegment = useCallback((value) => {
    if (value) dispatch(createSegmentAction(value, id));
    handleVisibleSegmentModal(false);
  }, [id]);

  const handleDeleteSegment = useCallback((idSegment) => {
    dispatch(deleteSegmentAction(idSegment));
    handleVisibleSegmentModal(false);
  }, [id]);

  const handleDeleteWorkSheet = useCallback(() => {
    dispatch(deleteWorkSheetAction(id));
    setDeleteModal(false);
  }, [id]);

  const handleSelectSegment = useCallback((idSegment) => {
    const chooseSegment = segmentList.find(({ id: idItem }) => idItem === idSegment);
    setSelectSegment(chooseSegment);
    handleVisibleSegmentModal(true);
  }, [segmentList]);

  const handleOpenVideo = useCallback((url) => {
    setVideoUrl(url);
  }, []);

  const { loadingFile } = useUpload(selectedFile, (data) => handleUpdatePhoto(data));

  return (
    <Page
      pageContainer={styles.container}
      switchPages={switchPages}
      breadcrumbs={breadcrumbs}
      newAction={<NewAction onClick={handleCreateNewCustomer} text={t('newCustomer')} />}
      loader={loading}
    >
      <div className={styles.content}>
        <StakeholderHeader
          segmentList={segmentList}
          onChangeName={(text) => handleUpdateWorkSheep('name', text)}
          initialName={name}
          initialSegment={segment}
          onChangeSegment={handleUpdateWorkSheepSegment}
          onOpenModal={() => handleVisibleSegmentModal(true)}
          onEditSegment={handleSelectSegment}
          disabled={segmentModal}
          withSegment
          title={t('customer')}
          tipContent={<p>{t('m1Tip')}</p>}
        />
        <Tip styleContainer={styles.tipCustomer}>
          <M1CustomerTipContent onOpenVideo={handleOpenVideo} />
        </Tip>
        <div className={styles.coreMeaningContainer}>
          <PhotoWithReference
            avatar={avatars?.url}
            loadingFile={loadingFile}
            onUpload={handleFileSelect}
            link={links[0]}
            onOpenModal={() => handleVisibleLinkModal(true)}
            onDelete={() => setDeleteLinkModal(links[0])}
            onOpen={handleOpenLink}
            tipContent={<M1LinkTipContent />}
          />
          <CoreMeaning
            meaningsList={meaningsList}
            meanings={meanings}
            onChange={handleChangeMeaning}
          />
        </div>

        <Drivers
          drivers={drivers}
          onChange={handleChangeDriver}
          driversTypes={driversTypeCustomer}
          maxLength={250}
          placeholder={t('driverPlaceholder')}
        />
        <Triggers
          triggers={triggers}
          onChange={handleChangeTrigger}
          onDeleteWorksheet={() => setDeleteModal(true)}
          triggersTypes={triggersTypeCustomer}
        />
        <M1CustomerProfile
          customerAge={age ? age.toString() : ''}
          customerOccupation={occupation || ''}
          customerLocation={location || ''}
          customerBiography={biography || ''}
          onChange={(field, value) => handleUpdateWorkSheep(field, value)}
        />

        {linkModal && (
          <LinkModal
            link={links[0]}
            onClose={() => handleVisibleLinkModal(false)}
            onSave={handleChangeLink}
          />
        )}
        {segmentModal && (
          <ModalConfirmDelete
            onClose={() => handleVisibleSegmentModal(false)}
            onAdd={createSegment}
            onEdit={handleEditSegment}
            onDelete={handleDeleteSegment}
            value={selectSegment}
            title={selectSegment ? t('editSegment') : t('addSegment')}
            text={t('segment')}
            placeholder={t('segmentPlaceholder')}
          />
        )}

        {deleteModal && (
          <ModalDelete
            onClose={() => setDeleteModal(false)}
            onDelete={handleDeleteWorkSheet}
            text={t('customer')}
          />
        )}

        {!!deleteLinkModal && (
          <ModalDelete
            onClose={() => setDeleteLinkModal(null)}
            onDelete={() => handleDeleteLink(deleteLinkModal)}
            text={t('link')}
          />
        )}
      </div>

      {!!videoUrl && (
        <VideoModal
          url={videoUrl}
          onClose={() => setVideoUrl(null)}
        />
      )}
    </Page>
  );
};

export default M1;
