import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
  cleanEditTrends,
  editTrendAction,
  getEditTrendScenarioAction,
  getTrendAction,
  getTrendEditInfluence
} from '../../../store/admin/adminEditTrend/actions';
import { getIndustriesListAction, getTrendCategoryAction } from '../../../store/general/actions';
import { editTrendValidationSchema } from '../../../utils/validation';
import { TREND, SCENARIO } from '../../../constants/general';
import rateOptions from '../../../constants/rateOptions';
import { convertFromIdToName } from '../../../utils/helpers';
import AdminPage from '../../../layout/admin/AdminPage/AdminPage';
import Title from '../../../components/Title/Title';
import Input from '../../../components/Input/Input';
import SelectInput from '../../../components/SelectInput/SelectInput';
import AdminEditTrendButtons from './components/AdminEditTrendButtons';
import ScenarioBreadcrumbs from '../../../components/ScenarioBreadcumbs/ScenarioBreadcrumbs';
import styles from './sass/AdminEditTrend.module.scss';

const AdminEditTrend = () => {
  const { trendId, id: scenarioId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    adminEditTrend: {
      loading, influenceList, trend: trendData, scenario
    },
    general: { categoryList, industriesList }
  } = useSelector(({ adminEditTrend, general }) => ({ adminEditTrend, general }));

  const {
    handleSubmit,
    values: {
      trend,
      category,
      influence,
      industry,
      rank,
      message
    },
    errors,
    setFieldValue,
    handleChange,
    validateForm,
  } = useFormik({
    initialValues: {
      trend: trendData?.name || '',
      category: trendData?.trend_type_id || null,
      influence: trendData?.influence_id || null,
      industry: trendData?.industry_id || null,
      rank: trendData?.value.toString() || '0',
      message: trendData?.trend_message || '',
    },
    onSubmit: (values) => validateForm(values)
      .then(() => dispatch(editTrendAction(values, trendId, navigate, scenarioId))),
    validationSchema: editTrendValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true
  });

  useEffect(() => {
    batch(() => {
      if (trendId) dispatch(getTrendAction(trendId, scenarioId));
      if (scenarioId) dispatch(getEditTrendScenarioAction(scenarioId));
      dispatch(getTrendCategoryAction());
      dispatch(getTrendEditInfluence());
      dispatch(getIndustriesListAction());
    });
    return () => {
      dispatch(cleanEditTrends());
    };
  }, [trendId, scenarioId]);

  const breadcrumbs = useMemo(() => [
    {
      name: scenario?.name || t('scenario'),
      link: `/admin/dashboard/scenarios/${scenarioId}`
    }, 
    {
      name: t('trends'),
      link: `/admin/dashboard/scenarios/${scenarioId}/trends`
    },
    {
      name: trendData?.name || t('newScenarioTrend'),
      link: ''
    }
  ], [scenario?.name, trendData?.name, scenarioId]);

  const handleChangeField = useCallback((field, value) => {
    setFieldValue(field, value);
  }, []);

  const handleBackNavigation = useCallback(() => navigate(-1), []);

  return (
    <AdminPage pageContainer={styles.container} loader={loading} activeTab={scenarioId ? SCENARIO : TREND}>
      <div className={styles.content}>
        {scenarioId && (
          <ScenarioBreadcrumbs breadcrumbs={breadcrumbs} />
        )}

        <form onSubmit={handleSubmit}>
          <Title text={t(trendId ? 'editTrend' : 'addTrend')} style={styles.title} />

          <SelectInput
            styleContainer={styles.inputContainer}
            styleSelectInputItem={styles.inputContainerItem}
            onChange={(value) => handleChangeField('industry', value)}
            title={t('industry')}
            values={industriesList}
            value={convertFromIdToName(industriesList, industry)}
            errorText={errors.industry}
          />

          <SelectInput
            styleContainer={styles.inputContainer}
            styleSelectInputItem={styles.inputContainerItem}
            onChange={(value) => handleChangeField('category', value)}
            title={t('category')}
            values={categoryList}
            value={convertFromIdToName(categoryList, category)}
            errorText={errors.category}
          />

          <Input
            onChange={handleChange}
            name="trend"
            title={t('trend')}
            value={trend}
            errorText={errors.trend}
          />

          <SelectInput
            styleContainer={styles.inputContainer}
            styleSelectInputItem={styles.inputContainerItem}
            onChange={(value) => handleChangeField('influence', value)}
            title={t('influence')}
            values={influenceList}
            value={convertFromIdToName(influenceList, influence)}
            errorText={errors.influence}
          />

          <div className={styles.rankContainer}>
            <span>{t('rank')}</span>
            <SelectInput
              styleContainer={styles.rateInputContainer}
              onChange={(value) => handleChangeField('rank', value)}
              value={rank.toString()}
              values={rateOptions}
              checkId={false}
            />
            {errors.rank && <span className={styles.errorText}>{errors.rank}</span>}
          </div>

          <Input
            onChange={handleChange}
            name="message"
            title={t('message')}
            value={message}
          />

          <AdminEditTrendButtons onGoBack={handleBackNavigation} handleSubmit={handleSubmit} />
        </form>
      </div>
    </AdminPage>
  );
};

export default AdminEditTrend;
