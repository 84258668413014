import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { formatSelectedDateToString } from '../../../../utils/helpers';
import Button from '../../../../components/Button/Button';
import styles from '../sass/AdminTrends.module.scss';

import { ReactComponent as Edit } from '../../../../assets/icons/edit.svg';
import { ReactComponent as Delete } from '../../../../assets/icons/deleteAdmin.svg';

const AdminTrendTrendsRow = ({
  id,
  influence,
  name,
  category,
  onDelete,
  industry,
  createdAt,
  value,
}) => {
  const navigate = useNavigate();
  const date = formatSelectedDateToString(new Date(createdAt), 'mm/dd/yyyy');

  return (
    <div className={`${styles.row} ${styles.line}`}>
      <div className={`${styles.column} ${styles.createDate}`}>
        <span>{date}</span>
      </div>
      <div className={`${styles.column} ${styles.industry}`}>
        <span>{industry}</span>
      </div>
      <div className={`${styles.column} ${styles.category}`}>
        <span>{category}</span>
      </div>
      <div className={`${styles.column} ${styles.trend}`}>
        <span>{name}</span>
      </div>
      <div className={`${styles.column} ${styles.influence}`}>
        <span>{influence}</span>
      </div>
      <div className={`${styles.column} ${styles.rank}`}>
        {value}
      </div>
      <div className={`${styles.column} ${styles.actions}`}>
        <Button
          icon={<Edit />}
          style={styles.buttonActionContainer}
          styleButton={styles.button}
          onClick={() => navigate(`${id}`)}
        />
        <Button
          icon={<Delete width={12} height={12} />}
          style={styles.buttonActionContainer}
          styleButton={styles.button}
          onClick={() => onDelete(id)}
        />
      </div>
    </div>

  );
};

AdminTrendTrendsRow.propTypes = {
  id: PropTypes.number.isRequired,
  influence: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  industry: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};

export default AdminTrendTrendsRow;
