import {
  CLEAN_O3,
  SET_O3_FAILED,
  SET_O3_PRIORITIES_SUCCESS,
  SET_O3_REQUEST,
  SET_O3_STAKEHOLDERS_LIST_SUCCESS
} from './types';

const initialState = {
  stakeholdersList: [],
  priorities: {},
  loading: false,
  error: null
};

export default function o3(state = initialState, action) {
  switch (action.type) {
    case SET_O3_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_O3_STAKEHOLDERS_LIST_SUCCESS:
      return {
        ...state,
        stakeholdersList: action.data,
        loading: false,
        error: null
      };
    case SET_O3_PRIORITIES_SUCCESS:
      return {
        ...state,
        priorities: action.data,
        loading: false,
        error: null
      };
    case SET_O3_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data
      };
    case CLEAN_O3:
      return initialState;
    default:
      return state;
  }
}
