import React from 'react';
import { useTranslation } from 'react-i18next';
import AuthLayout from '../../../layout/AuthLayout/AuthLayout';
import Title from '../../../components/Title/Title';
import styles from '../sass/SuccessRegistration.module.scss';
import Loader from '../../../assets/icons/loader.svg';

const SuccessRegistrationLoading = () => {
  const { t } = useTranslation();
  return (
    <AuthLayout>
      <div className={styles.loader}>
        <img src={Loader} alt="check-icon" />
      </div>
      <Title text={t('verificationEmail')} style={styles.title} />
    </AuthLayout>
  );
};

export default SuccessRegistrationLoading;
