import React, {
  useState, useCallback, useLayoutEffect, useMemo
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { deleteSpacesInString, isNumberValueValid, percentage } from '../../../utils/helpers';
import Title from '../../../components/Title/Title';
import Input from '../../../components/Input/Input';
import styles from '../sass/M6.module.scss';

const M6ServiceableObtainableMarketPercent = ({
  somPercentage,
  tamQty,
  tamAmount,
  onChangeServiceableObtainableMarket
}) => {
  const { t } = useTranslation();
  const [percentageValue, setPercentageValue] = useState(null);

  useLayoutEffect(() => {
    setPercentageValue(somPercentage);
  }, [somPercentage]);

  const handleChangePercentage = useCallback((event) => {
    const number = deleteSpacesInString(event.target.value);
    if (isNumberValueValid(false, number)) {
      setPercentageValue(number);
    }
  }, []);

  const handleBlurPercentage = useCallback(() => {
    if (somPercentage !== +percentageValue) {
      onChangeServiceableObtainableMarket({
        somPercentage: +percentageValue,
        somQty: percentage(tamQty, +percentageValue),
        somAmount: percentage(tamAmount, +percentageValue)
      });
    }
  }, [somPercentage, percentageValue]);

  const somPercentValue = useMemo(() => {
    const isTamValue = !!tamQty || !!tamAmount;
    return isTamValue && percentageValue ? percentageValue.toString() : '';
  }, [percentageValue, tamAmount, tamQty]);

  return (
    <div className={styles.percentContainer}>
      <Title
        text={t('serviceableObtainableMarket')}
        Type="h4"
        style={styles.percentTitle}
      />
      <div className={styles.percentInputContainer}>
        <Input
          onChange={handleChangePercentage}
          value={somPercentValue}
          style={styles.percentInput}
          onBlur={handleBlurPercentage}
          disabled={!tamQty && !tamAmount}
        />
        <span>%</span>
      </div>
    </div>
  );
};

M6ServiceableObtainableMarketPercent.propTypes = {
  somPercentage: PropTypes.number.isRequired,
  tamQty: PropTypes.number.isRequired,
  tamAmount: PropTypes.number.isRequired,
  onChangeServiceableObtainableMarket: PropTypes.func.isRequired
};

export default M6ServiceableObtainableMarketPercent;
