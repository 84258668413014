import React from 'react';
import PropTypes from 'prop-types';
import { SAM } from '../../../constants/general';
import availableSegment from '../../../constants/availableM2';
import Title from '../../../components/Title/Title';
import Input from '../../../components/Input/Input';
import styles from '../sass/M2.module.scss';

const M2AvailableItemTitle = ({
  title, isPercent, disabled, percentageValue, type, onChange, onBlur, tip
}) => {
  return (
    <div className={styles.availableItemTitleContainer}>
      <div className={styles.availableItemTitleWrapper}>
        <Title text={title} style={styles.availableItemTitle} />
        {tip}
      </div>
      {isPercent && (
        <div className={styles.availableItemInputContainer}>
          <Input
            title=""
            style={styles.availableItemInput}
            name={type === SAM ? availableSegment.sam.field.percentage : availableSegment.som.field.percentage}
            onChange={onChange}
            disabled={disabled}
            value={percentageValue}
            onBlur={onBlur}
          />
          <span>%</span>
        </div>
      )}
    </div>
  );
};

M2AvailableItemTitle.propTypes = {
  title: PropTypes.string.isRequired,
  isPercent: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  percentageValue: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  tip: PropTypes.node.isRequired
};

export default M2AvailableItemTitle;
