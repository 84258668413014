import React from 'react';
import { useTranslation } from 'react-i18next';
import Tip from '../Tip/Tip';
import styles from './sass/M2Tips.module.scss';

const TipSAM = () => {
  const { t } = useTranslation();
  return (
    <Tip styleTipTextContainer={styles.container} styleTriangle={styles.triangle}>
      <p>
        {t('tipM2SAM')}
      </p>
    </Tip>
  );
};

export default TipSAM;
