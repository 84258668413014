import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import styles from '../sass/Dashboard.module.scss';

const Operations = ({ data, title, id }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.row}>
      <div
        className={styles.name}
        style={{
          backgroundColor: '#3D8897'
        }}
      >
        <h4>{title}</h4>
      </div>
      {data.map((item) => (
        <div
          key={item.title}
          className={styles.itemContainer}
          style={{
            backgroundColor: item.style.backgroundColor,
            borderRadius: item.style.borderRadius
          }}
        >
          <button
            className={styles.item}
            style={{ backgroundColor: item.style.backgroundColorItem }}
            onClick={() => navigate(`/${id}${item.link}`)}
            disabled={item.disabled}
          >
            <span>{item.title}</span>
          </button>
        </div>
      ))}
    </div>
  );
};

Operations.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
    name: PropTypes.string,
    style: PropTypes.shape({ backgroundColor: PropTypes.string })
  })).isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired
};

export default Operations;
