import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useOnBlur from '../../../hooks/useOnBlur';
import { deleteSpacesInString } from '../../../utils/helpers';
import rateOptions from '../../../constants/rateOptions';
import Input from '../../../components/Input/Input';
import SelectInput from '../../../components/SelectInput/SelectInput';
import styles from '../sass/O1.module.scss';

const O1ItemOfferings = ({
  title,
  value,
  onChangeOffering,
  onDeleteOffering
}) => {
  const { t } = useTranslation();

  const [titleOffer, setTitleOffer] = useState(title);

  const handleChangeTitleOffer = useCallback((e) => {
    const text = deleteSpacesInString(e.target.value);
    setTitleOffer(text);
  }, []);

  const handleBlurTitleOffer = useOnBlur(
    title,
    titleOffer,
    () => onChangeOffering({ title: titleOffer, value }),
    onDeleteOffering
  );

  return (
    <div className={styles.offeringContainer}>
      <Input
        onChange={handleChangeTitleOffer}
        style={styles.inputContainer}
        value={titleOffer}
        onBlur={handleBlurTitleOffer}
        title={t('offeringPlaceholder')}
      />
      <SelectInput
        title=""
        styleContainer={styles.selectContainer}
        onChange={(rate) => onChangeOffering({ title: titleOffer, value: +rate })}
        values={rateOptions}
        value={value.toString()}
        checkId={false}
      />
    </div>
  );
};

O1ItemOfferings.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChangeOffering: PropTypes.func.isRequired,
  onDeleteOffering: PropTypes.func.isRequired
};

export default O1ItemOfferings;
