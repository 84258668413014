import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
  cleanAdminStakeholders,
  deleteStakeholderAction,
  getStakeholdersListAction,
  getScenarioAction,
  getStakeholdersAction
} from '../../../store/admin/adminStakeholders/actions';
import { PAGE_SIZE, SCENARIO } from '../../../constants/general';
import AdminPage from '../../../layout/admin/AdminPage/AdminPage';
import Title from '../../../components/Title/Title';
import Button from '../../../components/Button/Button';
import AdminStakeholdersFilter from './components/AdminStakeholdersFilter';
import AdminStakeholdersList from './components/AdminStakeholdersList';
import Pagination from '../../../components/Pagination/Pagination';
import ModalDelete from '../../../components/ModalDelete/ModalDelete';
import styles from './sass/AdminStakeholders.module.scss';

import { ReactComponent as ChevronIcon } from '../../../assets/icons/chevronLeft.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icons/plusBlack.svg';

const AdminStakeholders = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [idDriver, setIdDriver] = useState(null);

  const [filterParam, setFilterParam] = useState({
    name: searchParams.get('name') || '',
    type: +searchParams.get('type') || null,
    end: searchParams.get('end') || null,
    start: searchParams.get('start') || null,
    page: +searchParams.get('page') || 1,
    sortOrder: searchParams.get('sortOrder') || 'DESC',
    sortBy: searchParams.get('sortBy') || 'created_at',
  });

  const {
    adminStakeholders: {
      loading, stakeholders, totalCount, scenario, stakeholdersList
    },
    general: { fromPath }
  } = useSelector(({ adminStakeholders, general }) => ({ adminStakeholders, general }));

  useEffect(() => {
    batch(() => {
      dispatch(getStakeholdersAction(id, filterParam));
      dispatch(getStakeholdersListAction());
      dispatch(getScenarioAction(id));
    });
    return () => {
      dispatch(cleanAdminStakeholders());
    };
  }, [id]);

  const handleBackNavigation = useCallback(() => {
    navigate(fromPath || '/admin/dashboard/scenarios');
  }, [fromPath]);

  const handleSort = useCallback((sortBy, sortOrder) => {
    setFilterParam({ ...filterParam, sortBy, sortOrder });
    dispatch(getStakeholdersAction(id, { ...filterParam, sortBy, sortOrder }, setSearchParams));
  }, [filterParam]);

  const handlePagination = useCallback((page) => {
    setFilterParam({ ...filterParam, page });
  }, [filterParam]);

  const handleDeleteDriver = useCallback(() => {
    if (stakeholders.length === 1 && filterParam.page > 1) {
      setFilterParam({ ...filterParam, page: filterParam.page - 1 });
      dispatch(deleteStakeholderAction(id, idDriver, { ...filterParam, page: filterParam.page - 1 }, setSearchParams));
    } else {
      dispatch(deleteStakeholderAction(id, idDriver, filterParam));
    }
    setIdDriver(null);
  }, [filterParam, idDriver, stakeholders, id]);

  const handleSearchInput = useCallback((event) => {
    setFilterParam((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  }, []);

  const handleFilter = useCallback(() => {
    setFilterParam({ ...filterParam, end: filterParam.end || filterParam.start, page: 1 });
    dispatch(getStakeholdersAction(
      id,
      { ...filterParam, end: filterParam.end || filterParam.start, page: 1 },
      setSearchParams
    ));
  }, [filterParam]);

  const handleSearchStakeholder = useCallback((value, field) => {
    setFilterParam((prevState) => ({ ...prevState, [field]: value === prevState[field] ? null : value }));
  }, []);

  const handleSearchDate = useCallback((start, end) => {
    setFilterParam((prevState) => ({ ...prevState, start, end }));
  }, []);

  return (
    <AdminPage pageContainer={styles.container} loader={loading} activeTab={SCENARIO}>
      <div className={styles.content}>
        <div className={styles.logo} />
        <Title text={t('administration')} style={styles.title} />
        <Button
          text={scenario?.name}
          icon={<ChevronIcon />}
          style={styles.scenarioBackButtonContainer}
          styleButton={styles.scenarioBackButton}
          onClick={handleBackNavigation}
        />

        <div className={styles.subtitle}>
          <Title text={t('stakeholders')} Type="h3" style={styles.subtitleText} />
          <Button
            text={t('addScenarioStakeholder')}
            icon={<PlusIcon />}
            style={styles.buttonContainer}
            styleButton={styles.button}
            onClick={() => navigate('create')}
          />
        </div>

        <AdminStakeholdersFilter
          onClick={handleFilter}
          onChange={handleSearchInput}
          driver={filterParam.name}
          onChangeStakeholder={handleSearchStakeholder}
          stakeholdersList={stakeholdersList}
          stakeholder={filterParam.type}
          onChangeDate={handleSearchDate}
          start={filterParam.start}
          end={filterParam.end}
        />

        <AdminStakeholdersList
          stakeholders={stakeholders}
          onDelete={setIdDriver}
          sortBy={filterParam.sortBy}
          sortOrder={filterParam.sortOrder}
          onSort={handleSort}
        />

        <Pagination
          currentPage={filterParam.page}
          totalCount={totalCount}
          pageSize={PAGE_SIZE}
          onPageChange={(page) => handlePagination(page)}
        />
      </div>

      {!!idDriver && (
        <ModalDelete
          onDelete={handleDeleteDriver}
          onClose={() => setIdDriver(null)}
          text={t('decisionDrivers')}
        />
      )}
    </AdminPage>
  );
};

export default AdminStakeholders;
