import { ADMIN, USER } from './general';

const accessLevel = [
  {
    name: ADMIN
  },
  {
    name: USER
  }
];

export default accessLevel;
