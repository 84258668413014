import {
  GET_LOGIN_REQUEST,
  GET_LOGIN_SUCCESS,
  SET_TOKENS,
  GET_LOGIN_FAILED,
  CLEAN_AUTH,
  CLEAN_STORE,
  CLEAR_TOKENS,
  GET_FORGOT_PASSWORD_SUCCESS,
  GET_RESET_PASSWORD_SUCCESS,
  GET_FORGOT_PASSWORD_REQUEST,
  GET_RESET_PASSWORD_REQUEST,
  GET_RESET_PASSWORD_FAILED,
  GET_FORGOT_PASSWORD_FAILED,
  REFRESH_TOKENS
} from './types';

const initialState = {
  tokens: {
    accessToken: JSON.parse(localStorage.getItem('accessToken')) || undefined,
    refreshToken: JSON.parse(localStorage.getItem('refreshToken')) || undefined,
  },
  loading: false,
  error: null,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case GET_LOGIN_SUCCESS:
      return {
        ...state,
        tokens: {
          accessToken: action.accessToken,
          refreshToken: action.refreshToken
        },
        loading: false,
        error: null
      };
    case GET_FORGOT_PASSWORD_SUCCESS:
    case GET_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case GET_LOGIN_REQUEST:
    case GET_FORGOT_PASSWORD_REQUEST:
    case GET_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true
      };
    case GET_LOGIN_FAILED:
    case GET_FORGOT_PASSWORD_FAILED:
    case GET_RESET_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case SET_TOKENS:
      return {
        ...state,
        tokens: { ...state.tokens, ...action.tokens }
      };
    case REFRESH_TOKENS:
      return {
        ...state,
        tokens: { ...state.tokens, ...action.tokens }
      };
    case CLEAR_TOKENS:
      return {
        ...state,
        tokens: { ...initialState.tokens },
      };
    case CLEAN_AUTH:
      return {
        tokens: {
          accessToken: undefined,
          refreshToken: undefined,
        },
      };
    case CLEAN_STORE:
      return initialState;
    default:
      return state;
  }
}
