import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { exportFileAction } from '../../store/general/actions';
import outputData from '../../constants/outputData';
import Modal from '../Modal/Modal';
import Photo from '../Photo/Photo';
import Input from '../Input/Input';
import RadioButton from '../RadioButton/RadioButton';
import Button from '../Button/Button';
import styles from './sass/OutputDataModal.module.scss';

import { ReactComponent as ProjectLogo } from '../../assets/icons/projectIcon.svg';

const OutputDataModal = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    project: { id, name },
    general: { loading }
  } = useSelector(({ project, general }) => ({ project, general }), shallowEqual);

  const [typeDocument, setTypeDocument] = useState({
    summary: true,
    type: outputData.summary.docx.value
  });

  const handleChangeTypeDocument = useCallback((isSummary, type) => {
    setTypeDocument({
      summary: isSummary,
      type
    });
  }, []);

  const handleExportFile = useCallback(() => {
    dispatch(exportFileAction(id, typeDocument.type, name));
  }, [id, typeDocument, name]);

  return (
    <Modal
      onClose={onClose}
      text={t('outputDataByProject')}
    >
      <div className={styles.container}>
        <Photo placeholder={<ProjectLogo />} />
        <Input
          onChange={() => {}}
          title={t('projectName')}
          value={name}
          disabled
          style={styles.inputContainer}
        />
        <div className={styles.summaryContainer}>
          <RadioButton
            onChange={() => handleChangeTypeDocument(true, outputData.summary.docx.value)}
            checked={typeDocument.summary}
            label={outputData.summary.label}
            styleContainer={styles.summaryRadio}
            id="summary"
            name="summary"
          />
          <div className={styles.summaryTypesDocument}>
            <RadioButton
              onChange={() => handleChangeTypeDocument(true, outputData.summary.docx.value)}
              checked={typeDocument.type === outputData.summary.docx.value}
              label={outputData.summary.docx.label}
              disabled={!typeDocument.summary}
              id="docx"
              name={outputData.summary.docx.value}
            />
            <RadioButton
              onChange={() => handleChangeTypeDocument(true, outputData.summary.pptx.value)}
              checked={typeDocument.type === outputData.summary.pptx.value}
              label={outputData.summary.pptx.label}
              disabled={!typeDocument.summary}
              id="pptx"
              name={outputData.summary.pptx.value}
            />
          </div>
        </div>
        <div className={styles.allDataContainer}>
          <RadioButton
            onChange={() => handleChangeTypeDocument(false, outputData.allData.xlsx.value)}
            checked={!typeDocument.summary}
            label={outputData.allData.label}
            id="allData"
            name="allData"
          />
          <RadioButton
            onChange={() => handleChangeTypeDocument(false, outputData.allData.xlsx.value)}
            checked={typeDocument.type === outputData.allData.xlsx.value}
            label={outputData.allData.xlsx.label}
            disabled={typeDocument.summary}
            id="xlsx"
            name={outputData.allData.xlsx.value}
          />
        </div>
        <Button
          onClick={handleExportFile}
          text={t('download')}
          style={styles.buttonContainer}
          styleButton={styles.button}
          disabled={loading}
        />
      </div>
    </Modal>
  );
};

OutputDataModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default OutputDataModal;
