import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import StakeholdersProfilesItem from './StakeholdersProfilesItem';
import styles from '../sass/Stakeholders.module.scss';

const StakeholdersProfiles = ({
  profiles, nextLinkProfiles, onClick, onLoadMore, title
}) => {
  const { t } = useTranslation();
  return (
    profiles.length ? (
      <div className={styles.profilesContainer}>
        <div className={styles.profilesContent}>
          <div className={styles.title}>{`${t('other')} ${title}s`}</div>
          {profiles.map(({ full_name: name, id }) => (
            <StakeholdersProfilesItem
              key={id}
              name={name}
              id={id}
              onClick={onClick}
            />
          ))}
          {nextLinkProfiles && (
            <div
              role="presentation"
              onClick={onLoadMore}
              className={styles.profilesItem}
            >
              {t('more')}
            </div>
          )}
        </div>
      </div>
    ) : null
  );
};

StakeholdersProfiles.propTypes = {
  profiles: PropTypes.arrayOf(PropTypes.shape({
    full_name: PropTypes.string,
    id: PropTypes.number,
  })),
  nextLinkProfiles: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

StakeholdersProfiles.defaultProps = {
  profiles: [],
  nextLinkProfiles: null,
};

export default StakeholdersProfiles;
