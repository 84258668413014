import React from 'react';
import PropTypes from 'prop-types';

const M4ChartCustomizedShape = (props) => {
  const {
    cx, cy, positionX, positionY, competitorId
  } = props;

  const isActive = cx === positionX && cy === positionY;

  return (
    isActive ? (
      <circle
        cx={cx}
        cy={cy}
        r={!competitorId ? '14.9124' : '6.91235'}
        stroke={!competitorId ? undefined : '#515E71'}
        strokeWidth="6"
        fill={!competitorId ? '#00C437' : 'transparent'}
        cursor="pointer"
      />
    ) : (
      <circle
        cx={cx}
        cy={cy}
        r={!competitorId ? '12.9124' : '7.91235'}
        stroke={!competitorId ? '#00C337' : '#515E71'}
        strokeWidth="4"
        fill="transparent"
        cursor="pointer"
      />
    )
  );
};

M4ChartCustomizedShape.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  competitorId: PropTypes.number,
  positionX: PropTypes.number,
  positionY: PropTypes.number
};

M4ChartCustomizedShape.defaultProps = {
  cx: undefined,
  cy: undefined,
  competitorId: undefined,
  positionX: undefined,
  positionY: undefined
};

export default M4ChartCustomizedShape;
