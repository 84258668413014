import React, { useCallback, useMemo, useState } from 'react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  Tooltip,
  Label,
} from 'recharts';
import PropTypes from 'prop-types';
import { getChartData } from '../../../utils/helpers';
import chart from '../../../constants/chart';
import M4ChartArrowButton from './M4ChartArrowButton';
import M4ChartCustomizedShape from './M4ChartCustomizedShape';
import M4ChartCustomizedTooltip from './M4ChartCustomizedTooltip';
import M4ChartCustomLabelY from './M4ChartCustomLabelY';
import M4ChartCustomLabelX from './M4ChartCustomLabelX';
import styles from '../sass/M4.module.scss';

const M4Chart = ({
  decisionDriverX,
  decisionDriverY,
  driverXId,
  driverYId,
  driversList,
  onChangeDriverX,
  onChangeDriverY,
  onNextDriverX,
  onNextDriverY,
}) => {
  const [positionX, setPositionX] = useState(null);
  const [positionY, setPositionY] = useState(null);

  const handleChangePosition = useCallback((x, y) => {
    setPositionX(x);
    setPositionY(y);
  }, []);

  const data = useMemo(() => {
    return getChartData(decisionDriverX, decisionDriverY);
  }, [decisionDriverX, decisionDriverY]);

  return (
    <div className={styles.chartContainer}>
      <div className={styles.chart}>
        <ScatterChart
          width={chart.scatterChart.width}
          height={chart.scatterChart.height}
          margin={chart.scatterChart.margin}
          overflow={chart.scatterChart.overflow}
        >
          <Scatter
            data={data}
            shape={(
              <M4ChartCustomizedShape
                positionX={positionX}
                positionY={positionY}
              />
            )}
            onMouseLeave={() => handleChangePosition(null, null)}
            onMouseOver={({ cx, cy }) => handleChangePosition(cx, cy)}
          />
          <Tooltip
            cursor={chart.tooltip.cursor}
            isAnimationActive={chart.tooltip.isAnimationActive}
            content={<M4ChartCustomizedTooltip data={data} />}
            wrapperStyle={chart.tooltip.wrapperStyle}
            position={{
              x: positionX ?? 0,
              y: positionY ?? 0
            }}
          />
          <XAxis
            type="number"
            tick={chart.axis.tick}
            dataKey="x"
            stroke={chart.axis.stroke}
          >
            <Label content={(
              <M4ChartCustomLabelX
                driverXId={driverXId}
                driversList={driversList.filter(({ id }) => id !== driverYId)}
                onChange={onChangeDriverX}
              />
            )}
            />
          </XAxis>
          <YAxis
            type="number"
            tick={chart.axis.tick}
            dataKey="y"
            stroke={chart.axis.stroke}
          >
            <Label content={(
              <M4ChartCustomLabelY
                driverYId={driverYId}
                driversList={driversList.filter(({ id }) => id !== driverXId)}
                onChange={onChangeDriverY}
              />
            )}
            />
          </YAxis>
        </ScatterChart>
      </div>

      <M4ChartArrowButton
        color={chart.topNavigation.color}
        styleContainer={styles.topArrow}
        style={styles.arrow}
        onClick={onNextDriverY}
      />

      <M4ChartArrowButton
        color={chart.rightNavigation.color}
        styleContainer={styles.rightArrow}
        style={styles.arrow}
        onClick={onNextDriverX}
      />
    </div>
  );
};

M4Chart.propTypes = {
  decisionDriverX: PropTypes.arrayOf(PropTypes.shape({
    competitor_name: PropTypes.string,
    value: PropTypes.number,
    competitor_id: PropTypes.number
  })),
  decisionDriverY: PropTypes.arrayOf(PropTypes.shape({
    competitor_name: PropTypes.string,
    value: PropTypes.number,
    competitor_id: PropTypes.number
  })),
  driverXId: PropTypes.number.isRequired,
  driverYId: PropTypes.number.isRequired,
  driversList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  onChangeDriverX: PropTypes.func.isRequired,
  onChangeDriverY: PropTypes.func.isRequired,
  onNextDriverX: PropTypes.func.isRequired,
  onNextDriverY: PropTypes.func.isRequired,
};

M4Chart.defaultProps = {
  decisionDriverX: [],
  decisionDriverY: [],
  driversList: []
};

export default M4Chart;
