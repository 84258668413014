import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useOnBlur from '../../../hooks/useOnBlur';
import { deleteSpacesInString } from '../../../utils/helpers';
import rateOptions from '../../../constants/rateOptions';
import Input from '../../../components/Input/Input';
import SelectInput from '../../../components/SelectInput/SelectInput';
import styles from '../sass/M5.module.scss';

const M5Offering = ({
  title, value, messageForOffering, id, onUpdateOffering, onDeleteOffering
}) => {
  const { t } = useTranslation();

  const [offeringTitle, setOfferingTitle] = useState(title);
  const [offeringMessage, setOfferingMessage] = useState(messageForOffering);

  const handleTitleChange = useCallback((e) => {
    const text = deleteSpacesInString(e.target.value);
    setOfferingTitle(text);
  }, []);

  const handleMessageForOfferingChange = useCallback((e) => {
    const text = deleteSpacesInString(e.target.value);
    setOfferingMessage(text);
  }, []);

  const handleBlurOfferingTitle = useOnBlur(
    title,
    offeringTitle,
    () => onUpdateOffering({
      id,
      title: offeringTitle,
      messageForOffering: offeringMessage,
      value
    }),
    () => onDeleteOffering(id)
  );

  const handleBlurOfferingMessage = useOnBlur(
    messageForOffering,
    offeringMessage,
    () => onUpdateOffering({
      id,
      title: offeringTitle,
      messageForOffering: offeringMessage,
      value
    }),
    () => onUpdateOffering({
      id,
      title: offeringTitle,
      messageForOffering: offeringMessage,
      value
    }),
  );

  return (
    <div className={styles.offeringContainer}>
      <div className={styles.potentialCustomer}>
        <Input
          onChange={handleTitleChange}
          style={styles.input}
          value={offeringTitle}
          onBlur={handleBlurOfferingTitle}
          title={t('offeringPlaceholder')}
        />
        <SelectInput
          onChange={(rate) => onUpdateOffering({
            id,
            title,
            messageForOffering,
            value: +rate
          })}
          styleContainer={styles.select}
          value={!id ? '' : value.toString()}
          title={t('rank')}
          values={rateOptions}
          checkId={false}
          disabled={!id}
          t
        />
      </div>
      <Input
        onChange={handleMessageForOfferingChange}
        style={styles.potentialMessaging}
        value={offeringMessage}
        onBlur={handleBlurOfferingMessage}
        disabled={!id}
        title={t('messagePlaceholder')}
      />
    </div>
  );
};

M5Offering.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  messageForOffering: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  onUpdateOffering: PropTypes.func.isRequired,
  onDeleteOffering: PropTypes.func.isRequired
};

export default M5Offering;
