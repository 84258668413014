import {
  SET_STAKEHOLDERS_AVATAR_SUCCESS,
  SET_STAKEHOLDERS_WORK_SHEET_NAME_SUCCESS,
  SET_STAKEHOLDERS_WORK_SHEET_MEANING_SUCCESS,
  SET_STAKEHOLDERS_WORK_SHEET_LINKS_SUCCESS,
  SET_STAKEHOLDERS_WORK_SHEET_DRIVERS_SUCCESS,
  SET_STAKEHOLDERS_WORK_SHEET_TRIGGERS_SUCCESS,
  SET_STAKEHOLDERS_WORK_SHEET_SUCCESS,
  SET_STAKEHOLDERS_REQUEST,
  SET_STAKEHOLDERS_FAILED,
  CLEAN_STAKEHOLDERS,
  SET_STAKEHOLDERS_LIST_SUCCESS,
  SET_STAKEHOLDERS_PROFILES_SUCCESS,
  SET_STAKEHOLDERS_RANK_SUCCESS
} from './types';

const initialState = {
  stakeholdersList: [],
  name: '',
  id: undefined,
  avatars: null,
  stakeholderId: undefined,
  profiles: [],
  nextLinkProfiles: null,
  totalCountProfiles: 0,
  rank: null,
  meanings: [],
  drivers: [],
  triggers: [],
  links: [],
  loading: false,
  error: null,
};

export default function stakeholders(state = initialState, action) {
  switch (action.type) {
    case SET_STAKEHOLDERS_AVATAR_SUCCESS:
      return {
        ...state,
        avatars: action.data,
        loading: false,
        error: null
      };
    case SET_STAKEHOLDERS_WORK_SHEET_NAME_SUCCESS:
      return {
        ...state,
        name: action.data,
        loading: false,
        error: null
      };
    case SET_STAKEHOLDERS_WORK_SHEET_MEANING_SUCCESS:
      return {
        ...state,
        meanings: action.data,
        loading: false,
        error: null
      };
    case SET_STAKEHOLDERS_WORK_SHEET_LINKS_SUCCESS:
      return {
        ...state,
        links: action.data,
        loading: false,
        error: null
      };
    case SET_STAKEHOLDERS_WORK_SHEET_DRIVERS_SUCCESS:
      return {
        ...state,
        drivers: action.data,
        loading: false,
        error: null
      };
    case SET_STAKEHOLDERS_WORK_SHEET_TRIGGERS_SUCCESS:
      return {
        ...state,
        triggers: action.data,
        loading: false,
        error: null
      };
    case SET_STAKEHOLDERS_WORK_SHEET_SUCCESS:
      return {
        ...state,
        id: action.data.id,
        name: action.data.full_name,
        avatars: action.data.avatars,
        stakeholderId: action.data.stakeholder_id,
        segment: action.data.segment_id,
        meanings: action.data.meanings,
        drivers: action.data.drivers,
        triggers: action.data.triggers,
        links: action.data.links,
        rank: action.data.value,
        loading: false,
        error: null
      };
    case SET_STAKEHOLDERS_RANK_SUCCESS:
      return {
        ...state,
        rank: action.data,
        loading: false,
        error: null
      };
    case SET_STAKEHOLDERS_LIST_SUCCESS:
      return {
        ...state,
        stakeholdersList: action.data,
        loading: false,
        error: null
      };
    case SET_STAKEHOLDERS_PROFILES_SUCCESS: {
      return {
        ...state,
        profiles: action.data,
        nextLinkProfiles: action.nextLink,
        totalCountProfiles: action.totalCount,
        loading: false,
        error: null
      };
    }
    case SET_STAKEHOLDERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_STAKEHOLDERS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case CLEAN_STAKEHOLDERS:
      return initialState;
    default:
      return state;
  }
}
