import {
  SET_M3_FAILED,
  SET_M3_REQUEST,
  SET_M3_SEGMENT_LIST_SUCCESS,
  SET_M3_TOP_MARKET_DRIVERS_SUCCESS,
  SET_M3_ORGANIZATION_DRIVERS_SUCCESS,
  SET_M3_COMPETITORS_DRIVERS_SUCCESS,
  SET_M3_CHANGE_SEGMENT_SUCCESS,
  CLEAN_M3,
  SET_M3_OWN_ORGANIZATION_NAME_SUCCESS,
  SET_M3_OWN_ORGANIZATION_OFFERING_SUCCESS,
} from './types';

const initialState = {
  segmentCustomerLists: [],
  topMarketDrivers: [],
  organizationDrivers: [],
  competitorsDrivers: [],
  segmentId: 0,
  ownOrganizationName: '',
  ownOrganizationOffering: '',
  loading: false,
  error: null
};

export default function m3(state = initialState, action) {
  switch (action.type) {
    case SET_M3_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_M3_SEGMENT_LIST_SUCCESS:
      return {
        ...state,
        segmentCustomerLists: action.data,
        loading: false,
        error: null
      };
    case SET_M3_TOP_MARKET_DRIVERS_SUCCESS:
      return {
        ...state,
        topMarketDrivers: action.data,
        loading: false,
        error: null
      };
    case SET_M3_ORGANIZATION_DRIVERS_SUCCESS:
      return {
        ...state,
        organizationDrivers: action.data,
        loading: false,
        error: null
      };
    case SET_M3_COMPETITORS_DRIVERS_SUCCESS:
      return {
        ...state,
        competitorsDrivers: action.data,
        loading: false,
        error: null
      };
    case SET_M3_CHANGE_SEGMENT_SUCCESS:
      return {
        ...state,
        segmentId: action.data
      };
    case SET_M3_OWN_ORGANIZATION_NAME_SUCCESS:
      return {
        ...state,
        ownOrganizationName: action.data,
        loading: false,
        error: null
      };
    case SET_M3_OWN_ORGANIZATION_OFFERING_SUCCESS:
      return {
        ...state,
        ownOrganizationOffering: action.data,
        loading: false,
        error: null
      };
    case SET_M3_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case CLEAN_M3:
      return initialState;
    default:
      return state;
  }
}
