const rateOptions = [
  { name: '10' },
  { name: '9' },
  { name: '8' },
  { name: '7' },
  { name: '6' },
  { name: '5' },
  { name: '4' },
  { name: '3' },
  { name: '2' },
  { name: '1' },
  { name: '0' }
];

export default rateOptions;
