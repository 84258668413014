import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeScenarioStatusAction,
  cleanAdminScenarios,
  deleteScenarioAction,
  getScenariosAction
} from '../../../store/admin/adminScenarios/actions';
import { setFromPath } from '../../../store/general/actions';
import { PAGE_SIZE, SCENARIO } from '../../../constants/general';
import Button from '../../../components/Button/Button';
import AdminPage from '../../../layout/admin/AdminPage/AdminPage';
import Title from '../../../components/Title/Title';
import Pagination from '../../../components/Pagination/Pagination';
import AdminScenarioScenarios from './components/AdminScenarioScenarios';
import AdminScenariosFilter from './components/AdminScenariosFilter';
import ModalDelete from '../../../components/ModalDelete/ModalDelete';
import styles from './sass/AdminScenarios.module.scss';

import { ReactComponent as PlusIcon } from '../../../assets/icons/plusBlack.svg';

const AdminScenarios = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [idScenario, setIdScenario] = useState(null);

  const {
    adminScenarios: {
      loading, scenarios, totalCount
    },
  } = useSelector(({ adminScenarios }) => ({ adminScenarios }));

  const [filterParam, setFilterParam] = useState({
    name: searchParams.get('name') || '',
    start: searchParams.get('start') || null,
    end: searchParams.get('end') || null,
    page: +searchParams.get('page') || 1,
    sortOrder: searchParams.get('sortOrder') || 'DESC',
    sortBy: searchParams.get('sortBy') || 'created_at',
  });

  useEffect(() => {
    dispatch(getScenariosAction(filterParam));
    return () => {
      dispatch(cleanAdminScenarios());
    };
  }, []);

  const handleSearchDate = useCallback((start, end) => {
    setFilterParam((prevState) => ({ ...prevState, start, end }));
  }, []);

  const handleSearchInput = useCallback((event) => {
    setFilterParam((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
  }, []);

  const handleFilter = useCallback(() => {
    setFilterParam({ ...filterParam, end: filterParam.end || filterParam.start, page: 1 });
    dispatch(getScenariosAction({
      ...filterParam,
      end: filterParam.end || filterParam.start,
      page: 1
    }, setSearchParams));
  }, [filterParam]);

  const handlePagination = useCallback((page) => {
    setFilterParam({ ...filterParam, page });
    dispatch(getScenariosAction({ ...filterParam, page }, setSearchParams));
  }, [filterParam]);

  const handleSort = useCallback((sortBy, sortOrder) => {
    setFilterParam({ ...filterParam, sortBy, sortOrder });
    dispatch(getScenariosAction({ ...filterParam, sortBy, sortOrder }, setSearchParams));
  }, [filterParam]);

  const handleDeleteScenario = useCallback(() => {
    if (scenarios.length === 1 && filterParam.page > 1) {
      setFilterParam({ ...filterParam, page: filterParam.page - 1 });
      dispatch(deleteScenarioAction(idScenario, { ...filterParam, page: filterParam.page - 1 }, setSearchParams));
    } else {
      dispatch(deleteScenarioAction(idScenario, filterParam));
    }
    setIdScenario(null);
  }, [idScenario, scenarios, filterParam]);

  const handleChangeStatus = useCallback((id, status) => {
    dispatch(changeScenarioStatusAction(id, status, scenarios, totalCount));
  }, [scenarios, totalCount]);

  const handleSaveScenariosPath = useCallback((path) => {
    dispatch(setFromPath(path));
  }, []);
  
  return (
    <AdminPage pageContainer={styles.container} loader={loading} activeTab={SCENARIO}>
      <div className={styles.content}>
        <div className={styles.logo} />
        <Title text={t('administration')} style={styles.title} />
        <div className={styles.subtitle}>
          <Title text={t('scenarios')} Type="h3" style={styles.subtitleText} />
          <Button
            text={t('addScenario')}
            icon={<PlusIcon />}
            style={styles.buttonContainer}
            styleButton={styles.button}
            onClick={() => navigate('create')}
          />
        </div>

        <AdminScenariosFilter
          name={filterParam.name}
          start={filterParam.start}
          end={filterParam.end}
          onChange={handleSearchInput}
          onChangeDate={handleSearchDate}
          onClick={handleFilter}
        />

        <AdminScenarioScenarios
          scenarios={scenarios}
          sortBy={filterParam.sortBy}
          sortOrder={filterParam.sortOrder}
          onSort={handleSort}
          onDelete={setIdScenario}
          onChangeStatus={handleChangeStatus}
          onSaveScenariosPath={handleSaveScenariosPath}
        />

        <Pagination
          currentPage={filterParam.page}
          totalCount={totalCount}
          pageSize={PAGE_SIZE}
          onPageChange={(page) => handlePagination(page)}
        />
      </div>

      {!!idScenario && (
        <ModalDelete
          onDelete={handleDeleteScenario}
          onClose={() => setIdScenario(null)}
          text={t('scenario')}
        />
      )}
    </AdminPage>
  );
};

export default AdminScenarios;
