import instance from '../../../services/axios';
import {
  SET_ADMIN_USER_SUCCESS,
  SET_ADMIN_USER_REQUEST,
  SET_ADMIN_USER_FAILED,
  SET_ADMIN_USER_EDIT_REQUEST,
  SET_ADMIN_USER_EDIT_SUCCESS,
  SET_ADMIN_USER_EDIT_FAILED,
  CLEAN_EDIT_USERS
} from './types';
import { POST, PUT, USERS_URL } from '../../../constants/api';
import notify from '../../../utils/notify';

export const setAdminUserSuccess = (data) => ({ type: SET_ADMIN_USER_SUCCESS, data });
export const setAdminUserRequest = () => ({ type: SET_ADMIN_USER_REQUEST });
export const setAdminUserFailed = (error) => ({ type: SET_ADMIN_USER_FAILED, error });

export const setEditUserRequest = () => ({ type: SET_ADMIN_USER_EDIT_REQUEST });
export const setEditUserSuccess = () => ({ type: SET_ADMIN_USER_EDIT_SUCCESS });
export const setEditUserFailed = (error) => ({ type: SET_ADMIN_USER_EDIT_FAILED, error });

export const cleanEditUsers = () => ({ type: CLEAN_EDIT_USERS });

export const getUserAction = (id) => {
  return (dispatch) => {
    dispatch(setAdminUserRequest());
    instance.get(`${USERS_URL}/${id}`)
      .then(({ data: { data } }) => dispatch(setAdminUserSuccess(data)))
      .catch(({ response: { data: errorData } }) => {
        dispatch(setAdminUserFailed(errorData));
        notify('error', errorData.message);
      });
  };
};

export const editUserAction = (values, id, navigate) => {
  const editUser = {
    first_name: values.firstName,
    last_name: values.lastName,
    email: values.email,
    user_type: values.userType
  };
  return (dispatch) => {
    dispatch(setEditUserRequest());
    instance[id ? PUT : POST](id ? `${USERS_URL}/${id}` : USERS_URL, editUser)
      .then(() => {
        dispatch(setEditUserSuccess());
        notify('success', 'Success');
        navigate(-1);
      })
      .catch(({ response: { data } }) => {
        dispatch(setEditUserFailed(data));
        notify('error', data.message);
      });
  };
};
