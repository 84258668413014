import React from 'react';
import PropTypes from 'prop-types';
import O1ItemOfferings from './O1ItemOfferings';
import O1ItemCapabilities from './O1ItemCapabilities';
import styles from '../sass/O1.module.scss';

const O1Item = ({
  title,
  value,
  onChangeOffering,
  onChangeCapabilities,
  onDeleteOffering,
  onDeleteCapabilities,
  operationalPriorities
}) => {
  return (
    <div className={styles.itemContainer}>
      <O1ItemOfferings
        title={title}
        value={value}
        onChangeOffering={onChangeOffering}
        onDeleteOffering={onDeleteOffering}
      />
      <O1ItemCapabilities
        onChangeCapabilities={onChangeCapabilities}
        onDeleteCapabilities={onDeleteCapabilities}
        operationalPriorities={operationalPriorities}
      />
    </div>
  );
};

O1Item.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChangeOffering: PropTypes.func.isRequired,
  onChangeCapabilities: PropTypes.func.isRequired,
  onDeleteOffering: PropTypes.func.isRequired,
  onDeleteCapabilities: PropTypes.func.isRequired,
  operationalPriorities: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    value: PropTypes.number
  })).isRequired
};

export default O1Item;
