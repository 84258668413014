import {
  CLEAN_EDIT_STAKEHOLDER,
  SET_ADMIN_STAKEHOLDER_EDIT_FAILED,
  SET_ADMIN_STAKEHOLDER_EDIT_REQUEST,
  SET_ADMIN_STAKEHOLDER_EDIT_SUCCESS,
  SET_ADMIN_STAKEHOLDER_SUCCESS,
  SET_ADMIN_STAKEHOLDER_SCENARIO_SUCCESS,
  SET_ADMIN_STAKEHOLDER_STAKEHOLDERS_LIST_SUCCESS
} from './types';
import {
  ADMIN_SCENARIO_STAKEHOLDERS_URL,
  ADMIN_SCENARIOS_URL,
  POST,
  PUT,
  STAKEHOLDER_TYPES_URL
} from '../../../constants/api';
import instance from '../../../services/axios';
import notify from '../../../utils/notify';

export const setEditStakeholderRequest = () => ({ type: SET_ADMIN_STAKEHOLDER_EDIT_REQUEST });
export const setEditStakeholderFailed = (error) => ({ type: SET_ADMIN_STAKEHOLDER_EDIT_FAILED, error });
export const setEditDriverSuccess = () => ({ type: SET_ADMIN_STAKEHOLDER_EDIT_SUCCESS });
export const setAdminStakeholderSuccess = (data) => ({ type: SET_ADMIN_STAKEHOLDER_SUCCESS, data });
export const setScenarioSuccess = (data) => ({ type: SET_ADMIN_STAKEHOLDER_SCENARIO_SUCCESS, data });
export const cleanEditStakeholder = () => ({ type: CLEAN_EDIT_STAKEHOLDER });
export const setStakeholdersListSuccess = (data) => ({ type: SET_ADMIN_STAKEHOLDER_STAKEHOLDERS_LIST_SUCCESS, data });

export const getStakeholderAction = (id, scenarioId) => {
  return (dispatch) => {
    dispatch(setEditStakeholderRequest());
    instance.get(`${ADMIN_SCENARIOS_URL}/${scenarioId}/scenario_stakeholders/${id}`)
      .then(({ data: { data } }) => dispatch(setAdminStakeholderSuccess(data)))
      .catch(({ response: { data } }) => {
        dispatch(setEditStakeholderFailed(data));
        notify('error', data.message);
      });
  };
};

export const getEditStakeholderListAction = () => {
  return (dispatch) => {
    dispatch(setEditStakeholderRequest());
    instance.get(STAKEHOLDER_TYPES_URL)
      .then(({ data: { data } }) => dispatch(setStakeholdersListSuccess(data)))
      .catch(({ response: { data } }) => {
        dispatch(setEditStakeholderFailed(data));
        notify('error', data.message);
      });
  };
};

export const getEditStakeholderScenarioAction = (scenarioId) => {
  return (dispatch) => {
    dispatch(setEditStakeholderRequest());
    instance.get(`${ADMIN_SCENARIOS_URL}/${scenarioId}`)
      .then(({ data: { data } }) => dispatch(setScenarioSuccess(data)))
      .catch(({ response: { data } }) => {
        dispatch(setEditStakeholderFailed(data));
        notify('error', data.message);
      });
  };
};

export const editStakeholderDrivers = (drivers, stakeholderId, navigate) => {
  return (dispatch) => {
    instance.post(`${ADMIN_SCENARIO_STAKEHOLDERS_URL}/${stakeholderId}/scenario_stakeholder_drivers`, drivers)
      .then(() => {
        dispatch(setEditDriverSuccess());
        notify('success', 'Success');
        navigate(-1);
      })
      .catch(({ response: { data } }) => {
        dispatch(setEditStakeholderFailed(data));
        notify('error', data.message);
      });
  };
};

export const editScenarioStakeholder = (
  values,
  scenarioId,
  stakeholderId,
  navigate
) => {
  const url = stakeholderId
    ? `${ADMIN_SCENARIOS_URL}/${scenarioId}/scenario_stakeholders/${stakeholderId}`
    : `${ADMIN_SCENARIOS_URL}/${scenarioId}/scenario_stakeholders`;
  const editStakeholder = {
    name: values.name,
    stakeholder_type_id: values.stakeholderType,
  };
  return (dispatch) => {
    dispatch(setEditStakeholderRequest());
    instance[stakeholderId ? PUT : POST](url, editStakeholder)
      .then(({ data: { data } }) => {
        dispatch(editStakeholderDrivers(values.drivers, data.id, navigate));
      })
      .catch(({ response: { data } }) => {
        dispatch(setEditStakeholderFailed(data));
        notify('error', data.message);
      });
  };
};
