import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import styles from './sass/VideoModal.module.scss';
import Modal from '../Modal/Modal';

const VideoModal = ({ url, onClose }) => {
  return (
    <Modal onClose={onClose}>
      <div className={styles.container}>
        <ReactPlayer
          url={url}
          controls
          volume={1}
          width="100%"
          height="100%"
        />
      </div>
    </Modal>
  );
};

VideoModal.propTypes = {
  url: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

VideoModal.defaultProps = {
  url: ''
};

export default VideoModal;
