export const SET_M1_SEGMENT_LIST_SUCCESS = 'SET_M1_SEGMENT_LIST_SUCCESS';
export const SET_M1_CREATE_WORK_SHEET_SUCCESS = 'SET_M1_CREATE_WORK_SHEET_SUCCESS';
export const SET_M1_LAST_WORK_SHEET_SUCCESS = 'SET_M1_LAST_WORK_SHEET_SUCCESS';
export const SET_M1_SELECT_WORK_SHEET_SUCCESS = 'SET_M1_SELECT_WORK_SHEET_SUCCESS';
export const SET_M1_REQUEST = 'SET_M1_REQUEST';
export const SET_M1_FAILED = 'SET_M1_FAILED';
export const CLEAN_M1 = 'CLEAN_M1';
export const SET_M1_AVATAR_SUCCESS = 'SET_M1_AVATAR_SUCCESS';
export const SET_M1_WORK_SHEET_SUCCESS = 'SET_M1_WORK_SHEET_SUCCESS';
export const SET_M1_WORK_SHEET_SEGMENT_SUCCESS = 'SET_M1_WORK_SHEET_SEGMENT_SUCCESS';
export const SET_M1_WORK_SHEET_MEANING_SUCCESS = 'SET_M1_WORK_SHEET_MEANING_SUCCESS';
export const SET_M1_WORK_SHEET_LINKS_SUCCESS = 'SET_M1_WORK_SHEET_LINKS_SUCCESS';
export const SET_M1_WORK_SHEET_DRIVERS_SUCCESS = 'SET_M1_WORK_SHEET_DRIVERS_SUCCESS';
export const SET_M1_WORK_SHEET_TRIGGERS_SUCCESS = 'SET_M1_WORK_SHEET_TRIGGERS_SUCCESS';
