import React from 'react';
import PropTypes from 'prop-types';
import styles from './sass/Title.module.scss';

const Title = ({
  text, Type, style, icon, visibleTitle
}) => {
  return (
    <Type className={`${styles.text} ${style} ${!visibleTitle && styles.noVisible}`}>
      {text}
      {icon}
    </Type>
  );
};

Title.propTypes = {
  text: PropTypes.string.isRequired,
  Type: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']),
  style: PropTypes.string,
  icon: PropTypes.node,
  visibleTitle: PropTypes.bool
};

Title.defaultProps = {
  Type: 'h1',
  style: '',
  icon: null,
  visibleTitle: true
};

export default Title;
