import React from 'react';
import PropTypes from 'prop-types';
import AdminScenarioScenariosHeader from './AdminScenarioScenariosHeader';
import AdminScenarioRow from './AdminScenarioRow';
import NoData from '../../../../components/NoData/NoData';
import styles from '../sass/AdminScenarios.module.scss';

const AdminScenarioScenarios = ({
  scenarios, sortBy, sortOrder, onSort,
  onDelete, onChangeStatus, onSaveScenariosPath
}) => {
  return (
    <div className={styles.scenarios}>
      {scenarios.length ? (
        <>
          <AdminScenarioScenariosHeader
            sortBy={sortBy}
            sortOrder={sortOrder}
            onSort={onSort}
          />

          {scenarios.map(({
            id,
            name,
            description,
            created_at: createdAt,
            is_active: isActive,
            stakeholders_count: stakeholdersCount,
            trends_count: trendsCount
          }) => (
            <AdminScenarioRow
              key={id}
              id={id}
              name={name}
              description={description}
              createdAt={createdAt}
              isActive={isActive}
              stakeholdersCount={stakeholdersCount}
              trendsCount={trendsCount}
              onDelete={onDelete}
              onChangeStatus={onChangeStatus}
              onSaveScenariosPath={onSaveScenariosPath}
            />
          ))}
        </>
      ) : <NoData />}
    </div>
  );
};

AdminScenarioScenarios.propTypes = {
  scenarios: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    stakeholders_count: PropTypes.number,
    trends_count: PropTypes.number,
    is_active: PropTypes.bool,
    created_at: PropTypes.string
  })).isRequired,
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onSaveScenariosPath: PropTypes.func.isRequired
};

export default AdminScenarioScenarios;
