import React from 'react';
import PropTypes from 'prop-types';
import O3Header from './O3Header';
import O3PotentialPartnerships from './O3PotentialPartnerships';
import styles from '../sass/O3.module.scss';

const O3Item = ({
  value,
  name,
  potentialPartnership,
  style,
  onChangePriority,
  onDeletePriority,
  stakeholdersList,
  onChangePartnership,
  onDeletePartnership,
  title,
  idPriority,
  meaningsList,
  meaningId
}) => {
  return (
    <div className={styles.itemContainer}>
      <O3Header
        name={name}
        value={value}
        title={title}
        style={style}
        onChangePriority={onChangePriority}
        onDeletePriority={onDeletePriority}
        meaningsList={meaningsList}
        meaningId={meaningId}
      />
      <O3PotentialPartnerships
        potentialPartnership={potentialPartnership}
        stakeholdersList={stakeholdersList}
        onChangePartnership={onChangePartnership}
        onDeletePartnership={onDeletePartnership}
        idPriority={idPriority}
      />
    </div>
  );
};

O3Item.propTypes = {
  value: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  potentialPartnership: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.number,
    stakeholder_type_id: PropTypes.number,
  })),
  onChangePriority: PropTypes.func.isRequired,
  onDeletePriority: PropTypes.func.isRequired,
  stakeholdersList: PropTypes.arrayOf(PropTypes.shape(
    {
      id: PropTypes.number,
      name: PropTypes.string,
      children: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number
      }))
    }
  )),
  onChangePartnership: PropTypes.func.isRequired,
  onDeletePartnership: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  style: PropTypes.shape({
    color: PropTypes.string,
    fontWeight: PropTypes.string,
  }).isRequired,
  idPriority: PropTypes.number.isRequired,
  meaningsList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })).isRequired,
  meaningId: PropTypes.number.isRequired,
};

O3Item.defaultProps = {
  potentialPartnership: [],
  stakeholdersList: []
};

export default O3Item;
