import {
  SET_ADMIN_USER_EDIT_FAILED,
  SET_ADMIN_USER_EDIT_REQUEST,
  SET_ADMIN_USER_EDIT_SUCCESS,
  SET_ADMIN_USER_SUCCESS,
  SET_ADMIN_USER_FAILED,
  CLEAN_EDIT_USERS,
  SET_ADMIN_USER_REQUEST,
} from './types';

const initialState = {
  user: null,
  loading: false,
  error: null,
};

export default function adminEditUser(state = initialState, action) {
  switch (action.type) {
    case SET_ADMIN_USER_EDIT_REQUEST:
    case SET_ADMIN_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_ADMIN_USER_EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case SET_ADMIN_USER_SUCCESS:
      return {
        ...state,
        user: action.data,
        loading: false,
        error: null,
      };
    case SET_ADMIN_USER_EDIT_FAILED:
    case SET_ADMIN_USER_FAILED:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case CLEAN_EDIT_USERS:
      return initialState;
    default:
      return state;
  }
}
