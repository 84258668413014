import { batch } from 'react-redux';
import toast from 'react-hot-toast';
import instance from '../../services/axios';
import {
  SET_INDUSTRIES_LIST_SUCCESS, GET_SIGNUP_REQUEST, GET_SIGNUP_FAILED, SET_LOADING
} from './types';
import {
  INDUSTRIES_LIST,
  SIGN_UP_URL,
  RESEND_EMAIL_URL,
} from '../../constants/api';
import { setEmail } from '../user/actions';

export const setIndustriesListSuccess = (data) => ({ type: SET_INDUSTRIES_LIST_SUCCESS, industriesList: data });
export const getSignUpRequest = () => ({ type: GET_SIGNUP_REQUEST });
export const getSignUpFailed = (error) => ({ type: GET_SIGNUP_FAILED, error });
export const setLoading = (value) => ({ type: SET_LOADING, value });

export const getIndustriesList = () => {
  return (dispatch) => {
    dispatch(getSignUpRequest());
    instance.get(INDUSTRIES_LIST)
      .then(({ data: { data } }) => {
        dispatch(setIndustriesListSuccess(data));
      })
      .catch((error) => {
        dispatch(getSignUpFailed(error));
      });
  };
};

export const registration = (values, setFieldError, navigate) => {
  const data = {
    first_name: values.firstName,
    last_name: values.lastName,
    email: values.email,
    password: values.password,
    password_confirmation: values.confirmPassword,
    company_name: values.projectCompany,
    industry_id: values.industry,
    currency_id: values.currency,
  };
  return (dispatch) => {
    dispatch(getSignUpRequest());
    instance.post(SIGN_UP_URL, data, { withoutAuth: true })
      .then(() => {
        batch(() => {
          dispatch(setEmail(values.email));
          dispatch(setLoading(false));
        });
        localStorage.setItem('email', JSON.stringify(values.email));
        navigate('/verify');
      })
      .catch(({ response: { data: errorData } }) => {
        dispatch(getSignUpFailed(errorData));
        if (errorData.errors.email) setFieldError('email', ...errorData.errors.email);
      });
  };
};

export const resendEmailAction = () => {
  const notify = (text) => toast.error(text, { position: 'top-right' });
  return (dispatch, getState) => {
    const { user: { email } } = getState();
    dispatch(getSignUpRequest());
    instance.post(RESEND_EMAIL_URL, { email }, { withoutAuth: true })
      .then(() => dispatch(setLoading(false)))
      .catch((({ response: { data: errorData } }) => {
        dispatch(getSignUpFailed(errorData));
        notify(errorData?.message || 'Something went wrong');
      }));
  };
};
