import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import TriggersColumn from './components/TriggersColumn';
import styles from './sass/Triggers.module.scss';

const Triggers = ({
  triggers,
  onChange,
  onDeleteWorksheet,
  triggersTypes
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.triggersContainer}>
      <Button
        text={t('deleteProfile')}
        style={`${styles.buttonContainer} ${onDeleteWorksheet && styles.buttonContainerVisible}`}
        onClick={onDeleteWorksheet}
      />
      <div className={styles.triggersWrapper}>
        {Object.keys(triggersTypes).map((item) => {
          const data = triggers.filter(({ type }) => type === item);
          return (
            <TriggersColumn
              key={item}
              type={item}
              trigger={data[0]}
              onChange={onChange}
              title={t(`${item}Triggers`)}
              tip={triggersTypes[item].tip}
            />
          );
        })}
      </div>
    </div>
  );
};

Triggers.propTypes = {
  triggers: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    type: PropTypes.string,
    id: PropTypes.number
  })),
  onChange: PropTypes.func.isRequired,
  onDeleteWorksheet: PropTypes.func,
  triggersTypes: PropTypes.shape({
    emotional: PropTypes.shape({ type: PropTypes.string, tip: PropTypes.node }),
  }).isRequired
};

Triggers.defaultProps = {
  triggers: [],
  onDeleteWorksheet: undefined
};

export default Triggers;
