import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Toaster } from 'react-hot-toast';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useMenu from '../../hooks/useMenu';
import { logOutAction } from '../../store/auth/actions';
import Header from '../Header/Header';
import SimpleHeader from '../SimpleHeader/SimpleHeader';
import Loader from '../../components/Loader/Loader';
import OutputDataModal from '../../components/OutputDataModal/OutputDataModal';
import styles from './sass/Page.module.scss';

export default function Page({
  children,
  simpleHeader,
  loader,
  pageContainer,
  whiteTheme,
  breadcrumbs,
  switchPages,
  newAction,
  logout,
  goBack
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    project: { loading: projectLoader }
  } = useSelector(({ project }) => ({ project }), shallowEqual);

  const [showOutputDataModal, setShowOutputDataModal] = useState(false);
  
  const handleLogout = useCallback(() => {
    dispatch(logOutAction());
  }, []);

  const handleGoBack = useCallback(() => navigate('admin'), []);

  const menuButtons = useMenu(
    () => {},
    () => {},
    () => {},
    () => {},
    () => {},
    () => setShowOutputDataModal(true),
    () => {}
  );

  const loading = projectLoader || loader;

  return (
    <div className={`${styles.page} ${whiteTheme && styles.pageBlue}`}>
      {simpleHeader
        ? (
          <SimpleHeader
            onLogout={handleLogout}
            logout={logout}
            goBack={goBack}
            onGoBack={handleGoBack}
          />
        )
        : (
          <Header
            whiteTheme={whiteTheme}
            breadcrumbs={breadcrumbs} 
            switchPages={switchPages}
            newAction={newAction}
            onLogout={handleLogout}
            menuButtons={menuButtons}
          />
        )}
      {loading && <Loader />}
      {showOutputDataModal && (
        <OutputDataModal onClose={() => setShowOutputDataModal(false)} />
      )}
      <div className={`container ${pageContainer}`}>
        {children}
      </div>
      <Toaster />
    </div>
  );
}

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string
  })),
  switchPages: PropTypes.shape({
    prevLink: PropTypes.string,
    nextLink: PropTypes.string
  }),
  simpleHeader: PropTypes.bool,
  loader: PropTypes.bool,
  whiteTheme: PropTypes.bool,
  pageContainer: PropTypes.string,
  newAction: PropTypes.node,
  logout: PropTypes.bool,
  goBack: PropTypes.bool
};

Page.defaultProps = {
  breadcrumbs: [],
  switchPages: null,
  simpleHeader: false,
  loader: false,
  whiteTheme: false,
  pageContainer: '',
  newAction: null,
  logout: false,
  goBack: false
};
