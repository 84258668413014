import React from 'react';
import { useTranslation } from 'react-i18next';

const OrganizationTipLinkContent = () => {
  const { t } = useTranslation();
  return (
    <p>{t('tipOrganizationLink')}</p>
  );
};

export default OrganizationTipLinkContent;
