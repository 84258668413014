import { useEffect } from 'react';
import {
  Outlet, useNavigate, useParams, Navigate
} from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getProject } from '../store/project/actions';

const Project = () => {
  const dispatch = useDispatch();
  const { id: idProject } = useParams();
  const navigate = useNavigate();
  const {
    project: { id }
  } = useSelector(({ project }) => ({ project }), shallowEqual);

  useEffect(() => {
    if (+idProject !== id) {
      dispatch(getProject(idProject, navigate));
    }
  }, [idProject, id]);

  return (
    Number.isInteger(+idProject) ? <Outlet /> : <Navigate to="/" />
  );
};

export default Project;
