import React from 'react';
import { useTranslation } from 'react-i18next';

const M6TipContent = () => {
  const { t } = useTranslation();
  return (
    <>
      <p>
        {t('m6Tip1')}
      </p>
      <p>{t('m6Tip2')}</p>
    </>
  );
};

export default M6TipContent;
