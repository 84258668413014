import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useOnBlur from '../../../hooks/useOnBlur';
import { deleteSpacesInString } from '../../../utils/helpers';
import rateOptions from '../../../constants/rateOptions';
import TitleWithTip from '../../../components/TitleWithTip/TitleWithTip';
import Input from '../../../components/Input/Input';
import SelectInput from '../../../components/SelectInput/SelectInput';
import styles from '../sass/O3.module.scss';

const O3Header = ({
  name,
  value,
  title,
  style,
  onChangePriority,
  onDeletePriority,
  meaningsList,
  meaningId
}) => {
  const { t } = useTranslation();
  const [priorityName, setPriorityName] = useState(name);

  const handleChangeDriverName = useCallback((e) => {
    const text = deleteSpacesInString(e.target.value);
    setPriorityName(text);
  }, []);

  const handleBlurPriorityName = useOnBlur(
    name,
    priorityName,
    () => onChangePriority(priorityName, value),
    () => onDeletePriority()
  );

  return (
    <div className={styles.headerContainer}>
      <TitleWithTip
        text={t(`operational${title}`)}
        styleContainer={styles.titleContainer}
        styleTitle={styles.title}
      >
        <p>{t('tipO3')}</p>
      </TitleWithTip>
      <div className={styles.headerOptions}>
        {meaningId ? (
          <SelectInput
            onChange={(idMeaning) => onChangePriority({
              name, value, meaningId, selectIdMeaning: idMeaning
            })}
            values={meaningsList}
            value={name}
            customStyle={style}
            styleContainer={`${styles.selectContainer} ${styles.selectContainerMeaning}`}
          />
        ) : (
          <Input
            onChange={handleChangeDriverName}
            style={styles.inputContainer}
            value={priorityName}
            onBlur={handleBlurPriorityName}
            customStyle={style}
            title={t('typeHere')}
          />
        )}

        <SelectInput
          onChange={(rate) => onChangePriority({
            name, value: +rate, meaningId
          })}
          styleContainer={styles.selectContainer}
          value={value.toString()}
          values={rateOptions}
          checkId={false}
        />
      </div>
    </div>
  );
};

O3Header.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  style: PropTypes.shape({
    color: PropTypes.string,
    fontWeight: PropTypes.string,
  }).isRequired,
  onChangePriority: PropTypes.func.isRequired,
  onDeletePriority: PropTypes.func.isRequired,
  meaningsList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })).isRequired,
  meaningId: PropTypes.number.isRequired
};

export default O3Header;
