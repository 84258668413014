import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Toaster } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import useMenu from '../../../hooks/useMenu';
import Loader from '../../../components/Loader/Loader';
import { logOutAction } from '../../../store/auth/actions';
import AdminHeader from '../AdminHeader/AdminHeader';
import styles from './sass/AdminPage.module.scss';

const AdminPage = ({
  children, loader, pageContainer, activeTab
}) => {
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    dispatch(logOutAction());
  }, []);

  const menuButtons = useMenu(
    () => {},
    () => {},
    () => {},
    () => {},
    () => {},
    () => {},
    () => {},
    true
  );
  return (
    <div className={styles.page}>
      <AdminHeader activeTab={activeTab} onLogout={handleLogout} menuButtons={menuButtons} />
      { loader && <Loader /> }
      <div className={`container ${pageContainer}`}>
        {children}
      </div>
      <Toaster />
    </div>
  );
};

AdminPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  loader: PropTypes.bool,
  pageContainer: PropTypes.string,
  activeTab: PropTypes.string.isRequired
};

AdminPage.defaultProps = {
  loader: false,
  pageContainer: ''
};

export default AdminPage;
