import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  batch, shallowEqual, useDispatch, useSelector
} from 'react-redux';
import {
  cleanOrganization,
  deleteOrganizationLinkAction,
  getOrganizationWorkSheetAction,
  updateOrganizationDriverAction,
  updateOrganizationLinkAction,
  updateOrganizationMeaningAction,
  updateOrganizationTriggerAction,
  updateOrganizationWorkSheetNameAction,
  uploadOrganizationPhotoAction
} from '../../store/organization/actions';
import { getMeaningListAction } from '../../store/general/actions';
import { getBreadcrumbs } from '../../utils/helpers';
import { driversTypeOrganization } from '../../constants/drivers';
import { triggersTypeOrganization } from '../../constants/triggers';
import useUpload from '../../hooks/useUpload';
import Page from '../../layout/Page/Page';
import StakeholderHeader from '../../components/StakeholderHeader/StakeholderHeader';
import Tip from '../../components/Tip/Tip';
import PhotoWithReference from '../../components/PhotoWithReference/PhotoWithReference';
import CoreMeaning from '../../components/CoreMeaning/CoreMeaning';
import Drivers from '../../components/Drivers/Drivers';
import Triggers from '../../components/Triggers/Triggers';
import LinkModal from '../../components/LinkModal/LinkModal';
import ModalDelete from '../../components/ModalDelete/ModalDelete';
import OrganizationTipContent from './components/OrganizationTipContent';
import VideoModal from '../../components/VideoModal/VideoModal';
import StakeholderHeaderTipContent from './components/StakeholderHeaderTipContent';
import OrganizationTipLinkContent from './components/OrganizationTipLinkContent';
import styles from './sass/Organization.module.scss';

const Organization = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    organization: {
      loading,
      name,
      avatars,
      meanings,
      drivers,
      triggers,
      links
    },
    general: { meaningsList },
    project: { name: projectName }
  } = useSelector(({ organization, general, project }) => ({ organization, general, project }), shallowEqual);

  const [selectedFile, setSelectedFile] = useState(null);
  const [linkModal, setLinkModal] = useState(false);
  const [deleteLinkModal, setDeleteLinkModal] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);

  const breadcrumbs = useMemo(() => {
    return getBreadcrumbs(projectName, `/${id}/dashboard`, 'Organization');
  }, [projectName, id]);

  useEffect(() => {
    batch(() => {
      dispatch(getMeaningListAction());
      dispatch(getOrganizationWorkSheetAction(id));
    });
    return () => {
      dispatch(cleanOrganization());
    };
  }, []);

  const handleUpdateOrganizationWorkSheetName = useCallback((value) => {
    dispatch(updateOrganizationWorkSheetNameAction(value));
  }, []);

  const handleUpdatePhoto = useCallback((data) => {
    dispatch(uploadOrganizationPhotoAction(data));
    setSelectedFile(null);
  }, []);

  const handleFileSelect = useCallback((event) => {
    setSelectedFile(event.target.files[0]);
  }, []);

  const handleVisibleLinkModal = useCallback((value) => {
    setLinkModal(value);
  }, []);

  const handleChangeLink = useCallback((values) => {
    dispatch(updateOrganizationLinkAction(values));
    handleVisibleLinkModal(false);
  }, []);

  const handleDeleteLink = useCallback((values) => {
    dispatch(deleteOrganizationLinkAction(values));
    setDeleteLinkModal(null);
  }, []);

  const handleOpenLink = useCallback((url) => {
    window.open(url, '_blank');
  }, []);

  const handleChangeMeaning = useCallback((meaningItem) => {
    dispatch(updateOrganizationMeaningAction(meaningItem));
  }, []);

  const handleChangeDriver = useCallback((values, type) => {
    dispatch(updateOrganizationDriverAction(values, type));
  }, []);

  const handleChangeTrigger = useCallback((values, type) => {
    dispatch(updateOrganizationTriggerAction(values, type));
  }, []);

  const handleOpenVideo = useCallback((url) => {
    setVideoUrl(url);
  }, []);

  const { loadingFile } = useUpload(selectedFile, (data) => handleUpdatePhoto(data));

  return (
    <Page
      breadcrumbs={breadcrumbs}
      pageContainer={styles.container}
      loader={loading}
    >
      <div className={styles.content}>
        <StakeholderHeader
          initialName={name}
          onChangeName={handleUpdateOrganizationWorkSheetName}
          title={t('organization')}
          tipContent={<StakeholderHeaderTipContent />}
        />
        <Tip styleContainer={styles.tipOrganization}>
          <OrganizationTipContent onOpenVideo={handleOpenVideo} />
        </Tip>
        <div className={styles.coreMeaningContainer}>
          <PhotoWithReference
            avatar={avatars?.url}
            loadingFile={loadingFile}
            onUpload={handleFileSelect}
            link={links[0]}
            onOpenModal={() => handleVisibleLinkModal(true)}
            onDelete={() => setDeleteLinkModal(links[0])}
            onOpen={handleOpenLink}
            tipContent={<OrganizationTipLinkContent />}
          />
          <CoreMeaning
            meaningsList={meaningsList}
            meanings={meanings}
            onChange={handleChangeMeaning}
          />
        </div>

        <Drivers
          drivers={drivers}
          onChange={handleChangeDriver}
          driversTypes={driversTypeOrganization}
          maxLength={250}
          placeholder={t('attributePlaceholder')}
        />

        <Triggers
          triggers={triggers}
          onChange={handleChangeTrigger}
          triggersTypes={triggersTypeOrganization}
        />
      </div>

      {linkModal && (
        <LinkModal
          link={links[0]}
          onClose={() => handleVisibleLinkModal(false)}
          onSave={handleChangeLink}
        />
      )}

      {!!videoUrl && (
        <VideoModal url={videoUrl} onClose={() => setVideoUrl(null)} />
      )}

      {!!deleteLinkModal && (
        <ModalDelete
          onClose={() => setDeleteLinkModal(null)}
          onDelete={() => handleDeleteLink(deleteLinkModal)}
          text={t('link')}
        />
      )}
    </Page>
  );
};

export default Organization;
