import {
  CLEAN_O5,
  SET_O5_FAILED,
  SET_O5_REQUEST,
  SET_O5_OFFERING_REVENUES_SUCCESS,
  SET_O5_TIME_INTERVALS_SUCCESS
} from './types';
import instance from '../../services/axios';
import {
  OFFERING_REVENUES_LIST_URL,
  OFFERING_REVENUES_URL,
  POTENTIAL_CUSTOMER_OFFERING_URL,
  TIME_INTERVALS_URL
} from '../../constants/api';
import notify from '../../utils/notify';

export const setO5Request = () => ({ type: SET_O5_REQUEST });
export const setO5Failed = (error) => ({ type: SET_O5_FAILED, error });
export const cleanO5 = () => ({ type: CLEAN_O5 });

export const setOfferingRevenues = (data) => ({ type: SET_O5_OFFERING_REVENUES_SUCCESS, data });

export const setTimeIntervals = (data) => ({ type: SET_O5_TIME_INTERVALS_SUCCESS, data });

export const getOfferingRevenuesAction = (id) => {
  return (dispatch) => {
    dispatch(setO5Request());
    instance.get(`${OFFERING_REVENUES_LIST_URL}/${id}`)
      .then(({ data: { data } }) => {
        dispatch(setOfferingRevenues(data));
      })
      .catch(({ response: { data } }) => {
        dispatch(setO5Failed(data));
        notify('error', data.message);
      });
  };
};

export const getTimeIntervalsAction = () => {
  return (dispatch) => {
    dispatch(setO5Request());
    instance.get(TIME_INTERVALS_URL)
      .then(({ data: { data } }) => {
        dispatch(setTimeIntervals(data));
      })
      .catch(({ response: { data } }) => {
        dispatch(setO5Failed(data));
        notify('error', data.message);
      });
  };
};
export const changeOfferingRevenueAction = (id, values) => {
  const {
    offeringRevenueId,
    name,
    value,
    timeIntervalId,
    currency,
    refresh
  } = values;
  return (dispatch, getState) => {
    const { o5: { offeringRevenues } } = getState();
    dispatch(setO5Request());
    instance.put(`${POTENTIAL_CUSTOMER_OFFERING_URL}/${offeringRevenueId}`, {
      title: name,
      value,
      time_interval_id: timeIntervalId,
      currency
    })
      .then(({ data: { data } }) => {
        if (refresh) {
          dispatch(getOfferingRevenuesAction(id));
        } else {
          const updateOfferingRevenues = offeringRevenues
            .map((item) => (item.id === offeringRevenueId
              ? {
                ...item,
                name: data.title,
                currency: data.currency,
                time_interval_id: data.time_interval_id
              }
              : item));
          dispatch(setOfferingRevenues(updateOfferingRevenues));
        }
      })
      .catch(({ response: { data } }) => {
        dispatch(setO5Failed(data));
        notify('error', data.message);
      });
  };
};

export const deleteOfferingRevenueAction = (id, offeringRevenueId) => {
  return (dispatch) => {
    dispatch(setO5Request());
    instance.delete(`${POTENTIAL_CUSTOMER_OFFERING_URL}/${offeringRevenueId}`)
      .then(() => dispatch(getOfferingRevenuesAction(id)))
      .catch(({ response: { data } }) => {
        dispatch(setO5Failed(data));
        notify('error', data.message);
      });
  };
};

export const addRevenueAction = (id, name, closePlaceholder) => {
  const newRevenue = {
    revenue_name: name,
    revenue_value: 0,
    parent_id: id,
  };
  return (dispatch, getState) => {
    const { o5: { offeringRevenues } } = getState();
    dispatch(setO5Request());
    instance.post(OFFERING_REVENUES_URL, newRevenue)
      .then(({ data: { data } }) => {
        const updateOfferingRevenues = offeringRevenues.map((item) => (item.id === id
          ? {
            ...item,
            list: [...item.list, data]
          }
          : item));
        closePlaceholder();
        dispatch(setOfferingRevenues(updateOfferingRevenues));
      })
      .catch(({ response: { data } }) => {
        dispatch(setO5Failed(data));
        notify('error', data.message);
      });
  };
};

export const changeRevenueAction = (id, values) => {
  const updateRevenue = {
    revenue_name: values.revenueName,
    revenue_value: values.revenueValue ? +values.revenueValue : 0,
    coast_name: values.coastName,
    coast_value: values.coastValue ? +values.coastValue : 0,
    capital_sources_name: values.capitalSourcesName,
    capital_sources_value: values.capitalSourcesValue ? +values.capitalSourcesValue : 0,
  };
  return (dispatch, getState) => {
    const { o5: { offeringRevenues } } = getState();
    dispatch(setO5Request());
    instance.put(`${OFFERING_REVENUES_URL}/${values.revenueId}`, updateRevenue)
      .then(({ data: { data } }) => {
        const updateOfferingRevenues = offeringRevenues.map((item) => (item.id === id
          ? {
            ...item,
            list: item.list.map((revenue) => (revenue.id === data.id ? data : revenue))
          }
          : item));
        dispatch(setOfferingRevenues(updateOfferingRevenues));
      })
      .catch(({ response: { data } }) => {
        dispatch(setO5Failed(data));
        notify('error', data.message);
      });
  };
};

export const deleteRevenueAction = (id, revenueId) => {
  return (dispatch, getState) => {
    const { o5: { offeringRevenues } } = getState();
    dispatch(setO5Request());
    instance.delete(`${OFFERING_REVENUES_URL}/${revenueId}`)
      .then(() => {
        const updateOfferingRevenues = offeringRevenues.map((item) => (item.id === id
          ? {
            ...item,
            list: item.list.filter((revenue) => revenue.id !== revenueId)
          }
          : item));
        dispatch(setOfferingRevenues(updateOfferingRevenues));
      })
      .catch(({ response: { data } }) => {
        dispatch(setO5Failed(data));
        notify('error', data.message);
      });
  };
};
