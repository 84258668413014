import {
  SAM, SOM, TAM
} from '../constants/general';
import { numericValidation, twoNumberAfterDot } from './validation';

export const convertFromIdToName = (list, id) => {
  const selectItem = list.find(({ id: idItem }) => idItem === id);
  return selectItem?.name || '';
};

export const convertFromIdToNameChildren = (list, id) => {
  const childrenList = list.flatMap(({ children }) => children);
  const selectItem = childrenList.find(({ id: idItem }) => idItem === id);
  return selectItem?.name || '';
};

export const getSearchParams = (params) => {
  const copyFilterParam = { ...params };
  Object.keys(copyFilterParam).forEach((key) => {
    if (!copyFilterParam[key]) {
      delete copyFilterParam[key];
    }
  });
  return copyFilterParam;
};

export const getSwitchPages = (prevLink, nextLink) => ({ prevLink, nextLink });

export const getBreadcrumbs = (projectName, link, title) => [
  {
    name: 'Projects',
    link: '/'
  },
  {
    name: projectName,
    link
  },
  {
    name: title,
    link: null
  },
];

export const getBreadcrumbsDashboard = (projectName) => [
  {
    name: 'Projects',
    link: '/'
  },
  {
    name: `${projectName} Dashboard`,
    link: null
  },
];

export const getPrevLinkAndNextLink = (list, id, initialId, text) => {
  const currentIndex = list.findIndex((item) => item.id === initialId);
  const nextItem = list[currentIndex + 1];
  const prevItem = list[currentIndex - 1];
  const nextLink = nextItem
    ? `/${id}/${text}?type=${nextItem?.id}`
    : `/${id}/${text}?type=${list[0]?.id}`;
  const prevLink = prevItem
    ? `/${id}/${text}?type=${prevItem?.id}`
    : `/${id}/${text}?type=${list[list.length - 1]?.id}`;
  return { prevLink, nextLink };
};

export const getQtyValue = (type, availableItem) => {
  switch (type) {
    case TAM:
      return availableItem?.tam_qty ? availableItem.tam_qty.toString() : '';
    case SAM:
      return availableItem?.sam_qty ? availableItem.sam_qty.toString() : '';
    case SOM:
      return availableItem?.som_qty ? availableItem.som_qty.toString() : '';
    default:
      return '';
  }
};

export const getCurrencyValue = (type, availableItem) => {
  switch (type) {
    case TAM:
      return availableItem?.tam_currency ? availableItem?.tam_currency : '';
    case SAM:
      return availableItem?.sam_currency ? availableItem?.sam_currency : '';
    case SOM:
      return availableItem?.som_currency ? availableItem?.som_currency : '';
    default:
      return '';
  }
};

export const getAmountValue = (type, availableItem) => {
  switch (type) {
    case TAM:
      return availableItem?.tam_amount ? availableItem.tam_amount.toString() : '';
    case SAM:
      return availableItem?.sam_amount ? availableItem.sam_amount.toString() : '';
    case SOM:
      return availableItem?.som_amount ? availableItem.som_amount.toString() : '';
    default:
      return '';
  }
};

export const getPercentageValue = (type, availableItem) => {
  switch (type) {
    case SAM:
      return availableItem?.sam_percentage ? availableItem.sam_percentage.toString() : '';
    case SOM:
      return availableItem?.som_percentage ? availableItem.som_percentage.toString() : '';
    default:
      return '';
  }
};

export const percentage = (num, per) => {
  const result = (num / 100) * per;
  return Number.isInteger(result) ? result : +result.toFixed(2);
};

export const getEmptyArray = (length) => new Array(length).fill(undefined);

export const isNumberValueValid = (isInteger, value) => (
  isInteger ? numericValidation.test(value) : twoNumberAfterDot.test(value)
);

export const getChartData = (x, y) => {
  const arrayX = x.map((item) => {
    return {
      x: item.value,
      name: item.competitor_name,
      competitorId: item.competitor_id
    };
  });
  const arrayY = y.map((item) => {
    return {
      y: item.value,
      name: item.competitor_name,
      competitorId: item.competitor_id
    };
  });
  const filterX = arrayX.reduce((acc, item) => {
    const find = arrayY.find((it) => it.competitorId === item.competitorId);
    if (find) {
      return [...acc, { ...item, y: find?.y }];
    }
    return [...acc, { ...item, y: 0 }];
  }, []);
  const filterY = arrayY
    .filter((item) => !filterX.some(({ competitorId }) => competitorId === item.competitorId))
    .reduce((acc, item) => [...acc, { ...item, x: 0 }], []);
  return [...filterX, ...filterY];
};

export const marketsAndOperationsData = (scheme, initialData) => {
  const schemeValues = Object.values(scheme);
  if (schemeValues.length) {
    return initialData.map((item, index) => (
      {
        ...item,
        style: { ...item.style, backgroundColorItem: schemeValues[index]?.color || '' },
        disabled: schemeValues[index]?.disable || false
      }
    ));
  }
  return initialData;
};

export const trendsData = (trendsTypes, initialData) => {
  if (Object.keys(trendsTypes).length) {
    return initialData.map((predefinedData) => {
      const trendType = trendsTypes[predefinedData.name];
      return {
        ...predefinedData,
        style: {
          ...predefinedData.style,
          backgroundColorItem: trendType?.color || ''
        },
        disabled: trendType?.disable || false,
        link: `${predefinedData.link}${trendType ? `?type=${trendType.id}` : ''}`
      };
    });
  }
  return initialData;
};

export const stakeholdersData = (scheme, initialData, initialIndex) => {
  if (scheme.length) {
    const schemeValues = Object.values(scheme[initialIndex]);
    return initialData.map((item, index) => (
      {
        ...item,
        style: { ...item.style, backgroundColorItem: schemeValues[index]?.color || '' },
        disabled: !schemeValues[index]?.visible,
        link: `${item.link}${schemeValues[index]?.id ? `?type=${schemeValues[index].id}` : ''}`,
        id: schemeValues[index]?.id || null,
        visible: !!schemeValues[index]?.visible,
      }
    ));
  }
  return initialData;
};

export const deleteSpacesInString = (text) => text.replace(/^\s+/, '').replace(/\s\s+/g, ' ');

export const capitalizeFirstLetter = (text) => text.charAt(0).toUpperCase() + text.slice(1);

export const formatSelectedDateToString = (date, format) => {
  if (!date) return null;
  const dayWithZero = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const monthWithZero = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year = date.getFullYear();
  switch (format) {
    case 'mm/dd/yyyy':
      return `${monthWithZero}/${dayWithZero}/${year}`;
    default:
      return `${dayWithZero}-${monthWithZero}-${year}`;
  }
};

export const formatDateFromStringToISO = (date) => {
  if (!date) return null;
  const [day, month, year] = date.split('-');
  return new Date(year, month - 1, day);
};
