import React, {
  useMemo, useState, useCallback, useLayoutEffect
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  deleteSpacesInString,
  isNumberValueValid,
  percentage
} from '../../../utils/helpers';
import Input from '../../../components/Input/Input';
import Title from '../../../components/Title/Title';
import styles from '../sass/M6.module.scss';

const M6OfferingItemSom = ({
  offeringSomCurrency,
  offeringSomPercentage,
  somAmount,
  somQty,
  projectedIncreaseId,
  onChangeOfferingSOM,
  somPercentage,
  tamQty,
  tamAmount,
}) => {
  const { t } = useTranslation();
  const [percentageValue, setPercentageValue] = useState(null);

  useLayoutEffect(() => {
    setPercentageValue(offeringSomPercentage);
  }, [offeringSomPercentage]);

  const offeringSomQty = useMemo(() => {
    return percentage(somQty, offeringSomPercentage) ? percentage(somQty, offeringSomPercentage).toString() : '';
  }, [somQty, offeringSomPercentage]);

  const offeringSomAmount = useMemo(() => {
    return percentage(somAmount, offeringSomPercentage) ? percentage(somAmount, offeringSomPercentage).toString() : '';
  }, [somAmount, offeringSomPercentage]);

  const handleChangePercentage = useCallback((event) => {
    const number = deleteSpacesInString(event.target.value);
    if (isNumberValueValid(false, number)) {
      setPercentageValue(number);
    }
  }, []);

  const handleBlurPercentage = useCallback(() => {
    if (offeringSomPercentage !== +percentageValue) {
      onChangeOfferingSOM({
        somCurrency: offeringSomCurrency,
        somPercentage: +percentageValue,
        somQty: percentage(somQty, percentageValue),
        somAmount: percentage(somAmount, percentageValue),
        projectedIncreaseId
      });
    }
  }, [offeringSomCurrency, offeringSomPercentage, percentageValue, somQty, somAmount, projectedIncreaseId]);

  const somPercentValue = useMemo(() => {
    const isTamValue = !!tamQty || !!tamAmount;
    return isTamValue && somPercentage && percentageValue ? percentageValue.toString() : '';
  }, [percentageValue, tamAmount, tamQty, tamAmount, somPercentage]);
  
  return (
    <div className={styles.som}>
      <Title style={styles.somTitle} text={t('projectedIncreasedSOM')} Type="h4" />
      <div className={`${styles.somInputContainer} ${styles.somInputPercent}`}>
        <Input
          onChange={handleChangePercentage}
          onBlur={handleBlurPercentage}
          value={somPercentValue}
          style={styles.somInput}
          disabled={!somPercentage}
        />
        <span>%</span>
      </div>
      <div className={styles.somInputContainer}>
        <Input
          onChange={() => {}}
          value={offeringSomQty}
          style={`${styles.somInput} ${styles.somInputValue}`}
          disabled
        />
        <span>#</span>
      </div>
      <div className={styles.somInputContainer}>
        <Input
          onChange={() => {}}
          value={offeringSomAmount}
          style={`${styles.somInput} ${styles.somInputValue}`}
          disabled
        />
        <span>{offeringSomCurrency}</span>
      </div>
    </div>
  );
};

M6OfferingItemSom.propTypes = {
  offeringSomCurrency: PropTypes.string.isRequired,
  offeringSomPercentage: PropTypes.number,
  somQty: PropTypes.number.isRequired,
  somAmount: PropTypes.number.isRequired,
  projectedIncreaseId: PropTypes.number,
  onChangeOfferingSOM: PropTypes.func.isRequired,
  somPercentage: PropTypes.number.isRequired,
  tamQty: PropTypes.number.isRequired,
  tamAmount: PropTypes.number.isRequired,
};

M6OfferingItemSom.defaultProps = {
  projectedIncreaseId: null,
  offeringSomPercentage: null
};

export default M6OfferingItemSom;
