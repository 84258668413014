import {
  CLEAN_M4,
  SET_M4_COMPETITORS_LIST_SUCCESS,
  SET_M4_DECISION_DRIVERS_SUCCESS,
  SET_M4_DRIVER_OFFER_SUCCESS,
  SET_M4_FAILED,
  SET_M4_REQUEST,
  SET_M4_SEGMENT_DRIVERS_LIST_SUCCESS,
  SET_M4_SEGMENT_LIST_SUCCESS,
  SET_M4_SEGMENT_SUCCESS
} from './types';

const initialState = {
  decisionDriverX: {},
  decisionDriverY: {},
  segmentCustomerList: [],
  driversList: [],
  competitorsList: [],
  driverOffer: {
    offerId: 0,
    competitorId: 0,
    our: '',
    delivers: ''
  },
  segmentId: 0,
  loading: false,
  error: null
};

export default function m4(state = initialState, action) {
  switch (action.type) {
    case SET_M4_DECISION_DRIVERS_SUCCESS:
      return {
        ...state,
        decisionDriverX: action.decisionDriverX,
        decisionDriverY: action.decisionDriverY,
        loading: false,
        error: null
      };
    case SET_M4_SEGMENT_LIST_SUCCESS:
      return {
        ...state,
        segmentCustomerList: action.data,
        loading: false,
        error: null
      };
    case SET_M4_SEGMENT_DRIVERS_LIST_SUCCESS:
      return {
        ...state,
        driversList: action.data,
        loading: false,
        error: null
      };
    case SET_M4_SEGMENT_SUCCESS:
      return {
        ...state,
        segmentId: action.data
      };
    case SET_M4_COMPETITORS_LIST_SUCCESS:
      return {
        ...state,
        competitorsList: action.data,
        loading: false,
        error: null
      };
    case SET_M4_DRIVER_OFFER_SUCCESS:
      return {
        ...state,
        driverOffer: action.data,
        loading: false,
        error: null
      };
    case SET_M4_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_M4_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case CLEAN_M4:
      return initialState;
    default:
      return state;
  }
}
