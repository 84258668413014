import React from 'react';
import { useTranslation } from 'react-i18next';
import Tip from '../Tip/Tip';
import styles from './sass/OrganizationTips.module.scss';

const TipOrganizationEmotionalTrigger = () => {
  const { t } = useTranslation();
  return (
    <Tip
      styleTipTextContainer={styles.triggerContainer}
      styleTriangle={styles.triangle}
    >
      <p>
        {t('tipOrganizationEmotionalTrigger')}
      </p>
    </Tip>
  );
};

export default TipOrganizationEmotionalTrigger;
