import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useOnBlur from '../../../hooks/useOnBlur';
import { convertFromIdToName, deleteSpacesInString } from '../../../utils/helpers';
import rateOptions from '../../../constants/rateOptions';
import Input from '../../../components/Input/Input';
import SelectInput from '../../../components/SelectInput/SelectInput';
import styles from '../sass/Trends.module.scss';

const TrendsItemValues = ({
  name,
  value,
  influenceId,
  influenceList,
  onUpdateTrend,
  id,
  trendMessage,
  onDeleteTrend
}) => {
  const { t } = useTranslation();
  const [trendName, setTrendName] = useState(name);

  const handleChangeName = useCallback((e) => {
    const text = deleteSpacesInString(e.target.value);
    setTrendName(text);
  }, []);

  const handleBlurName = useOnBlur(
    name,
    trendName,
    () => onUpdateTrend({
      name: trendName, value, influenceId, trendMessage 
    }),
    () => onDeleteTrend(id),
  );

  return (
    <div className={styles.trendItemValuesContainer}>
      <Input
        onChange={handleChangeName}
        onBlur={handleBlurName}
        style={styles.inputContainer}
        value={trendName}
        title={t('trendPlaceholder')}
      />
      <SelectInput
        onChange={(influence) => onUpdateTrend({
          name: trendName, value, influenceId: +influence, trendMessage 
        })}
        styleContainer={styles.selectContainer}
        styleSelectInputItem={styles.selectInputItem}
        value={convertFromIdToName(influenceList, influenceId)}
        values={influenceList}
        title={t('influence')}
        disabled={!id}
      />
      <SelectInput
        onChange={(rate) => onUpdateTrend({
          name: trendName, value: +rate, influenceId, trendMessage 
        })}
        styleContainer={`${styles.selectContainer} ${styles.selectContainerRight}`}
        value={!id ? '' : value.toString()}
        values={rateOptions}
        checkId={false}
        disabled={!id}
        title={t('rank')}
      />
    </div>
  );
};

TrendsItemValues.propTypes = {
  name: PropTypes.string.isRequired,
  influenceId: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  influenceList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  onUpdateTrend: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  trendMessage: PropTypes.string.isRequired,
  onDeleteTrend: PropTypes.func.isRequired
};

TrendsItemValues.defaultProps = {
  influenceList: []
};

export default TrendsItemValues;
