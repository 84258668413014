export default {
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  userName: 'username',
  password: 'Password',
  confirmPassword: 'Confirm password',
  projectCompany: 'Project/Company name',
  industry: 'Industry',
  register: 'Register',
  cancel: 'Cancel',
  registration: 'Registration',
  login: 'Login',
  didNotRegistered: 'Didn\'t registered yet?',
  createAnAccount: 'Create an account',
  send: 'send',
  youWillGetValidation: 'You will get validation link on your email box',
  ifYouDidNotReceiveIt: 'If you didn\'t receive it',
  resendEmail: 'resend email',
  restorePassword: 'Restore password',
  createNewPassword: 'Create new password',
  forgotPassword: 'Forgot password?',
  thankYouForRegistration: 'Thank you for registration!',
  ok: 'ok',
  success: 'Success!',
  verificationEmail: 'Verification email...',
  somethingWentWrong: 'Something went wrong...',
  organization: 'Organization',
  market: 'market',
  operations: 'operations',
  trends: 'Trends',
  supplyChain: 'supply chain',
  stakeholderAndInfrastructure: 'stakeholder/infrastructure',
  projectName: 'Project Name',
  description: 'Description',
  delete: 'Delete',
  duplicate: 'Duplicate',
  save: 'Save',
  create: 'Create',
  addNewProject: 'Add a new Project',
  editProject: 'Edit a Project',
  addProject: 'Add project',
  edit: 'Edit',
  administration: 'Administration',
  users: 'users',
  addUser: 'Add User',
  addTrends: 'Add Trends',
  addScenarioTrend: 'Add Scenario Trend',
  searchByUser: 'Search by User...',
  searchByTrend: 'Search by Trend...',
  searchByStakeholderDriver: 'Search by Stakeholder Name / Decision Driver',
  searchByAccessLevel: 'Search by Access Level',
  searchByIndustry: 'Search by Industry',
  category: 'Category',
  searchByProject: 'Search by Project',
  searchByProjectName: 'Search by Project name...',
  filter: 'Filter',
  fullName: 'Full name',
  access: 'Access',
  project: 'Project',
  actions: 'Actions',
  predefinedTrends: 'predefined trends',
  editUser: 'Edit user',
  userType: 'User type',
  loading: 'Loading...',
  stakeholderName: 'Stakeholder Name',
  segments: 'Segments',
  createNewSegment: 'Create New Segment',
  chooseStakeholderType: 'Choose Stakeholder Type',
  newCustomer: 'New customer',
  deleteProfile: 'Delete profile',
  editCoreMeaning: 'Edit Core Meaning',
  coreMeaning: 'Core meaning',
  typeHere: 'Type here ...',
  createNewDriver: 'Create new driver',
  referancePhoto: 'Referance photo (optional)',
  emotionalDrivers: 'Emotional Decision-Drivers:',
  financialDrivers: 'Financial Decision-Drivers:',
  functionalDrivers: 'Functional Decision-Drivers:',
  emotionalTriggers: 'Emotional Triggers:',
  financialTriggers: 'Financial Triggers:',
  functionalTriggers: 'Functional Triggers:',
  linkEvidence: 'Link Evidence...',
  add: 'Add',
  editLink: 'Edit link',
  addLink: 'Add link',
  segment: 'Segment',
  addSegment: 'Add Segment',
  detailedProfiles: 'Detailed Profiles:',
  addCustomer: 'Add Customer',
  more: 'more...',
  rank: 'Rank',
  trend: 'Trend',
  influenceDefault: 'Influence Default',
  editTrend: 'Edit trend',
  addTrend: 'Add trend',
  global: 'Global',
  internal: 'Internal',
  no: 'NO',
  yes: 'YES',
  all: 'all',
  selectedCustomerSegment: 'Selected Customer Segment',
  addCompetitor: 'Add competitor',
  topMarketDecisionDrivers: 'Top Market Decision-Drivers:',
  legend: 'Legend:',
  competitorNamePlaceholder: 'Competitor Name ...',
  areYouSureYouWantDeleteCompetitor: 'Are you sure you want to delete competitor?',
  driverPlaceholder: 'Driver ...',
  mostAdvantageousPositions: 'Most Advantageous Positions',
  decisionDriver: 'Decision-Driver',
  for: 'for',
  whoseDecisionsAreDrivenBy: 'Whose decisions are driven by',
  and: 'and',
  our: 'our',
  unlike: 'Unlike',
  delivers: 'Delivers',
  deliversPlaceholder: 'Delivers ...',
  customer: 'Customer',
  projectedIncreasedSOM: 'Projected increased SOM',
  serviceableObtainableMarket: 'Serviceable Obtainable Market (SOM):',
  m6Tip1: 'Your top Opportunities for all of your Market Segments will be automatically listed here. If you want'
    + 'to change the rankings, you can do that here or on the previous page. The Serviceable Obtainable Market '
    + '(SOM) numbers from the Market Segment page have been added for each Market Segment. '
    + 'For each Offering, estimate (by percentage) how much of an increase (or decrease) this Offering would'
    + ' create in sales. The numbers and value will be calculated automatically.',
  m6Tip2: 'This concludes the most important part of the Market Sequence. You’ve successfully identified'
    + ' your customers’ most important drivers of their decisions and focused on those you can create the'
    + ' most value and most easily differentiate your organization in the market. The next, Operations Sequence,'
    + ' will help you identify how best to meet these market opportunities.',
  link: 'link',
  m7Tip: 'For each Market Segment, the top messages form the Opportunity page have been carried '
    + 'over for you to track. You can add new messages, refine them here, and rank them. '
    + 'Ultimately, these will contribute to the identification, formulation, and external messaging '
    + 'of your organization’s Brand. For now, these are open text fields for you to use how you wish.',
  m1Tip: 'Type a name for this customer to keep them differentiated.',
  m1Ti2: ' If you have a files of customer research that support the information '
    + 'in the profile, link them here to this profile.',
  m1Tip3_1: 'Meaningful Value is the deepest value you can provide to customers and the basis of customer '
    + 'loyalty and lifetime customer value. It requires specific research techniques to uncover '
    + '(like laddering). If you don’t have this research, experience with this kind of value, '
    + 'or how to discover it, don’t worry, you can just ignore this section. However, know that '
    + 'there is deeper value at stake. For more on this topic, watch these videos: '
    + '5 Kinds of Value',
  logout: 'Logout',
  areYouSure: 'Are you sure you want to delete',
  competitor: 'Competitor',
  addNewCustomer: 'Add New Customer',
  tipEmotionalDriver: 'Decision-Drivers are the things that drive customer decisions. They are often hidden'
    + ' and non-obvious. However, they are the real reasons behind the choices customers make, whether'
    + ' they’re stated or not. This makes them difficult to determine, sometimes—especially when they’re'
    + ' driven by emotions, which can be subconscious (customers may be unaware of them).'
    + ' In these cases, asking customers directly won’t identify them. Yet, they are critical to choice-making'
    + ' as they usually override stated drivers that are functional or financial. If you have salespeople or'
    + ' customer service representatives that directly interact with customers, these people will usually have'
    + ' better insight into decision-drivers than directly asking customers.',
  tipFinancialDriver: 'Decision-Drivers are the things that drive customer decisions. They are often'
    + ' hidden and non-obvious. However, they are the real reasons behind the choices customers make,'
    + ' whether they’re stated or not. Financial drivers guide customer decisions of price and perceived'
    + ' economic value. These are often more conscious and deliberate than Meaningful or Emotional Value.',
  tipFunctionalDriver: 'Decision-Drivers are the things that drive customer decisions. They are often hidden'
    + ' and non-obvious. However, they are the real reasons behind the choices customers make, whether they’re'
    + ' stated or not. Functional drivers guide customer decisions of features and performance.'
    + ' These are often more conscious and deliberate than Meaningful or Emotional Value. However, customers'
    + ' often identify features or performance standards that appeal to them but don’t actually drive their decisions.',
  tipEmotionalTrigger: 'Emotional Triggers are the signals that impact customer emotional value. What imagery,'
    + ' colors, materials, sounds, words, visuals, etc. “speak” to customers on an emotional level and make them'
    + ' feel what they do? Note these here.',
  tipFinancialTrigger: 'Financial Triggers are the signals that help customers determine if something is worth'
    + ' the price. What imagery, colors, materials, sounds, words, visuals, etc. “say” to customers that something'
    + ' is of appropriate value for the price? What “says” quality or “expensive” or “inexpensive”'
    + ' or “value” to them? Note these here.',
  tipFunctionalTrigger: 'Functional Triggers are the signals that something will meet their needs.'
    + ' Are there imagery, colors, materials, sounds, words, visuals, etc. that “say” to customers that'
    + ' something will suit them or be of appropriate quality? Note these here.',
  tipM2Segment: 'Segments are simply groupings of customers that are similar in some way. How you segment creates'
    + ' a distinct market of customers you should approach in distinct ways. While many businesses segment'
    + ' on the basis of demographic data (age, income, geography, etc.) segmenting on the basis of psychographics'
    + ' or any of the decision-drivers is often more powerful. You can create segments of any of your customers.'
    + ' You should consider the decision-drivers with the highest rankings to see if there are segments that'
    + ' emphasize those differences. You can name a segment in this field and then Add Customers to this list and'
    + ' their decision-drivers and rankings will automatically compile.',
  tipM2DetailedProfiles: 'When you add a customer profile to a market segment. Their profile will be listed below.'
    + ' Use the Add Customer button to select from your list of profiles.',
  tipM2TAM: 'Total Available Market (TAM) is the size of a customer segment. If you know this, type the estimated'
    + ' number of customers and total value in the fields below. You can always come back later and add or refine'
    + ' these numbers.',
  tipM2SAM: 'Serviceable Available Market (SAM) is an estimate of how much of the total market you can realistically'
    + ' reach. You can’t reach everyone in a market, especially with a new offering or in a new category because of'
    + ' many factors: geography, language, loyalty, price, needs, etc. If you have a realistic estimate of this,'
    + ' type the percentage of the TAM to the right and the numbers and total value will reflect this. You can'
    + ' always come back later and add or refine this percentage.',
  tipM2SOM: 'Serviceable Obtainable Market (SOM) is an estimate of how much of the serviceable market you can'
    + ' realistically win or serve. You can’t make every sale nor convince everyone in a market to choose your'
    + ' offering, especially with a new offering or in a new category. If you have a realistic estimate of this,'
    + ' type the percentage of the SOM (the market share you expect to sell) to the right and the numbers and total'
    + ' value will reflect this. You can always come back later and add or refine this percentage.',
  tipM3TopMarket1: 'For each of your market segments (the drop down above), the top-ranked decision-drivers for all'
    + ' customers in that segment will appear in this list. This is a focusing exercise so we’ve automatically'
    + ' included the top 10. You can add more at the bottom of this list but our recommendation is that 10 is more'
    + ' than enough for competitive analysis. If you don’t agree with the drivers in this list, you’ll need to go'
    + ' readdress their rankings in the customer profiles. However, be careful that you’re not just re-ranking in'
    + ' order to get a list you like instead of one that reflects how your customers really make decisions.',
  tipM3TopMarket2: 'Next, type the names of your top competitors in the columns to the right. If you need more,'
    + ' use the Add Competitor button. The last column is a special one (see that tool tip).',
  tip3Competitor: 'Competitor X is the competitor you don’t yet know about. This could be a startup not yet on your'
    + ' radar or a new initiative in one of your larger competitors. You should use a fictitious name and rank your'
    + ' company against this one based on what you think a new startup might achieve with a fresh approach.'
    + ' If this is difficult for you, just imagine Apple entering your market. How would their solution in this'
    + ' market compare? If you don’t want to use a Competitor X, that’s fine, too.',
  tipM3Legend: 'This tool automatically generates Market Strengths, Weaknesses, Opportunities, and Threats as a'
    + ' function of you comparative rankings of the customer decision-drivers you’ve identified. If there are'
    + ' Strengths, Weaknesses, Opportunities, and Threats that aren’t here, either they aren’t ranked as highly'
    + ' as these decision-drivers or you didn’t identify them in your customer research. It’s also possible that'
    + ' what you consider a Strength, Weakness, Opportunity, or Threat is Operational and not Market-driven. In this'
    + ' case, you’ll have the opportunity to list them in the Capabilities page. For more on this topic, watch this'
    + ' video: Fixing the SWOT Analysis',
  tipM4Position1: 'This tool automatically presents your best position, in each Market Segment, based on your'
    + ' competitive analysis (previous page) and shows them both in visual form (in the chart below) and textual'
    + ' form (in the blank positioning statement to the right). However, you can explore different possibilities by'
    + ' choosing a different top driver from any of the four drop-down menus (two in the chart and two in the'
    + ' positioning statement). In the chart, you should look for a position with few competitors around you.'
    + ' This gives you a more clearly differentiated value proposition than if you’re in a spot crowded with'
    + ' competitors who perform equally. However, some positions aren’t great even if there is open space.'
    + ' For example, terrible quality at a very high price, probably isn’t a great value proposition for your'
    + ' customers.',
  tipM4Position2: 'When you find a position you think is valuable, for each Market Segment, add to the statement in'
    + ' the blank fields to elaborate on why.',
  tipM5Segment: 'For each Market Segment (the first drop-down at the top of the page), the two decision-drivers form'
    + ' your saved Position on the previous page are listed automatically, below. For each, type into the fields'
    + ' below some potential offerings you think would satisfy the customers in this Market Segment and rank their'
    + ' importance. If you have ideas on how you would message these offerings to these customers, type those in the'
    + ' field to the right of each. You don’t have to do this now, however.',
  tipOrganizationStakeholder: 'Here, you can identify and rank the Brand Attributes for your organization.'
    + ' These should be the result of internal research into your current brand and the attribute you may need to'
    + ' change in order to reach your intended markets and customers. These will be influenced by your new'
    + ' Positioning. Every organization’s brand and culture are different, even though all will say similar things'
    + ' (“we want to be successful,” “we aim to deliver exceptional customer value,” etc.). These attributes need'
    + ' to be a honest reflection of the organization’s culture and will form the basis for Meaning Strategy later.',
  tipOrganizationEmotionalDriver: 'Decision-Drivers are the things that drive how your organization makes decisions.'
    + ' They are often hidden and non-obvious. However, they are the real reasons behind the choices your organization'
    + ' make, whether they’re stated or not. This makes them difficult to determine, sometimes—especially when they’re'
    + ' driven by emotions, which can be subconscious (employees and leaders may be unaware of them). In these cases,'
    + ' asking people directly won’t identify them. You need to observe them.',
  tipOrganizationFinancialDriver: 'Decision-Drivers are the things that drive customer decisions. They are often'
    + ' hidden and non-obvious. However, they are the real reasons behind the choices your organization makes,'
    + ' whether they’re stated or not. Financial drivers guide your organization’s decisions of price and perceived'
    + ' economic value. These are often more conscious and deliberate than Meaningful or Emotional Value.',
  tipOrganizationFunctionalDriver: 'Decision-Drivers are the things that drive how your organization makes decisions.'
    + ' They are often hidden and non-obvious. However, they are the real reasons behind the choices your organization'
    + ' makes, whether they’re stated or not. Functional drivers guide your organization’s decisions of functionality'
    + ' and operational performance. These are often more conscious and deliberate than Meaningful or Emotional Value.'
    + ' Often, however, employees and leaders often identify features or performance standards that appeal to them but'
    + ' don’t actually drive their decisions.',
  tipOrganizationEmotionalTrigger: 'Emotional Triggers are the signals that impact your organization’s brand.'
    + ' What imagery, colors, materials, sounds, words, visuals, etc. “speak” to your customers, as well as your'
    + ' leaders and employees, on an emotional level and make them feel that your brand has value? Note these here.',
  tipOrganizationFinancialTrigger: 'Financial Triggers are the signals that help customers determine if something is'
    + ' worth the price. What imagery, colors, materials, sounds, words, visuals, etc. “say” to leaders and employees'
    + ' that your organization is of appropriate value or that decisions made are the right ones? What “says” quality'
    + ' or “expensive” or “inexpensive” or “value” to them? Note these here.',
  tipOrganizationFunctionalTrigger: 'Functional Triggers are the signals that something will meet their needs.'
    + ' Are there imagery, colors, materials, sounds, words, visuals, etc. that “say” to leaders and employees that'
    + ' something will suit them or be of appropriate quality? Note these here.',
  emotionalAttr: 'Emotional Brand Attributes:',
  financialAttr: 'Financial Brand Attributes:',
  functionalAttr: 'Functional Brand Attributes:',
  addBrandAttr: 'Add Brand Attribute',
  tipOrganizationLink: 'If you have a files of organization research that support the information in the profile,'
    + ' link them here to this profile.',
  newStakeholder: 'New Stakeholder',
  tipStakeholderHeader: 'Type a name for this stakeholder to keep them differentiated. Choose a stakeholder category'
    + ' that represents this stakeholder from the drop down to the right.',
  tipStakeholder: 'Meaningful Value is the deepest value you can provide to stakeholders and the basis of loyal'
    + ' partnerships and lasting stakeholder value. It requires specific research techniques to uncover'
    + ' (like laddering). If you don’t have this research, experience with this kind of value, or how to discover it,'
    + ' don’t worry, you can just ignore this section. However, know that there is deeper value at stake. For more on'
    + ' this topic, watch these videos: 5 Kinds of Value',
  tipStakeholderLink: 'If you have a files of stakeholder research that support the information in the profile,'
    + ' link them here to this profile.',
  tipStakeholderEmotionalDriver: 'Decision-Drivers are the things that drive stakeholder decisions. They are often'
    + ' hidden and non-obvious. However, they are the real reasons behind the choices customers make, whether they’re'
    + ' stated or not. This makes them difficult to determine, sometimes—especially when they’re driven by emotions,'
    + ' which can be subconscious (stakeholders may be unaware of them). In these cases, asking stakeholders directly'
    + ' won’t identify them. Yet, they are critical to choice-making as they usually override stated drivers that are'
    + ' functional or financial. If you have partnership or relationships managers who interact with these'
    + ' stakeholders, these people will usually have better insight into decision-drivers than directly asking'
    + ' stakeholders.',
  tipStakeholderFinancialDriver: 'Decision-Drivers are the things that drive stakeholders decisions. They are often'
    + ' hidden and non-obvious. However, they are the real reasons behind the choices customers make, whether'
    + ' they’re stated or not. Financial drivers guide stakeholder decisions of price and perceived economic'
    + ' value. These are often more conscious and deliberate than Meaningful or Emotional Value.',
  tipStakeholderFunctionalDriver: 'Decision-Drivers are the things that drive stakeholder decisions.'
    + ' They are often hidden and non-obvious. However, they are the real reasons behind the choices stakeholders'
    + ' make, whether they’re stated or not. Functional drivers guide stakeholder decisions of features and'
    + ' performance. These are often more conscious and deliberate than Meaningful or Emotional Value.'
    + ' However, stakeholders often identify features or performance standards that appeal to them but don’t actually'
    + ' drive their decisions.',
  tipStakeholderEmotionalTrigger: 'Emotional Triggers are the signals that impact stakeholder emotional value.'
    + ' What imagery, colors, materials, sounds, words, visuals, etc. “speak” to stakeholders on an emotional level'
    + ' and make them feel what they do? Note these here.',
  tipStakeholderFinancialTrigger: 'Financial Triggers are the signals that help stakeholders determine if something'
    + ' is worth the price. What imagery, colors, materials, sounds, words, visuals, etc. “say” to stakeholders that'
    + ' something is of appropriate value for the price? What “says” quality or “expensive” or “inexpensive” or'
    + ' “value” to them? Note these here.',
  tipStakeholderFunctionalTrigger: 'Functional Triggers are the signals that something will meet their needs.'
    + ' Are there imagery, colors, materials, sounds, words, visuals, etc. that “say” to stakeholders that something'
    + ' will suit them or be of appropriate quality? Note these here.',
  stakeholder: 'Stakeholder',
  other: 'Other',
  createNewTrend: 'Create New Trend',
  tipTrendHeader1: 'This page lists trends that may impact your business. Some of these may impact your market'
    + ' (and customers), others your operations (and industry), and some may impact both. We’ve populated this page'
    + ' with trends we’ve identified that may impact your business but you can delete them, change their rankings'
    + ' (importance), or add more and rank them. Market Trends with sufficient ranking automatically integrate with'
    + ' your decision-drivers in Competitive Analysis. Operational Trends with sufficient ranking automatically'
    + ' integrate with your Operational Priorities. You can always make changes to these trends over time. Note these'
    + ' here.',
  tipTrendHeader2: 'Note: Trends analysis is a complicated business. You may find trends in many places.'
    + ' Trends are also the basis for exploring specific Scenarios of what might happen in the future.',
  marketOfferings: 'Market Offerings:',
  operationalCapabilitiesNeeded: 'Operational Capabilities Needed:',
  tipO1_1: 'The top-ranked Offerings that meet your market Opportunities have been carried over to this page.'
    + ' You can add more to the list but understand that these have ben vetted by the Market Sequence.'
    + ' If you add new Offerings, they will not be supported by your market research or competitive analysis.',
  tipO1_2: 'For each Offering, type an Operational Capability that will be needed to make that Offering viable and'
    + ' rank its importance (this rank sets its Priority). If there are more than one Capability for each offering,'
    + ' for now, list them on the same line.',
  back: '< Back',
  markets: 'Markets',
  editSegment: 'Edit Segment',
  editCompetitor: 'Edit Competitor',
  tip02: 'Your top-ranked Capabilities are automatically generated from the total list of capabilities you’ve'
    + ' identified. For each Capability, compare your ability to implement against the same Competitors you identified'
    + ' in the Competitive Analysis. As with that analysis, your Operational Strengths, Weaknesses, Opportunities,'
    + ' and Threats are generated and identified automatically.',
  user: 'User',
  keyResources: 'Key Resources:',
  potentialPartnerships: 'Potential Partnerships:',
  stakeholderType: 'Stakeholder Type:',
  priority: 'Priority:',
  tipO3: 'Your top-ranked Capabilities (the ones that represent your most advantageous Priorities) are automatically'
    + ' generated from the last page. For each, identify and type potential partners and resources you might need to'
    + ' successfully implement or strengthen that Priority. Later, you can do a more thorough analysis of partnerships'
    + ' in the context of stakeholders. Or, if you identify and rank stakeholders, those rankings will be applied to'
    + ' your Capabilities rankings automatically (along with Trends).',
  tipO4: 'For now, your highest-ranked Partnerships and Resources, identified on the previous page, are automatically'
    + ' listed here. You can identify add Key Activities, Revenue Streams, and Revenue Structures on this page'
    + ' (these are the essential elements of the popular Business Model Canvas). The rest of the elements of that'
    + ' popular tool have already been identified in the previous Market sequence.',
  keyOfferings: 'Key Offerings:',
  keyPartnerships: 'Key Partnerships:',
  missionStatement: 'Mission Statement:',
  createNewOffering: 'Create New Offering',
  createNewPartnership: 'Create New Partnership',
  potentialMessagingForOfferingNeeds: 'Potential Messaging for Offering needs to be added',
  youNeedToAddOffering: 'You need to add Offering',
  AddOpportunityThreat: 'Add Opportunity/Threat',
  threat: 'Threat:',
  opportunity: 'Opportunity:',
  operationalopportunity: 'Operational Opportunity:',
  operationalthreat: 'Operational Threat:',
  operationalweakness: 'Operational Weakness:',
  operationalstrength: 'Operational Strengths:',
  company: 'Company:',
  offering: 'Offering:',
  offeringPlaceholder: 'Offering ...',
  topOperationalCapabilities: 'Top Operational Capabilities:',
  download: 'Download',
  outputDataByProject: 'Output data by Project',
  revenue: 'Revenue:',
  costs: 'Costs:',
  capitalSources: 'Capital Sources:',
  revenueSteam: 'Revenue Steam ...',
  operationalCost: 'Operational Cost ...',
  capitalSource: 'Capital Source ...',
  addRevenue: 'Add Revenue',
  currency: 'Currency',
  timeInterval: 'Time interval:',
  messagePlaceholder: 'Message ...',
  operationalCapabilityNeededPlaceholder: 'Operational Capability Needed ...',
  capabilityPlaceholder: 'Capability ...',
  keyOfferingsPlaceholder: 'Key Offerings ...',
  keyPartnershipsPlaceholder: 'Key Partnerships ...',
  keyResourcesPlaceholder: 'Key Resources ...',
  stakeholderTypePlaceholder: 'Stakeholder Type',
  potentialPartnerPlaceholder: 'Potential Partner ...',
  attributePlaceholder: 'Attribute ...',
  triggerPlaceholder: 'Trigger ...',
  segmentPlaceholder: 'Segment ...',
  competitorOfferingPlaceholder: 'Competitor Offering ...',
  ourPlaceholder: 'Our ...',
  missionStatementPlaceholder: 'Mission Statement ...',
  trendPlaceholder: 'Trend ...',
  influence: 'Influence',
  message: 'Message',
  age: 'Age:',
  agePlaceholder: 'Age ...',
  occupation: 'Occupation:',
  occupationPlaceholder: 'Occupation ...',
  location: 'Location:',
  locationPlaceholder: 'Location ...',
  biography: 'Biography:',
  biographyPlaceholder: 'Biography ...',
  thisIsBetaSoftware: 'This is beta software still in development.',
  thereAreToolTips: 'There are tool tips throughout but currently no instructional videos.',
  forMoreInformationAbout: 'For more information about this new tool you can contact',
  createDate: 'Date created',
  strength: 'Strength',
  weakness: 'Weakness',
  ops: 'Ops',
  considerImpacts: 'Consider impacts on Air, Water, Soil, and Biodiversity.',
  considerArtificial: 'Consider: Artificial Intelligence, Blockchain, Nanometer'
    + ' Processors, Distributed Computing, Quantum Computing, etc.',
  scenario: 'Scenario',
  scenarios: 'Scenarios',
  addScenario: 'Add Scenario',
  editScenario: 'Edit Scenario',
  duplicateProject: 'Duplicate a Project',
  newScenario: 'New scenario',
  searchByScenario: 'Search by scenario',
  newScenarioTrend: 'New Scenario Trend',
  newScenarioDecisionDriver: 'New Scenario Decision-Driver',
  addScenarioStakeholder: 'Add Scenario Stakeholder',
  stakeholders: 'Stakeholders',
  editStakeholder: 'Edit Stakeholder',
  addStakeholder: 'Add Stakeholder',
};
