import React from 'react';
import { useTranslation } from 'react-i18next';
import Tip from '../Tip/Tip';
import styles from './sass/M2Tips.module.scss';

const TipSOM = () => {
  const { t } = useTranslation();
  return (
    <Tip
      styleTipTextContainer={`${styles.container} ${styles.containerSOM}`}
      styleTriangle={styles.triangle}
    >
      <p>
        {t('tipM2SOM')}
      </p>
    </Tip>
  );
};

export default TipSOM;
