const chart = {
  scatterChart: {
    width: 560,
    height: 470,
    margin: {
      top: 10,
      left: 20,
      bottom: 20,
      right: 10
    },
    overflow: 'visible'
  },
  tooltip: {
    wrapperStyle: {
      outline: 'none'
    },
    cursor: false,
    isAnimationActive: false
  },
  axis: {
    stroke: 'rgba(194, 194, 194, 0.5)',
    tick: false
  },
  topNavigation: {
    color: 'rgba(113,81,95,0.4)'
  },
  rightNavigation: {
    color: 'rgba(81,94,113,0.5)'
  }
};

export default chart;
