import {
  SET_DASHBOARD_FAILED,
  SET_DASHBOARD_REQUEST,
  SET_DASHBOARD_SUCCESS,
  CLEAN_DASHBOARD,
} from './types';

const initialState = {
  marketsScheme: {},
  operationsScheme: {},
  trendsScheme: {},
  stakeholdersScheme: [],
  loading: false,
  error: null
};

export default function dashboard(state = initialState, action) {
  switch (action.type) {
    case SET_DASHBOARD_SUCCESS:
      return {
        ...state,
        marketsScheme: action.data.market,
        operationsScheme: action.data.operations,
        trendsScheme: action.data.trends,
        stakeholdersScheme: action.data.stakeholders,
        loading: false,
        error: null
      };
    case SET_DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SET_DASHBOARD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case CLEAN_DASHBOARD:
      return initialState;
    default:
      return state;
  }
}
