import React from 'react';
import PropTypes from 'prop-types';
import { convertFromIdToName } from '../../../utils/helpers';
import SelectInput from '../../../components/SelectInput/SelectInput';
import M5TipSegment from './M5TipSegment';
import styles from '../sass/M5.module.scss';

const M5Header = ({ segmentList, segmentId, onChangeSegment }) => {
  return (
    <div className={styles.headerWrapper}>
      <SelectInput
        styleContainer={styles.header}
        title=""
        onChange={(value) => onChangeSegment(value)}
        values={segmentList}
        value={convertFromIdToName(segmentList, segmentId)}
      />
      <M5TipSegment />
    </div>
  );
};

M5Header.propTypes = {
  segmentList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })).isRequired,
  segmentId: PropTypes.number.isRequired,
  onChangeSegment: PropTypes.func.isRequired
};

export default M5Header;
