import React, { useCallback, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useOnBlur from '../../../hooks/useOnBlur';
import { deleteSpacesInString } from '../../../utils/helpers';
import SelectInput from '../../SelectInput/SelectInput';
import rateOptions from '../../../constants/rateOptions';
import Input from '../../Input/Input';
import styles from '../sass/CoreMeaning.module.scss';

const CoreMeaningItem = ({ meaningsList, data, onChange }) => {
  const { t } = useTranslation();

  const [meaningItem, setMeaningItem] = useState(data);

  const { description, id } = data;

  const handleChangeMeaningItemDescription = useCallback((event) => {
    setMeaningItem({
      ...meaningItem,
      [event.target.name]: deleteSpacesInString(event.target.value)
    });
  }, [meaningItem]);

  const handleBlurMeaningItemDescription = useOnBlur(
    description || '',
    meaningItem.description || '',
    () => onChange(meaningItem),
    () => onChange(meaningItem),
  );

  useLayoutEffect(() => {
    setMeaningItem(data);
  }, [data]);

  return (
    <div className={styles.coreMeaning}>
      <div className={styles.coreMeaningSelectWrapper}>
        <SelectInput
          title={t('coreMeaning')}
          name="name"
          onChange={(name) => onChange({ ...meaningItem, name })}
          styleContainer={styles.coreMeaningSelectContainer}
          values={meaningsList}
          value={meaningItem.name}
        />
        <SelectInput
          title={t('rank')}
          name="value"
          onChange={(value) => onChange({ ...meaningItem, value })}
          styleContainer={styles.coreMeaningRateContainer}
          value={!id ? '' : meaningItem.value.toString()}
          values={rateOptions}
          checkId={false}
          disabled={!meaningItem.name}
        />
      </div>

      <div className={styles.coreMeaningTypeContainer}>
        <Input
          title={t('typeHere')}
          name="description"
          onChange={handleChangeMeaningItemDescription}
          style={styles.coreMeaningType}
          value={meaningItem.description || ''}
          onBlur={handleBlurMeaningItemDescription}
          disabled={!meaningItem.name}
        />
      </div>
    </div>
  );
};

CoreMeaningItem.propTypes = {
  meaningsList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    value: PropTypes.number,
    description: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired
};

CoreMeaningItem.defaultProps = {
  meaningsList: [],
  data: {
    name: '',
    value: 0,
    description: '',
    id: null,
  }
};

export default CoreMeaningItem;
