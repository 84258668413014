import React from 'react';
import { useTranslation } from 'react-i18next';
import TitleWithTip from '../../../components/TitleWithTip/TitleWithTip';
import Title from '../../../components/Title/Title';
import O1TipContent from './O1TipContent';
import styles from '../sass/O1.module.scss';

const O1Header = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.headerContainer}>
      <TitleWithTip
        text={t('marketOfferings')}
        styleContainer={styles.headerTitle}
        styleTitle={styles.title}
        styleTipTextContainer={styles.tipTextContainer}
        styleTriangle={styles.triangle}
      >
        <O1TipContent />
      </TitleWithTip>
      <Title
        text={t('operationalCapabilitiesNeeded')}
        Type="h4"
        style={styles.titleCapabilities}
      />
    </div>
  );
};

export default O1Header;
