import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { EMPTY_O1_ITEM } from '../../../constants/general';
import O1ItemCapabilityItem from './O1ItemCapabilityItem';
import styles from '../sass/O1.module.scss';

const O1ItemCapabilities = ({
  onChangeCapabilities,
  onDeleteCapabilities,
  operationalPriorities
}) => {
  const [newOperationalPriorities, setNewOperationalPriorities] = useState(false);

  const operationalPrioritiesList = useMemo(() => {
    return newOperationalPriorities
      ? [...operationalPriorities, EMPTY_O1_ITEM]
      : operationalPriorities;
  }, [operationalPriorities, newOperationalPriorities]);

  return (
    <div className={styles.capabilitiesContainer}>

      {operationalPrioritiesList.map((item) => (
        <O1ItemCapabilityItem
          key={item.id}
          operationalPrioritiesTitle={item.title}
          operationalPrioritiesValue={item.value}
          operationalPrioritiesId={item.id}
          onDeleteCapabilities={onDeleteCapabilities}
          onChangeCapabilities={(values, operationalPrioritiesId) => {
            onChangeCapabilities(values, operationalPrioritiesId, () => setNewOperationalPriorities(false));
          }}
          onClickAdd={() => setNewOperationalPriorities(true)}
          disabled={newOperationalPriorities || operationalPrioritiesList.length > 9 || !item.id}
        />
      ))}
    </div>
  );
};

O1ItemCapabilities.propTypes = {
  onChangeCapabilities: PropTypes.func.isRequired,
  onDeleteCapabilities: PropTypes.func.isRequired,
  operationalPriorities: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    value: PropTypes.number
  })).isRequired
};

export default O1ItemCapabilities;
