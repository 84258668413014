import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Tip from '../../../components/Tip/Tip';
import { videoSWOTAnalysis } from '../../../constants/general';
import styles from '../sass/M3.module.scss';

const M3TipLegend = ({ onOpenVideo }) => {
  const { t } = useTranslation();
  return (
    <Tip styleTipTextContainer={styles.tipTextLegendContainer} styleTriangle={styles.triangle}>
      <p>
        {t('tipM3Legend')}
        <button onClick={() => onOpenVideo(videoSWOTAnalysis)}>{` (${t('link')}).`}</button>
      </p>
    </Tip>
  );
};

M3TipLegend.propTypes = {
  onOpenVideo: PropTypes.func.isRequired,
};

export default M3TipLegend;
