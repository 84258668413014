import {
  CLEAN_ADMIN_SCENARIOS,
  SET_ADMIN_SCENARIOS_FAILED,
  SET_ADMIN_SCENARIOS_REQUEST,
  SET_ADMIN_SCENARIOS_SUCCESS,
} from './types';
import instance from '../../../services/axios';
import { ADMIN_SCENARIOS_URL } from '../../../constants/api';
import notify from '../../../utils/notify';
import { getSearchParams } from '../../../utils/helpers';

export const setAdminScenariosSuccess = (data, total) => ({ type: SET_ADMIN_SCENARIOS_SUCCESS, data, total });
export const setAdminScenariosRequest = () => ({ type: SET_ADMIN_SCENARIOS_REQUEST });
export const setAdminScenariosFailed = (error) => ({ type: SET_ADMIN_SCENARIOS_FAILED, error });

export const cleanAdminScenarios = () => ({ type: CLEAN_ADMIN_SCENARIOS });

export const getScenariosAction = (filterParam, setSearchParams) => {
  const getParams = new URLSearchParams({
    ...getSearchParams(filterParam)
  }).toString();
  return (dispatch) => {
    dispatch(setAdminScenariosRequest());
    instance.get(`${ADMIN_SCENARIOS_URL}?${getParams}`)
      .then(({ data: { data, meta: { total } } }) => {
        if (setSearchParams) setSearchParams(getParams);
        dispatch(setAdminScenariosSuccess(data, total));
      })
      .catch(({ response: { data } }) => {
        dispatch(setAdminScenariosFailed(data));
        notify('error', data.message);
      });
  };
};

export const deleteScenarioAction = (id, params, setSearchParams) => {
  return (dispatch) => {
    dispatch(setAdminScenariosRequest());
    instance.delete(`${ADMIN_SCENARIOS_URL}/${id}`)
      .then(() => dispatch(getScenariosAction(params, setSearchParams)))
      .catch(({ response: { data } }) => {
        dispatch(setAdminScenariosFailed(data));
        notify('error', data.message);
      });
  };
};

export const changeScenarioStatusAction = (id, status, scenarios, totalCount) => {
  return (dispatch) => {
    dispatch(setAdminScenariosRequest());
    instance.put(`${ADMIN_SCENARIOS_URL}/${id}/is-active`, { is_active: status })
      .then(() => {
        const newScenarios = scenarios.map((scenario) => (
          scenario.id === id ? { ...scenario, is_active: status } : scenario
        ));
        dispatch(setAdminScenariosSuccess(newScenarios, totalCount));
      })
      .catch(({ response: { data } }) => {
        dispatch(setAdminScenariosFailed(data));
        notify('error', data.message);
      });
  };
};
