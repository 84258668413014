import {
  CLEAN_EDIT_TREND,
  SET_ADMIN_TREND_EDIT_FAILED,
  SET_ADMIN_TREND_EDIT_INFLUENCE_SUCCESS,
  SET_ADMIN_TREND_EDIT_REQUEST,
  SET_ADMIN_TREND_EDIT_SUCCESS,
  SET_ADMIN_TREND_SUCCESS,
  SET_ADMIN_TREND_EDIT_SCENARIO_SUCCESS
} from './types';
import instance from '../../../services/axios';
import {
  INFLUENCES_URL,
  POST,
  PUT,
  ADMIN_TRENDS_URL,
  ADMIN_SCENARIOS_URL
} from '../../../constants/api';
import notify from '../../../utils/notify';

export const setEditTrendRequest = () => ({ type: SET_ADMIN_TREND_EDIT_REQUEST });
export const setEditTrendFailed = (error) => ({ type: SET_ADMIN_TREND_EDIT_FAILED, error });
export const setTrendEditInfluenceSuccess = (data) => ({ type: SET_ADMIN_TREND_EDIT_INFLUENCE_SUCCESS, data });
export const setEditTrendSuccess = () => ({ type: SET_ADMIN_TREND_EDIT_SUCCESS });
export const setAdminTrendSuccess = (data) => ({ type: SET_ADMIN_TREND_SUCCESS, data });
export const setScenarioSuccess = (data) => ({ type: SET_ADMIN_TREND_EDIT_SCENARIO_SUCCESS, data });
export const cleanEditTrends = () => ({ type: CLEAN_EDIT_TREND });

export const getTrendAction = (id, scenarioId) => {
  const url = scenarioId
    ? `${ADMIN_SCENARIOS_URL}/${scenarioId}/scenario_trends/${id}`
    : `${ADMIN_TRENDS_URL}/${id}`;
  return (dispatch) => {
    dispatch(setEditTrendRequest());
    instance.get(url)
      .then(({ data: { data } }) => dispatch(setAdminTrendSuccess(data)))
      .catch(({ response: { data } }) => {
        dispatch(setEditTrendFailed(data));
        notify('error', data.message);
      });
  };
};

export const getTrendEditInfluence = () => {
  return (dispatch) => {
    dispatch(setEditTrendRequest());
    instance.get(INFLUENCES_URL)
      .then(({ data: { data } }) => dispatch(setTrendEditInfluenceSuccess(data)))
      .catch(({ response: { data } }) => {
        dispatch(setEditTrendFailed(data));
        notify('error', data.message);
      });
  };
};

export const editTrendAction = (values, id, navigate, scenarioId) => {
  const editTrend = {
    name: values.trend,
    trend_type_id: values.category,
    influence_id: values.influence,
    industry_id: values.industry,
    value: +values.rank,
    trend_message: values.message
  };
  const urlPost = scenarioId
    ? `${ADMIN_SCENARIOS_URL}/${scenarioId}/scenario_trends`
    : ADMIN_TRENDS_URL;
  const urlPut = scenarioId
    ? `${ADMIN_SCENARIOS_URL}/${scenarioId}/scenario_trends/${id}`
    : `${ADMIN_TRENDS_URL}/${id}`;
  return (dispatch) => {
    dispatch(setEditTrendRequest());
    instance[id ? PUT : POST](id ? urlPut : urlPost, editTrend)
      .then(() => {
        dispatch(setEditTrendSuccess());
        notify('success', 'Success');
        navigate(-1);
      })
      .catch(({ response: { data } }) => {
        dispatch(setEditTrendFailed(data));
        notify('error', data.message);
      });
  };
};

export const getEditTrendScenarioAction = (scenarioId) => {
  return (dispatch) => {
    dispatch(setEditTrendRequest());
    instance.get(`${ADMIN_SCENARIOS_URL}/${scenarioId}`)
      .then(({ data: { data } }) => dispatch(setScenarioSuccess(data)))
      .catch(({ response: { data } }) => {
        dispatch(setEditTrendFailed(data));
        notify('error', data.message);
      });
  };
};
