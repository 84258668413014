import React from 'react';
import PropTypes from 'prop-types';
import M7OfferingItem from './M7OfferingItem';
import styles from '../sass/M7.module.scss';

const M7Offering = ({ offerings, onChangeOffering }) => {
  return (
    <div className={styles.offeringContainer}>
      {offerings.map((offering) => (
        offering.message_for_offering && (
          <M7OfferingItem
            key={offering.id}
            message={offering.message_for_offering}
            messageValue={offering?.message_value || 0}
            onChangeOffering={(values) => onChangeOffering(offering.id, values)}
          />
        )
      ))}
    </div>
  );
};

M7Offering.propTypes = {
  offerings: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    message_for_offering: PropTypes.string,
    message_value: PropTypes.number,
  })).isRequired,
  onChangeOffering: PropTypes.func.isRequired
};

export default M7Offering;
