import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SupplyChainRow from './SupplyChainRow';
import styles from '../sass/Dashboard.module.scss';

const SupplyChain = ({
  dataSupplyChainFirst,
  dataSupplyChainSecond,
  dataSupplyChainThird,
  dataSupplyChainFourth,
  dataSupplyChainFifth,
  dataSupplyChainSixth,
  id,
  onChangeVisible
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.supplyContainer}>
      <div className={styles.name}>
        <h4>{t('stakeholderAndInfrastructure')}</h4>
      </div>
      <div className={styles.supplyRows}>
        <SupplyChainRow id={id} data={dataSupplyChainFirst} onChangeVisible={onChangeVisible} />
        <SupplyChainRow id={id} data={dataSupplyChainSecond} onChangeVisible={onChangeVisible} />
        <SupplyChainRow id={id} data={dataSupplyChainThird} onChangeVisible={onChangeVisible} />
        <SupplyChainRow id={id} data={dataSupplyChainFourth} onChangeVisible={onChangeVisible} />
        <SupplyChainRow id={id} data={dataSupplyChainFifth} onChangeVisible={onChangeVisible} />
        <SupplyChainRow id={id} data={dataSupplyChainSixth} onChangeVisible={onChangeVisible} />
      </div>
      <div className={styles.stakeholdersBlock} />
    </div>
  );
};

SupplyChain.propTypes = {
  dataSupplyChainFirst: PropTypes.arrayOf(PropTypes.shape({
    isEmpty: PropTypes.bool,
    title: PropTypes.string,
    link: PropTypes.string,
    name: PropTypes.string,
    style: PropTypes.shape({
      backgroundColor: PropTypes.string,
      borderRadius: PropTypes.string,
      letterSpacing: PropTypes.string,
    })
  })).isRequired,
  dataSupplyChainSecond: PropTypes.arrayOf(PropTypes.shape({
    isEmpty: PropTypes.bool,
    title: PropTypes.string,
    link: PropTypes.string,
    name: PropTypes.string,
    style: PropTypes.shape({
      backgroundColor: PropTypes.string,
      borderRadius: PropTypes.string,
      letterSpacing: PropTypes.string,
    })
  })).isRequired,
  dataSupplyChainThird: PropTypes.arrayOf(PropTypes.shape({
    isEmpty: PropTypes.bool,
    title: PropTypes.string,
    link: PropTypes.string,
    name: PropTypes.string,
    style: PropTypes.shape({
      backgroundColor: PropTypes.string,
      borderRadius: PropTypes.string,
      letterSpacing: PropTypes.string,
    })
  })).isRequired,
  dataSupplyChainFifth: PropTypes.arrayOf(PropTypes.shape({
    isEmpty: PropTypes.bool,
    title: PropTypes.string,
    link: PropTypes.string,
    name: PropTypes.string,
    style: PropTypes.shape({
      backgroundColor: PropTypes.string,
      borderRadius: PropTypes.string,
      letterSpacing: PropTypes.string,
    })
  })).isRequired,
  dataSupplyChainFourth: PropTypes.arrayOf(PropTypes.shape({
    isEmpty: PropTypes.bool,
    title: PropTypes.string,
    link: PropTypes.string,
    name: PropTypes.string,
    style: PropTypes.shape({
      backgroundColor: PropTypes.string,
      borderRadius: PropTypes.string,
      letterSpacing: PropTypes.string,
    })
  })).isRequired,
  dataSupplyChainSixth: PropTypes.arrayOf(PropTypes.shape({
    isEmpty: PropTypes.bool,
    title: PropTypes.string,
    link: PropTypes.string,
    name: PropTypes.string,
    style: PropTypes.shape({
      backgroundColor: PropTypes.string,
      borderRadius: PropTypes.string,
      letterSpacing: PropTypes.string,
    })
  })).isRequired,
  id: PropTypes.string.isRequired,
  onChangeVisible: PropTypes.func.isRequired
};

export default SupplyChain;
