import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from '../sass/ProjectCard.module.scss';
import AddLogo from '../../../assets/icons/addLogo.svg';

const ProjectAddCard = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <div
      role="presentation"
      className={`${styles.container} ${styles.containerAddCard}`}
      onClick={onClick}
    >
      <div className={`${styles.imgContainer} ${styles.addCardImg}`}>
        <img src={AddLogo} alt="logo" />
      </div>
      <div className={styles.text}>{t('addProject')}</div>
    </div>
  );
};

ProjectAddCard.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default ProjectAddCard;
