import instance from '../../services/axios';
import {
  SET_ORGANIZATION_AVATAR_SUCCESS,
  SET_ORGANIZATION_WORK_SHEET_NAME_SUCCESS,
  SET_ORGANIZATION_WORK_SHEET_MEANING_SUCCESS,
  SET_ORGANIZATION_WORK_SHEET_LINKS_SUCCESS,
  SET_ORGANIZATION_WORK_SHEET_DRIVERS_SUCCESS,
  SET_ORGANIZATION_WORK_SHEET_TRIGGERS_SUCCESS,
  SET_ORGANIZATION_WORK_SHEET_SUCCESS,
  SET_ORGANIZATION_REQUEST,
  SET_ORGANIZATION_FAILED,
  CLEAN_ORGANIZATION,
} from './types';
import {
  DRIVERS_URL,
  LAST_WORK_SHEET_URL,
  MEANING_URL,
  POST,
  PUT,
  TRIGGERS_URL,
  WORK_SHEET_LINK_URL,
  WORK_SHEET_URL,
} from '../../constants/api';
import notify from '../../utils/notify';
import { assignFile } from '../general/actions';

export const setOrganizationRequest = () => ({ type: SET_ORGANIZATION_REQUEST });
export const setOrganizationFailed = (error) => ({ type: SET_ORGANIZATION_FAILED, error });
export const cleanOrganization = () => ({ type: CLEAN_ORGANIZATION });

export const setOrganizationWorkSheetNameSuccess = (data) => ({ type: SET_ORGANIZATION_WORK_SHEET_NAME_SUCCESS, data });

export const setOrganizationWorkSheetSuccess = (data) => ({ type: SET_ORGANIZATION_WORK_SHEET_SUCCESS, data });

export const setOrganizationWorkSheetMeaningSuccess = (data) => ({
  type: SET_ORGANIZATION_WORK_SHEET_MEANING_SUCCESS, data
});

export const setOrganizationAvatarSuccess = (data) => ({ type: SET_ORGANIZATION_AVATAR_SUCCESS, data });

export const setOrganizationWorkSheetLinksSuccess = (data) => ({
  type: SET_ORGANIZATION_WORK_SHEET_LINKS_SUCCESS, data
});

export const setOrganizationWorkSheetDriversSuccess = (data) => ({
  type: SET_ORGANIZATION_WORK_SHEET_DRIVERS_SUCCESS, data
});

export const setOrganizationWorkSheetTriggersSuccess = (data) => ({
  type: SET_ORGANIZATION_WORK_SHEET_TRIGGERS_SUCCESS, data
});

export const updateOrganizationWorkSheetNameAction = (value) => {
  return (dispatch, getState) => {
    const { organization: { id } } = getState();
    dispatch(setOrganizationRequest());
    instance.put(`${WORK_SHEET_URL}/${id}`, { full_name: value })
      .then(() => dispatch(setOrganizationWorkSheetNameSuccess(value)))
      .catch(({ response: { data } }) => {
        dispatch(setOrganizationFailed(data));
        notify('error', data.message);
      });
  };
};

export const createOrganizationAction = (idProject) => {
  return (dispatch) => {
    dispatch(setOrganizationRequest());
    instance.post(`${WORK_SHEET_URL}/organisation`, {
      full_name: 'Default name',
      project_id: +idProject,
    })
      .then(({ data: { data } }) => dispatch(setOrganizationWorkSheetSuccess(data)))
      .catch(({ response: { data } }) => {
        dispatch(setOrganizationFailed(data));
        notify('error', data.message);
      });
  };
};

export const getOrganizationWorkSheetAction = (idProject) => {
  return (dispatch) => {
    dispatch(setOrganizationRequest());
    instance.get(`${LAST_WORK_SHEET_URL}/${idProject}/organisation`)
      .then(({ data: { data } }) => {
        if (data?.id) {
          dispatch(setOrganizationWorkSheetSuccess(data));
        } else dispatch(createOrganizationAction(idProject));
      })
      .catch(({ response: { data } }) => {
        dispatch(setOrganizationFailed(data));
        notify('error', data.message);
      });
  };
};

export const updateOrganizationMeaningAction = (values) => {
  return (dispatch, getState) => {
    const {
      id, name, value, description
    } = values;
    const { organization: { meanings, id: idWorkSheep } } = getState();
    dispatch(setOrganizationRequest());
    instance[id ? PUT : POST](id ? `${MEANING_URL}/${id}` : MEANING_URL, {
      meaning_id: Number.isNaN(+name) ? undefined : +name,
      worksheet_id: +idWorkSheep,
      value: +value,
      description
    })
      .then(({ data: { data } }) => {
        const newMeaning = {
          id: data.id,
          description: data.description,
          name: data.meaning.name,
          value: data.value,
          worksheet_id: data.worksheet_id
        };
        if (id) {
          const newMeanings = meanings.map((item) => (item.id === id ? newMeaning : item));
          dispatch(setOrganizationWorkSheetMeaningSuccess(newMeanings));
        } else dispatch(setOrganizationWorkSheetMeaningSuccess([...meanings, newMeaning]));
      })
      .catch(({ response: { data } }) => {
        dispatch(setOrganizationFailed(data));
        notify('error', data.message);
      });
  };
};

export const uploadOrganizationPhotoAction = (data) => {
  return (dispatch, getState) => {
    const { organization: { id } } = getState();
    dispatch(setOrganizationRequest());
    assignFile(data.id, 'Worksheet', id, 'avatar')
      .then(() => {
        dispatch(setOrganizationAvatarSuccess(data));
      })
      .catch(({ response: { data: error } }) => {
        dispatch(setOrganizationFailed(error));
        notify('error', error.message);
      });
  };
};

export const updateOrganizationLinkAction = (values) => {
  return (dispatch, getState) => {
    const { id, url } = values;
    const { organization: { id: idWorkSheet } } = getState();
    dispatch(setOrganizationRequest());
    if (id) {
      instance.delete(`${WORK_SHEET_LINK_URL}/${id}`)
        .then(() => {
          instance.post(WORK_SHEET_LINK_URL, { url, worksheet_id: idWorkSheet })
            .then(({ data: { data } }) => dispatch(setOrganizationWorkSheetLinksSuccess([data])))
            .catch(({ response: { data } }) => {
              dispatch(setOrganizationFailed(data));
              notify('error', data.message);
            });
        })
        .catch(({ response: { data } }) => {
          dispatch(setOrganizationFailed(data));
          notify('error', data.message);
        });
    } else {
      instance.post(WORK_SHEET_LINK_URL, { url, worksheet_id: idWorkSheet })
        .then(({ data: { data } }) => dispatch(setOrganizationWorkSheetLinksSuccess([data])))
        .catch(({ response: { data } }) => {
          dispatch(setOrganizationFailed(data));
          notify('error', data.message);
        });
    }
  };
};

export const deleteOrganizationLinkAction = (values) => {
  return (dispatch) => {
    const { id } = values;
    dispatch(setOrganizationRequest());
    instance.delete(`${WORK_SHEET_LINK_URL}/${id}`)
      .then(() => dispatch(setOrganizationWorkSheetLinksSuccess([])))
      .catch(({ response: { data } }) => {
        dispatch(setOrganizationFailed(data));
        notify('error', data.message);
      });
  };
};

const deleteOrganizationDriverAction = (idDriver) => {
  return (dispatch, getState) => {
    const { organization: { drivers } } = getState();
    dispatch(setOrganizationRequest());
    instance.delete(`${DRIVERS_URL}/${idDriver}`)
      .then(() => {
        const newDrivers = drivers.filter((item) => (item.id !== idDriver));
        dispatch(setOrganizationWorkSheetDriversSuccess(newDrivers));
      })
      .catch(({ response: { data: error } }) => {
        dispatch(setOrganizationFailed(error));
        notify('error', error.message);
      });
  };
};

export const updateOrganizationDriverAction = (values, type) => {
  const { name, value, id } = values;
  return (dispatch, getState) => {
    const { organization: { drivers, id: idWorkSheet } } = getState();
    if (id && !name) {
      dispatch(deleteOrganizationDriverAction(id));
    } else {
      dispatch(setOrganizationRequest());
      instance[id ? PUT : POST](id ? `${DRIVERS_URL}/${id}` : DRIVERS_URL, {
        name,
        value: +value,
        type,
        worksheet_id: idWorkSheet
      })
        .then(({ data: { data } }) => {
          if (id) {
            const newDrivers = drivers.map((item) => (item.id === id ? data : item));
            dispatch(setOrganizationWorkSheetDriversSuccess(newDrivers));
          } else {
            dispatch(setOrganizationWorkSheetDriversSuccess([...drivers, data]));
          }
        })
        .catch(({ response: { data: error } }) => {
          dispatch(setOrganizationFailed(error));
          notify('error', error.message);
        });
    }
  };
};

export const deleteOrganizationTriggerAction = (idTrigger) => {
  return (dispatch, getState) => {
    const { organization: { triggers } } = getState();
    dispatch(setOrganizationRequest());
    instance.delete(`${TRIGGERS_URL}/${idTrigger}`)
      .then(() => {
        const newTriggers = triggers.filter((item) => (item.id !== idTrigger));
        dispatch(setOrganizationWorkSheetTriggersSuccess(newTriggers));
      })
      .catch(({ response: { data: error } }) => {
        dispatch(setOrganizationFailed(error));
        notify('error', error.message);
      });
  };
};

export const updateOrganizationTriggerAction = (values, type) => {
  const { id, description } = values;
  return (dispatch, getState) => {
    const { organization: { id: idWorkSheet, triggers } } = getState();
    if (id && !description) {
      dispatch(deleteOrganizationTriggerAction(id));
    } else {
      dispatch(setOrganizationRequest());
      instance[id ? PUT : POST](id ? `${TRIGGERS_URL}/${id}` : TRIGGERS_URL, {
        type, description, worksheet_id: idWorkSheet
      })
        .then(({ data: { data } }) => {
          if (id) {
            const newTriggers = triggers.map((item) => (item.id === id ? data : item));
            dispatch(setOrganizationWorkSheetTriggersSuccess(newTriggers));
          } else {
            dispatch(setOrganizationWorkSheetTriggersSuccess([...triggers, data]));
          }
        })
        .catch(({ response: { data: error } }) => {
          dispatch(setOrganizationFailed(error));
          notify('error', error.message);
        });
    }
  };
};
