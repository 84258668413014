import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { convertFromIdToNameChildren } from '../../../utils/helpers';
import SelectInput from '../../../components/SelectInput/SelectInput';
import styles from '../sass/O3.module.scss';

const O3StakeholderType = ({
  stakeholdersList,
  stakeholderTypeId,
  id,
  name,
  value,
  onChangePartnership,
  onSelectStakeholderType,
  onResetStakeholderPartnerList
}) => {
  const { t } = useTranslation();

  const handleChangeStakeholderType = useCallback((stakeholderId) => {
    if (id) {
      onChangePartnership(name, value, id, stakeholderId);
    } else {
      onSelectStakeholderType(stakeholderId);
      onResetStakeholderPartnerList();
    }
  }, [id, onResetStakeholderPartnerList]);

  return (
    <SelectInput
      onChange={(stakeholderId) => handleChangeStakeholderType(+stakeholderId)}
      styleContainer={styles.stakeholderTypesContainer}
      values={stakeholdersList}
      value={convertFromIdToNameChildren(stakeholdersList, stakeholderTypeId)}
      title={t('stakeholderTypePlaceholder')}
    />
  );
};

O3StakeholderType.propTypes = {
  stakeholdersList: PropTypes.arrayOf(PropTypes.shape(
    {
      id: PropTypes.number,
      name: PropTypes.string,
      children: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number
      }))
    }
  )),
  stakeholderTypeId: PropTypes.number,
  id: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.number,
  onChangePartnership: PropTypes.func.isRequired,
  onSelectStakeholderType: PropTypes.func.isRequired,
  onResetStakeholderPartnerList: PropTypes.func.isRequired
};

O3StakeholderType.defaultProps = {
  stakeholdersList: [],
  stakeholderTypeId: 0,
  id: 0,
  name: '',
  value: 0,
};

export default O3StakeholderType;
