import React from 'react';
import PropTypes from 'prop-types';
import M6OfferingItem from './M6OfferingItem';
import styles from '../sass/M6.module.scss';

const M6Offering = ({
  offerings,
  onChangeOffering,
  somQty,
  somAmount,
  onChangeOfferingSOM,
  onDeleteOffering,
  somPercentage,
  tamQty,
  tamAmount
}) => {
  return (
    <div className={styles.offeringContainer}>
      {offerings.map((offering) => (
        <M6OfferingItem
          key={offering.id}
          title={offering.title}
          value={offering.value}
          offeringSomCurrency={offering.som_currency || undefined}
          offeringSomPercentage={offering.som_percentage}
          somQty={somQty}
          somAmount={somAmount}
          somPercentage={somPercentage}
          tamQty={tamQty}
          tamAmount={tamAmount}
          projectedIncreaseId={offering.projected_increase_id}
          onChangeOffering={(values) => onChangeOffering(offering.id, values)}
          onChangeOfferingSOM={(values) => onChangeOfferingSOM(offering.id, values)}
          onDeleteOffering={() => onDeleteOffering(offering.id)}
        />
      ))}
    </div>
  );
};

M6Offering.propTypes = {
  offerings: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.number,
    projected_increase_id: PropTypes.number,
    som_currency: PropTypes.string,
    som_percentage: PropTypes.number,
    id: PropTypes.number
  })).isRequired,
  somQty: PropTypes.number.isRequired,
  somAmount: PropTypes.number.isRequired,
  onChangeOffering: PropTypes.func.isRequired,
  onChangeOfferingSOM: PropTypes.func.isRequired,
  onDeleteOffering: PropTypes.func.isRequired,
  somPercentage: PropTypes.number.isRequired,
  tamQty: PropTypes.number.isRequired,
  tamAmount: PropTypes.number.isRequired
};

export default M6Offering;
