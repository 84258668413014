import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { convertFromIdToName } from '../../../utils/helpers';
import useOutsideClick from '../../../hooks/useOutSideClick';
import M4ChartCustomLabelItem from './M4ChartCustomLabelItem';
import styles from '../sass/M4.module.scss';

import Arrow from '../../../assets/icons/ArrowDownChart.svg';

const M4ChartCustomLabelX = ({ driverXId, driversList, onChange }) => {
  const { t } = useTranslation();
  const [openList, setOpenList] = useState(false);
  const handleChangeOpenList = useCallback(() => {
    setOpenList((prev) => !prev);
  }, []);

  const ref = useOutsideClick(handleChangeOpenList, !openList);

  return (
    <foreignObject style={{ overflow: 'visible' }} x={90} y={445} width={410} height={20}>
      <div ref={ref} className={styles.customLabelXContainer}>
        <div role="presentation" onClick={handleChangeOpenList} className={styles.labelContainer}>
          <div className={styles.driver}>
            {`${t('decisionDriver')} ${driverXId ? convertFromIdToName(driversList, driverXId) : ''}`}
          </div>
          <img src={Arrow} alt="" />
        </div>

        <div className={`${styles.driversList} ${openList && styles.openDriversList}`}>
          <div className={styles.driversContainer}>
            {driversList.map(({ id, name }) => (
              <M4ChartCustomLabelItem
                key={id}
                name={name}
                active={driverXId === id}
                onClick={() => {
                  onChange(id);
                  handleChangeOpenList();
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </foreignObject>
  );
};

M4ChartCustomLabelX.propTypes = {
  driverXId: PropTypes.number.isRequired,
  driversList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  onChange: PropTypes.func.isRequired
};

M4ChartCustomLabelX.defaultProps = {
  driversList: []
};

export default M4ChartCustomLabelX;
