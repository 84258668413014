import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import rateOptions from '../../../constants/rateOptions';
import SelectInput from '../../../components/SelectInput/SelectInput';
import styles from '../sass/M2.module.scss';

const M2MeaningItem = ({ data, meaningsList, onChange }) => {
  const { t } = useTranslation();
  const [meaning] = useState(data);

  const { name: initialName, id } = data;

  return (
    <div className={styles.itemContainer}>
      <SelectInput
        name="name"
        onChange={(name) => onChange({ ...meaning, name })}
        title={t('coreMeaning')}
        styleContainer={`${styles.itemSelectInput} ${styles.stakeholderSelectInput}`}
        styleDropdownContent={styles.itemDropDown}
        value={meaning.name}
        values={meaningsList}
        disabled={!initialName}
      />
      <SelectInput
        title={t('rank')}
        name="value"
        checkId={false}
        onChange={(value) => onChange({ ...meaning, value })}
        value={!id ? '' : meaning.value.toString()}
        values={rateOptions}
        styleContainer={styles.itemSelectInput}
        styleDropdownContent={styles.itemDropDown}
        disabled={!initialName}
      />
    </div>
  );
};

M2MeaningItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    worksheet_id: PropTypes.number,
    value: PropTypes.number,
    description: PropTypes.string
  }),
  meaningsList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  })),
  onChange: PropTypes.func.isRequired
};

M2MeaningItem.defaultProps = {
  data: {
    id: null,
    name: '',
    worksheet_id: null,
    value: 0,
    description: ''
  },
  meaningsList: []
};

export default memo(M2MeaningItem);
