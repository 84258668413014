import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import DashboardGuide from './DashboardGuide';
import styles from '../sass/Dashboard.module.scss';

const Market = ({
  data,
  title,
  id,
  guideTip,
  onGuideClose
}) => {
  const navigate = useNavigate();

  return (
    <div className={styles.row}>
      <div
        className={styles.name}
        style={{
          backgroundColor: '#AF8CB0'
        }}
      >
        <h4>{title}</h4>
      </div>
      {data.map((item) => (
        <div
          key={item.title}
          className={styles.itemContainer}
          style={{
            backgroundColor: item.style.backgroundColor,
            borderRadius: item.style.borderRadius
          }}
        >
          <button
            className={styles.item}
            style={{
              backgroundColor: item.style.backgroundColorItem,
            }}
            disabled={item.disabled}
            onClick={() => navigate(`/${id}${item.link}`)}
          >
            <span>{item.title}</span>
          </button>
          {guideTip && item?.guideText && (
            <DashboardGuide
              onGuideClose={onGuideClose}
              text={item.guideText}
            />
          )}
        </div>
      ))}
    </div>
  );
};

Market.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
    style: PropTypes.shape({
      backgroundColor: PropTypes.string,
      backgroundColorItem: PropTypes.string,
      borderRadius: PropTypes.string
    }),
    disabled: PropTypes.bool,
    guideText: PropTypes.string
  })).isRequired,
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  guideTip: PropTypes.bool.isRequired,
  onGuideClose: PropTypes.func.isRequired
};

export default Market;
