import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button/Button';
import styles from '../sass/O2.module.scss';

import { ReactComponent as Plus } from '../../../assets/icons/plusBlue.svg';

const O2Header = ({ onAddCompetitor, disabledAddCompetitor }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.header}>
      <Button
        text={t('addCompetitor')}
        icon={<Plus />}
        style={styles.buttonContainer}
        styleButton={styles.button}
        onClick={onAddCompetitor}
        disabled={disabledAddCompetitor}
      />
    </div>
  );
};

O2Header.propTypes = {
  onAddCompetitor: PropTypes.func.isRequired,
  disabledAddCompetitor: PropTypes.bool.isRequired
};

export default O2Header;
