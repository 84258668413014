import React, { useMemo, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { cleanM7, getSegmentsAction, changeOfferingAction } from '../../store/m7/actions';
import { getBreadcrumbs, getSwitchPages } from '../../utils/helpers';
import Page from '../../layout/Page/Page';
import NoData from '../../components/NoData/NoData';
import M7Item from './components/M7Item';
import styles from './sass/M7.module.scss';

const M7 = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    m7: { loading, segments },
    project: { name: projectName }
  } = useSelector(({ m7, project }) => ({ m7, project }), shallowEqual);

  const breadcrumbs = useMemo(
    () => getBreadcrumbs(projectName, `/${id}/dashboard`, 'Messaging'),
    [projectName, id]
  );

  const switchPages = useMemo(
    () => getSwitchPages(`/${id}/m6`, `/${id}/o1`),
    [id]
  );

  useEffect(() => {
    dispatch(getSegmentsAction(id));
    return () => {
      dispatch(cleanM7());
    };
  }, []);

  const handleChangeOffering = useCallback((idSegment, idOffer, values) => {
    dispatch(changeOfferingAction(idSegment, idOffer, values));
  }, []);

  const segmentsWithMessage = useMemo(() => {
    return segments.filter((segment) => segment.offerings.some((offering) => offering.message_for_offering));
  }, [segments]);

  return (
    <Page
      pageContainer={styles.container}
      breadcrumbs={breadcrumbs}
      switchPages={switchPages}
      loader={loading}
    >
      <div className={styles.contentContainer}>
        {segmentsWithMessage.length ? segmentsWithMessage.map(({
          id: idSegment,
          name,
          avatar,
          offerings
        }) => {
          const sortedOfferings = offerings.sort((a, b) => b.message_value - a.message_value);
          return (
            <M7Item
              key={idSegment}
              name={name}
              avatar={avatar?.url}
              offerings={sortedOfferings}
              onChangeOffering={(idOffer, values) => handleChangeOffering(idSegment, idOffer, values)}
            />
          );
        }) : (
          <NoData
            textButton={t('potentialMessagingForOfferingNeeds')}
            id={id}
            projectName={projectName}
            page="m5"
          />
        )}
      </div>
    </Page>
  );
};

export default M7;
