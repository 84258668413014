import React, { useCallback, useMemo, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { cleanScenario, editScenarioAction, getScenarioAction } from '../../../store/admin/adminEditScenario/actions';
import { SCENARIO } from '../../../constants/general';
import { editScenarioValidationSchema } from '../../../utils/validation';
import AdminPage from '../../../layout/admin/AdminPage/AdminPage';
import ScenarioBreadcrumbs from '../../../components/ScenarioBreadcumbs/ScenarioBreadcrumbs';
import Title from '../../../components/Title/Title';
import Input from '../../../components/Input/Input';
import Button from '../../../components/Button/Button';
import styles from './sass/AdminEditScenario.module.scss';

const AdminEditScenario = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    adminEditScenario: {
      loading, scenario: scenarioData
    },
  } = useSelector(({ adminEditScenario }) => ({ adminEditScenario }));

  const {
    handleSubmit,
    values: {
      name,
      description,
    },
    errors,
    handleChange,
    validateForm,
  } = useFormik({
    initialValues: {
      name: scenarioData?.name || '',
      description: scenarioData?.description || '',
    },
    onSubmit: (values) => validateForm(values).then(() => {
      dispatch(editScenarioAction(values, id, navigate));
    }),
    validationSchema: editScenarioValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true
  });

  useEffect(() => {
    if (id) {
      dispatch(getScenarioAction(id));
    }
    return () => {
      dispatch(cleanScenario());
    };
  }, [id]);

  const breadcrumbs = useMemo(() => [{
    name: scenarioData?.name || t('newScenario'),
    link: ''
  }], [id, scenarioData?.name]);

  const handleBackNavigation = useCallback(() => navigate(-1), []);

  return (
    <AdminPage pageContainer={styles.container} loader={loading} activeTab={SCENARIO}>
      <div className={styles.content}>
        <ScenarioBreadcrumbs breadcrumbs={breadcrumbs} />
        <form onSubmit={handleSubmit}>
          <Title text={id ? t('editScenario') : t('addScenario')} style={styles.title} />

          <Input
            style={styles.inputContainer}
            onChange={handleChange}
            name="name"
            title={t('scenario')}
            value={name}
            errorText={errors.name}
          />

          <Input
            style={styles.inputContainer}
            onChange={handleChange}
            name="description"
            title={t('description')}
            value={description}
            errorText={errors.description}
          />

          <div className={styles.buttonsContainer}>
            <Button
              text={t('cancel')}
              style={styles.buttonContainer}
              styleButton={styles.button}
              onClick={handleBackNavigation}
            />
            <Button
              text={t('save')}
              type="submit"
              style={styles.buttonContainer}
              styleButton={styles.button}
              onClick={handleSubmit}
            />
          </div>
        </form>
      </div>
    </AdminPage>
  );
};

export default AdminEditScenario;
