import {
  CLEAN_PROJECT,
  SET_PROJECT_FAILED,
  SET_PROJECT_REQUEST,
  SET_PROJECT_SUCCESS,
  SET_PROJECT_GUIDE_TIP_SUCCESS
} from './types';

const initialState = {
  id: null,
  name: '',
  avatar: '',
  firstTip: true,
  secondTip: true,
  loading: false,
  error: null
};

export default function project(state = initialState, action) {
  switch (action.type) {
    case SET_PROJECT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case SET_PROJECT_SUCCESS:
      return {
        ...state,
        id: action.data.id,
        name: action.data.name,
        firstTip: action.data.firstTip,
        secondTip: action.data.secondTip,
        loading: false,
        error: null
      };
    case SET_PROJECT_GUIDE_TIP_SUCCESS:
      return {
        ...state,
        [action.tip]: true
      };
    case SET_PROJECT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case CLEAN_PROJECT:
      return initialState;
    default:
      return state;
  }
}
